import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDispatch, useSelector } from 'react-redux';
import { addHealthAndSafety, adminActions, deleteHealth, fetchCustomer, fetchHealthAndSafety, signOut } from '../store/adminSlice';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderScaff from '../components/HeaderScaff';
import escapeHtml from '../functions/Validator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import URL from '../functions/URLString';
import $  from 'jquery'
$.DataTable = require('datatables.net')

function HealthAndsafety() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(false);
  const dispatch=useDispatch();
  const newCustomer=useSelector((state)=>state.admin.newCustomer);

  const stateSwalAlert = useSelector((state) => state.admin.swalAlert);
  const stateHealthAndSafety=useSelector((state)=>state.admin.healthAndSafety)
  const [documents,setDocument]=useState(stateHealthAndSafety)
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]=useState({customer:""})
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
    useEffect(() => {
      dispatch(adminActions.removeSwalAlert())
      dispatch(fetchCustomer({ adminID: _id }))
      dispatch(fetchHealthAndSafety({ adminID: _id }))
      setTimeout(() => {
        $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
        
      }, 5000);
      
   
    }, [])
  
    useEffect(() => {
      if(!_id){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
        
      }
    },[])
  useEffect(() => {
  console.log(stateHealthAndSafety)
},[stateHealthAndSafety])
 
    useEffect(() => {
      console.log("hello world"); 
      if(stateSwalAlert==="added"){
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'success',
          confirmButtonColor:"#A7B778",
          text: 'Job Added Successfully! ',
        }).then(()=>{
        dispatch(adminActions.removeSwalAlert())
        window.location.reload(true)
      })
    }
    },[stateSwalAlert]);

    const submit=(e)=>{
      e.preventDefault();
      setLoading(true);

    if (
      data.customer === "" ||
     
      documents.length === 0 
    ) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly fill all the inputs & Upload logo/documents.!",
      });
    } else {
      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        formData.append("companyDocument", documents[i]);
      }
      formData.append("customer", data.customer);
    
      formData.append("adminID", _id);
      console.log(formData);
      dispatch(addHealthAndSafety(formData));
    }
  };

  useEffect(() => {
    console.log("hello world");
    if (!localStorage.getItem("firstLoad")) {
      console.log("hello world2");
      localStorage["firstLoad"] = true;
      // window.location.reload();
    } else {
      localStorage.removeItem("firstLoad");
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  }, []);
  const deleteItem = (id) => {
    dispatch(deleteHealth({_id:id,adminID:_id}))
  }

  return (
    <div className='w-full h-full'>
      {/* <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24'> */}
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        {/* <Logo/>
        <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
          <FourButtonsTwo/>
        </div>
      </header> */}
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full flex flex-col": "hidden" : "w-full flex flex-col" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4'>Health & Safety</p>
          
            <form  className='lg:flex lg:flex-row flex-col' onSubmit={submit}>
           <div className='lg:basis-1/2 w-full'>
           
           <div className='p-2 flex flex-col w-full '>
           <div className="lg:basis-1/2 w-full">
                <div className="p-2 flex flex-col w-full ">
                  <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                    Select Customer
                  </p>
                  <select
                    name="cars"
                    onChange={(e) =>
                      setData({ ...data, customer: e.target.value })
                    }
                    className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                    id="cars"
                  >
                    <option value="" className="text-xs xxl:text-xl">
                      Select Customer
                    </option>
                    {newCustomer.map((c) => {
                      return (
                        <option value={c._id} className="text-xs xxl:text-xl">
                          {c.customerCompanyName}
                        </option>
                      );
                    })}
                    </select>
                    <div className='flex w-full mt-4 h-fit flex-col sm:flex-row items-center'>
                    <div className='p-2 flex flex-col sm:flex-row w-full ml-0  items-center justify-between '>
                   <div className='flex flex-col w-fit justify-center whitespace-nowrap'>
                    <p className='pl-0 p-1 pt-0 font-semibold text-sm xxl:text-xl'>Upload your health & safety documents</p>
                    <p className='text-xs xxl:text-base'>i.e RAMS, METHOD, STATEMENT, etc</p>
                  </div>
                  <div className='flex w-full space-x-2 sm:ml-2 justify-center py-3 sm:py-0 sm:justify-end'>
                  <label htmlFor="file-upload" className='w-24 h-8 xxl:w-32 xxl:h-10 self-end items-center flex justify-center text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>
                    <input className='absolute -ml-5 opacity-0'  onChange={(e)=>setDocument(e.target.files)}  style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="file" required/>Select
                  </label>
                  <label htmlFor="file-upload" className='w-24 h-8 xxl:w-32 xxl:h-10 self-end rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>
                      <input className='absolute -ml-5 opacity-0 cursor-pointer'  style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="submit" required/>Submit
                  </label>
                  </div>
                </div>
              </div>
            </div>
                </div>
                
              </div>
              
            </div>
            <div className="p-2 flex-col w-full">
                 <div className='lg:basis-1/2 w-full'>
              <div className='flex w-full pl-2 xl:pl-10 h-fit flex-col sm:flex-row items-center'>
                <p className='hidden sm:flex opacity-0 rounded-full bg-lightgreen h-fit flex-shrink-0 p-1 sm:p-2 w-10 sm:w-12 xxl:w-[66px] xxl:p-4 xxl:text-3xl text-2xl text-white'><span className='ml-[10px]'>8</span></p>
                <div className='p-2 flex flex-col w-full ml-0 sm:ml-2 xl:ml-8 items-center justify-between'>
                  <div className='flex flex-col w-fit justify-center'>
                    <p className='pl-0 p-1 pt-0 font-semibold text-sm xxl:text-xl'>Extra Bonus</p>
                    <p className='text-xs xxl:text-base'>Download our ready made documents for you to add your logo edit to suit your use and upload them as your personal company documents</p>
                  </div>
                  <button className='w-24 h-8 xxl:w-32 xxl:h-10 mt-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>
                    <a href="./document/scafflod_mern.zip" download >
                      Download
                    </a>
                  </button>
                </div>
              </div>
                </div>
                </div>
       
            </form>
    
          <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2'>Health And Safety Documents</p>
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '   id="table">
             <thead className='w-full'>
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Customer Name</th>
             {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th> */}
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Document Name</th>

             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
           <tbody className=' '>
           {stateHealthAndSafety?
                  stateHealthAndSafety.map((data) => {
            var name= data.documents.split("-")
             return (
             <tr className=''>
             {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm text-center'>{data._id}</td> */}
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customer.customerCompanyName}</td>
                 <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'><a href={`${URL}/uploads/${data.documents}`}> {name[3]}  </a></td>
                
          
             <td className=' w-2/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                 
                <div onClick={(e) => deleteItem(data._id)} className='flex flex-col items-center'>
                <DeleteIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Delete</p>
                </div>                      
               </div>
             </td>
             </tr>
             )})
            :<></>
            }
</tbody>
          


</table>
         
</div>
       </div>
   </div>
</div>
  )
}

export default HealthAndsafety