import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import Sidebar from '../components/Sidebar'
// import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Logo from '../components/Logo';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { addANewVechile, adminActions, deleteVechicle, editVechicle, fetchVechicle, signOut } from '../store/adminSlice';
import FourButtonsTwo from '../components/FourButtonsTwo';
import HeaderScaff from '../components/HeaderScaff';
import escapeHtml from '../functions/Validator';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import $  from 'jquery'
$.DataTable = require('datatables.net')

function AddANewVechile() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const dispatch=useDispatch();
  const [ needed, setNeeded ] = useState(false);
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]= useState({vechicleMake:"",model:"",registration:"",MOTDate:"",roadTaxDate:"",currentMilage:"",adminID:_id})

  const newVechicle=useSelector((state)=>state.admin.newVechicle);
  const customerSwalAlert=useSelector((state)=>state.admin.customerSwalAlert);
  const [custommerDetails,setCustommerDetails]=useState(newVechicle)

  const [ edit, setEdit] = useState(false)
  const [editID,setEditID]= useState("")
  const [forEdit,setForEdit]= useState({vechicleMake:"",model:"",registration:"",MOTDate:"",roadTaxDate:"",currentMilage:"",adminID:_id,_id:""})
  const navigate = useNavigate()
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
  useEffect(() => {
    if(!_id){
      navigate("/signin")
    } else if (!subscribed) {
      navigate("/signin")
      
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  },[])
  const handleSubmit = (e) => {
    console.log("je;;;")
    e.preventDefault();

    if(data.vechicleMake === "" || data.model === "" || data.registration === "" || data.MOTDate === "" || data.roadTaxDate === "" || data.currentMilage === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Kindly fill all the inputs.!',
      })
      return;
    }
    dispatch(addANewVechile(data)).then(()=>{
      setData({vechicleMake:"",model:"",registration:"",MOTDate:"",roadTaxDate:"",currentMilage:"",adminID:_id});
    });
  }

  useEffect(() => {
    setCustommerDetails(newVechicle)
  }, [newVechicle])

  useEffect(() => {
    dispatch(fetchVechicle({adminID:_id}))
    setCustommerDetails(newVechicle)
    setTimeout(() => {
      $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      
    }, 5000);
  },[])

  useEffect(() => {
    if (customerSwalAlert==="emailDuplicated"){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Email already registered.!',
      }).then(() => {
        dispatch(adminActions.removeCustomerSwalAlert());
      })
    }
  },[customerSwalAlert])

  const deleteItem = (thtItem) => {
    var nn= {thtItem:thtItem,_id:thtItem._id,adminID:_id}
    dispatch(deleteVechicle(nn))
  };

  const editItem = (thtItem) => {
    setData({...data,vechicleMake:thtItem.vechicleMake,model:thtItem.model,registration:thtItem.registration,MOTDate:thtItem.MOTDate,roadTaxDate:thtItem.roadTaxDate,currentMilage:thtItem.currentMilage,adminID:_id});
    setEditID(thtItem._id);
    setEdit(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if(data.vechicleMake === "" || data.model === "" || data.registration === "" || data.MOTDate === "" || data.roadTaxDate === "" || data.currentMilage === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Kindly fill all the inputs.!',
      })
      return;
    }
    setForEdit({...forEdit,vechicleMake:data.vechicleMake,model:data.model,registration:data.registration,MOTDate:data.MOTDate,roadTaxDate:data.roadTaxDate,currentMilage:data.currentMilage,adminID:_id,_id:editID})
  }
  const onChangeMOTDate = e => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setData({ ...data, MOTDate: escapeHtml(newDate) });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };
  const onChangeRoadTaxDate = e => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setData({ ...data, roadTaxDate: escapeHtml(newDate) });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };
  const handleUpdateCancel = () =>{
    setEdit(false);
    setData({vechicleMake:"",model:"",registration:"",MOTDate:"",roadTaxDate:"",currentMilage:"",adminID:_id})
  }

  useEffect(() => {
    dispatch(editVechicle(forEdit));
    setData({ vechicleMake: "", model: "", registration: "", MOTDate: "", roadTaxDate: "", currentMilage: "", adminID: _id })
    setEdit(false);
  }, [forEdit])

  return (
    <div className='w-full h-full'>
      {/* <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24'> */}
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        {/* <Logo/>
        <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
          <FourButtonsTwo/>
        </div>
      </header> */}
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full flex flex-col": "hidden" : "w-full flex flex-col" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4'>Add Vehicle</p>
          
            <form onSubmit={handleSubmit} className='lg:flex lg:flex-row flex-col'>
           <div className='lg:basis-1/2 w-full'>
           
           <div className='p-2 flex flex-col w-full '>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Vehicle Make</p>
             <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.vechicleMake} onChange={(e)=>setData({...data,vechicleMake:escapeHtml(e.target.value)})} type="text" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Model</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.model} onChange={(e)=>setData({...data,model:escapeHtml(e.target.value)})} type="text" />
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Registration</p>
           <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' autoComplete="off" value={data.registration} onChange={(e)=>setData({...data,registration:escapeHtml(e.target.value)})} type="registration" />
          </div>
         </div>
     
         <div className='basis-1/2 flex-col flex  justify-between '>
            <div className='p-2 flex flex-col w-full '>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>MOT Date</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.MOTDate} onChange={(e)=>onChangeMOTDate(e)} type="date" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Road Tax Date</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.roadTaxDate} onChange={(e)=>onChangeRoadTaxDate(e)} type="date" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Current Milage</p>
             <div className='flex justify-between '>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 w-1/2 outline-none pl-1' value={data.currentMilage} onChange={(e)=>setData({...data,currentMilage:escapeHtml(e.target.value)})} type="text" required/>    
             {/* <button onClick={handleSubmit} className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen'>Upload</button> */}
             <div className='pl-2 w-full'>
                {edit?
                <>
                  <button className=' w-24 h-8 mr-3 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' type="submit" onClick={(e) => handleUpdate(e)}>Update</button>
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' onClick={handleUpdateCancel}>Cancel</button>
                </>
                :
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' type="submit" onSubmit={handleSubmit}>Save</button>                
                }
              </div>
             </div>
            </div>

         <div className=' flex content-between justify-between '>
           
             
             
          
         </div>
            </div>
       
            </form>
    
          <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2'>Vehicle List</p>
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '    id="table">
             <thead className='w-full'>
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Vehicle Make</th>
             {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th> */}
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>MOT Date</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Road Tax Date</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Registration</th>
           
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Model</th>
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Current Milage</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
           {custommerDetails?
                  custommerDetails.map((data) => {
                    const MOTDate = moment(new Date(data.MOTDate)).format('DD-MM-YYYY');
                    const roadTaxDate = moment(new Date(data.roadTaxDate)).format('DD-MM-YYYY');

             return (
             <tr className=''>
             {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm text-center'>{data._id}</td> */}
             <td className='w-2 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.vechicleMake}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[10px] xxl:text-[13px]'>{MOTDate}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{roadTaxDate}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.registration}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.model}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.currentMilage}</td>
             <td className=' w-2/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                 <div onClick={(e) => editItem(data)} className='flex flex-col items-center'>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Edit</p>
                </div>
                <div onClick={(e) => deleteItem(data)} className='flex flex-col items-center'>
                <DeleteIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Delete</p>
                </div>                      
               </div>
             </td>
             </tr>
             )})
            :<></>
            }
</tbody>

</table>
         
</div>
       </div>
   </div>
</div>
  )
}

export default AddANewVechile