import React, { useEffect } from "react";
import logoo from "../pictures/1.png";
import building from "../pictures/highresolution.png"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminActions } from "../store/adminSlice";
import { staffActions } from "../store/staffSlice";
import { customerActions } from "../store/customerSlice";

function MainVersionTwo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.removeSignout);
    dispatch(staffActions.removesignout);
    dispatch(customerActions.removesignout);
  }, []);
  return (
    <div className="w-full lg:h-screen   h-auto flex flex-col overflow-hidden">
      <header className="border-b-2 w-full lg:block flex justify-center pt-3 xxl:pt-8 px-8 sm:px-24 top-0">
        <img
          className="w-38 h-14 w-[280px]  h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
          src={logoo}
          alt="logo"
        />
      </header>
      <div className=" w-full lg:h-screen  h-auto z-20">
          <div className="flex-col items-center flex w-full mt-8 mb-4">
          <span className=" font-semibold mb-3">What our customer say</span>
          <span className="italic font-semibold ">"It's a game changer, we signed up</span>
          <span className="italic font-semibold">to eScaff and within minutes we realised </span>
          <span className="italic font-semibold">how organized everything was"</span>
          </div>
        <div className="h-full grid grid-cols-4">
          <div className="lg:col-span-1 col-span-4">
            <div className="flex justify-center items-center flex-col xxl:mt-32">
              <video width="200x" height="200px" controls muted loop autoPlay>
                <source src="./videos/intro.mp4" type="video/mp4" />
              </video>
              <button onClick={()=>navigate("/signup")} class="bg-transparent mt-8 hover:bg-lightgreen text-lightgreen-700 font-semibold hover:text-white py-2 border border-darkgray hover:border-transparent rounded-xl w-52 ">
            Free 30 Days Trial
              </button>
              <button onClick={()=>navigate("/signin")} class="bg-transparent mt-4 hover:bg-lightgreen text-lightgreen-700 font-semibold hover:text-white py-2  border border-darkgray hover:border-transparent rounded-xl w-52">
                Existing Customr Sign In
              </button>
              <button onClick={()=>(window.location.href = "https://tutorials.escaff.uk/")} class="bg-transparent mt-4 hover:bg-lightgreen text-lightgreen-700 font-semibold hover:text-white py-2  border border-darkgray hover:border-transparent rounded-xl w-52  ">
                Tutorials
              </button>
              <span className=" text-darkgray mt-8">
                tel: 07525 648263 email: admin@escaff.uk
              </span>
            </div>
          </div>
          <div className="lg:col-span-3 col-span-4 h-4/4 xxl:h-3/4 xxl:mb-72 mb-44 justify-end flex object-cover w-full lg:relative lg:bottom-28">

              <img src={building} className="" alt="building" />
         
          </div>
        </div>
      </div>
 
    </div>
  );
}

export default MainVersionTwo;
