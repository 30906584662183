import React, { useEffect, useState } from "react";
import logo from "../pictures/TheBestescafflogo.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "../components/Sidebar";
import FourButtons from "../components/FourButtons";
import HeaderScaff from "../components/HeaderScaff";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  fetchSingleescaffAdaptionqoute,
  fetchSingleescaffQoute,
  signOut,
} from "../store/adminSlice";
import axios from "../functions/axios";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import $ from "jquery";
import { fetchCompanyDetails ,  fetchPriceList} from "../store/customerSlice";
import URL from "../functions/URLString";
$.DataTable = require("datatables.net");

function CustomerEscaffScaffolldingQoutation() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [needed, setNeeded] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const escaffqouteid = queryParameters.get("escaffqouteid");
  const adaptionquoteid = queryParameters.get("adaptionquoteid");
  const dispatch = useDispatch();
  const _id = useSelector((state) => state.customer.customer_id)
  const statesignOut = useSelector((state) => state.admin.signOut);
  const statePriceList = useSelector((state) => state.customer.priceList)
  const statecompanyDetails = useSelector((state) => state.customer.companyLogo)
  
  const adminID = useSelector((state) => state.customer.adminID)
  const stateEscaffQoutation = useSelector(
    (state) => state.admin.escaffQoutation
  );
  const adaptionDetails = useSelector(
    (state) => state.admin.adaptionDetails
  );
  const stateEscaffAdaptionQoutation = useSelector(
    (state) => state.admin.escaffAdaptionQoutation
  );
 
  const [qouteResult, setQouteResult] = useState(0);

  const [data, setData] = useState(stateEscaffQoutation);
  const [data2, setData2] = useState(stateEscaffAdaptionQoutation);
  const [qouteData, setQouteData] = useState({
    gantryResult: 0,
    bayResult: 0,
    elevationResult: 0,
    deckResult: 0,
    hopResult: 0,
    saddleResult: 0,
    throwInResult: 0,
    hakkiLayherResult: 0,
    fivebyoneResult: 0,
    eightByFourResult: 0,
    tenResult: 0,
    thirteenResult: 0,
    additionalItemss: 0,
    nweek: 0,
    theVatPercentage: 0,
    orignalPercentage: 0,
    scaffoldingCost: 0,
  });
  const [qouteData2, setQouteData2] = useState({
    gantryResult: 0,
    bayResult: 0,
    elevationResult: 0,
    deckResult: 0,
    hopResult: 0,
    saddleResult: 0,
    throwInResult: 0,
    hakkiLayherResult: 0,
    fivebyoneResult: 0,
    eightByFourResult: 0,
    tenResult: 0,
    thirteenResult: 0,
    additionalItemss: 0,
    nweek: 0,
    theVatPercentage: 0,
    orignalPercentage: 0,
    scaffoldingCost: 0,
    adaptionDetails:""
  });
  const navigate = useNavigate();
  const print = () => {
    window.print();
}
  useEffect(() => {
  
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
    dispatch(adminActions.removeSwalAlert());
    dispatch(fetchSingleescaffQoute({ adminID: adminID, _id: escaffqouteid }));
    dispatch(
      fetchSingleescaffAdaptionqoute({ adminID: adminID, _id: adaptionquoteid })
    );
    dispatch(fetchPriceList({adminID:adminID,customerID:_id}))

    dispatch(fetchCompanyDetails({ adminID: adminID, customerID: _id }))
    console.log(adminID,"adminID")
    // handleQouteCalculation();
  }, []);
  useEffect(() => {
   
  }, [qouteData, setQouteData]);

  const handleQouteCalculation = () => {
  
    var WeeklyScaffoldingInspection =
      statePriceList.scaffoldingInspectionPerVisit;
    var elevationResult = data.dynamicOBJ.reduce(
      (sum, item) =>
        sum +
        item.elevationLength *
          item.elevationLifts *
          statePriceList.linearMerterageCost,
      0
    );

    var gantryResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data.gantryLength) *
      parseInt(data.gantryLift) *
      parseInt(data.gantryWidth);
      setQouteData({ ...qouteData, gantryResult: gantryResult });
    console.log(qouteData,"gantry check");
    var bayResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data.baylength) *
      parseInt(data.bayLift) *
      parseInt(data.bayWidth);
    console.log(bayResult, "bayresult");
    // setQouteData({ ...qouteData, bayResult });
    var deckResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data.deckLength) *
      parseInt(data.deckLift) *
      parseInt(data.deckWidth);
    // setQouteData({ ...qouteData, deckResult });
    var hopResult = parseInt(statePriceList.hopUpCost) * parseInt(data.hopUp);
    // setQouteData({ ...qouteData, hopResult });

    var saddleResult =
      parseInt(statePriceList.saddleCost) * parseInt(data.saddle);
    // setQouteData({ ...qouteData, saddleResult });
    var throwInResult =
      parseInt(statePriceList.throwinCost) * parseInt(data.throwIn);
    // setQouteData({ ...qouteData, throwInResult });
    var hakkiLayherResult =
      parseInt(statePriceList.hakkiLayher) * parseInt(data.hakkiStair);
    // setQouteData({ ...qouteData, hakkiLayherResult });
    var fivebyoneResult =
      parseInt(statePriceList.fivebyonepointeightTower) *
      parseInt(data.fiveFootTower) *
      parseInt(data.fiveFootTowerLift);
    // setQouteData({ ...qouteData, fivebyoneResult });
    var eightByFourResult =
      parseInt(statePriceList.eightbytwopointfourTower) *
      parseInt(data.eightFootTower) *
      parseInt(data.eightFootTowerLift);
    // setQouteData({ ...qouteData, hakkiLayherResult });
    var tenResult =
      parseInt(statePriceList.tenbythreeTower) *
      parseInt(data.tenFootTower) *
      parseInt(data.tenFootTowerLift);
    // setQouteData({ ...qouteData, tenResult });
    var thirteenResult =
      parseInt(statePriceList.thirteenbyfourTower) *
      parseInt(data.thirteenFootTower) *
      parseInt(data.thirteenFootTowerLift);
    // setQouteData({ ...qouteData, thirteenResult });
    var additionalItemss = 0;
    if (data.additionalItem.length !== 0) {
      additionalItemss = data.additionalItem.reduce(
        (sum, item) =>
          sum +
          parseInt(item.additionalItemCost) *
            parseInt(item.numberOfadditionalItem),
        0
      );
    }
    var nonpercentage =
      parseInt(data.streetLicenseCost) + parseInt(data.externalDesignCost);
    console.log(qouteData, "===qoute");
    var res = 0;
    res =
      res +
      elevationResult +
      gantryResult +
      bayResult +
      deckResult +
      hopResult +
      saddleResult +
      throwInResult +
      hakkiLayherResult +
      fivebyoneResult +
      eightByFourResult +
      tenResult +
      thirteenResult +
      additionalItemss;
    var scaffoldingCost = res
    setQouteData({ ...qouteData, scaffoldingCost: scaffoldingCost });


    var weeks = data.numberOfWeekRequired;
    var scaffoldingInspection = 0;
    if (data.WeeklyScaffoldingInspections) {
      var nweek = weeks - 1;
      var r1 = nweek * WeeklyScaffoldingInspection;
      res = res + r1;
      scaffoldingInspection = r1;
      console.log(scaffoldingInspection,"============sscaoggole")
    }
    var theVatPercentage =0
    if (data.customerVatRegisterSwitch) {
      var newresult =
        elevationResult +
        gantryResult +
        bayResult +
        deckResult +
        hopResult +
        saddleResult +
        throwInResult +
        hakkiLayherResult +
        fivebyoneResult +
        eightByFourResult +
        tenResult +
        thirteenResult +
        additionalItemss;
       theVatPercentage = (data.customerVatRegister / 100) * newresult;
      res = res + theVatPercentage;
    
    }
    var overhiredd=0
    if (data.numberOfWeekRequired > data.standardNumberOfWeek) {
      var percentageWeek = weeks - data.standardNumberOfWeek;

       var overhired = statePriceList.overhirePercentage * percentageWeek;

      var orignalPercentage = (overhired / 100) * res;
      overhiredd = orignalPercentage
      console.log(overhiredd,"====overhired")
      res = res + nonpercentage + orignalPercentage;
      setQouteResult(res);
   
      setQouteData({
        ...qouteData,
        gantryResult,
        bayResult,
        elevationResult,
        deckResult,
        hopResult,
        saddleResult,
        throwInResult,
        hakkiLayherResult,
        fivebyoneResult,
        eightByFourResult,
        tenResult,
        thirteenResult,
        additionalItemss,
        nweek,
        theVatPercentage,
        orignalPercentage,
        scaffoldingCost,
        overhiredd,
        scaffoldingInspection,
        
        
      });
      console.log(qouteData, "qoutedata");

    } else {
      res = res + nonpercentage;
      setQouteResult(res);
      setQouteData({
        ...qouteData,
        gantryResult,
        bayResult,
        elevationResult,
        deckResult,
        hopResult,
        saddleResult,
        throwInResult,
        hakkiLayherResult,
        fivebyoneResult,
        eightByFourResult,
        tenResult,
        thirteenResult,
        additionalItemss,
        nweek,
        theVatPercentage,
        orignalPercentage,
        scaffoldingCost,
        overhiredd,
        scaffoldingInspection,
        
      });
    }
    console.log(qouteData, "qoutedata");
    console.log(stateEscaffQoutation, "stateqoute");
  };
  const handleAdaptionCalculation = () => {
  
    var WeeklyScaffoldingInspection =
      statePriceList.scaffoldingInspectionPerVisit;
    var elevationResult = data2.dynamicOBJ.reduce(
      (sum, item) =>
        sum +
        item.elevationLength *
          item.elevationLifts *
          statePriceList.linearMerterageCost,
      0
    );

    var gantryResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data2.gantryLength) *
      parseInt(data2.gantryLift) *
      parseInt(data2.gantryWidth);
      setQouteData2({ ...qouteData2, gantryResult: gantryResult });
    console.log(gantryResult,"gantry check2222");
    var bayResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data2.baylength) *
      parseInt(data2.bayLift) *
      parseInt(data2.bayWidth);
    console.log(bayResult, "bayresult");
    // setQouteData({ ...qouteData, bayResult });
    var deckResult =
      parseInt(statePriceList.linearMerterageCost) *
      parseInt(data2.deckLength) *
      parseInt(data2.deckLift) *
      parseInt(data2.deckWidth);
    // setQouteData({ ...qouteData, deckResult });
    var hopResult = parseInt(statePriceList.hopUpCost) * parseInt(data2.hopUp);
    // setQouteData({ ...qouteData, hopResult });

    var saddleResult =
      parseInt(statePriceList.saddleCost) * parseInt(data2.saddle);
    // setQouteData({ ...qouteData, saddleResult });
    var throwInResult =
      parseInt(statePriceList.throwinCost) * parseInt(data2.throwIn);
    // setQouteData({ ...qouteData, throwInResult });
    var hakkiLayherResult =
      parseInt(statePriceList.hakkiLayher) * parseInt(data2.hakkiStair);
    // setQouteData({ ...qouteData, hakkiLayherResult });
    var fivebyoneResult =
      parseInt(statePriceList.fivebyonepointeightTower) *
      parseInt(data2.fiveFootTower) *
      parseInt(data2.fiveFootTowerLift);
    // setQouteData({ ...qouteData, fivebyoneResult });
    var eightByFourResult =
      parseInt(statePriceList.eightbytwopointfourTower) *
      parseInt(data2.eightFootTower) *
      parseInt(data2.eightFootTowerLift);
    // setQouteData({ ...qouteData, hakkiLayherResult });
    var tenResult =
      parseInt(statePriceList.tenbythreeTower) *
      parseInt(data2.tenFootTower) *
      parseInt(data2.tenFootTowerLift);
    // setQouteData({ ...qouteData, tenResult });
    var thirteenResult =
      parseInt(statePriceList.thirteenbyfourTower) *
      parseInt(data2.thirteenFootTower) *
      parseInt(data2.thirteenFootTowerLift);
    // setQouteData({ ...qouteData, thirteenResult });
    var additionalItemss = 0;
    if (data2.additionalItem.length !== 0) {
      additionalItemss = data2.additionalItem.reduce(
        (sum, item) =>
          sum +
          parseInt(item.additionalItemCost) *
            parseInt(item.numberOfadditionalItem),
        0
      );
    }
    var nonpercentage =
      parseInt(data2.streetLicenseCost) + parseInt(data2.externalDesignCost);
    console.log(qouteData2, "===qoute2");
    var res = 0;
    res =
      res +
      elevationResult +
      gantryResult +
      bayResult +
      deckResult +
      hopResult +
      saddleResult +
      throwInResult +
      hakkiLayherResult +
      fivebyoneResult +
      eightByFourResult +
      tenResult +
      thirteenResult +
      additionalItemss;
    var scaffoldingCost = res
    setQouteData2({ ...qouteData2, scaffoldingCost: scaffoldingCost });


    var weeks = data2.numberOfWeekRequired;
    var scaffoldingInspection = 0;
    if (data2.WeeklyScaffoldingInspections) {
      var nweek = weeks - 1;
      var r1 = nweek * WeeklyScaffoldingInspection;
      res = res + r1;
      scaffoldingInspection = r1;
      console.log(scaffoldingInspection,"============sscaoggole")
    }
    var theVatPercentage=0
    if (data2.customerVatRegisterSwitch) {
      var newresult =
        elevationResult +
        gantryResult +
        bayResult +
        deckResult +
        hopResult +
        saddleResult +
        throwInResult +
        hakkiLayherResult +
        fivebyoneResult +
        eightByFourResult +
        tenResult +
        thirteenResult +
        additionalItemss;
       theVatPercentage = (data2.customerVatRegister / 100) * newresult;
      res = res + theVatPercentage;
    
    }
    var overhiredd=0
    if (data2.numberOfWeekRequired > data2.standardNumberOfWeek) {
      var percentageWeek = weeks - data2.standardNumberOfWeek;

       var overhired = statePriceList.overhirePercentage * percentageWeek;

      var orignalPercentage = (overhired / 100) * res;
      overhiredd = orignalPercentage
      
      res = res + nonpercentage + orignalPercentage;
      setQouteResult(res);
   
      setQouteData2({
        ...qouteData2,
        gantryResult,
        bayResult,
        elevationResult,
        deckResult,
        hopResult,
        saddleResult,
        throwInResult,
        hakkiLayherResult,
        fivebyoneResult,
        eightByFourResult,
        tenResult,
        thirteenResult,
        additionalItemss,
        nweek,
        theVatPercentage,
        orignalPercentage,
        scaffoldingCost,
        overhiredd,
        scaffoldingInspection,
        adaptionDetails:"hi"

        
      });
      console.log("hi", "stateEscaffAdaptionQoutation.adaptionID.adaptionDetails");

    } else {
      res = res + nonpercentage;
      setQouteResult(res);
      setQouteData2({
        ...qouteData2,
        gantryResult,
        bayResult,
        elevationResult,
        deckResult,
        hopResult,
        saddleResult,
        throwInResult,
        hakkiLayherResult,
        fivebyoneResult,
        eightByFourResult,
        tenResult,
        thirteenResult,
        additionalItemss,
        nweek,
        theVatPercentage,
        orignalPercentage,
        scaffoldingCost,
        overhiredd,
        scaffoldingInspection,
        adaptionDetails:"hi"
        
      });
    }
    console.log("hi", "stateEscaffAdaptionQoutation.adaptionID.adaptionDetails");

    // setAdaptionDetails("hi")
  };

  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
    setData(stateEscaffQoutation);
    setData2(stateEscaffAdaptionQoutation)
  
    
    handleQouteCalculation();
    handleAdaptionCalculation();
    
 
 
    
  }, [statesignOut, stateEscaffQoutation, stateEscaffAdaptionQoutation,data,data2]);
  useEffect(() => {
   
    setData2(stateEscaffAdaptionQoutation)
    setData(stateEscaffQoutation)
    
    handleQouteCalculation();
    handleAdaptionCalculation();
    // setQouteData2({...qouteData2,adaptionDetails:stateEscaffAdaptionQoutation.adaptionID.adaptionDetails})

  }, [qouteData, setQouteData, data,data2, qouteData, setQouteData ,stateEscaffQoutation, stateEscaffAdaptionQoutation,statecompanyDetails]);
  return (
    <div className="w-full h-full">
      {/* <HeaderScaff setSidebarOpen={setSidebarOpen} /> */}
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        {/* <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div> */}

        {/* <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div> */}

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide"
                : "hidden"
              : "w-full overflow-x-scroll scrollbar-hide"
          } " "`}
        >
          <div className="flex flex-col  p-5">
          <div className="ml-4 w-full text-center"><span className="font-semibold text-lg">Quotation</span>
              </div>
            <div className="flex justify-between">
              <div className="ml-4"><span className=" ">To</span><br />
                <span>{data.customerID.customerCompanyName}</span><br/>
                <span>{data.customerID.address}</span><br/>
                <span>{data.customerID.postcode}</span><br/>
                <span>{data.customerID.telephone}</span><br/>
              </div>
              <div > <img src={`${URL}/uploads/${statecompanyDetails}`} className=" w-28 object-contain" alt="logo"/></div>
             
              <div className="mr-4"><span className="text-[11px] xxl:text-sm "></span><br />
                <span>{data.jobID.job1}</span><br/>
                <span>{data.jobID.job2}</span><br/>
                <span>{data.jobID.postCode}</span><br/>
              </div>
              
           </div>

            <div className="lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 ml-1">
              <table className="editor_listing_table " id="table">
                <thead className="w-full ">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base  text-white rounded-l-md font-normal border-[1px] border-r-white"></th>
                    {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job ID</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th> */}
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Quantity
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Cost
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Gantry
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data2.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {"£" + parseFloat(qouteData2.gantryResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Loading Bay
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.bayResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Hop ups
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.hopResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Saddles
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.saddleResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Throw in
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.throwInResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Hakki/lahyer stairs
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.hakkiLayherResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      5ft/1.6m tower
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.fivebyoneResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      8ft/2.4m tower
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.eightByFourResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      10ft/3m tower
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.tenResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      13ft/4m tower
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.thirteenResult).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Additional items
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {data.additionalItem.length}
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.additionalItemss).toFixed(2)}
            
                    </td>
                  </tr>
                  {data.additionalItem.map((items) => {
                    return (
                      <tr className="">
                      <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                        {items.additionalItemName}
                      </td>
                      {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
               <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                      <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {items.numberOfadditionalItem}                      </td>
                      <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {items.additionalItemCost}              
                      </td>
                    </tr>
                    )
                  })}
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Elevation cost
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.elevationResult).toFixed(2)}
                    </td>
                  </tr>
                 
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Scaffolding Cost
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {data.standardNumberOfWeek} number of weeks
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {"£" + parseFloat(qouteData.scaffoldingCost).toFixed(2)}

                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                     -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                     -

                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Extra Hire Cost 
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {data.numberOfWeekRequired-data.standardNumberOfWeek} number of week
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.overhiredd).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Over Hire Percentage
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      %{statePriceList.overhirePercentage}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
          -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                 -
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Sub Total
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                 {parseFloat(parseInt(qouteData.scaffoldingCost)+parseInt(qouteData.overhiredd)).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
           -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                 -
                    </td>
                  </tr> <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
           -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                     -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                 -
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Scaffold inspections
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.scaffoldingInspection).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Street license
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.streetLicenseCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      External Design
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      0
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.externalDesignCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                    
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      Sub Total
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(parseFloat(qouteData.scaffoldingCost)+parseFloat(qouteData.overhiredd)+parseFloat(qouteData.scaffoldingInspection)+parseFloat(data.streetLicenseCost)+parseFloat(data.externalDesignCost)).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                    
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      VAT
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.theVatPercentage).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                    
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      Your Total Qoutation
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.totalCostResult).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
         
            {adaptionquoteid !== "0" ? (
              <>
                  <div className="ml-4  text-center  w-full"><span className="font-semibold text-lg">Adaption</span>
               </div>
               <div className="flex justify-between">
               <div className="ml-4"><span className="">Adaption Details</span><br />
                 <span>{adaptionDetails}</span><br/>
                 
               </div>
              
               
            </div>
              
                 <div className="lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 ml-1">
                 <table className="editor_listing_table " id="table">
                   <thead className="w-full ">
                     <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                       <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base  text-white rounded-l-md font-normal border-[1px] border-r-white"></th>
                       {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job ID</th>
                <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th> */}
                       <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                         Quantity
                       </th>
                       <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                         Cost
                       </th>
                     </tr>
                   </thead>
                   <div className="h-2"></div>
                   <tbody className=" ">
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Gantry
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data2.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         {"£" + parseFloat(qouteData2.gantryResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Loading Bay
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.bayResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Hop ups
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.hopResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Saddles
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.saddleResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Throw in
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.throwInResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Hakki/lahyer stairs
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.hakkiLayherResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         5ft/1.6m tower
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.fivebyoneResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         8ft/2.4m tower
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.eightByFourResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         10ft/3m tower
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.tenResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         13ft/4m tower
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.thirteenResult).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Additional items
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         {data.additionalItem.length}
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.additionalItemss).toFixed(2)}
               
                       </td>
                     </tr>
                     {data2.additionalItem.map((items) => {
                       return (
                         <tr className="">
                         <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                           {items.additionalItemName}
                         </td>
                         {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                  <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                         <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         {items.numberOfadditionalItem}                      </td>
                         <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         {items.additionalItemCost}              
                         </td>
                       </tr>
                       )
                     })}
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Elevation cost
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.elevationResult).toFixed(2)}
                       </td>
                     </tr>
                    
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Scaffolding Cost
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         {data2.standardNumberOfWeek} number of weeks
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                       {"£" + parseFloat(qouteData2.scaffoldingCost).toFixed(2)}
   
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                        -
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        -
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                        -
   
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Extra Hire Cost 
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         {data2.numberOfWeekRequired-data2.standardNumberOfWeek} number of week
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(qouteData2.overhiredd).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Over Hire Percentage
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         %{statePriceList.overhirePercentage}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
            -
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        -
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    -
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Sub Total
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        -
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    {parseFloat(parseInt(qouteData2.scaffoldingCost)+parseInt(qouteData2.overhiredd)).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
            -
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        -
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    -
                       </td>
                     </tr> <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
            -
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                        -
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    -
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Scaffold inspections
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(qouteData2.scaffoldingInspection).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         Street license
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(data2.streetLicenseCost).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                         External Design
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         1
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(data2.externalDesignCost).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                       
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         Sub Total
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(parseFloat(qouteData2.scaffoldingCost)+parseFloat(qouteData2.overhiredd)+parseFloat(qouteData2.scaffoldingInspection)+parseFloat(data2.streetLicenseCost)+parseFloat(data2.externalDesignCost)).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                       
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         VAT
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(qouteData2.theVatPercentage).toFixed(2)}
                       </td>
                     </tr>
                     <tr className="">
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                       
                       </td>
                       {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
                <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                       <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                         Your Total Qoutation
                       </td>
                       <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                         £{parseFloat(data2.totalCostResult).toFixed(2)}
                       </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
               </> ):""}
          </div>
          <div className="flex justify-center items-center">
          <button id="abw" className='h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen'onClick={()=>print()} >Print</button>
          <button id="abw" className='h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen'onClick={()=>navigate(-1)} >Back</button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerEscaffScaffolldingQoutation;
