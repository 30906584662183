import React from "react";
import logo from "../pictures/EcoLogo.png";
import logo1 from "../pictures/poc.png";
import chrome from "../pictures/chrome.png";
import firefox from "../pictures/firefox.png";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import me from "../pictures/me.png";

function FooterScaff() {
  const footerResponce = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  return (
    <footer className=" bottom-0 w-full xxl:h-[174px] z-20 ">

          <div className="relative w-full">
            <div className="flex left-44 w-2  absolute -bottom-3 lg:w-full  z-20">
              <img src={me}  className="w-44"alt="captain"/>
            </div>
          </div>
          <div className="relative w-full">
            <div className="absolute xxl:right-32 right-20 xxl:bottom-12 bottom-8  object-contain overflow-hidden z-40">
              <video width="230px" height="230px" controls muted loop autoPlay>
                <source src="./videos/intro.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

      

      <div className="flex flex-col md:flex-row h-full w-full justify-between items-center px-16 py-2 sm:py-4 bg-darkgreen">
        { <div className='flex'>
        { <img className={`${!footerResponce? "hidden":""} w-20`} src={logo} alt="" /> }
               
            </div> }
        <div className="flex flex-col justify-center text-white">
          
          <p className="text-center text-xs">
            tel: 07525 648263
            <br />
            email: admin@escaff.uk
          </p>
          <p className="text-center text-xs font-semibold py-1">
            Copyright @ 2023 eScaff. All rights reserved
          </p>
        </div>
        {/* <div className="flex">
          <a href="https://www.google.com/chrome/?brand=YTUH&gclid=Cj0KCQjwxYOiBhC9ARIsANiEIfZ39I2kbiqf3U23yj90FPrOgIuTN387-oVClwaYMwJBMRmECApvc-AaAu6pEALw_wcB&gclsrc=aw.ds">
            <p className="text-center text-xs text-white mt-8">
              Window user sign up with Chrome
            </p>
          </a>
          <a href="https://www.google.com/chrome/?brand=YTUH&gclid=Cj0KCQjwxYOiBhC9ARIsANiEIfZ39I2kbiqf3U23yj90FPrOgIuTN387-oVClwaYMwJBMRmECApvc-AaAu6pEALw_wcB&gclsrc=aw.ds">
            <img
              className={`${
                footerResponce ? "" : "hidden"
              } w-12 h-12 sm:w-20 sm:h-20 mx-2 sm:mx-8  xxl:top-10 top-6 right-0`}
              src={chrome}
              alt=""
            />
          </a>
        </div> */}
         { <div className='flex'>
         <button
              id="abw"
              className="h-8 mr-4 w-fit xxl:w-full xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer whitespace-nowrap bg-lightgreen"
              onClick={() => navigate("/signup")}
            >
              Start 30 Days Free Trial
            </button>
               
            </div> }
      </div>
    </footer>
  );
}

export default FooterScaff;
