import React, { useEffect } from "react";
import FooterScaff from "../components/FooterScaff";
import logoo from "../pictures/TheBestescafflogo.png";
import image from "../pictures/MeSmall.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminActions } from "../store/adminSlice";
import { staffActions } from "../store/staffSlice";
import { customerActions } from "../store/customerSlice";

function TermsAndConditions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.removeSignout);
    dispatch(staffActions.removesignout);
    dispatch(customerActions.removesignout);
  }, []);
  return (
    <div className="w-full h-fit flex flex-col  ">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 top-0">
        <img
          className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
          src={logoo}
          alt="logo"
        />
        <div className="w-full flex justify-end px-0 sm:px-2 xl:pr-12 -mt-5 lg:-mt-5 xxl:-mt-8">
          <button
            className="whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-10 text-[7px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen"
            onClick={() => navigate("/signin")}
          >
            Signin/Signup
          </button>
        </div>
      </header>
      <div className="flex flex-col  w-full h-full ">
        <div className="flex flex-col h-4/6 w-full    px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>TERMS AND CONDITIONS</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              These terms and conditions (the "Terms and Conditions") govern the
              use of https://www.escaff.uk (the "Site"). This Site is owned and
              operated by Jeremy Griffin. This Site is a scaffolding management
              system.
              <br />
              By using this Site, you indicate that you have read and understand
              these Terms and Conditions and agree to abide by them at all
              times.
              <br />
              THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE
              THAT IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE
              READ IT CAREFULLY
              <br />
            </p>
          </div>
        </div>

        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Intellectual Property</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              All content published and made available on our Site is the
              property of Jeremy Griffin and the Site's creators. This includes,
              but is not limited to images, text, logos, documents, downloadable
              files and anything that contributes to the composition of our
              Site.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Accounts</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              When you create an account on our Site, you agree to the
              following:
              <br />
              <br />
              1.You are solely responsible for your account and the security and
              privacy of your account, including passwords or sensitive
              information attached to that account; and
              <br />
              2.All personal information you provide to us through your account
              is up to date, accurate, and truthful and that you will update
              your personal information if it changes.
              <br />
              We reserve the right to suspend or terminate your account if you
              are using our Site illegally or if you violate these Terms and
              Conditions.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Sale of Services</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              These Terms and Conditions govern the sale of services available
              on our Site.
              <br />
              <br />
              We are under a legal duty to supply goods that match the
              description of the good(s) you order on our Site.
              <br />
              <br />
              The following services are available on our Site:
              <br />
              <br />
              <ul>
                <li>&#x2022;Scaffolding Management Services. </li>
              </ul>
              <br />
              <br />
              The services will be paid for in full when the services are
              ordered. These Terms and Conditions apply to all the services that
              are displayed on our Site at the time you access it. All
              information, descriptions, or images that we provide about our
              services are as accurate as possible. However, we are not legally
              bound by such information, descriptions, or images as we cannot
              guarantee the accuracy of all services we provide. You agree to
              purchase services from our Site at your own risk.
              <br />
              <br />
              We reserve the right to modify, reject or cancel your order
              whenever it becomes necessary. If we cancel your order and have
              already processed your payment, we will give you a refund equal to
              the amount you paid. You agree that it is your responsibility to
              monitor your payment instrument to verify receipt of any refund.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Subscriptions</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Your subscription automatically renews and you will be
              automatically billed until we receive notification that you want
              to cancel the subscription.
              <br />
              <br />
              To cancel your subscription, please follow these steps: 30 day
              cancellation.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Free Trial</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              We offer the following free trial of our services: 30 days free
              trial.
              <br />
              <br />
              At the end of your free trial, the following will occur: payment
              is made.
              <br />
              <br />
              To cancel your free trial, please follow these steps: Contact
              eScaff.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Payments</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              We accept the following payment methods on our Site:
              <br />
              <br />
              &#x2022;Credit Card;
              <br />
              <br />
              &#x2022;PayPal; and
              <br />
              <br />
              &#x2022;Debit
              <br />
              <br />
              When you provide us with your payment information, you authorise
              our use of and access to the payment instrument you have chosen to
              use. By providing us with your payment information, you authorise
              us to charge the amount due to this payment instrument.
              <br />
              <br />
              If we believe your payment has violated any law or these Terms and
              Conditions, we reserve the right to cancel or reverse your
              transaction.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>Right to Cancel and Receive Reimbursement</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              If you are a customer living in the United Kingdom or the Eurpoean
              Union you have the right to cancel your contract to purchase
              services from us within 14 days without giving notice. The
              cancellation period:
              <br />
              <br />
              &#x2022;Will end 14 days from the date of purchase when you
              purchased a service.;
              <br />
              <br />
              To exercise your right to cancel you must inform us of your
              decision to cancel within the cancellation period. To cancel,
              contact us by email at admin@escaff.uk or by post at __________.
              You may use a copy of the Cancellation Form, found at the end of
              these Terms and Conditions, but you are not required to do so.
              <br />
              <br />
              The right to cancel does not apply to:
              <br />
              <br />
              &#x2022; Goods or services, other than the supply of water, gas,
              electricity, or district heating, where the price depends upon
              fluctuations in the financial market that we cannot control and
              that may occur during the cancellation period.
              <br />
              <br />
              &#x2022; Services that the customer has requested for the purpose
              of carrying out urgent repairs or maintenance;
              <br />
              <br />
              &#x2022; Newspapers, magazines, or periodicals, except for
              subscriptions to such publications; and
              <br />
              <br />
              &#x2022; Accommodation, transport of goods, vehicle rental
              services, catering, or services related to leisure activities, if
              the contract includes a specific date or period of performance.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Effects of Cancellation</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              If you requested the performance of services begin during the
              cancellation period, you are required to pay us an amount which is
              in proportion to what has been performed until you have
              communicated to us your decision to cancel this contract. We will
              reimburse to you any amount you have paid above this proportionate
              payment.
              <br />
              <br />
              We will make the reimbursement using the same form of payment as
              you used for the initial purchase unless you have expressly agreed
              otherwise. You will not incur any fees because of the
              reimbursement.
              <br />
              <br />
              This right to cancel and to reimbursement is not affected by any
              return or refund policy we may have.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Refunds</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              We provide refunds for services sold on our Site as follows:
              <br />
              <br />
              The services will be fully refunded if the services are cancelled
              at least 48 hours before the services were scheduled to be
              provided.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Consumer Protection Law</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Where the Sale of Goods Act 1979, the Consumer Rights Act 2015, or
              any other consumer protection legislation in your jurisdiction
              applies and cannot be excluded, these Terms and Conditions will
              not limit your legal rights and remedies under that legislation.
              These Terms and Conditions will be read subject to the mandatory
              provisions of that legislation. If there is a conflict between
              these Terms and Conditions and that legislation, the mandatory
              provisions of the legislation will apply.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Links to Other Websites</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Our Site contains links to third party websites or services that
              we do not own or control. We are not responsible for the content,
              policies, or practices of any third party website or service
              linked to on our Site. It is your responsibility to read the terms
              and conditions and privacy policies of these third party websites
              before using these sites.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Limitation of Liability</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Jeremy Griffin and our directors, officers, agents, employees,
              subsidiaries, and affiliates will not be liable for any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees from your use of the Site
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Indemnity</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Except where prohibited by law, by using this Site you indemnify
              and hold harmless Jeremy Griffin and our directors, officers,
              agents, employees, subsidiaries, and affiliates from any actions,
              claims, losses, damages, liabilities and expenses including legal
              fees arising out of your use of our Site or your violation of
              these Terms and Conditions.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Applicable Law</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              These Terms and Conditions are governed by the laws of the Country
              of England.
              <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Dispute Resolution</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              Subject to any exceptions specified in these Terms and Conditions,
              if you and Jeremy Griffin are unable to resolve any dispute
              through informal discussion, then you and Jeremy Griffin agree to
              submit the issue before an arbitrator. The decision of the
              arbitrator will be final and binding. Any arbitrator must be a
              neutral party acceptable to both you and Jeremy Griffin. The costs
              of any arbitration will be paid by the unsuccessful party.
              <br />
              <br />
              Notwithstanding any other provision in these Terms and Conditions,
              you and Jeremy Griffin agree that you both retain the right to
              bring an action in small claims court and to bring an action for
              injunctive relief or intellectual property infringement.
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Severability</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid. <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Changes</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
            These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site. <br />
            </p>
          </div>
        </div>
        <div className="flex flex-col h-4/6 w-full  -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen mt-9">
            <i>Contact Details</i>{" "}
          </h1>
          <div className=" flex-col my-6  flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2  text-xs sm:text-base xxl:text-[24px] ">
            Please contact us if you have any questions or concerns. Our contact details are as follows: <br />
                          <br />
                          07525 648263 <br />
                          admin@email.uk <br />
                          Little Lidgey Laity Moor Ponsanooth TR3 7HR <br />
            </p>
          </div>
        </div>
      </div>

      <FooterScaff />
    </div>
  );
}

export default TermsAndConditions;
