import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Logo from '../components/Logo';
import Sidebar from '../components/Sidebar'
import escapeHtml from '../functions/Validator';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchPriceList, savePriceList, signOut } from '../store/adminSlice';
import HeaderScaff from '../components/HeaderScaff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

function PriceList() {
    const isNonMobile = useMediaQuery("(min-width: 768px)");
    const [ sidebarOpen, setSidebarOpen ] = useState(true);
    const navigate = useNavigate();
    const [ needed, setNeeded ] = useState(false);
    const adminID=useSelector((state)=>state.admin._id);
    const fetchedPriceList=useSelector((state)=>state.admin.priceList);

    const [ priceList, setPriceList] = useState({linearMerterageCost:"35.00",hopUpCost:"35.00",saddleCost:"36.00",throwinCost:"35.00",hakkiLayher:"35.00",temporaryRoof:"28.00",monoflex:"3.00",shrinkwrap:"6.00",fivebyonepointeightTower:"175.00",fivebyonepointeightTowerCostPerLift:"75.00",eightbytwopointfourTower:"200.00",eightbytwopointfourTowerCostPerLift:"85.00",tenbythreeTower:"250.00",tenbythreeTowerCostPerLift:"95.00",thirteenbyfourTower:"300.00",thirteenbyfourTowerCostPerLift:"100.00",scaffoldingInspectionPerVisit:"75.00",overhirePercentage:"10",addItem:[],adminID:adminID});
    const [ temp, setTemp ] = useState({name:"",cost:""})
    const [tempArr, setTempArr] = useState([]);
    const dispatch=useDispatch();
    const [loading, setLoading] = useState(false);
    const subscribed = useSelector((state) => state.admin.subscribed);
    const statesignOut=useSelector((state)=>state.admin.signOut)
    useEffect(() => {
        if (statesignOut==="signOut") {
            dispatch(signOut())
        }
    },[statesignOut])
    useEffect(() => {
      if(!adminID){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
      }
      if(!isNonMobile){
        setSidebarOpen(false);
      }
    },[])
    
    const addHtml = (e) => {
        e.preventDefault();
        if(temp.name !== "" && temp.cost !== ""){
            tempArr.push(temp);
            setPriceList({...priceList,addItem:tempArr});
            setTemp({name:"",cost:""});
        }
    };

    const removeItem = (a) => {
        setPriceList({...priceList, addItem:priceList.addItem.filter((c) => c.name !== a.name)});
        setTempArr(tempArr.filter((c) => c.name !== a.name));
    };

    const handleSubmit=()=>{
        setLoading(true);
        // priceList.map((a) => {
        //     setPriceList({...priceList,a:a +0.00});
        // })
        dispatch(savePriceList(priceList)).then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Saved',
              confirmButtonColor:"#A7B778",
            })
          })
    };

    useEffect(()=>{
        dispatch(fetchPriceList({adminID:adminID}))
        setPriceList(fetchedPriceList);
    },[])

    useEffect(()=>{
        setPriceList(fetchedPriceList);
        if(tempArr.length <= 0){
            fetchedPriceList.addItem.map((a) => {
                tempArr.push(a);
            })
        }
    },[fetchedPriceList])

    return (
    <div className='w-full h-full'>
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "basis-4/5 flex flex-col -ml-3 sm:ml-2 -mt-1 md:pt-4": "hidden" : "w-full flex flex-col pl-3 pt-3" } " "`}>
            <p className='text-lg xxl:text-2xl flex w-full text-gray-500'>This is your price list</p>
            <div className='flex flex-col lg:flex-row w-full'>

                <div className='basis-1 lg:basis-1/2 flex flex-col w-full'>
                    <p className='text-[11px] xxl:text-base w-11/12 font-semibold pr-3'>Please enter your costs per item below or use our default values, these can be changed at anytime.</p>
                    <div className='flex flex-col w-full mt-2'>
                        <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Your Linear Meterage cost</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,linearMerterageCost:escapeHtml(e.target.value)})} value={priceList.linearMerterageCost} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Saddle Cost</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,saddleCost:escapeHtml(e.target.value)})} value={priceList.saddleCost} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Throw-in Cost</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,throwinCost:escapeHtml(e.target.value)})} value={priceList.throwinCost} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Hop-Up Cost</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,hopUpCost:escapeHtml(e.target.value)})} value={priceList.hopUpCost} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Hakki/Layher stairs per lift</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,hakkiLayher:escapeHtml(e.target.value)})} value={priceList.hakkiLayher} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>

                        <p className='px-1 pt-3 pb-1 pl-0 font-semibold text-sm xxl:text-xl'>Temporary Roofs</p>

                        <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Temporary Roof per m2</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,temporaryRoof:escapeHtml(e.target.value)})} value={priceList.temporaryRoof} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Monoflex Per m2</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,monoflex:escapeHtml(e.target.value)})} value={priceList.monoflex} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Shrinkwrap per m2</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,shrinkwrap:escapeHtml(e.target.value)})} value={priceList.shrinkwrap} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                    </div>
                </div>
            
                <div className='basis-1 lg:basis-1/2 flex flex-col w-full pr-5 lg:pr-8 xl:pr-14 mt-5 lg:-mt-1'>
                    <p className='text-sm xxl:text-xl font-semibold'>Towers</p>
                    <div className='flex flex-col w-full mt-2'>
                        <div className='flex flex-col sm:flex-row w-full sm:space-x-2'>
                            <div className='basis-3/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Cost for 5 foot/1.8 mtr tower</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,fivebyonepointeightTower:escapeHtml(e.target.value)})} value={priceList.fivebyonepointeightTower} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                            <div className='basis-1/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base whitespace-nowrap'>Cost per lift</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,fivebyonepointeightTowerCostPerLift:escapeHtml(e.target.value)})} value={priceList.fivebyonepointeightTowerCostPerLift} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row w-full sm:space-x-2 pt-3 sm:pt-2'>
                            <div className='basis-3/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Cost for 8 foot/2.4 mtr tower</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,eightbytwopointfourTower:escapeHtml(e.target.value)})} value={priceList.eightbytwopointfourTower} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                            <div className='basis-1/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base whitespace-nowrap'>Cost per lift</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,eightbytwopointfourTowerCostPerLift:escapeHtml(e.target.value)})} value={priceList.eightbytwopointfourTowerCostPerLift} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row w-full sm:space-x-2 pt-3 sm:pt-2'>
                            <div className='basis-3/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Cost for 10 foot/3.0 mtr tower</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,tenbythreeTower:escapeHtml(e.target.value)})} value={priceList.tenbythreeTower} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                            <div className='basis-1/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base whitespace-nowrap'>Cost per lift</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,tenbythreeTowerCostPerLift:escapeHtml(e.target.value)})} value={priceList.tenbythreeTowerCostPerLift} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row w-full sm:space-x-2 pt-3 sm:pt-2'>
                            <div className='basis-3/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Cost for 13 foot/4.0 mtr tower</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,thirteenbyfourTower:escapeHtml(e.target.value)})} value={priceList.thirteenbyfourTower} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                            <div className='basis-1/4 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base whitespace-nowrap'>Cost per lift</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,thirteenbyfourTowerCostPerLift:escapeHtml(e.target.value)})} value={priceList.thirteenbyfourTowerCostPerLift} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                        </div>
                        <p className='px-1 pt-3 pb-1 pl-0 font-semibold text-sm xxl:text-xl'>Other Costs</p>

                        <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Scaffolding Inspections per visit</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setPriceList({...priceList,scaffoldingInspectionPerVisit:escapeHtml(e.target.value)})} value={priceList.scaffoldingInspectionPerVisit} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                        <p className='px-1 pt-3 xxl:pt-4 pl-0 font-semibold text-[11px] xxl:text-base'>Enter the percentage of cost for overhire - for example enter 10% so a 4 week hire is say £1000.00 your overhire will be £100.00 per week added to every week over the initial 4 weeks Initial cost</p>
                        <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-6 xxl:pl-7 z-20' onChange={(e)=>setPriceList({...priceList,overhirePercentage:escapeHtml(e.target.value)})} value={priceList.overhirePercentage} type="number" required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>%</i>

                        <p className='px-1 pt-5 pb-1 pl-0 font-semibold text-sm xxl:text-xl'>Add a new item you sell</p>
                        <div className='flex flex-row w-11/12 space-x-2 pt-0'>
                            <div className='basis-1/2 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Enter item name</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1' type="text" onChange={(e) => setTemp({...temp,name:escapeHtml(e.target.value)})} value={temp.name} required/>
                            </div>
                            <div className='basis-1/2 flex flex-col w-full'>
                                <p className='px-1 pl-0 font-semibold text-[12px] xxl:text-base'>Enter item cost</p>
                                <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-4 xxl:pl-6 z-20' type="number" onChange={(e) => setTemp({...temp,cost:escapeHtml(e.target.value)})} value={temp.cost} required/><i className='z-10 relative pl-1 xxl:pl-2 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>
                            </div>
                        </div>
                        <div className='flex flex-col w-11/12 space-y-1 pt-3 justify-between'>
                            {priceList.addItem?
                                priceList.addItem.map((a) => (
                                <div key={a.name} className='flex flex-row h-fit p-1 bg-gray-200  opacity-50 w-full items-center justify-between'>
                                    <div className='flex pr-3 mr-1 flex-row h-fit bg-gray-200 w-full justify-between border-r-2 border-gray-400'>
                                        <p className='px-1 font-semibold text-[12px] xxl:text-base'>item name : {a.name}</p>
                                        <p className='px-1 font-semibold text-[12px] xxl:text-base'>item cost : £ {a.cost}</p>
                                    </div>
                                    <p onClick={(e) => removeItem(a)} className='px-2 hover:font-bold cursor-pointer hover:bg-gray-400'>X</p>
                                </div>
                            ))
                            : <></>}
                        </div>
                        <div className='flex flex-row w-11/12 space-x-2 pt-2 justify-between'>
                            <button onClick={addHtml} className='self-start w-24 h-8 xxl:w-32 xxl:h-10 mt-2 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>Add item</button>
                            {loading?(
                                <div className='flex flex-row items-center'>
                                    <CircularProgress className='p-2 mx-1' color="success"/>
                                    <p className='text-base'>Submitting Form</p>
                                </div>
                            ):(
                                <button className='w-24 h-8 xxl:w-32 xxl:h-10 mt-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen' onClick={handleSubmit}>Save</button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
    
        </div>
    </div>
    </div>
  )
}

export default PriceList