import React, { useEffect, useState } from "react";
import Logo from "../components/Logo";
import { useDispatch, useSelector } from "react-redux";
import FourButtonsTwo from "../components/FourButtonsTwo";
import moment from "moment";
import { addHandOver, fetchSingleHandover, getCompanydata, signOut } from "../store/adminSlice";
import escapeHtml from "../functions/Validator";
import Swal from "sweetalert2";
import { adminActions } from "../store/adminSlice";
import { useNavigate } from "react-router-dom";
import URL from "../functions/URLString";

function ViewForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const stateSwalAlert = useSelector((state) => state.admin.swalAlert);
  const queryParameters = new URLSearchParams(window.location.search);
  const companyDetails=useSelector((state)=>state.admin.companyDetails)  
  const handOverData=useSelector((state)=>state.admin.handOverData)  
  const id = queryParameters.get("id");
  const type = queryParameters.get("type");

  const adminID = useSelector((state) => state.admin._id);
  const [data, setData] = useState({
    customerID: "",
    clientName:"",
    address:"",
    postcode:"",
    dateOfScoffolldingCompletion: "",
    time: "",
    descriptionHandover: "",
    drawingNo: "",
    MaximumLoading: "",
    methodOfTyping: "",
    tiesBeenTested: "",
    methodOfCladding: "",
    handover: "",
    detailsNessesary: "",
    scaffoldingContractor: "",
    scaffolderEmail: "",
    adminID: adminID,
  });
  const statesignOut = useSelector((state) => state.admin.signOut);
  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [statesignOut]);
  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, dateOfScoffolldingCompletion: newDate });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };

  useEffect(() => {
    if (stateSwalAlert === "handoverAdded") {
      Swal.fire({
        icon: "success",
        title: "Saved",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        navigate("/liveprojects");
      });
    }
  }, [stateSwalAlert]);
  useEffect(() => {
    setData(handOverData);
        
     
        
        
    }, [companyDetails,handOverData])
    useEffect(() => {
        dispatch(getCompanydata(adminID))    
      setData({ ...data, scaffoldingContractor: companyDetails.customerOrCompanyName })
      dispatch(fetchSingleHandover({_id:id,type:type,adminID:adminID  }))
      


    }, [])
    

  return (
    <div className="w-full h-full">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24">
        <Logo />
        <div className="w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8">
          <FourButtonsTwo />
        </div>
      </header>
      <div className="w-full flex h-auto p-6">
        {/* <Sidebar needed={needed}/> */}

        <div className="lg:22-3/4 w-full ">
          <p className="text-lg xxl:text-2xl text-slate-400">
            Scaffolding Handover
          </p>
          <div className="lg:flex lg:flex-row flex-col">
            <div className="basis-1 lg:basis-1/2 flex flex-col w-full">
              <div className="flex flex-col w-full mt-1">
                <p className="px-1 pl-0 font-semibold text-[12px] xxl:text-base">
                  Client name
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.clientName}
                  onChange={(e) =>
                    setData({ ...data, clientName: escapeHtml(e.target.value) })
                  }
                  required
                />

                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Address
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: escapeHtml(e.target.value) })
                  }
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Post code
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.postcode}
                  onChange={(e) =>
                    setData({ ...data, clientName: escapeHtml(e.target.value) })
                  }
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Date scaffolding Completion
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="date"
                  value={data.dateOfScoffolldingCompletion}
                  onChange={onChangeDate}
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Time
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.time}
                  onChange={(e) =>
                    setData({ ...data, time: escapeHtml(e.target.value) })
                  }
                  required
                />

                <p className="px-1 my-2 pl-0 xxl:pt-2 font-semibold text-sm xxl:text-xl">
                  Scaffold specification
                </p>

                <p className="px-1 xxl:pt-1 pl-0 font-normal text-[11px] xxl:text-base w-11/12">
                  In accordance with The working at height Regulations 2005 this
                  scaffold must be inspected by the User and a record maintained
                  of the inspections as follows; Before being taken into use for
                  the first time After substantial addition, dismantling or
                  other alterations After any event likely to have affected its
                  strength or stability At regular intervals not exceeding 7
                  days
                </p>

                <p className="px-1 pl-0 font-semibold xxl:my-4 text-[12px] xxl:text-base my-2">
                  The inside boards only take a loading limit of 0.75kn/m2
                </p>

                <p className="px-1 pl-0 font-semibold text-[12px] xxl:text-base">
                  Description of section handed over
                </p>
                <textarea
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  id="w3review"
                  name="w3review"
                  rows="3"
                  cols="50"
                  value={data.descriptionHandover}
                  onChange={(e) =>
                    setData({
                      ...data,
                      descriptionHandover: escapeHtml(e.target.value),
                    })
                  }
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Drawing No/TG20:21 compliance sheet ref:
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.drawingNo}
                  onChange={(e) =>
                    setData({ ...data, drawingNo: escapeHtml(e.target.value) })
                  }
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Maximum loading
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.MaximumLoading}
                  onChange={(e) =>
                    setData({
                      ...data,
                      MaximumLoading: escapeHtml(e.target.value),
                    })
                  }
                  required
                />
              </div>
            </div>

            <div className="basis-1 lg:basis-1/2 flex flex-col w-full">
              <div className="flex flex-col w-full mt-1">
                <p className="px-1 pl-0 font-semibold text-[12px] xxl:text-base">
                  Method of tying
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.methodOfTyping}
                  onChange={(e) =>
                    setData({
                      ...data,
                      methodOfTyping: escapeHtml(e.target.value),
                    })
                  }
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Have the ties been tested?
                </p>
                <select
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  value={data.tiesBeenTested}
                  onChange={(e) =>
                    setData({
                      ...data,
                      tiesBeenTested: escapeHtml(e.target.value),
                    })
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Method of cladding
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.methodOfCladding}
                  onChange={(e) =>
                    setData({
                      ...data,
                      methodOfCladding: escapeHtml(e.target.value),
                    })
                  }
                  required
                />

                <p className="px-1 xxl:mt-4 mt-2 mb-1 pl-0 font-semibold text-sm xxl:text-xl">
                  Handover
                </p>

                <p className="px-1  pl-0 font-normal text-[11px] xxl:text-base w-11/12">
                  The scaffolding as described above has now been completed in
                  accordance with client requirements. It is structurally sound
                  and should only be used, cladded and loaded in accordance with
                  this handover certificate.
                </p>

                <p className="px-1 pt-1 xxl:my-4 pl-0 font-normal text-[11px] xxl:text-base w-11/12">
                  Have the requirements of the work at heights regulations 2005
                  and any other relevant legislation and NASC guidance such as
                  SG4 and TG20:21 been compiled with?
                </p>

                <select
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  value={data.handover}
                  onChange={(e) =>
                    setData({ ...data, handover: escapeHtml(e.target.value) })
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <p className="px-1 pl-0 font-semibold text-[12px] xxl:text-base mt-2">
                  Details of any further action considered necessary:
                </p>
                <textarea
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  id="w3review"
                  name="w3review"
                  rows="3"
                  cols="50"
                  value={data.detailsNessesary}
                  onChange={(e) =>
                    setData({
                      ...data,
                      detailsNessesary: escapeHtml(e.target.value),
                    })
                  }
                />
                <p className="px-1 mt-2 xxl:mt-4 mb-1 pl-0 font-semibold text-sm xxl:text-xl">
                  Acceptance
                </p>

                <p className="px-1 xxl:mb-4 pl-0 font-normal text-[11px] xxl:text-base w-11/12">
                  We accept that the scaffold must be inspected by a competent
                  person at intervals not exceeding seven days and after any
                  event that may affect its adequacy. We (the client) will not
                  modify the scaffold without authorisation and the scaffold
                  will be used within the parameters set out within this
                  document.
                </p>

                <p className="px-1 pl-0 xxl:mb-4 font-semibold text-[12px] xxl:text-base my-1">
                  Acceptance on behalf of Scaffold firm
                </p>

                <p className="px-1 pl-0 font-semibold text-[12px] xxl:text-base">
                  Name of Company
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  value={data.scaffoldingContractor}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setData({
                      ...data,
                      scaffoldingContractor: escapeHtml(e.target.value),
                    })
                  }
                  required
                />
                <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                  Name of Scaffolder
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  value={data.scaffolderEmail}
                  onChange={(e) =>
                    setData({
                      ...data,
                      scaffolderEmail: escapeHtml(e.target.value),
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center ">
           
            <button
              id="abw"
              className="h-8 mr-4 w-24 xxl:w-32  mt-4  xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
          <div className='grid  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xxl:grid-cols-5 mt-4'>
          <div className='mx-2 my-2 flex items-center justify-center'>
                <img className='mt-2 object-contain' src={`${URL}/uploads/${data.Images}`} key={data.Images} alt='addJobPhoto' />
            </div>
                </div>
        </div>
      </div>
    </div>
  );
}

export default ViewForm;
