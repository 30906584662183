import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../functions/axios";

const staffData = localStorage.getItem("staffData")!==null ? JSON.parse(localStorage.getItem("staffData")):{staff_id:"",staffEmail:"",staffFullName:"",staffIsLoading:false} 
const companyLogoo=localStorage.getItem("companyLogo")!==null?localStorage.getItem("companyLogo"):""
const config = {
    headers:{
        authorization: 'arham BnD3Tb4QN4xGzX85XQdfghjhgbnbvghj2223errAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
        // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
    }
  };

export const staffLogin = createAsyncThunk('/staffLogin',async(user)=>{
    const res = await axios.post("/staff/staffLogin",user,config);
    return res.data;
})
export const fetchCompanyDetails = createAsyncThunk('/fetchcompanydetails',async(user)=>{
    const res = await axios.post("/staff/fetchcompanydetails",user,config);
    return res.data;
})
export const fetchJobsForStaffMember = createAsyncThunk('/fetchJobsForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchjobsforstaffmember",user,config);
    return res.data;
})
export const fetchQoutaionForStaffMember = createAsyncThunk('/fetchQoutaionForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchqoutationforstaffmember",user,config);
    return res.data;
})
export const fetchAdaptionForStaffMember = createAsyncThunk('/fetchAdaptionForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchadaptionforstaffmember",user,config);
    return res.data;
})
export const fetchSingleJobsForStaffMember = createAsyncThunk('/fetchSingleJobsForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchsinglejobsforstaffmember",user,config);
    return res.data;
})
export const fetchSingleAdaptionsForStaffMember = createAsyncThunk('/fetchSingleAdaptionsForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchsingleadaptionforstaffmember",user,config);
    return res.data;
})
export const fetchSingleQoutationsForStaffMember = createAsyncThunk('/fetchSingleQoutationsForStaffMember',async(user)=>{
    const res = await axios.post("/staff/fetchsingleqoutationforstaffmember",user,config);
    return res.data;
})
export const fetchAdaption = createAsyncThunk('/fetchAdaption',async(user)=>{
    const res = await axios.post("/staff/fetchadaption",user,config);
    return res.data;
})
export const fetchAdaptionQoute = createAsyncThunk('/fetchAdaptionQoute',async(user)=>{
    const res = await axios.post("/staff/fetchadaptionqoute",user,config);
    return res.data;
})
export const jobStatusStart = createAsyncThunk('/jobStatusStart',async(user)=>{
    const res = await axios.post("/staff/jobstatusstart",user,config);
    return res.data;
})
export const jobStatusComplete = createAsyncThunk('/jobStatusComplete',async(user)=>{
    const res = await axios.post("/staff/jobstatuscomplete",user,config);
    return res.data;
})
export const adaptionStatusStart = createAsyncThunk('/adaptionstatusstart',async(user)=>{
    const res = await axios.post("/staff/adaptionstatusstart",user,config);
    return res.data;
})
export const adaptionStatusComplete = createAsyncThunk('/adaptionstatuscomplete',async(user)=>{
    const res = await axios.post("/staff/adaptionstatuscomplete",user,config);
    return res.data;
})
export const signOut = createAsyncThunk("/signOut", async (user) => {
    const res = await axios.post("/admin/signout",user,config);
    return res.data;   
})

const staffSlice=createSlice({
    name:"post",
    initialState:{
        staff_id:staffData.staff_id,
        staffEmail:staffData.staffEmail,
        staffFullName:staffData.staffFullName,
        staffIsLoading:staffData.staffIsLoading,
        staffAlert: ["","",""],
        staffpendingQoutes: [],
        adminID:staffData.adminID,
        swalAlert:"",
        companyDetails:{staffOrCompanyName:"",telephone:"",email:"",address1:"",address2:"",postCode:""},
        companyDocuments:[],
        companyLogo: companyLogoo,
        jobsForStaffMember:[],
        qoutaionForStaffMember:[],
        adaptionsForStaffMember:[],
        singlejobsForStaffMember:{},
        singleAdaptionsForStaffMember:{},
        jobStatus: 0,
        adaptionDetails: "",
        signOut:""

    },
    reducers:{
        addAlert(state,action){
            state.staffAlert=[action.payload.type,action.payload.title,action.payload.message]
        },
        removeSwalAlert(state,action){
            state.swalAlert="";
        },
        signout(state, action) {
            state.staff_id = "";
            state.staffEmail = "";
            state.staffFullName = "";
            state.adminID = "";
            state.staffAlert = [];
            state.companyDocuments = [];
        },
        removesignout(state, action) {
            state.signOut = ""
          
        }
    },
    extraReducers: {
        [fetchCompanyDetails.fulfilled]:(state,{payload})=>{
            state.companyDetails = {staffOrCompanyName:payload[0].staffOrCompanyName,telephone:payload[0].telephone,email:payload[0].email,address1:payload[0].address1,address2:payload[0].address2,postCode:payload[0].postCode}
            state.companyDocuments = payload[0].companyDocument;
            state.companyLogo = payload[0].companyLogo;
            localStorage.setItem("companyLogo",payload[0].companyLogo)
        },
        [staffLogin.fulfilled]: (state, { payload }) => {
            if (payload.message === "email") {
                state.staffAlert = ["unsuccess", "Unsuccessfull", "E-mail Not Found!"]
            } else if (payload.message === "password") {
                state.staffAlert = ["unsuccess", "Unsuccessfull", "Incorrect Password!"]
            } else {
                localStorage.setItem('staffData', JSON.stringify({ staff_id: payload._id, staffFullName: payload.fullName, staffEmail: payload.email, staffIsLoading: false ,adminID:payload.adminID}))

                state.staff_id = payload._id
                state.staffFullName = payload.fullName
                state.staffEmail = payload.email
                state.staffIsLoading = false
                state.adminID = payload.adminID
                state.staffAlert = ["success", "LoggedIn Successfully!", ""]
            }
        },
        [fetchJobsForStaffMember.fulfilled]: (state, { payload }) => {
            state.jobsForStaffMember = payload;
        },
        [fetchQoutaionForStaffMember.fulfilled]: (state, { payload }) => {
            state.qoutaionForStaffMember = payload;
        },
        [fetchAdaptionForStaffMember.fulfilled]: (state, { payload }) => {
            state.adaptionsForStaffMember = payload;
        },
        [fetchSingleJobsForStaffMember.fulfilled]: (state, { payload }) => {
            state.singlejobsForStaffMember = payload;
        },
        [fetchSingleAdaptionsForStaffMember.fulfilled]: (state, { payload }) => {
            state.singleAdaptionsForStaffMember = payload;
        },
        [jobStatusStart.fulfilled]: (state, { payload }) => {
            state.singlejobsForStaffMember = payload;
            // state.jobStatus = 1;
        },
        [jobStatusComplete.fulfilled]: (state, { payload }) => {
            state.singlejobsForStaffMember = payload;
            // state.jobStatus = payload.status;
        },
        [adaptionStatusStart.fulfilled]: (state, { payload }) => {
            state.singleAdaptionsForStaffMember = payload;
            // state.jobStatus = 1;
        },
        [adaptionStatusComplete.fulfilled]: (state, { payload }) => {
            console.log(payload)
            state.singleAdaptionsForStaffMember = payload;
            // state.jobStatus = payload.status;
        },
        [fetchAdaption.fulfilled]: (state, { payload }) => {
            // state.singlejobsForStaffMember = payload;
            // state.jobStatus = payload.status;
            state.adaptionDetails=payload
            // console.log(payload)
        },
        [fetchAdaptionQoute.fulfilled]: (state, { payload }) => {
            // state.singlejobsForStaffMember = payload;
            // state.jobStatus = payload.status;
            state.adaptionDetails=payload
            // console.log(payload)
        },
        [fetchSingleQoutationsForStaffMember.fulfilled]: (state, { payload }) => {
            // state.singlejobsForStaffMember = payload;
            // state.jobStatus = payload.status;
            state.adaptionDetails=payload
            // console.log(payload)
        },
        
        [signOut.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut="signOut";
            } else if (!payload.error) {
                
            if (payload.message === "signOut") {
                
                window.localStorage.clear();
                state.signOut = "signOut";
                
            }
         
            }
         },
    }
})

export const staffActions=staffSlice.actions;
export default staffSlice;