import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

function Alert(props) {
  return (
    <div className='my-1 w-full'>
      {(props.type == "")?(
        <div className='flex w-full justify-center mt-1'>
          <CircularProgress color="success" sx={{width:"5px",height:"5px"}} spacing={4}/>
        </div>
      ):(
      props.type=="unsuccess"?
        <div class="bg-red-50 border border-red-200 text-red-700 px-2 border-x-0 text-center w-full relative" role="alert">
          <strong class="font-semibold text-sm font-sans">{props.title}</strong>
          <span class="block sm:inline text-sm font-sans"> {props.message}</span>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
        
          </span>
        </div>
        :
        <div class="bg-green-50 border border-green-200 text-green-700 px-2 border-x-0 text-center w-full relative" role="alert">
          <strong class="font-semibold text-sm font-sans">{props.title}</strong>
          <span class="block sm:inline text-sm font-sans"> {props.message}</span>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          </span>
        </div>
      )}
    </div>
  )
}

export default Alert