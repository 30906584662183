import React, { useEffect, useRef, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import Sidebar from '../components/Sidebar'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Logo from '../components/Logo';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions, createNewCustomer, deleteCustomer, editCustomer, fetchCustomer, signOut } from '../store/adminSlice';
import FourButtonsTwo from '../components/FourButtonsTwo';
import HeaderScaff from '../components/HeaderScaff';
import escapeHtml from '../functions/Validator';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import $  from 'jquery'
$.DataTable = require('datatables.net')




function CreateNewCustomer() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const dispatch=useDispatch();
  const [ needed, setNeeded ] = useState(false);
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]= useState({customerCompanyName:"",email:"",telephone:"",address:"",password:"",postcode:"",adminID:_id})

  const newCustomer=useSelector((state)=>state.admin.newCustomer);
  const customerSwalAlert=useSelector((state)=>state.admin.customerSwalAlert);
  const [custommerDetails,setCustommerDetails]=useState(newCustomer)

  const [ edit, setEdit] = useState(false)
  const [editID,setEditID]= useState("")
  const [forEdit,setForEdit]= useState({customerCompanyName:"",email:"",telephone:"",address:"",password:"",postcode:"",adminID:_id,_id:""})
  const navigate = useNavigate()
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if(!_id){
      navigate("/signin")
    } else if (!subscribed) {
      navigate("/signin")
      
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  },[])
  const handleSubmit = (e) => {
    console.log("je;;;")
    e.preventDefault();

    if(data.customerCompanyName === "" || data.email === "" || data.telephone === "" || data.address === "" || data.password === "" || data.postcode === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Kindly fill all the inputs.!',
      })
      return;
    }
    dispatch(createNewCustomer(data)).then(()=>{
      setData({customerCompanyName:"",email:"",telephone:"",address:"",password:"",postcode:"",adminID:_id});
    });
  }
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
  useEffect(() => {
    setCustommerDetails(newCustomer)
   
  }, [newCustomer])

  useEffect(() => {
    dispatch(fetchCustomer({adminID:_id}))
    setCustommerDetails(newCustomer)
    // setTimeout(() => {
    //   $('#table').DataTable().destroy();
      setTimeout(() => {
        $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
        
      }, 5000);
      
    // }, 2000);
    
  },[])

  useEffect(() => {
    if (customerSwalAlert==="emailDuplicated"){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Email already registered.!',
      }).then(() => {
        dispatch(adminActions.removeCustomerSwalAlert());
      })
    }
  },[customerSwalAlert])

  const deleteItem = (thtItem) => {
    var nn= {thtItem:thtItem,_id:thtItem._id,adminID:_id}
    dispatch(deleteCustomer(nn))
  };

  const editItem = (thtItem) => {
    console.log("he");
    setData({...data,customerCompanyName:thtItem.customerCompanyName,email:thtItem.email,telephone:thtItem.telephone,address:thtItem.address,postcode:thtItem.postcode,password:"",adminID:_id});
    setEditID(thtItem._id);
    setEdit(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if(data.customerCompanyName === "" || data.email === "" || data.telephone === "" || data.address === "" || data.password === "" || data.postcode === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Kindly fill all the inputs.!',
      })
      return;
    }
    setForEdit({...forEdit,customerCompanyName:data.customerCompanyName,email:data.email,telephone:data.telephone,address:data.address,postcode:data.postcode,password:data.password,adminID:_id,_id:editID})
  }

  const handleUpdateCancel = () =>{
    setEdit(false);
    setData({customerCompanyName:"",email:"",telephone:"",address:"",password:"",postcode:"",adminID:_id})
  }

  useEffect(() => {
    dispatch(editCustomer(forEdit));
    setData({customerCompanyName:"",email:"",telephone:"",address:"",password:"",postcode:"",adminID:_id})
  }, [forEdit])



 
  return (
    <div className='w-full h-full'>
      {/* <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24'> */}
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        {/* <Logo/>
        <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
          <FourButtonsTwo/>
        </div>
      </header> */}
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full flex flex-col": "hidden" : "w-full flex flex-col" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4'>Create new customer</p>
          
            <form onSubmit={handleSubmit} className='lg:flex lg:flex-row flex-col'>
           <div className='lg:basis-1/2 w-full'>
           
           <div className='p-2 flex flex-col w-full '>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Customer / Company Name</p>
             <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.customerCompanyName} onChange={(e)=>setData({...data,customerCompanyName:escapeHtml(e.target.value)})} type="text" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Telephone</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.telephone} onChange={(e)=>setData({...data,telephone:escapeHtml(e.target.value)})} type="text" />
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Email</p>
           <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' autoComplete="off" value={data.email} onChange={(e)=>setData({...data,email:escapeHtml(e.target.value)})} type="email" />
          </div>
         </div>
     
         <div className='basis-1/2 flex-col flex  justify-between '>
            <div className='p-2 flex flex-col w-full '>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>Address</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.address} onChange={(e)=>setData({...data,address:escapeHtml(e.target.value)})} type="text" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>PostCode</p>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.postcode} onChange={(e)=>setData({...data,postcode:escapeHtml(e.target.value)})} type="text" required/>
             <p className='p-1 font-semibold text-xs xxl:text-xl'>{!edit?"Password":"New Password"}</p>
             <div className='flex justify-between '>
             <input className='bg-gray-200 opacity-50 p-[2px] xxl:p-3 w-1/2 outline-none pl-1' value={data.password} onChange={(e)=>setData({...data,password:escapeHtml(e.target.value)})} type="text" required/>    
             {/* <button onClick={handleSubmit} className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen'>Upload</button> */}
             <div className='pl-2 w-full'>
                {edit?
                <>
                  <button className=' w-24 h-8 mr-3 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' type="submit" onClick={(e) => handleUpdate(e)}>Update</button>
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' onClick={handleUpdateCancel}>Cancel</button>
                </>
                :
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' type="submit" onSubmit={handleSubmit}>Save</button>                
                }
              </div>
             </div>
            </div>

         <div className=' flex content-between justify-between '>
           
             
             
          
         </div>
            </div>
       
            </form>
    
          <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2'>Customer List</p>
          {/* <div className='flex space-x-4 justify-end '>
           <div>
            <p className='text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap'>Search for a customer</p>
            </div>
                <div className='flex items-center'>
                    <SearchIcon sx={{width:'17px',height:"17px"}} className="-mr-5 relative left-0 text-lightgreen"/>
                    <input type="text" className='outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen'/>
                </div>
            
          </div> */}
            
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '  id="table">
             <thead className='w-full'>
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>PostCode</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Email</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Telephone</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
           {custommerDetails?
           custommerDetails.map((data) => {
             return (
             <tr className=''>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customerCompanyName}</td>
             <td className='w-4/12 border-[1px] border-gray-300 pl-2 p-1 text-[10px] xxl:text-[13px]'>{data.address}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.postcode}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.email}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.telephone}</td>
             <td className=' w-2/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                 <div onClick={(e) => editItem(data)} className='flex flex-col items-center'>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Edit</p>
                </div>
                <div onClick={(e) => deleteItem(data)} className='flex flex-col items-center'>
                <DeleteIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Delete</p>
                </div>                      
               </div>
             </td>
             </tr>
             )})
            :<></>
            }
</tbody>

           </table>
           
         
</div>
       </div>
   </div>
</div>
  )
}


export default CreateNewCustomer