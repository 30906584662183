import React ,{useEffect, useState}from 'react'
import { useNavigate } from 'react-router-dom';
import {useDispatch,useSelector} from "react-redux"
import {  adminActions, loginUser} from '../store/adminSlice';
import Alert from '../components/Alert';
import escapeHtml from '../functions/Validator'
import { customerActions, customerLogin } from '../store/customerSlice';
import { staffActions, staffLogin } from '../store/staffSlice';

function SignIn() {  
  const dispatch=useDispatch();
  const stateAlert=useSelector((state)=>state.admin.alert);
  const customerStateAlert=useSelector((state)=>state.customer.customerAlert);
  const staffStateAlert = useSelector((state)=> state.staff.staffAlert)
  const [data,setData]=useState({email:'',password:"",role:'1'})
  const [alert,SetAlert]=useState(false);
  const [customerAlert,SetCustomerAlert]=useState(false);
  const [staffAlert, SetStaffAlert] = useState(false);
  const signOut=useSelector((state)=>state.admin.signOut)
  const navigate = useNavigate();

  useEffect(() => {
    if(stateAlert[0]==="success"){
      navigate("/subscription")
    } else {
      navigate("/signin")
    }
  }, [stateAlert])

  useEffect(() => {
   
    dispatch(adminActions.removeSignout)
  
    }, [])

  useEffect(() => {
    if(customerStateAlert[0]==="success"){
      navigate("/customerpendingqoutes")
    } else {
      navigate("/signin")
    }
  },[customerStateAlert])

  useEffect(() => {
    if(staffStateAlert[0]==="success"){
      navigate("/staffjoblist")
    } else {
      navigate("/signin")
    }
  },[staffStateAlert])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    if(data.role==="1"){
      dispatch(loginUser(data));
      SetAlert(true);
    }else if(data.role==="2"){
      dispatch(customerLogin(data));
      dispatch(customerActions.addAlert({type:"unsuccess",title:"UnsuccessFull" ,message:" Password Not Matched"}))
      SetCustomerAlert(true);
    }else if(data.role==="3"){
      dispatch(staffLogin(data));
      dispatch(staffActions.addAlert({type:"unsuccess",title:"UnsuccessFull" ,message:" Password Not Matched"}))
      SetStaffAlert(true);
    }
  }
  // useEffect(()=>{
  //   dispatch(staffActions.addAlert({type:"unsuccess",title:"UnsuccessFull" ,message:" Password Not Matched"}))
  // },[])
  return (
    <div className='w-full h-screen bg-white flex items-center justify-center'>
        <div className='w-11/12 sm:w-80 h-11/12 bg-white rounded-md shadow-xl p-4 flex flex-col justify-center'>
          <form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <div className='flex items-center flex-col w-full'>
                <h1 className='font-bold font-sans text-lg'>Sign In To eScaff</h1>
                <p className='text-sm font-sans text-grayy'>New here?<span className='text-gray-800 hover:underline cursor-pointer' onClick={() => navigate('/signup')}> Sign up?</span></p>
                {alert && <Alert type={stateAlert[0]} title={stateAlert[1]} message={stateAlert[2]}/>}
                {customerAlert && <Alert type={customerStateAlert[0]} title={customerStateAlert[1]} message={customerStateAlert[2]}/>}
                {staffAlert && <Alert type={staffStateAlert[0]} title={staffStateAlert[1]} message={staffStateAlert[2]}/>}
            </div>
            <div className='flex flex-col space-y-2'>
              <p className='font-sans font-semibold text-gray-800 text-sm'>Email</p>
              <input className='bg-gray-200 opacity-50 outline-none p-1 rounded-md' autoComplete="off" onChange={(e)=>setData({...data,email:escapeHtml(e.target.value)})} value={data.email} type="email" />
              <p className='font-sans font-semibold text-gray-800 text-sm'>Password</p>
              <input className='bg-gray-200 opacity-50 outline-none p-1 rounded-md' onChange={(e)=>setData({...data,password:escapeHtml(e.target.value)})} type="password" />

              <p className='font-sans font-semibold text-white text-sm'>AA</p>
              <select className='bg-blue-50/90 p-1 outline-none' onChange={(e)=>setData({...data,role:e.target.value})} name="cars" id="cars">
                <option value="1">Admin</option>
                <option value="2">Customer</option>
                <option value="3">Staff</option>
              </select>

            </div>
            <div className='flex items-center flex-col py-3 pt-5'>
                <button type='submit' className='w-full bg-lightgreen rounded-md p-1 my-2 text-white hover:bg-darkgreen'>Continue</button>
                <p className='text-sm text-gray-700'>OR</p>
                <button className='w-full bg-lightgreen rounded-md p-1 my-2 text-white hover:bg-darkgreen' onClick={() => navigate("/")}>Cancel</button>
            </div>
        </form>
        </div>
    </div>

  )
}

export default SignIn