import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoo from "../pictures/TheBestescafflogo.png";
import { getCompanydata } from "../store/adminSlice";
import URL from "../functions/URLString";

function Logo1() {
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState("");
  const _id = useSelector((state) => state.admin._id);
  const stateCompanyLogo = useSelector((state) => state.admin.companyLogo);
  useEffect(() => {
    setCompanyLogo(stateCompanyLogo);
  }, [stateCompanyLogo]);
  useEffect(() => {
   
  }, []);
  return (
    <>
      <img
        className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
        src={companyLogo !== "" ? `${URL}/uploads/` + stateCompanyLogo : logoo}
        alt="logo"
      />
    </>
  );
}

export default Logo1;
