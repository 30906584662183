import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Logo from './Logo';
import {adminActions} from "../store/adminSlice"
import Logo1 from './LogoAdminUpload';

function HeaderScaffAdminUpload({setSidebarOpen}) {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const signOut = useSelector((state) => state.admin.signOut)
  const dispatch=useDispatch();
  const pendingQuotes = () => {
    if(!isNonMobile){
      navigate('/pendingprojectqoutes')
      setSidebarOpen(false);
    } else {
      navigate('/pendingprojectqoutes')
    }
  }

  const quotesApproved = () => {
    if(!isNonMobile){
      navigate('/quotesapproved')
      setSidebarOpen(false);
    } else {
      navigate('/quotesapproved')
    }
  }

  const quotesDeclined = () => {
    if(!isNonMobile){
      navigate('/quotesdeclined')
      setSidebarOpen(false);
    } else {
      navigate('/quotesdeclined')
    }
  }

  const liveProjects = () => {
    if(!isNonMobile){
      navigate('/liveprojects');
      setSidebarOpen(false);
    } else {
      navigate('/liveprojects');
    }
  }
  useEffect(() => {
    // if (signOut === "signOut") {
    //   navigate("/")
    // }
  },[signOut])
  useEffect(() => {
    dispatch(adminActions.removeSignout())
  },[])

  return (
    <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 top-0'>
      <Logo1/>
      <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
          <button className='whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-8 text-[10px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen' onClick={pendingQuotes}>Pending Quotes</button>
          <button className='whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-8 text-[10px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen' onClick={quotesApproved}>Quotes Approved</button>
          <button className='whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-8 text-[10px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen' onClick={quotesDeclined}>Quotes Declined</button>
          <button className='whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-8 text-[10px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen' onClick={liveProjects}>Live Projects</button>
        </div>
    </header>
)
}

export default HeaderScaffAdminUpload