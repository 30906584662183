import React, { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import googleMaps from "../pictures/googleMaps.png";
import URL from "../functions/URLString";

// const images = [
//     {
//         link:"https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg",
//     },
//     {
//         link:"https://media.istockphoto.com/id/619503842/photo/wazir-khan-mosque-lahore-pakistan.jpg?s=612x612&w=0&k=20&c=3vrR52_8PkripvQhwP_TOVCW4e9xvv4lIjlSRyPd9Hs=",
//     },
//     {
//         link:"https://media.istockphoto.com/id/538601654/photo/sunehri-mosque-peshawar-pakistan.jpg?s=612x612&w=0&k=20&c=-P-90BMVitvcbTp46wmt-0iojU7uK_Wbme4erbpDjaE=",
//     },
//     {
//         link:"https://www.esa.int/var/esa/storage/images/esa_multimedia/images/2022/07/webb_s_first_images_-_highlights/24358377-1-eng-GB/Webb_s_first_images_-_highlights_pillars.jpg",
//     },
// ]

function ImageSlider({ images, stateSingleJob }) {
  const [image, setImage] = useState(images[0]);
  console.log(images);

  const next = () => {
    if (image === images[images.length - 1]) {
      setImage(images[0]);
      return image;
    } else {
      images.map((im, index) => {
        if (im === image) {
          setImage(images[index + 1]);
        }
        return image;
      });
    }
    return image;
  };

  const back = () => {
    if (image === images[0]) {
      setImage(images[images.length - 1]);
      return image;
    } else {
      images.map((im, index) => {
        if (im === image) {
          setImage(images[index - 1]);
        }
        return image;
      });
    }
    return image;
  };

  return (
    <div className="flex h-auto flex-col w-full mt-5 sm:mt-0">
      <div className="sm:relative sm:top-32 md:top-44 lg:top-10  ">
        <div className="flex flex-col w-full bg-darkgreen border-[2px] border-darkgreen shadow-xl xxl:mt-6">
          <div className="flex h-auto xl:h-auto justify-center w-full bg-white">
            <img
              className="object-contain"
              src={`${URL}/uploads/${image}`}
              alt=""
            />
          </div>
          <div className="bg-darkgreen flex justify-center space-x-32 p-[2px] xxl:p-2">
            <ArrowBackIosNewIcon
              onClick={back}
              sx={{ width: "20px", height: "20px" }}
              className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"
            />
            <ArrowForwardIosIcon
              onClick={next}
              sx={{ width: "20px", height: "20px" }}
              className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"
            />
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2 xxl:space-y-3 pb-0 p-7 px-2 xxl:p-6">
          <div className="basis-1 flex flex-col w-full">
            <h1 className="text-base font-bold xxl:text-2xl">
              Job Description
            </h1>
            <p className="text-base xxl:text-xl">
              {stateSingleJob.jobDescription}
            </p>
          </div>
          <div className="basis-1 flex flex-col w-full">
            <h1 className="text-base font-bold xxl:text-2xl">Job Access</h1>
            <p className="text-base xxl:text-xl">{stateSingleJob.jobDetails}</p>
          </div>
        </div>
        {/* <a href="https://www.google.com/maps" rel="noreferrer" target="_blank"><div className='flex bg-white flex-row space-x-4 w-full justify-start px-4 items-center py-3 xl:py-8 z-10' >
                <img src={googleMaps} alt="mape" className='object-contain w-10 h-10'/>
                <p className='text-sm xxl:text-xl'>Open Google maps in a separate window</p>
            </div></a> */}
      </div>
    </div>
  );
}

export default ImageSlider;
