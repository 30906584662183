import React, { useEffect, useState } from "react";
import logo from "../pictures/TheBestescafflogo.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Sidebar from "../components/Sidebar";
import FourButtons from "../components/FourButtons";
import HeaderScaff from "../components/HeaderScaff";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,

  fetchSingleescaffAdaptionqoute,
  fetchSingleescaffQoute,
  fetchsingletemproryroofqadaptionqoute,
  fetchsingletemproryroofqoute,
  signOut,
} from "../store/adminSlice";
import axios from "../functions/axios";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import $ from "jquery";
import { fetchCompanyDetails,  fetchPriceList } from "../store/customerSlice";
import URL from "../functions/URLString";
$.DataTable = require("datatables.net");

function CustomerTemproryQoutationPDF() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [adaptionDetails, setAdaptionDetails] = useState("");
  const [needed, setNeeded] = useState(false);
  const _id = useSelector((state) => state.customer.customer_id)
  const statePriceList = useSelector((state) => state.customer.priceList)
  const statecompanyDetails = useSelector((state) => state.customer.companyLogo)
  
  const adminID = useSelector((state) => state.customer.adminID)
  const queryParameters = new URLSearchParams(window.location.search);
  const escaffqouteid = queryParameters.get("escaffqouteid");
  const adaptionquoteid = queryParameters.get("adaptionquoteid");
  const dispatch = useDispatch();
  const statesignOut = useSelector((state) => state.admin.signOut);
  const stateEscaffQoutation = useSelector(
    (state) => state.admin.temporaryRoofQoutation
  );
  const stateEscaffAdaptionQoutation = useSelector(
    (state) => state.admin.temporaryRoofAdaptionQoutation
  );

 

  const [data, setData] = useState(stateEscaffQoutation);

  const [qouteData, setQouteData] = useState({
    lowerScaffoldSection: 0,
    tempRoof: 0,
    covering: 0,
    customerCompanyName: "",
    address: "",
    postcode: "",
    temproofCost:0,
    telephone:"",
    job1:"",
    job2:"",
    jobpostcode:"",
    nweek: 0,
    theVatPercentage: 0,
    orignalPercentage: 0,
    scaffoldingCost: 0,
  });

  const navigate = useNavigate();
  const print = () => {
    window.print();
  };
  useEffect(() => {
  
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
    dispatch(adminActions.removeSwalAlert());
    dispatch(
      fetchsingletemproryroofqoute({ adminID: _id, _id: escaffqouteid })
    );
    dispatch(
      fetchsingletemproryroofqadaptionqoute({
        adminID: _id,
        _id: adaptionquoteid,
      })
    );
    handleQouteCalculation();
    dispatch(fetchPriceList({adminID:adminID,customerID:_id}))

    dispatch(fetchCompanyDetails({adminID:adminID,customerID:_id}))
   
  }, []);
  useEffect(() => {

  }, [qouteData, setQouteData,data,setData]);

  const handleQouteCalculation = () => {
    
   
    var WeeklyScaffoldingInspection =
      statePriceList.scaffoldingInspectionPerVisit;
    var lowerScaffoldSection =
      parseInt(data.permiterOfBuilding) *
      parseInt(data.numberOfLiftsForPerimeter) *
      parseInt(statePriceList.linearMerterageCost);

    var upperScaffoldingSectionOne =
      parseInt(data.lengthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      2;

    var upperScaffoldingSectionTwo =
      parseInt(data.widthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      2;

    var covering =
      (upperScaffoldingSectionOne + upperScaffoldingSectionTwo) *
      parseInt(data.monoflexOrShrinkWrap);

    var tempRoof =
      parseInt(data.widthOfBuilding) *
      parseInt(data.lengthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      parseInt(statePriceList.temporaryRoof);

    var totalCostResult = lowerScaffoldSection + covering + tempRoof;
    var temproofCost=totalCostResult

    var weeks = data.numberOfWeeks;
    var inspectionCost=0
    if (data.weeklyScoffoldingInspectionSwitch) {
      var nweek = weeks - 1;
      var r1 = nweek * WeeklyScaffoldingInspection;
      inspectionCost=r1
      totalCostResult = totalCostResult + r1;
      console.log(totalCostResult);
    }
    var theVatPercentage=0
    if (data.customerVatRegisterSwitch) {
       theVatPercentage = (data.customerVatRegister / 100) * totalCostResult;

      totalCostResult = totalCostResult + theVatPercentage;
    }
    var nonpercentage =
      parseInt(data.streetLicenseCost) + parseInt(data.externalDesingCost);
      var overhiredd=0

    if (data.numberOfWeeks > data.standardNumberOfWeek) {
      var percentageWeek = data.numberOfWeeks - data.standardNumberOfWeek;

      var overhired = statePriceList.overhirePercentage * percentageWeek;

      var orignalPercentage = (overhired / 100) * totalCostResult;
      overhiredd=orignalPercentage
      console.log(orignalPercentage, "percentafeoverfi");
      totalCostResult = totalCostResult + nonpercentage + orignalPercentage;
    } else {
      console.log(orignalPercentage, "percentafeoverfi");
      totalCostResult = totalCostResult + nonpercentage;
    }
    setQouteData({...qouteData, lowerScaffoldSection,tempRoof,covering, customerCompanyName: stateEscaffQoutation.customerID.customerCompanyName,
      address: stateEscaffQoutation.customerID.address,
      postcode: stateEscaffQoutation.customerID.postcode,
      temproofCost,
      overhiredd,
      inspectionCost,
      telephone: stateEscaffQoutation.customerID.telephone,
      job1: stateEscaffQoutation.jobID.job1,
      job2: stateEscaffQoutation.jobID.job2,theVatPercentage,
       jobpostcode: stateEscaffQoutation.jobID.postCode})
  };

  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
    setData(stateEscaffQoutation);
    setData(stateEscaffAdaptionQoutation);
  //  checkfetched()
  //  setTimeout( , 2000)

    handleQouteCalculation();

    console.log(stateEscaffAdaptionQoutation, "stateEscaffAdaptionQoutation");
    console.log(data, "stateEscaffAdaptionQoutation");
    console.log(stateEscaffQoutation, "stateEscaffQoutation");
  }, [statesignOut, stateEscaffQoutation, stateEscaffAdaptionQoutation, data]);
  useEffect(() => {
    setData(stateEscaffQoutation);
  }, [qouteData, setQouteData, data, setData]);
  return (
    <div className="w-full h-full">
      {/* <HeaderScaff setSidebarOpen={setSidebarOpen} /> */}
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        {/* <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div> */}

        {/* <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div> */}

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide"
                : "hidden"
              : "w-full overflow-x-scroll scrollbar-hide"
          } " "`}
        >
          <div className="flex flex-col  p-5">
            <div className="ml-4 w-full text-center">
              <span className="font-semibold text-lg">Quotation</span>
            </div>
            <div className="flex justify-between">
              <div className="ml-4">
                <span className=" ">To</span>
                <br />
                <span>{qouteData.customerCompanyName}</span>
                <br />
                <span>{qouteData.address}</span>
                <br />
                <span>{qouteData.postcode}</span>
                <br />
                <span>{qouteData.telephone}</span>
                <br />
              </div>
              <div > <img src={`${URL}/uploads/${statecompanyDetails}`} className=" w-28 object-contain" alt="logo"/></div>

              <div className="mr-4">
                <span className="text-[11px] xxl:text-sm "></span>
                <br />
                <span>{qouteData.job1}</span>
                <br />
                <span>{qouteData.job2}</span>
                <br />
                <span>{qouteData.jobpostcode}</span>
                <br />
              </div>
            </div>

            <div className="lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 ml-1">
              <table className="editor_listing_table " id="table">
                <thead className="w-full ">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base  text-white rounded-l-md font-normal border-[1px] border-r-white"></th>
                    {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job ID</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th> */}
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Quantity
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Cost
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                     Scaffolding Cost To Guttering
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data2.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      1
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {"£" + parseFloat(qouteData.lowerScaffoldSection).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                    Guttering to Temp Roof Cost
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      1
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {"£" + parseFloat(qouteData.tempRoof).toFixed(2)}
                    </td>
                  </tr>
               
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                     MonoFlex or ShrinkWrap Covering Cost
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {/* {data.standardNumberOfWeek} number of weeks */}
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {"£" + parseFloat(qouteData.covering).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                     Hire Cost For Duration
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {data.standardNumberOfWeek} number of weeks
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      {"£" + parseFloat(qouteData.temproofCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      -
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Extra Hire Cost
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      {data.numberOfWeeks - data.standardNumberOfWeek}{" "}
                      number of week
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.overhiredd).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Over Hire Percentage
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm"></td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      %{statePriceList.overhirePercentage}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      -
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Sub Total
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                    £{parseFloat(
                        parseInt(qouteData.tempRoof) +
                          parseInt(qouteData.overhiredd)+parseInt(qouteData.temproofCost)+parseInt(qouteData.covering)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      -
                    </td>
                  </tr>{" "}
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      -
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      -
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      -
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Scaffold inspections
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm"></td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.inspectionCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      Street license
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm"></td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.streetLicenseCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                      External Design
                    </td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      1
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.externalDesingCost).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap"></td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      Sub Total
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £
                      {parseFloat(
                        parseInt(qouteData.tempRoof) +
                        parseInt(qouteData.overhiredd)+parseInt(qouteData.temproofCost)+parseInt(qouteData.covering)+parseInt(qouteData.inspectionCost)+parseInt(data.streetLicenseCost)+parseInt(data.externalDesingCost)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap"></td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      VAT
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(qouteData.theVatPercentage).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap"></td>
                    {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
             <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
                    <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm">
                      Your Total Qoutation
                    </td>
                    <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                      £{parseFloat(data.totalCostResult).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {adaptionquoteid !== "0" ? (
              <>
                <div className="ml-4  text-center  w-full">
                  <span className="font-semibold text-lg">Adaption</span>
                </div>
                <div className="flex justify-between">
                  <div className="ml-4">
                    <span className="">Adaption Details</span>
                    <br />
                    <span>{stateEscaffAdaptionQoutation.adaptionID.adaptionDetails}</span><br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <span className=" font-semibold">Adaption Cost</span><br/>
                    <span className=" font-semibold">£{stateEscaffAdaptionQoutation.totalCostResult}</span><br/>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="flex justify-center items-center">
            <button
              id="abw"
              className="h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
              onClick={() => print()}
            >
              Print
            </button>
            <button id="abw" className='h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen'onClick={()=>navigate(-1)} >Back</button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerTemproryQoutationPDF;
