import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import PanToolIcon from "@mui/icons-material/PanTool";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  adaptionStatusComplete,
  adaptionStatusStart,
  adminActions,
  assignAdaptionToStaffMember,
  assignJobToStaffMember,
  deleteApproveAdaptionQoutes,
  deleteApproveQoutes,
  fetchAdminApprovedAdaptionQoutesLiveProject,
  fetchAdminApprovedQoutesLiveProject,
  fetchJobsForStaffMember,
  fetchKeyStaffMember,
  jobStatusComplete,
  jobStatusStart,
  signOut,
} from "../store/adminSlice";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import moment from "moment";
import UpdateIcon from "@mui/icons-material/Update";
import HeaderScaff from "../components/HeaderScaff";
import { useMediaQuery } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Swal from "sweetalert2";
import $ from "jquery";
$.DataTable = require("datatables.net");

function LiveProjects() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [needed, setNeeded] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateApprovedQoutes = useSelector(
    (state) => state.admin.adminApprovedQoutes
  );
  const stateadminApprovedAdaptionQoutes = useSelector(
    (state) => state.admin.adminApprovedAdaptionQoutes
  );
  const statekeyMemberStaff = useSelector(
    (state) => state.admin.keyMemberStaff
  );
  const jobforStaffMember = useSelector(
    (state) => state.admin.jobforStaffMember
  );
  const _id = useSelector((state) => state.admin._id);
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut = useSelector((state) => state.admin.signOut);

  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }));
    dispatch(fetchAdminApprovedAdaptionQoutesLiveProject({ adminID: _id }));
    dispatch(fetchJobsForStaffMember());
    dispatch(fetchKeyStaffMember({ adminID: _id }));
    dispatch(adminActions.removeSwalAlert());
    setTimeout(() => {
      $("#table").DataTable({ pagingType: "simple_numbers", retrieve: true });
      $("#table2").DataTable({ pagingType: "simple_numbers", retrieve: true });
    }, 5000);
    console.log(stateadminApprovedAdaptionQoutes, "---------adaption");
  }, []);

  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [
    stateApprovedQoutes,
    statekeyMemberStaff,
    jobforStaffMember,
    statesignOut,
  ]);

  const assignStaffMember = (e, b, d) => {
    dispatch(
      assignJobToStaffMember({ jobID: b, staffMemberID: e, qouteID: d })
    );
  };
  const assignStaffMemberAdaption = (e, b, d) => {
    dispatch(
      assignAdaptionToStaffMember({ jobID: b, staffMemberID: e, qouteID: d })
    );
  };
  const deletee = (id) => {
    Swal.fire({
      title: "Do you want to delete the qoutes?",
      text: "All adaptions, dismantles, inspections handovers, etc etc will be deleted with this qoute ! ",
      showDenyButton: true,

      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: "#A7B778",
      customClass: {
        actions: "my-actions",

        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteApproveQoutes({ _id: id, adminID: _id, approved: true })
        ).then(() => {
          dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }));
          dispatch(
            fetchAdminApprovedAdaptionQoutesLiveProject({ adminID: _id })
          );

          // window.location.reload();
        });
      }
    });
  };
  const deleteee = (id) => {
    Swal.fire({
      title: "Do you want to delete the adaption?",
      text: "All adaptions, dismantles, inspections handovers, etc etc will be deleted with this qoute ! ",
      showDenyButton: true,

      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: "#A7B778",
      customClass: {
        actions: "my-actions",

        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteApproveAdaptionQoutes({
            adaptionID: id,
            adminID: _id,
            approved: true,
          })
        ).then(() => {
          dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }));
          dispatch(
            fetchAdminApprovedAdaptionQoutesLiveProject({ adminID: _id })
          );
        });
      }
    });
  };
  const handleStatusSt = ( e, jobID) => {
    e.preventDefault();
    dispatch(jobStatusStart({ jobID: jobID, adminID: _id }));
    dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }));
    //  setTimeout(()=>window.location.reload(true), 3000);
    
  }

  const handleStatusCm = (jobID) => {
    dispatch(jobStatusComplete({ jobID: jobID, adminID: _id }));
    dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }));
    // setTimeout(()=>window.location.reload(true), 3000);

  }
  const handleStatusSta = (id) => {
   console.log(id)
 
    dispatch(adaptionStatusStart({ jobID: id , adminID: _id }));
    dispatch(
      fetchAdminApprovedAdaptionQoutesLiveProject({ adminID: _id })
    );
    
  }

  const handleStatusCma = (id) => {
    dispatch(adaptionStatusComplete({ jobID: id , adminID: _id }));
    dispatch(
      fetchAdminApprovedAdaptionQoutesLiveProject({ adminID: _id })
    );

  }
  return (
    <div className="w-full h-full">
      <HeaderScaff setSidebarOpen={setSidebarOpen} />
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "basis-1/5"
                : "flex justify-center w-full"
              : "hidden"
          } "w-full h-full"`}
        >
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            needed={needed}
            isNonMobile={isNonMobile}
          />
        </div>

        <div
          onClick={() => setSidebarOpen(true)}
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "hidden"
                : "hidden"
              : isNonMobile
              ? "flex w-12 mt-4"
              : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
          } h-fit`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? ""
                  : ""
                : isNonMobile
                ? ""
                : "hidden"
            } `}
          >
            <IconButton onClick={() => setSidebarOpen(true)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "hidden"
                : "flex"
            } `}
          >
            <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
          </div>
        </div>

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide"
                : "hidden"
              : "w-full overflow-x-scroll scrollbar-hide"
          } " "`}
        >
          <div className="w-full flex justify-between mt-4 ">
            <div className="flex space-x-2">
              <p className="text-base xxl:text-2xl text-slate-400 pb-1 ml-2 hover:text-black cursor-pointer whitespace-nowrap">
                Your live projects
                <CircleIcon
                  sx={{ width: "17px", height: "17px" }}
                  className="text-green-600 ml-1"
                />
              </p>
              {/* <p onClick={() => navigate('/overhiredprojects')} className='text-base xxl:text-2xl text-slate-400 pb-1 ml-2 whitespace-nowrap hover:text-black cursor-pointer'>
                    Overhired project
                    <CircleIcon sx={{width:'17px',height:"17px"}} className="text-red-600 ml-1"/>
                </p> */}
            </div>
            {/* <div className='flex space-x-4 items-center'>
                <p className='text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap'>Search for a customer</p>
                <div className='flex items-center'>
                    <SearchIcon sx={{width:'17px',height:"17px"}} className="-mr-5 relative left-0 text-lightgreen"/>
                    <input type="text" className='outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen'/>
                </div>
            </div> */}
          </div>

          <div className="lg:w-full pr-1">
            <table className="editor_listing_table" id="table">
              <thead className="w-full ">
                <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white rounded-l-md font-normal border-[1px] border-r-white">
                   Job ID
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                  No. of Weeks
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Name
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Job Address
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Action Date
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Job Description
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Next Inspection
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Progress
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Partial Dismantle Details
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Dismantle Date
                  </th>
                  {/* <th className='text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white'>Date</th>
            <th className='text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white'>Status</th> */}
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Assign a member of staff
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Mark as
                  </th>
                  <th className="text-sm xxl:text-base text-white font-normal rounded-r-lg ">
                    Action
                  </th>
                </tr>
              </thead>
              <div className="h-2"></div>
              <tbody className=" ">
                {stateApprovedQoutes.length === 0
                  ? ""
                  : stateApprovedQoutes.map((data) => {
                      // console.log(data.nextInspectionDate, "dateeeeeeeeeeeeeee");
                      const today = moment().format("DD-MM-YYYY");
                      console.log(today);
                      const inspectionDate = moment(
                        moment(data.nextInspectionDate)
                          .add(7, "d")
                          .format("DD-MM-YYYY")
                      )._i;
                      const newDate = moment(
                        new Date(data.dateOfErection)
                      ).format("DD-MM-YYYY");
                      const dismantleDate = moment(
                        new Date(data.dismantleRequestedDate)
                      ).format("DD-MM-YYYY");
                      return (
                        <tr className="">
                          
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.jobID.jobNumber}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.type === "escaffQoute"
                              ? data.numberOfWeekRequired
                              : data.numberOfWeeks}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                            {data.customerID.customerCompanyName}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                            {data.jobID.job1 + " " + data.jobID.job2}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                            {newDate}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[10px] xxl:text-[13px]">
                            {data.jobID.jobDescription}
                          </td>
                          <td
                            className={
                              inspectionDate === today
                                ? "w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center  text-red-500"
                                : "w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center  "
                            }
                          >
                            {data.WeeklyScaffoldingInspections
                              ? inspectionDate
                              : data.weeklyScoffoldingInspectionSwitch
                              ? inspectionDate
                              : "not needed"}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center bg-teal-200">
                            {data.jobID.status === 0
                              ? "pending"
                              : data.jobID.status === 1
                              ? "In Progress"
                              : "Completed"}
                          </td>

                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[10px] xxl:text-[13px]">
                            {data.partialDismantleDescription}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                            {dismantleDate}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-start align-top">
                            <select
                              name="staff"
                              id="staff"
                              onChange={(e) =>
                                assignStaffMember(
                                  e.target.value,
                                  data.jobID._id
                                )
                              }
                              className="flex outline-none w-full h-full"
                            >
                              <option value="" className="px-1">
                                Select key Staff member
                              </option>
                              {statekeyMemberStaff.map((staff) =>
                                data.jobID.staffMemberID === staff._id ? (
                                  <option selected value={staff._id}>
                                    {staff.fullName}{" "}
                                  </option>
                                ) : (
                                  <option value={staff._id}>
                                    {staff.fullName}{" "}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                          {(data.jobID.status === 0)?(     <button onClick={(e)=>handleStatusSt(e,data.jobID._id)} class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                             Start
                            </button>    ):(
                  (data.jobID.status === 1)?(
                            <button onClick={()=>handleStatusCm(data.jobID._id)} class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                             Complete
                            </button>
                                ) : (
                                  <button class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                  Completed
                                 </button>
                   
                  )
                )}
                          </td>
                          <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                            <div className="flex flex-row justify-center space-x-2 xxl:space-x-3 px-1">
                              <div className="flex flex-col items-center">
                                <HomeWorkIcon
                                  sx={{ width: "17px", height: "17px" }}
                                  className={
                                    data.completeDismantle
                                      ? "text-red-500 hover:text-darkgreen cursor-pointer"
                                      : "text-lightgreen hover:text-darkgreen cursor-pointer"
                                  }
                                />
                                <p className="text-[10px] xxl:text-sm">
                                  Dismantle
                                </p>
                              </div>
                              <div
                                className="flex flex-col items-center"
                                onClick={(e) =>
                                  navigate(
                                    `/scaffoldinginspections?cutomerName=${
                                      data.customerID.customerCompanyName
                                    }&cutomerAddress=${
                                      data.customerID.address
                                    }&siteAddress=${
                                      data.jobID.job1 + " " + data.jobID.job2
                                    }&customerID=${data.customerID._id}&jobID=${
                                      data.jobID._id
                                    }&type=${
                                      data.type === "escaffQoute"
                                        ? "escaffQoute"
                                        : "temproof"
                                    }`
                                  )
                                }
                              >
                                {today === inspectionDate ? (
                                  <>
                                    {" "}
                                    <UpdateIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className=" text-red-600 hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] text-red-600 xxl:text-sm">
                                      Inspection
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <UpdateIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] xxl:text-sm">
                                      Inspection
                                    </p>
                                  </>
                                )}
                              </div>
                              <div
                                className="flex flex-col items-center"
                                onClick={(e) =>
                                  navigate(
                                    `/form?id=${data.customerID._id}&clientName=${data.customerID.customerCompanyName}&address=${data.jobID.job1}&postcode=${data.jobID.postCode}&adminname=${data.adminID.fullName}&adminemail=${data.adminID.email}`
                                  )
                                }
                              >
                                {data.jobID.status === 2 ? (
                                  <>
                                    <PanToolIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-red-600 hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] text-red-600 xxl:text-sm">
                                      Handover
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <PanToolIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] xxl:text-sm">
                                      Handover
                                    </p>
                                  </>
                                )}
                              </div>
                              {data.jobID.typeofScafollding === "0" ? (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/createquotation?id=${data.jobID._id}`
                                    )
                                  }
                                  className="flex flex-col items-center"
                                >
                                  <InsertDriveFileOutlinedIcon
                                    sx={{ width: "17px", height: "17px" }}
                                    className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  />
                                  <p className="text-[10px] xxl:text-sm">
                                    Quote
                                  </p>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/temporaryroofquotes?id=${data.jobID._id}`
                                    )
                                  }
                                  className="flex flex-col items-center"
                                >
                                  <InsertDriveFileOutlinedIcon
                                    sx={{ width: "17px", height: "17px" }}
                                    className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  />
                                  <p className="text-[10px] xxl:text-sm">
                                    Quote
                                  </p>
                                </div>
                              )}
                              <div className="flex flex-col items-center">
                                <DeleteIcon
                                  sx={{ width: "17px", height: "17px" }}
                                  className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  onClick={(e) => deletee(data.jobID._id)}
                                />
                                <p className="text-[10px] xxl:text-sm">
                                  Delete
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="w-full flex justify-between mt-4 ">
            <div className="flex space-x-2">
              <p className="text-base xxl:text-2xl text-slate-400 pb-1 ml-2 hover:text-black cursor-pointer whitespace-nowrap">
                Your Adaptions
                <CircleIcon
                  sx={{ width: "17px", height: "17px" }}
                  className="text-green-600 ml-1"
                />
              </p>
            </div>
            {/* <div className='flex space-x-4 items-center'>
                <p className='text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap'>Search for a customer</p>
                <div className='flex items-center'>
                    <SearchIcon sx={{width:'17px',height:"17px"}} className="-mr-5 relative left-0 text-lightgreen"/>
                    <input type="text" className='outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen'/>
                </div>
            </div> */}
          </div>

          <div className="lg:w-full pr-1">
            <table className="editor_listing_table" id="table2">
              <thead className="w-full ">
                <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white rounded-l-md font-normal border-[1px] border-r-white">
                   Job ID
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                  No. of Weeks
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Name
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Job Address
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Action Date
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Job Description
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Next Inspection
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Progress
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Adaption Details
                  </th>
                  {/* <th className='text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white'>Date</th>
            <th className='text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white'>Status</th> */}
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Assign a member of staff
                  </th>
                  <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                    Marked as
                  </th>
                  <th className="text-sm xxl:text-base text-white font-normal rounded-r-lg ">
                    Action
                  </th>
                </tr>
              </thead>
              <div className="h-2"></div>
              <tbody className=" ">
                {stateadminApprovedAdaptionQoutes.length === 0
                  ? ""
                  : stateadminApprovedAdaptionQoutes.map((data) => {
                      // console.log(data.nextInspectionDate, "dateeeeeeeeeeeeeee");
                      const today = moment().format("DD-MM-YYYY");
                      console.log(today);
                      const inspectionDate = moment(
                        moment(data.nextInspectionDate)
                          .add(7, "d")
                          .format("DD-MM-YYYY")
                      )._i;
                      const newDate = moment(
                        new Date(data.dateOfErection)
                      ).format("DD-MM-YYYY");
                      const dismantleDate = moment(
                        new Date(data.dismantleRequestedDate)
                      ).format("DD-MM-YYYY");
                      return (
                        <tr className="">
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.jobID.jobNumber}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.type === "escaffQoute"
                              ? data.numberOfWeekRequired
                              : data.numberOfWeeks}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                            {data.customerID.customerCompanyName}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                            {data.jobID.job1 + " " + data.jobID.job2}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                            {newDate}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[10px] xxl:text-[13px]">
                            {data.jobID.jobDescription}
                          </td>
                          <td
                            className={
                              inspectionDate === today
                                ? "w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center  text-red-500"
                                : "w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center  "
                            }
                          >
                            {data.WeeklyScaffoldingInspections
                              ? inspectionDate
                              : data.weeklyScoffoldingInspectionSwitch
                              ? inspectionDate
                              : "not needed"}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center bg-teal-200">
                            {data.adaptionID.status === 0
                              ? "pending"
                              : data.adaptionID.status === 1
                              ? "In Progress"
                              : "Completed"}
                          </td>

                          <td className="w-3/12 border-[1px] border-gray-300 p-1 text-[10px] xxl:text-[13px]">
                            {data.adaptionID.adaptionDetails}
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-start align-top">
                            <select
                              name="staff"
                              id="staff"
                              onChange={(e) =>
                                assignStaffMemberAdaption(
                                  e.target.value,
                                  data.adaptionID._id
                                )
                              }
                              className="flex outline-none w-full h-full"
                            >
                              <option value="" className="px-1">
                                Select key Staff member
                              </option>
                              {statekeyMemberStaff.map((staff) =>
                                data.adaptionID.staffMemberID === staff._id ? (
                                  <option selected value={staff._id}>
                                    {staff.fullName}{" "}
                                  </option>
                                ) : (
                                  <option value={staff._id}>
                                    {staff.fullName}{" "}
                                  </option>
                                )
                              )}
                            </select>
                          </td>
                          <td className="w-1/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                          {(data.adaptionID.status === 0)?(     <button onClick={(e)=>handleStatusSta(data.adaptionID._id)} class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                             Start
                            </button>    ):(
                  (data.adaptionID.status === 1)?(
                            <button onClick={()=>handleStatusCma(data.adaptionID._id)} class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                             Complete
                            </button>
                                ) : (
                                  <button class="bg-lightgreen hover:bg-lightgreen text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                  Completed
                                 </button>
                   
                  )
                )}
                          </td>
                          <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                            <div className="flex flex-row justify-center space-x-2 xxl:space-x-3 px-1">
                              <div
                                className="flex flex-col items-center"
                                onClick={(e) =>
                                  navigate(
                                    `/scaffoldinginspectionsadaption?adaptionID=${
                                      data.adaptionID._id
                                    }&cutomerName=${
                                      data.customerID.customerCompanyName
                                    }&cutomerAddress=${
                                      data.customerID.address
                                    }&siteAddress=${
                                      data.jobID.job1 + data.jobID.job2
                                    }&customerID=${data.customerID._id}&jobID=${
                                      data.jobID._id
                                    }&type=${
                                      data.type === "escaffQoute"
                                        ? "escaffQoute"
                                        : "temproof"
                                    }`
                                  )
                                }
                              >
                                {today === inspectionDate ? (
                                  <>
                                    {" "}
                                    <UpdateIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className=" text-red-600 hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] text-red-600 xxl:text-sm">
                                      Inspection
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <UpdateIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] xxl:text-sm">
                                      Inspection
                                    </p>
                                  </>
                                )}
                              </div>
                              <div
                                className="flex flex-col items-center"
                                onClick={(e) =>
                                  navigate(
                                    `/adaptionhandover?adaptionID=${data.adaptionID._id}&id=${data.customerID._id}&clientName=${data.customerID.customerCompanyName}&address=${data.jobID.job1}&postcode=${data.jobID.postCode}&adminname=${data.adminID.fullName}&adminemail=${data.adminID.email}`
                                  )
                                }
                              >
                                {data.adaptionID.status === 2 ? (
                                  <>
                                    <PanToolIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-red-600 hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] text-red-600 xxl:text-sm">
                                      Handover
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <PanToolIcon
                                      sx={{ width: "17px", height: "17px" }}
                                      className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                    />
                                    <p className="text-[10px] xxl:text-sm">
                                      Handover
                                    </p>
                                  </>
                                )}
                              </div>
                              {data.jobID.typeofScafollding === "0" ? (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/createadaptionqoutation?adaptionID=${data.adaptionID._id}&id=${data.jobID._id}`
                                    )
                                  }
                                  className="flex flex-col items-center"
                                >
                                  <InsertDriveFileOutlinedIcon
                                    sx={{ width: "17px", height: "17px" }}
                                    className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  />
                                  <p className="text-[10px] xxl:text-sm">
                                    Quote
                                  </p>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/temporaryroofadaptionQuotes?adaptionID=${data.adaptionID._id}&id=${data.jobID._id}`
                                    )
                                  }
                                  className="flex flex-col items-center"
                                >
                                  <InsertDriveFileOutlinedIcon
                                    sx={{ width: "17px", height: "17px" }}
                                    className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  />
                                  <p className="text-[10px] xxl:text-sm">
                                    Quote
                                  </p>
                                </div>
                              )}
                              <div className="flex flex-col items-center">
                                <DeleteIcon
                                  sx={{ width: "17px", height: "17px" }}
                                  className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                  onClick={(e) => deleteee(data.adaptionID._id)}
                                />
                                <p className="text-[10px] xxl:text-sm">
                                  Delete
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiveProjects;
