import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import logoo from "../pictures/TheBestescafflogo.png";
import URL from "../functions/URLString";
import { useNavigate } from 'react-router-dom';
import { staffActions } from '../store/staffSlice';

function StaffLogo() {
    const [companyLogo, setCompanyLogo] = useState("");
    const logo = useSelector((state) => state.staff.companyLogo);
    const StatesignOut = useSelector((state) => state.staff.signOut);
    const id = useSelector((state) => state.staff.staff_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
    useEffect(() => {
      setCompanyLogo(logo);
    }, [logo]);
  
    useEffect(() => {
      setCompanyLogo(logo);
      console.log(logo,"---",logo)
    }, []);
    useEffect(() => {
      if (StatesignOut === "signOut" || id==="") {
          dispatch(staffActions.removesignout())
        dispatch(staffActions.signout())
      navigate("/")
    }
  },[StatesignOut])
  
    return (
      <>
        <img
        //   className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
          className="w-[280px] h-[100px] md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] object-contain"
          src={companyLogo !== "" ? `${URL}/uploads/` + logo : logoo}
          alt="logo"
        />
      </>
    )
}

export default StaffLogo