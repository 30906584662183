import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import Sidebar from '../components/Sidebar'
import FourButtons from '../components/FourButtons'
import { Switch } from '@mui/material';
import outro from '../pictures/outro.png'
import { useDispatch, useSelector } from 'react-redux';
import { addTemproryRoofAdaoptionQoute, addTemproryRoofQoute, adminActions, fetchPriceList, fetchSingleJob, fetchTemproryRoofAdaptionQoutation, fetchTemproryRoofQoutation, signOut } from '../store/adminSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import escapeHtml from '../functions/Validator';
import Swal from 'sweetalert2';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

function TemporaryRoofAdaptionQuotes() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [needed, setNeeded] = useState(false);
  const _id=useSelector((state)=>state.admin._id)
  const navigate=useNavigate();

  const dispatch=useDispatch();

  const queryParameters = new URLSearchParams(window.location.search)

  const id = queryParameters.get("id")
  const adaptionID = queryParameters.get("adaptionID")
  const stateSingleJob=useSelector((state)=>state.admin.singleJob)
  const StateTemporaryRoofQoutation = useSelector((state) => state.admin.temporaryRoofAdaptionQoutation)
  const escaffQuotationSwalAlert=useSelector((state)=>state.admin.swalAlert)
  const [data, setData] = useState(StateTemporaryRoofQoutation)
  const [result, setResult] = useState(0)
  const statesignOut=useSelector((state)=>state.admin.signOut)
  const onChangeDate = e => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setData({ ...data, dateOfErection: newDate ,nextInspectionDate:newDate, customerID: stateSingleJob.customer._id,jobID:stateSingleJob._id,adaptionID:adaptionID})
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
  useEffect(() => {
   
    if(id){
        dispatch(fetchSingleJob({_id:id,adminID:_id}))
        dispatch(fetchPriceList({adminID:_id}))
    }else{
        navigate("/pendingprojectqoutes")
    }
    
  }, [])
  const subscribed=useSelector((state)=>state.admin.subscribed);
    useEffect(() => {
      if(!_id){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
        
      }
    },[])
  useEffect(() => {
    if(escaffQuotationSwalAlert.message==="Saved"){
        Swal.fire({
            icon: 'success',
            title: 'Saved',
            confirmButtonColor:"#A7B778",
            }).then(() => {
                dispatch(adminActions.removeSwalAlert());
                window.location.reload();
            })
    }
    if(escaffQuotationSwalAlert.message==="updatedEscaff") {
        Swal.fire({
            icon: 'success',
            title: 'Updated',
            confirmButtonColor:"#A7B778",
        }).then(() => {
            dispatch(adminActions.removeSwalAlert());
            window.location.reload();
        })
    }
  }, [escaffQuotationSwalAlert])
  useEffect(() => {
    dispatch(fetchTemproryRoofAdaptionQoutation({adaptionID:adaptionID,adminID:_id}))
    dispatch(adminActions.removeSwalAlert());
  }, [])
  useEffect(()=>{
    if(!window.location.hash) {
        // console.log("one");
        window.location = window.location + '#loaded';
        window.location.reload();
    }
    
},[])
  useEffect(() => {
    setData(StateTemporaryRoofQoutation)
  },[StateTemporaryRoofQoutation])

  const handleSubmit = () => {
    if(data.dateOfErection===""||data.adaptionCost===0){
      Swal.fire({
          icon: 'error',
          title: 'Oops...',
          confirmButtonColor:"#A7B778",
          text: 'Kindly calculate first.!',
        })
    } else {
      
      console.log(data);
    dispatch(addTemproryRoofAdaoptionQoute(data));
  }
  }
  return (
    <div className='w-full h-full'>
      <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24'>
        <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" />
        <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 xl:-mt-5 xxl:-mt-8'>
          <FourButtons/>
       </div>
   </header>
   <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
        <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
          <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
        </div>

        <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

        <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 flex w-full flex-col lg:flex-row": "hidden" : "flex w-full flex-col lg:flex-row" } " "`}>
        {/* <div className='lg:basis-3/4 flex w-full flex-col lg:flex-row'> */}

        <div className='flex flex-col basis-1 sm:basis-1/2 w-full px-1 xxl:mt-4'>

        <p className='text-base xxl:text-2xl text-slate-400'>Temporary roof quote</p>
        <div className='flex flex-col w-full mt-2'>
            <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Date of Adaption</p>
            <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1'value={data.dateOfErection} type="date"onChange={onChangeDate} required/>


          

            <hr className='my-2 bg-black h-[2px] w-full opacity-25'/>


            <p className='px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base'>Adaption Cost</p>
            <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-3/5 outline-none pl-4 xxl:pl-6 z-20' onChange={(e)=>setData({...data,totalCostResult:e.target.value})} value={data.totalCostResult} type="number" onWheel={(e) => e.target.blur()} required/><i className='z-10 relative pl-1 xxl:pl-2 text-black -mt-6 xxl:-mt-8 xxl:pb-2'>£</i>

           

            <div className='flex flex-row space-x-1 pt-2'>
                <button className='self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg' onClick={handleSubmit}>Submit</button>
            </div>

        </div>
        </div>
        
        <div className='flex w-full flex-col basis-1/2 h-fit sticky top-16'>
          <div className='sticky top-24 flex xxl:pt-40'>
            <img src={outro} alt="" className='object-contain flex' />
            </div>
            <div className='flex w-full flex-col space-y-2 xxl:space-y-3 pb-0 p-7 px-2 xxl:p-6'>
                <div className='basis-1 flex flex-col w-full'>
                    <h1 className='text-base font-bold xxl:text-2xl'>Job Description</h1>
                    <p className='text-base xxl:text-xl'>{stateSingleJob.jobDescription}</p>
                </div>
                <div className='basis-1 flex flex-col w-full'>
                    <h1 className='text-base font-bold xxl:text-2xl'>Job Access</h1>
                    <p className='text-base xxl:text-xl'>{stateSingleJob.jobDetails}</p>
                </div>
            </div>
          </div>
          

        </div>
        

      </div>
      
</div>
  )
}

export default TemporaryRoofAdaptionQuotes