import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomerFourButtonsTwo from '../components/CustomerFourButtonsTwo';
import CustomerSidebar from '../components/CustomerSidebar';
import CustomerLogo from '../components/CustomerLogo';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { fetchAdaptionHandOver, fetchCompanyDetails, fetchHandOver, fetchHealth } from '../store/customerSlice';
import URL from '../functions/URLString';
import axios from "../functions/axios"
import { saveAs } from 'file-saver';
function CustomerCompanyDetails() {
  const [needed, setNeeded] = useState(false);
  const navigate = useNavigate();
  const stateDocuments = useSelector((state)=>state.customer.companyDocuments);
  const stateCompanyDetails = useSelector((state)=>state.customer.companyDetails);
  const adminID = useSelector((state) => state.customer.adminID)
  const customerID = useSelector((state) => state.customer.customer_id)
    const stateHandOver = useSelector((state) => state.customer.handOver)
    const adaptionhandOver = useSelector((state) => state.customer.adaptionhandOver)
    const stateHealth=useSelector((state) => state.customer.healthAndSafety)
  

  const dispatch = useDispatch();

  useEffect(()=>{
      dispatch(fetchCompanyDetails({ adminID: adminID ,customerID}))
      dispatch(fetchHandOver({customerID}))
      dispatch(fetchAdaptionHandOver({customerID}))
      dispatch(fetchHealth({customerID}))
    // console.log(stateCompanyDetails);
  }, [])
    
  useEffect(()=>{
 
  }, [stateHandOver])
    const handleHandOver = (_id) => {
        axios.post("/customer/fetchsinglehandover", { _id: _id }).then((res) => {
            console.log(res.data)
            axios.post('/createpdftemplate',res.data)
            .then(() => axios.get('/fetchfile',
       
              {
                headers: {
                  'Content-type': 'application/json',
                },
                responseType: 'blob'
              }
      
            ))
            
            .then((res) => {
              // console.log(res.data);
                const pdfBlob = new Blob([res.data], { type: "application/pdf" })
                // console.log(JSON.stringify(pdfBlob));
              saveAs(pdfBlob, "new.pdf");
             
            })
        })
        
        
    }
    const handleAdaptionHandOver = (_id) => {
        axios.post("/customer/fetchsingleadaptionhandover", { _id: _id }).then((res) => {
            console.log(res.data)
            axios.post('/createpdftemplate',res.data)
            .then(() => axios.get('/fetchfile',
       
              {
                headers: {
                  'Content-type': 'application/json',
                },
                responseType: 'blob'
              }
      
            ))
            
            .then((res) => {
              // console.log(res.data);
                const pdfBlob = new Blob([res.data], { type: "application/pdf" })
                // console.log(JSON.stringify(pdfBlob));
              saveAs(pdfBlob, "new.pdf");
             
            })
        })
        
        
    }
        
  return (
    <div className='w-full h-full'>
        <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 '>
            {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
            <CustomerLogo />
            <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
                <CustomerFourButtonsTwo/>
            </div>
        </header>
        <div className='w-full flex  h-auto p-6'>
            <CustomerSidebar needed={needed}/>

            <div className='lg:basis-3/4 w-full'>
                <div className='flex md:flex-row flex-col w-full md:space-x-5 mt-5'>
                    <div className='flex flex-col w-full md:w-1/3'>
                        <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Uploaded Documents</p>
                         <table className='w-full'>
                            <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                            <th className='text-start px-2 py-1 text-xs xxl:text-lg text-white rounded-l-md font-normal border-[1px] border-r-white'>File Name</th>
                            <th className='rounded-r-md w-16 lg:w-44'></th>
                            </tr>
                            <div className='h-2'></div>
                            {
                                stateDocuments.map((data) => {
                                    var name=data.split("-")
                                    return (
                                        <tr key={data} className=''>
                                            <td className='w-2/3 border-[1px] border-gray-300 pl-3 p-1 text-sm xxl:text-lg'>{name[3]}</td>
                                            <td className=' border-[1px] border-gray-300 p-1'>
                                            <div className='flex flex-row justify-center space-x-2 xxl:space-x-6'>
                                                <a href={`${URL}/uploads/${data}`}>
                                                    <div className='flex flex-col items-center cursor-pointer'>
                                                        <UploadFileIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm'>View</p>
                                                    </div>
                                                </a>
                                            </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>

                    </div>

                    <div className='flex flex-col w-full md:w-1/3'>
                        <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Handover Documents</p>
                        <table className='w-full'>
                            <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                            <th className='text-start px-2 py-1 text-xs xxl:text-lg text-white rounded-l-md font-normal border-[1px] border-r-white'>File Name</th>
                            <th className='rounded-r-md w-16 lg:w-44'></th>
                            </tr>
                            <div className='h-2'></div>
                            {
                                stateHandOver.map((data) => {
                                  
                                    return (
                                        <tr key={data} className=''>
                                            <td className='w-2/3 border-[1px] border-gray-300 pl-3 p-1 text-sm xxl:text-lg'>{ data.address}</td>
                                            <td className=' border-[1px] border-gray-300 p-1'>
                                            <div className='flex flex-row justify-center space-x-2 xxl:space-x-6'>
                                               
                                                    <div className='flex flex-col items-center cursor-pointer' onClick={(e)=>handleHandOver(data._id)}>
                                                        <UploadFileIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm' >View</p>
                                                    </div>
                                                
                                            </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                      </div>
                    <div className='flex flex-col w-full md:w-1/3'>
                        <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Health & Safety</p>
                        <table className='w-full'>
                            <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                            <th className='text-start px-2 py-1 text-xs xxl:text-lg text-white rounded-l-md font-normal border-[1px] border-r-white'>File Name</th>
                            <th className='rounded-r-md w-16 lg:w-44'></th>
                            </tr>
                            <div className='h-2'></div>
                            {
                                stateHealth.map((data) => {
                                  
                                    return (
                                        <tr key={data} className=''>
                                            <td className='w-2/3 border-[1px] border-gray-300 pl-3 p-1 text-sm xxl:text-lg'>{ data.documents.split("-")[3]}</td>
                                            <td className=' border-[1px] border-gray-300 p-1'>
                                            <div className='flex flex-row justify-center space-x-2 xxl:space-x-6'>
                                               
                                                  <a href={`${URL}/uploads/${data.documents}`} download>  <div className='flex flex-col items-center cursor-pointer' >
                                                        <UploadFileIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm' >View</p>
                                                    </div>
                                                    </a>
                                                
                                            </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        </div>

                  </div>
                  <div className='flex md:flex-row flex-col w-full md:space-x-5 mt-5'>
                  <div className='flex flex-col w-full md:w-1/3'>
                        <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Adaption Hand Over</p>
                        <table className='w-full'>
                            <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                            <th className='text-start px-2 py-1 text-xs xxl:text-lg text-white rounded-l-md font-normal border-[1px] border-r-white'>File Name</th>
                            <th className='rounded-r-md w-16 lg:w-44'></th>
                            </tr>
                            <div className='h-2'></div>
                            {
                                adaptionhandOver.map((data) => {
                                  
                                    return (
                                        <tr key={data} className=''>
                                            <td className='w-2/3 border-[1px] border-gray-300 pl-3 p-1 text-sm xxl:text-lg'>{ data.address}</td>
                                            <td className=' border-[1px] border-gray-300 p-1'>
                                            <div className='flex flex-row justify-center space-x-2 xxl:space-x-6' onClick={(e)=>handleAdaptionHandOver(data._id)}>
                                               
                                                   <div className='flex flex-col items-center cursor-pointer' >
                                                        <UploadFileIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm' >View</p>
                                                    </div>
                                                
                                                
                                            </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                      </div>
                      
                  </div>
                </div>
            </div>
    </div>
  )
}

export default CustomerCompanyDetails