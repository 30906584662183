import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../functions/axios";

const customerData= localStorage.getItem("customerData")!==null ? JSON.parse(localStorage.getItem("customerData")):{customer_id:"",customerEmail:"",customerFullName:"",customerIsLoading:false} 

const config = {
    headers:{
        authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
        // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
    }
  };
export const fetchCustomerPendingQoutes = createAsyncThunk('/customerpendingQoutes', async (user) => {
    const res = await axios.post("/customer/pendingqoutes",user,config);
    return res.data;
})
export const fetchCustomerPendingAdaptionQoutes = createAsyncThunk('/fetchCustomerPendingAdaptionQoutes', async (user) => {
    const res = await axios.post("/customer/pendingadaptionqoutes",user,config);
    return res.data;
})
export const fetchCustomerApprovedQoutes = createAsyncThunk('/fetchCustomerApprovedQoutes', async (user) => {
    const res = await axios.post("/customer/fetchcustomerapprovedQoutes",user,config);
    return res.data;
})
export const fetchCustomerApprovedAdaptionQoutes = createAsyncThunk('/fetchCustomerApprovedAdaptionQoutes', async (user) => {
    const res = await axios.post("/customer/fetchcustomerapprovedadaptionQoutes",user,config);
    return res.data;
})
export const fetchCustomerAllQoutes = createAsyncThunk('/fetchcustomerallqoutes', async (user) => {
    const res = await axios.post("/customer/fetchcustomerallqoutes",user,config);
    return res.data;
})
export const fetchCustomerAllAdaptionQoutes = createAsyncThunk('/fetchcustomeralladaptionqoutes', async (user) => {
    const res = await axios.post("/customer/fetchcustomeralladaptionqoutes",user,config);
    return res.data;
})
export const customerLogin = createAsyncThunk('/customerLogin',async(user)=>{
const res = await axios.post("/customer/customerLogin",user,config);
return res.data;
})
export const approveEscaffQoutes = createAsyncThunk('/approveEscaffQoutes',async(user)=>{
const res = await axios.post("/customer/approveescaffqoute",user,config);
return res.data;
})
export const approveEscaffAdaptionQoutes = createAsyncThunk('/approveEscaffAdaptionQoutes',async(user)=>{
const res = await axios.post("/customer/approveescaffadaptionqoute",user,config);
return res.data;
})
export const approvetemproryroofqoute = createAsyncThunk('/approvetemproryroofqoute',async(user)=>{
const res = await axios.post("/customer/approvetemproryroofqoute",user,config);
return res.data;
})
export const approvetemproryroofAdaptionqoute = createAsyncThunk('/approvetemproryroofAdaptionqoute',async(user)=>{
    const res = await axios.post("/customer/approvetemproryroofadaptionqoute",user,config);
    return res.data;
})
export const declineEscaffQoutes = createAsyncThunk('/declineEscaffQoutes',async(user)=>{
const res = await axios.post("/customer/declineescaffqoute",user,config);
return res.data;
})
export const declineEscaffAdaptionQoutes = createAsyncThunk('/declineEscaffAdaptionQoutes',async(user)=>{
const res = await axios.post("/customer/declineescaffadaptionqoute",user,config);
return res.data;
})
export const declinetemproryroofqoute = createAsyncThunk('/declinetemproryroofqoute',async(user)=>{
const res = await axios.post("/customer/declinetemproryroofqoute",user,config);
return res.data;
})
export const declinetemproryroofAdaptionqoute = createAsyncThunk('/declinetemproryroofAdaptionqoute',async(user)=>{
const res = await axios.post("/customer/declinetemproryroofadaptionqoute",user,config);
return res.data;
})
export const addANewJob = createAsyncThunk('/addanewjob',async(user)=>{
    const res = await axios.post("/customer/addanewjob",user,config);
    return res.data;
})
export const fetchCompanyDetails = createAsyncThunk('/fetchcompanydetails',async(user)=>{
    const res = await axios.post("/customer/fetchcompanydetails",user,config);
    return res.data;
})
export const fetchHandOver = createAsyncThunk('/fetchHandOver',async(user)=>{
    const res = await axios.post("/customer/fetchhandover",user,config);
    return res.data;
})
export const fetchAdaptionHandOver = createAsyncThunk('/fetchAdaptionHandOver',async(user)=>{
    const res = await axios.post("/customer/fetchadaptionhandOver",user,config);
    return res.data;
})
export const fetchHealth = createAsyncThunk('/fetchHealth',async(user)=>{
    const res = await axios.post("/customer/fetchhealth",user,config);
    return res.data;
})
export const fetchCustomerInspectionList = createAsyncThunk('/fetchCustomerInspectionList',async(user)=>{
    const res = await axios.post("/customer/fetchcustomerinspectionlist",user,config);
    return res.data;
})
export const fetchCustomerAdaptionInspectionList = createAsyncThunk('/fetchCustomerAdaptionInspectionList',async(user)=>{
    const res = await axios.post("/customer/fetchcustomeradaptioninspectionlist",user,config);
    return res.data;
})
export const fetchCustomerSinfleInspectionList = createAsyncThunk('/fetchCustomerSinfleInspectionList',async(user)=>{
    const res = await axios.post("/customer/fetchcustomerinspectionlist",user,config);
    return res.data;
})
export const fetchPriceList = createAsyncThunk('/fetchpriceList',async(user)=>{
    const res = await axios.post("/customer/fetchpricelist",user,config);
    return res.data;
})
export const signOut = createAsyncThunk("/signOut", async (user) => {
    const res = await axios.post("/admin/signout",user,config);
    return res.data;   
})
const customerSlice=createSlice({
    name:"post",
    initialState:{
        customer_id:customerData.customer_id,
        customerEmail:customerData.customerEmail,
        customerFullName:customerData.customerFullName,
        customerIsLoading:customerData.customerIsLoading,
        customerAlert: ["","",""],
        customerpendingQoutes: [],
        customerpendingAdaptionQoutes: [],
        customerAllQoutes: [],
        customerAllAdaptions:[],
        adminID:customerData.adminID,
        swalAlert:"",
        companyDetails:{customerOrCompanyName:"",telephone:"",email:"",address1:"",address2:"",postCode:""},
        companyDocuments:[],
        companyLogo: "",
        approvedQoutes: [],
        approvedAdaptionQoutes: [],
        handOver: [],
        adaptionhandOver: [],
        customerInspection: [],
        customerAdaptionInspection: [],
        singleInspection: {},
        priceList: { linearMerterageCost: "35.00", hopUpCost: "35.00", saddleCost: "36.00", throwinCost: "35.00", hakkiLayher: "35.00", temporaryRoof: "28.00", monoflex: "3.00", shrinkwrap: "6.00", fivebyonepointeightTower: "175.00", fivebyonepointeightTowerCostPerLift: "75.00", eightbytwopointfourTower: "200.00", eightbytwopointfourTowerCostPerLift: "85.00", tenbythreeTower: "250.00", tenbythreeTowerCostPerLift: "95.00", thirteenbyfourTower: "300.00", thirteenbyfourTowerCostPerLift: "100.00", scaffoldingInspectionPerVisit: "75.00", overhirePercentage: "10", addItem: [], adminID: customerData.adminID },
        signOut: "",
        healthAndSafety:[]
                 

    },
    reducers:{
        addAlert(state,action){
            state.customerAlert=[action.payload.type,action.payload.title,action.payload.message]
        },
        removeSwalAlert(state,action){
            state.swalAlert="";
        },
        signout(state, action) {
            state.customer_id = ""
            state.customerFullName =""
            state.customerEmail =""
            state.customerIsLoading = ""
            state.adminID = ""
        },
        removesignout(state, action) {
            state.signOut = ""
          
        }
    },
    extraReducers: {
        [addANewJob.fulfilled]:(state,{payload})=>{
            state.swalAlert="added"
        },
        [fetchCompanyDetails.fulfilled]:(state,{payload})=>{
            state.companyDetails = {customerOrCompanyName:payload[0].customerOrCompanyName,telephone:payload[0].telephone,email:payload[0].email,address1:payload[0].address1,address2:payload[0].address2,postCode:payload[0].postCode}
            state.companyDocuments = payload[0].companyDocument;
            state.companyLogo = payload[0].companyLogo;
        },
        [customerLogin.fulfilled]: (state, { payload }) => {
            if (payload.message === "email") {
                state.customerAlert = ["unsuccess", "Unsuccessfull", "E-mail Not Found!"]
            } else if (payload.message === "password") {
                state.customerAlert = ["unsuccess", "Unsuccessfull", "Incorrect Password!"]
            } else {
                localStorage.setItem('customerData', JSON.stringify({ customer_id: payload._id, customerFullName: payload.customerCompanyName, customerEmail: payload.email, customerIsLoading: false ,adminID:payload.adminID}))

                state.customer_id = payload._id
                state.customerFullName = payload.customerCompanyName
                state.customerEmail = payload.email
                state.customerIsLoading = false
                state.adminID = payload.adminID
                state.customerAlert = ["success", "LoggedIn Successfully!", ""]
            }
        },
        [fetchCustomerPendingQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                if (payload.length !== 0) {
                    state.customerpendingQoutes = payload
                    // state.companyLogo = payload.adminID.companyLogo
                    console.log(payload);
                }
            }
        }
        ,
        [fetchCustomerPendingAdaptionQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                if (payload.length !== 0) {
                    state.customerpendingAdaptionQoutes = payload
                    // state.companyLogo = payload.adminID.companyLogo
                    console.log(payload);
                }
            }
        }
        ,
        [approveEscaffQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
          
                state.customerpendingQoutes = payload
            }
            
        }
        ,
        [approveEscaffAdaptionQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
          
                state.customerpendingAdaptionQoutes = payload
            }
            
        }
        ,
        [fetchPriceList.fulfilled]: (state, { payload }) => {
            console.log(payload)
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                if (!payload.error) {
                    if (payload == null) {
                    } else {
                        state.priceList = payload;
                    }
                }
            }
        },
        [approvetemproryroofqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingQoutes = payload
            }
        }
        ,
        [approvetemproryroofAdaptionqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingAdaptionQoutes = payload
            }
        }
        ,
        [fetchCustomerAllAdaptionQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerAllAdaptions = payload
            }
        }
        ,
        [fetchCustomerAllQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerAllQoutes = payload
            }
        }
        ,
        [declineEscaffQoutes.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingQoutes = payload
            }
        }
        ,
        [declineEscaffAdaptionQoutes.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingAdaptionQoutes = payload
            }
        }
        ,
        [declinetemproryroofqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingQoutes = payload
            }
        
        },
        [declinetemproryroofqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerpendingAdaptionQoutes = payload
            }
        
        },
        [fetchCustomerApprovedQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.approvedQoutes = payload
            }
        },
        [fetchCustomerApprovedAdaptionQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.approvedAdaptionQoutes = payload
            }
        },
        [fetchHandOver.fulfilled]: (state, { payload }) => {
          if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.handOver = payload
            }
            
        },
        [fetchAdaptionHandOver.fulfilled]: (state, { payload }) => {
          if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.adaptionhandOver = payload
            }
            
        },
        [fetchCustomerInspectionList.fulfilled]: (state, { payload }) => {
           if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerInspection = payload
            }
            
        },
        [fetchCustomerAdaptionInspectionList.fulfilled]: (state, { payload }) => {
           if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.customerAdaptionInspection = payload
            }
            
        },
        [fetchCustomerSinfleInspectionList.fulfilled]: (state, { payload }) => {
            console.log(payload)
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.singleInspection = payload
            }
            
        },
        [fetchHealth.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut = "signOut";
            } else if (!payload.error) {
                state.healthAndSafety = payload
            }
        },
        [signOut.fulfilled]: (state, { payload }) => {
            if (payload.error === "auth") {
                state.signOut="signOut";
            } else if (!payload.error) {
                state.customer_id=""
                state.customerEmail=""
                state.customerFullName=""
                state.customerIsLoading = ""
                state.adminID=""
                
            if (payload.message === "signOut") {
                
                window.localStorage.clear();
                state.signOut = "signOut";
                
            }
         
            }
         },
    }
})

export const customerActions=customerSlice.actions;
export default customerSlice;