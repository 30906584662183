import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Sidebar from '../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { addANewJob, adminActions, fetchCustomer, signOut } from '../store/adminSlice';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderScaff from '../components/HeaderScaff';
import escapeHtml from '../functions/Validator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

function AddANewJob() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(false);
  const dispatch=useDispatch();
  const newCustomer=useSelector((state)=>state.admin.newCustomer);
  const [selectedImages,setSelectedImages]=useState([]);
  const stateSwalAlert = useSelector((state)=>state.admin.swalAlert);
  const [documents,setDocument]=useState([])
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]=useState({customer:"",job1:"",job2:"",postCode:"",jobDescription:"",jobDetails:"",scafolldingType:""})
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
    useEffect(() => {
      dispatch(adminActions.removeSwalAlert())
      dispatch(fetchCustomer({adminID:_id}))
    }, [])
  
    useEffect(() => {
      if(!_id){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
        
      }
    },[])

  const imageHandler = (e) => {
    setDocument(e.target.files);
    console.log(documents);

      const fileArray= Array.from(e.target.files).map((file)=>URL.createObjectURL(file))
    
      setSelectedImages((prevImage)=>prevImage.concat(fileArray))
      Array.from(e.target.files).map((file)=>{
       return URL.revokeObjectURL(file);
      })

    }
    const renderPhotos= (source)=>{
     return source.map((photo)=>{
      return(<div className='mx-2 my-2 flex items-center justify-center'>
      <img className='mt-2 object-contain' src={photo} key={photo}alt='addJobPhoto' />
    </div>)
     })

    }
    useEffect(() => {
      console.log("hello world"); 
      if(stateSwalAlert==="added"){
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'success',
          confirmButtonColor:"#A7B778",
          text: 'Job Added Successfully! ',
        }).then(()=>{
        dispatch(adminActions.removeSwalAlert())
        window.location.reload(true)
      })
    }
    },[stateSwalAlert]);

    const submit=(e)=>{
      e.preventDefault();
      setLoading(true);

    if (
      data.customer === "" ||
      data.job1 === "" ||
      data.postCode === "" ||
      data.jobDescription === "" ||
      data.jobDetails === "" ||
      documents.length === 0 || data.scafolldingType===""
    ) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly fill all the inputs & Upload logo/documents.!",
      });
    } else {
      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        formData.append("images", documents[i]);
      }
      formData.append("customer", data.customer);
      formData.append("job1", data.job1);
      formData.append("job2", data.job2);
      formData.append("postCode", data.postCode);
      formData.append("jobDescription", data.jobDescription);
      formData.append("jobDetails", data.jobDetails);
      formData.append("typeofScafollding", data.scafolldingType);
      formData.append("adminID", _id);
      console.log(formData);
      dispatch(addANewJob(formData));
    }
  };

  useEffect(() => {
    console.log("hello world");
    if (!localStorage.getItem("firstLoad")) {
      console.log("hello world2");
      localStorage["firstLoad"] = true;
      // window.location.reload();
    } else {
      localStorage.removeItem("firstLoad");
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  }, []);
  return (
    <div className="w-full h-full">
      <HeaderScaff/>
      <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
        <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
          <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
        </div>

        <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

        <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full ": "hidden" : "w-full" } " "`}>
            <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4">
              Add a new job
            </p>
            <div className="lg:flex lg:flex-row flex-col">
              <div className="lg:basis-1/2 w-full">
                <div className="p-2 flex flex-col w-full ">
                  <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                    Select Customer
                  </p>
                  <select
                    name="cars"
                    onChange={(e) =>
                      setData({ ...data, customer: e.target.value })
                    }
                    className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                    id="cars"
                  >
                    <option value="" className="text-xs xxl:text-xl">
                      Select Customer
                    </option>
                    {newCustomer.map((c) => {
                      return (
                        <option value={c._id} className="text-xs xxl:text-xl">
                          {c.customerCompanyName}
                        </option>
                      );
                    })}
                  </select>
                  {/* <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' required type="text" required/> */}
                  <p className="p-1 font-semibold text-xs xxl:text-xl">
                    Add New Job Address
                  </p>
                  <input
                    className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                    value={data.job1}
                    onChange={(e) => setData({ ...data, job1: escapeHtml(e.target.value) })}
                    required
                    type="text"
                  />

                  <input
                    className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 mt-5"
                    value={data.job2}
                    onChange={(e) => setData({ ...data, job2: escapeHtml(e.target.value) })}
                    required
                    type="text"
                  />
                  <p className="p-1 font-semibold text-xs xxl:text-xl">
                    PostCode
                  </p>

                  <input
                    className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 w-1/2 outline-none pl-1"
                    value={data.postCode}
                    onChange={(e) =>
                      setData({ ...data, postCode: escapeHtml(e.target.value) })
                    }
                    required
                    type="text"
                  />
                </div>
              </div>

              <div className="basis-1/2 flex-col flex  justify-between ">
                <div className="p-2 flex flex-col w-full ">
                  <p className="p-1 font-semibold text-xs xxl:text-xl">
                    Job Description
                  </p>
                  <textarea
                    className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                    value={data.jobDescription}
                    onChange={(e) =>
                      setData({ ...data, jobDescription: escapeHtml(e.target.value) })
                    }
                    id="w3review"
                    name="w3review"
                    rows="3"
                    cols="50"
                  ></textarea>

                  <p className="p-1 font-semibold text-xs xxl:mt-2 xxl:text-xl mt-1">
                    Access To Job Details
                  </p>
                  <div className="flex justify-between">
                    <input
                      className="bg-gray-200 opacity-50 p-[2px] w-full xxl:p-3 outline-none pl-1"
                      value={data.jobDetails}
                      onChange={(e) =>
                        setData({ ...data, jobDetails: escapeHtml(e.target.value) })
                      }
                      required
                      type="text"
                    />
                  </div>
                    <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                    Type of Scaffolding (Please Select)
                  </p>
                  <select
                    name="cars"
                    onChange={(e) =>
                      setData({
                        ...data,
                        scafolldingType: e.target.value
                      })
                    }
                    className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                    id="cars"
                  >
                    <option value="">Select Scaffolding</option>
                    <option value="0">Scaffolding</option>
                    <option value="1">Temporary Roof</option>
                  </select>
                </div>
    
            
          </div>
          
        </div>
            <div className='ml-2 lg:w-full xxl:w-full mt-4 '>
              <label className=' w-24 h-8 xxl:w-32 xxl:h-10 px-4 p-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'   >
                <input type="file" multiple onChange={imageHandler} className='hidden'/>
                      Add Photo
                </label>
              <div className='flex justify-end -mt-6'>
                {loading?(
                  <>
                    <CircularProgress className='px-1 mx-2 -mt-2' color="success"/>
                    <p>Submitting Form</p>
                  </>
                ):(
                  <button id="abw" className='h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen' onClick={submit}>submit</button>
                )}
              </div>
              <div className='grid  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xxl:grid-cols-5 mt-4'>
                    {renderPhotos(selectedImages)}
              </div>
            </div>
        </div>
      </div>
    </div>
    )
}

export default AddANewJob;
