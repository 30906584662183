import React, { useEffect, useState } from "react";
import logo from "../pictures/TheBestescafflogo.png";
import Sidebar from "../components/Sidebar";
import FourButtons from "../components/FourButtons";
import { Switch } from "@mui/material";
import outro from "../pictures/outro.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addTemproryRoofQoute,
  adminActions,
  fetchPriceList,
  fetchSingleJob,
  fetchTemproryRoofQoutation,
  signOut,
} from "../store/adminSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import escapeHtml from "../functions/Validator";
import Swal from "sweetalert2";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";

function TemporaryRoofQuotes() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [needed, setNeeded] = useState(false);
  const _id = useSelector((state) => state.admin._id);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queryParameters = new URLSearchParams(window.location.search);

  const id = queryParameters.get("id");
  const stateSingleJob = useSelector((state) => state.admin.singleJob);
  const statePriceList = useSelector((state) => state.admin.priceList);
  const StateTemporaryRoofQoutation = useSelector(
    (state) => state.admin.temporaryRoofQoutation
  );
  const escaffQuotationSwalAlert = useSelector(
    (state) => state.admin.swalAlert
  );

  // { dateOfErection: "", numberOfWeeks: "", permiterOfBuilding: "", numberOfLiftsForPerimeter: "", widthOfBuilding: "", lengthOfBuilding: "", HeightFromGutteringToTop: "", HeightFromGutteringToRoof: "", weeklyScoffoldingInspectionSwitch: false, streetLicenseCost: "", customerVatRegister: false, externalDesingCost: "", adaptionDetails: "", adaptionCost: "", totalCostResult:"",jobID:id,adminID:_id,customerID:""}
  const [data, setData] = useState(StateTemporaryRoofQoutation);
  const [result, setResult] = useState(0);
  const statesignOut = useSelector((state) => state.admin.signOut);
  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, dateOfErection: newDate, nextInspectionDate: newDate });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };
  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [statesignOut]);
  useEffect(() => {
    if (id) {
      dispatch(fetchSingleJob({ _id: id, adminID: _id }));
      dispatch(fetchPriceList({ adminID: _id }));
    } else {
      navigate("/pendingprojectqoutes");
    }
  }, []);
  const subscribed = useSelector((state) => state.admin.subscribed);
  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
  }, []);
  useEffect(() => {
    if (escaffQuotationSwalAlert.message === "Saved") {
      Swal.fire({
        icon: "success",
        title: "Saved",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        window.location.reload();
      });
    }
    if (escaffQuotationSwalAlert.message === "updatedEscaff") {
      Swal.fire({
        icon: "success",
        title: "Updated",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        window.location.reload();
      });
    }
  }, [escaffQuotationSwalAlert]);
  useEffect(() => {
    dispatch(fetchTemproryRoofQoutation({ jobID: id, adminID: _id }));
    dispatch(adminActions.removeSwalAlert());
  }, []);
  useEffect(() => {
    if (!window.location.hash) {
      // console.log("one");
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    setData(StateTemporaryRoofQoutation);
  }, [StateTemporaryRoofQoutation]);
  const handleCalculate = () => {
    var WeeklyScaffoldingInspection =
      statePriceList.scaffoldingInspectionPerVisit;
    var lowerScaffoldSection =
      parseInt(data.permiterOfBuilding) *
      parseInt(data.numberOfLiftsForPerimeter) *
      parseInt(statePriceList.linearMerterageCost);

    var upperScaffoldingSectionOne =
      parseInt(data.lengthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      2;

    var upperScaffoldingSectionTwo =
      parseInt(data.widthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      2;

    var covering =
      (upperScaffoldingSectionOne + upperScaffoldingSectionTwo) *
      parseInt(data.monoflexOrShrinkWrap);

    var tempRoof =
      parseInt(data.widthOfBuilding) *
      parseInt(data.lengthOfBuilding) *
      parseInt(data.HeightFromGutteringToTop) *
      parseInt(statePriceList.temporaryRoof);

    var totalCostResult = lowerScaffoldSection + covering + tempRoof;

    var weeks = data.numberOfWeeks;
    if (data.weeklyScoffoldingInspectionSwitch) {
      var nweek = weeks - 1;
      var r1 = nweek * WeeklyScaffoldingInspection;
      console.log(r1);
      totalCostResult = totalCostResult + r1;
      console.log(totalCostResult);
    }
    if (data.customerVatRegisterSwitch) {
      var theVatPercentage = (data.customerVatRegister / 100) * totalCostResult;

      totalCostResult = totalCostResult + theVatPercentage;
    }
    var nonpercentage =
      parseInt(data.streetLicenseCost) + parseInt(data.externalDesingCost);
      if (data.numberOfWeeks> data.standardNumberOfWeek) {
        var percentageWeek = weeks - data.standardNumberOfWeek;
        
        var overhired = statePriceList.overhirePercentage * percentageWeek;
        console.log(overhired,"overhired")
        
        var orignalPercentage = (overhired / 100) * totalCostResult;
        
        console.log(orignalPercentage,"percentafeoverfi")
        totalCostResult = totalCostResult + nonpercentage + orignalPercentage;
      } else {
        console.log(orignalPercentage,"percentafeoverfi")
        totalCostResult = totalCostResult + nonpercentage;
      }
    
    console.log(data.numberOfWeeks,"ddd",data.standardNumberOfWeek)
    
    setResult(totalCostResult.toFixed(2));
    setData({
      ...data,
      jobID: id,
      customerID: stateSingleJob.customer._id,
      totalCostResult: totalCostResult,
      approved: 0,
    });
  };
  const handleSubmit = () => {
    if (!result) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly calculate first.!",
      });
    } else {
      dispatch(addTemproryRoofQoute(data));
    }
  };
  return (
    <div className="w-full h-full">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24">
        <img
          className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
          src={logo}
          alt="logo"
        />
        <div className="w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 xl:-mt-5 xxl:-mt-8">
          <FourButtons />
        </div>
      </header>
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "basis-1/5"
                : "flex justify-center w-full"
              : "hidden"
          } "w-full h-full"`}
        >
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            needed={needed}
            isNonMobile={isNonMobile}
          />
        </div>

        <div
          onClick={() => setSidebarOpen(true)}
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "hidden"
                : "hidden"
              : isNonMobile
              ? "flex w-12 mt-4"
              : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
          } h-fit`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? ""
                  : ""
                : isNonMobile
                ? ""
                : "hidden"
            } `}
          >
            <IconButton onClick={() => setSidebarOpen(true)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "hidden"
                : "flex"
            } `}
          >
            <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
          </div>
        </div>

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 flex w-full flex-col lg:flex-row"
                : "hidden"
              : "flex w-full flex-col lg:flex-row"
          } " "`}
        >
          {/* <div className='lg:basis-3/4 flex w-full flex-col lg:flex-row'> */}

          <div className="flex flex-col basis-1 sm:basis-1/2 w-full px-1 xxl:mt-4">
            <p className="text-base xxl:text-2xl text-slate-400">
              Temporary roof quote
            </p>
            <p className="text-xs xxl:text-base font-semibold">
              Create a quotation filling out the details below.
            </p>
            <div className="flex flex-col w-full mt-2">
              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Select the date of erection
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                value={data.dateOfErection}
                type="date"
                onChange={onChangeDate}
                required
              />
              <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                Enter the number of weeks required
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                value={data.numberOfWeeks}
                onChange={(e) =>
                  setData({
                    ...data,
                    numberOfWeeks: escapeHtml(e.target.value),
                  })
                }
                type="number"
                onWheel={(e) => e.target.blur()}
                required
              />
              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Enter the number of standard weeks hire before overhire commence
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                onChange={(e) =>
                  setData({
                    ...data,
                    standardNumberOfWeek: escapeHtml(e.target.value),
                  })
                }
                value={data.standardNumberOfWeek}
                type="number"
                required
                onWheel={(e) => e.target.blur()}
              />
              <p className="px-1 pt-3 pb-1 pl-0 font-semibold text-xs xxl:text-xl">
                Temporary roof dimensions
              </p>

              <div className="flex flex-col xl:flex-row w-full space-x-1 ">
                <div className="basis-3/5 flex flex-col items-center">
                  <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                    Enter the perimeter of your building{" "}
                    <span className="font-normal text-gray-400">
                      (Red line)
                    </span>
                  </p>
                  <input
                    className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                    value={data.permiterOfBuilding}
                    onChange={(e) =>
                      setData({
                        ...data,
                        permiterOfBuilding: escapeHtml(e.target.value),
                      })
                    }
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    required
                  />
                </div>
                <div className="basis-2/5 flex flex-col items-center">
                  <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                    Number of lifts{" "}
                    <span className="font-normal text-gray-400">
                      (Blue line)
                    </span>
                  </p>
                  <input
                    className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none  lg:pl-1"
                    value={data.numberOfLiftsForPerimeter}
                    onChange={(e) =>
                      setData({
                        ...data,
                        numberOfLiftsForPerimeter: escapeHtml(e.target.value),
                      })
                    }
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    required
                  />
                </div>
              </div>

              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Enter the width of the building
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                value={data.widthOfBuilding}
                onChange={(e) =>
                  setData({
                    ...data,
                    widthOfBuilding: escapeHtml(e.target.value),
                  })
                }
                type="number"
                onWheel={(e) => e.target.blur()}
                required
              />
              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Enter the length of the building
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={data.lengthOfBuilding}
                onChange={(e) =>
                  setData({
                    ...data,
                    lengthOfBuilding: escapeHtml(e.target.value),
                  })
                }
                required
              />
              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Enter the height from your guttering to the top of roof{" "}
                <span className="font-normal text-gray-400">(Green line)</span>
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={data.HeightFromGutteringToTop}
                onChange={(e) =>
                  setData({
                    ...data,
                    HeightFromGutteringToTop: escapeHtml(e.target.value),
                  })
                }
                required
              />

              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Shrinkwrap or Monoflex
              </p>

              {statePriceList.monoflex === data.monoflexOrShrinkWrap ? (
                <select
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-3/5 outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      monoflexOrShrinkWrap: escapeHtml(e.target.value),
                    })
                  }
                >
                  <option>Select</option>
                  <option selected value={statePriceList.monoflex}>
                    Monoflex
                  </option>
                  <option value={statePriceList.shrinkwrap}>Shrinkwrap</option>
                </select>
              ) : (
                <select
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-3/5 outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      monoflexOrShrinkWrap: escapeHtml(e.target.value),
                    })
                  }
                >
                  <option>Select</option>
                  <option selected value={statePriceList.monoflex}>
                    Monoflex
                  </option>
                  <option selected value={statePriceList.shrinkwrap}>
                    Shrinkwrap
                  </option>
                </select>
              )}

              <div className="flex flex-row w-full space-x-1 pr-8 items-center justify-start pt-4">
                <p className="pr-1 xxl:mr-20 font-semibold text-[12px] xxl:text-base whitespace-nowrap w-52 xxl:w-1/2">
                  Weekly Scaffolding Inspections
                </p>
                <Switch
                  className="ml-4 text-lightgreen -my-2"
                  onChange={() =>
                    setData({
                      ...data,
                      weeklyScoffoldingInspectionSwitch:
                        !data.weeklyScoffoldingInspectionSwitch,
                    })
                  }
                  checked={data.weeklyScoffoldingInspectionSwitch}
                  color="success"
                />
              </div>

              <p className="pt-3 font-semibold text-[12px] xxl:text-base">
                Enter the Street license Cost
              </p>
              <input
                className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
                type="number"
                onChange={(e) =>
                  setData({
                    ...data,
                    streetLicenseCost: escapeHtml(e.target.value),
                  })
                }
                value={data.streetLicenseCost}
                onWheel={(e) => e.target.blur()}
                required
              />

              <div className="flex flex-col w-full">
                <p className="pt-3 font-semibold text-[12px] xxl:text-base">
                  Customer is VAT Registered
                </p>
                <div className="flex flex-row justify-start w-full">
                  <input
                    className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
                    onChange={(e) =>
                      setData({
                        ...data,
                        customerVatRegister: escapeHtml(e.target.value),
                      })
                    }
                    value={data.customerVatRegister}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    required
                  />
                  <Switch
                    className="ml-4 xxl:-ml-2 text-lightgreen -my-2"
                    onChange={() =>
                      setData({
                        ...data,
                        customerVatRegisterSwitch:
                          !data.customerVatRegisterSwitch,
                      })
                    }
                    checked={data.customerVatRegisterSwitch}
                    color="success"
                  />
                </div>
              </div>

              <p className="pt-3 font-semibold text-[12px] xxl:text-base">
                External Design Cost
              </p>
              <input
                className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
                onChange={(e) =>
                  setData({
                    ...data,
                    externalDesingCost: escapeHtml(e.target.value),
                  })
                }
                value={data.externalDesingCost}
                type="number"
                onWheel={(e) => e.target.blur()}
                required
              />

              <hr className="my-2 bg-black h-[2px] w-full opacity-25" />

              <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
                Total cost
              </p>
              <input
                className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-3/5 outline-none pl-4 xxl:pl-6 z-20"
                disabled
                value={result}
                type="number"
                onWheel={(e) => e.target.blur()}
                required
              />
              <i className="z-10 relative pl-1 xxl:pl-2 text-black -mt-6 xxl:-mt-8 xxl:pb-2">
                £
              </i>

              <div className="flex flex-row w-full space-x-1 pr-8 items-center justify-start pt-4">
                <p className="pr-1 xxl:mr-20 font-semibold text-[12px] xxl:text-base whitespace-nowrap w-[220px]">
                  Adaptions are included in the quotation
                </p>
                <Switch className=" text-lightgreen -my-2" color="success" />
              </div>

              <div className="flex flex-row space-x-1 pt-2">
                <button
                  className="self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg"
                  onClick={handleCalculate}
                >
                  Calculate
                </button>
                <button className="self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg">
                  Edit
                </button>
                <button
                  className="self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col basis-1/2 h-fit sticky top-16">
            <div className="sticky top-24 flex xxl:pt-40">
              <img src={outro} alt="" className="object-contain flex" />
            </div>
            <div className="flex w-full flex-col space-y-2 xxl:space-y-3 pb-0 p-7 px-2 xxl:p-6">
              <div className="basis-1 flex flex-col w-full">
                <h1 className="text-base font-bold xxl:text-2xl">
                  Job Description
                </h1>
                <p className="text-base xxl:text-xl">
                  {stateSingleJob.jobDescription}
                </p>
              </div>
              <div className="basis-1 flex flex-col w-full">
                <h1 className="text-base font-bold xxl:text-2xl">Job Access</h1>
                <p className="text-base xxl:text-xl">
                  {stateSingleJob.jobDetails}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemporaryRoofQuotes;
