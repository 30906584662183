import { configureStore } from '@reduxjs/toolkit'
import adminSlice from './adminSlice';
import customerSlice from './customerSlice';
import staffSlice from './staffSlice';

 const store = configureStore({
  reducer: {
    admin:adminSlice.reducer,
    customer:customerSlice.reducer,
    staff:staffSlice.reducer,
  },
})
export default store;