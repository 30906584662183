import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions, allQoutedAdaption, allQuotations, approveAdaptions, approveQoutes, deleteAdaption, deleteJob, pendingAdaptions, pendingAdaptionsAddToUnArchive, pendingArchiveAdaptions, pendingArchiveQoutes, pendingQoutes, pendingQoutesAddToUnArchive, signOut } from '../store/adminSlice';
import { useNavigate } from 'react-router-dom';
import HeaderScaff from '../components/HeaderScaff';
import { Dialog, IconButton, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import $  from 'jquery'
import Swal from 'sweetalert2';
$.DataTable = require('datatables.net')

function ArchivePendingProjectQuotes() {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(false);
  const [data,setData]=useState([])
  const dispatch = useDispatch();
  const statePendingQoutes=useSelector((state)=>state.admin.pendingQoutes)
  const stateAllQuotationQuoted=useSelector((state)=>state.admin.allQuotationQuoted)
  const statePendingAdaptions=useSelector((state)=>state.admin.pendingAdaptions)
  const stateAllAdaptionQuoted=useSelector((state)=>state.admin.allQuotationAdaptionQuoted)
  const _id = useSelector((state) => state.admin._id)
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  const swalAlert=useSelector((state)=>state.admin.swalAlert)
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
 
  useEffect(() => {
    dispatch(pendingArchiveQoutes({adminID:_id}))
    dispatch(pendingArchiveAdaptions({adminID:_id}))
    dispatch(adminActions.setReloadToTrue())
    dispatch(adminActions.removeSwalAlert());
    dispatch(adminActions.removeEscaffFetched());
    dispatch(allQuotations({ adminID: _id }));
    dispatch(allQoutedAdaption({ adminID: _id }));
    setTimeout(() => {
      $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      $('#table1').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      
    }, 5000);
   
    if(!isNonMobile){
      setSidebarOpen(false);
    }
     
  }, [])
  useEffect(() => {
    dispatch(pendingArchiveQoutes({adminID:_id}))
    dispatch(pendingArchiveAdaptions({adminID:_id}))
    dispatch(adminActions.setReloadToTrue())
    dispatch(adminActions.removeSwalAlert());
    dispatch(adminActions.removeEscaffFetched());
    dispatch(allQuotations({ adminID: _id }));
    dispatch(allQoutedAdaption({ adminID: _id }));
    setTimeout(() => {
      $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      $('#table1').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      
    }, 5000);
    
  },[swalAlert])



  useEffect(() => {
    if(!_id){
      navigate("/signin")
    }
    else if (!subscribed) {
      navigate("/signin")
      
    }
  }, [_id, subscribed,statePendingQoutes])
  const unarchieveJob = (id) => {

    dispatch(pendingQoutesAddToUnArchive({_id:id,adminID:_id}))
}
const unarchieveAdaption = (id) => {
    dispatch(pendingAdaptionsAddToUnArchive({_id:id,adminID:_id}))
}

  return (
    <div className='w-full h-full'>
         <HeaderScaff setSidebarOpen={setSidebarOpen}/>
       <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
        <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} " h-full"`}>
          <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
        </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>
      <div className={`${sidebarOpen? isNonMobile? "": "hidden" : "" } 'flex flex-col w-full lg:basis-3/4 '`}>
      <div className={`${sidebarOpen? isNonMobile? "w-full overflow-x-scroll scrollbar-hide": "hidden" : "w-full overflow-x-scroll scrollbar-hide" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Archive Project Quotes</p>
         
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '  id="table">
             <thead className='w-full' >
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Job ID</th>
             {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job ID</th> */}
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job Details</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Type</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Email</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Telephone</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Quoted</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
                  {statePendingQoutes.length !== 0 && statePendingQoutes.map((data) => {
                    var result = stateAllQuotationQuoted.find((a) => a.jobID == data._id)
                if(result===undefined||result.approved===0){
             return (

             <tr className=''>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm text-center'>{data.jobNumber}</td>
             {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data._id}</td> */}
             <td className='w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobDescription}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customer.customerCompanyName}</td>
             <td className='w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[10px] xxl:text-[13px]'>{data.job1} {data.job2}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.typeofScafollding==="0"?"Scaffolding":"Temperory Roof"}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customer.email}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customer.telephone}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>
              <div className='flex justify-center'>
                {stateAllQuotationQuoted.find((a) => a.jobID === data._id)?(
                  <DoneIcon sx={{width:'17px',height:"17px"}} className="text-green-600 cursor-pointer"/>
                ):(
                  <CloseIcon sx={{width:'17px',height:"17px"}} className="text-red-600 cursor-pointer"/>
                )}
              </div>
              </td>
             <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
                  
                    
                  <div className='flex flex-col items-center' onClick={(e)=>unarchieveJob(data._id)}>
                   <UnarchiveIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Unarchive</p>
                  </div>                      
                </div>
             </td>
             </tr>
             )}})}
</tbody>

</table>
         
</div>
          </div>
          <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide": "hidden" : "w-full overflow-x-scroll scrollbar-hide" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Archive Adaption Quotes</p>
         
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '  id="table1">
             <thead className='w-full' >
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Job ID</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job Details</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Adaption Details</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Name</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Type</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Email</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Telephone</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Quoted</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
                  {statePendingAdaptions.length !== 0 && statePendingAdaptions.map((data) => {
                    var result = stateAllAdaptionQuoted.find((a) => a.adaptionID === data._id)
                    if(result===undefined||result.approved===0){
             return (

             <tr className=''>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm text-center'>{data.jobID.jobNumber}</td>
             {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data._id}</td> */}
             <td className='w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobID.jobDescription}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.adaptionDetails}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
             <td className='w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[10px] xxl:text-[13px]'>{data.jobID.job1} {data.jobID.job2}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.type==="escaffQoute"?"Scaffolding":"Temperory Roof"}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customerID.email}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.telephone}</td>
             <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>
              <div className='flex justify-center'>
                {stateAllAdaptionQuoted.find((a) => a.adaptionID === data._id)?(
                  <DoneIcon sx={{width:'17px',height:"17px"}} className="text-green-600 cursor-pointer"/>
                ):(
                  <CloseIcon sx={{width:'17px',height:"17px"}} className="text-red-600 cursor-pointer"/>
                )}
              </div>
              </td>
             <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
                   <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
                  
                  <div className='flex flex-col items-center' onClick={(e)=>unarchieveAdaption(data._id)}>
                   <UnarchiveIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Unarchive</p>
                  </div>                      
                </div>
             </td>
             </tr>
             )}})}
</tbody>

</table>
         
</div>  
          </div>
          
        </div>
   </div>
   
    </div>
  )
}

export default ArchivePendingProjectQuotes