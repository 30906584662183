import React, { useEffect } from "react";
import FooterScaff from "../components/FooterScaff";
import logoo from "../pictures/TheBestescafflogo.png";
import image from "../pictures/Holliedone.mov";
import me from "../pictures/me.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminActions } from "../store/adminSlice";
import { staffActions } from "../store/staffSlice";
import { customerActions } from "../store/customerSlice";

function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminActions.removeSignout);
    dispatch(staffActions.removesignout);
    dispatch(customerActions.removesignout);
  }, []);
  return (
    <div className="w-full h-screen flex flex-col overflow-hidden">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 top-0">
        <img
          className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
          src={logoo}
          alt="logo"
        />
        <div className="w-full flex justify-end px-0 sm:px-2 xl:pr-12 -mt-5 lg:-mt-5 xxl:-mt-8">
          <button
            className="whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-10 text-[7px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen"
            onClick={() =>
              (window.location.href = "https://www.escaff.uk/tutorials/")
            }
          >
            Tutorial
          </button>
          <button
            className="whitespace-nowrap border-t-2 mr-1 border-x-2 py-[2px] sm:py-1 px-1 sm:px-5 md:px-10 text-[7px] sm:text-xs xxl:mr-2 xxl:px-12 xxl:py-3 xxl:text-[21px] rounded-t-lg right-2 hover:shadow-sm hover:bg-lightgreen"
            onClick={() => navigate("/signin")}
          >
            Signin/Signup
          </button>
        </div>
      </header>
      <div className="flex flex-col items-center justify-center w-full h-full z-20">
        <div className="flex flex-col  h-4/6 items-center justify-between w-full z-20 -mt-28 sm:mt-0 px-3">
          <h1 className="font-sans text-center sm:text-xl xxl:text-[35px] xxl:whitespace-nowrap align-top text-darkgreen">
            <i>
              Welcome to eScaff - the most powerful scaffolding management
              software on the market
            </i>{" "}
          </h1>
          <div className="items-center flex-col border-l-4 border-lightgreen my-6 z-20 flex w-fit h-fit">
            <p className="text-darkgreen font-sans xxl:leading-relaxed ml-2 z-20 text-xs sm:text-base xxl:text-[24px] sm:whitespace-nowrap">
              Automate your everyday tasks
              <br />
              Work remotely to add new projects or from your office
              <br />
              Your quotations automatically appear on your dashboard once the
              customer has approved
              <br />
              Make instant decisions to your daily workload from the dashboard
              <br />
              Quote projects with the built in estimator which notify the
              customer for approval
              <br />
              Set your company up once and its reflected throughout - including
              due diligence
              <br />
              Customers can view the status of each job you are working on from
              the dashboard
            </p>
          </div>
          <div className="flex w-full sm:w-3/5 items-start justify-center sm:flex-col md:flex-row sm:justify-start">
            <p className="relative font-sans items-start mb-8 ml-0 sm:ml-14 text-sm sm:text-lg xxl:text-[30px] text-darkgreen z-20">
              Simple to use, easy to set up, works on any device
            </p>
            {/* <img src={webOffer} className=" ml-8 w-40 xxl:w-52  " alt="webOffer" /> */}
          </div>
          <div className="bg-watermark max-w-[1920px] opacity-40 bg-contain w-1/2 sm:w-1/3 h-5/6 md:h-11/12 bg-bottom -left-8 md:-left-32 bottom-[140px] bg-no-repeat absolute z-10"></div>
          {/* <div className="relative w-full">
            <div className="flex left-4 w-2  absolute bottom-1 lg:w-full  z-20">
              <img src={me}  className="w-32"alt="captain"/>
            </div>
          </div>
          <div className="relative w-full">
            <div className="absolute right-12 bottom-1">
              <video width="100px" height="100px" controls autoPlay>
                <source src="./videos/intro.mp4" type="video/mp4" />
              </video>
            </div>
          </div> */}
        </div>
      </div>
      {/* </div> */}
      <FooterScaff />
    </div>
  );
}

export default Main;
