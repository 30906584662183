import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import Sidebar from '../components/Sidebar'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { addKeyStaffMember, adminActions, deleteItemKeyStaffMembers, editKeyStaffMembers, fetchKeyStaffMember } from '../store/adminSlice';
import Swal from 'sweetalert2'
import Logo from '../components/Logo';
import HeaderScaff from '../components/HeaderScaff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import $  from 'jquery'
$.DataTable = require('datatables.net')

function StaffMember() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(false);
  const dispatch=useDispatch();
  const keyMemberStaff=useSelector((state)=>state.admin.keyMemberStaff);
  const swalAlert=useSelector((state)=>state.admin.swalAlert);
  const [staffMemberDetails,setStaffMemberDetails]=useState(keyMemberStaff)
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]= useState({fullName:"",email:"",telephone:"",password:"",adminID:_id})
  const [forEdit,setForEdit]= useState({fullName:"",email:"",telephone:"",password:"",adminID:_id,_id:""})
  const [editID,setEditID]= useState("")
  const navigate = useNavigate();
  const [ edit, setEdit] = useState(false)

  const handleSubmit = (e)=>{
    e.preventDefault();

    if(data.fullName === "" || data.email === "" || data.telephone === "" || data.password === ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Kindly fill all the inputs.!',
      })
      return;
    }
    if(!edit){
      dispatch(addKeyStaffMember(data)).then(()=>{
        setData({fullName:"",email:"",telephone:"",password:"",adminID:_id})
      });
    }
  }

  useEffect(() => {
    if (swalAlert==="emailDuplicated"){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor:"#A7B778",
        text: 'Email already registered.!',
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
      })
    }
  },[swalAlert])

  useEffect(() => {
    setStaffMemberDetails(keyMemberStaff)
  }, [keyMemberStaff])

  useEffect(() => {
    dispatch(fetchKeyStaffMember({adminID:_id}))
    setStaffMemberDetails(keyMemberStaff)
    // setTimeout(() => {
    //   $('#table').DataTable().destroy();
    setTimeout(() => {
      $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      
    }, 5000);
    
  // }, 2000);
    if(!isNonMobile){
      setSidebarOpen(false);
    }
},[])

  const deleteItem = (thtItem) => {
    var nn= {thtItem:thtItem,adminID:_id}
    dispatch(deleteItemKeyStaffMembers(nn))
  };

  const editItem = (thtItem) => {
    setData({...data,fullName:thtItem.fullName,email:thtItem.email,telephone:thtItem.telephone,password:""});
    setEditID(thtItem._id);
    setEdit(true);
  };
  
  useEffect(() => {
    dispatch(editKeyStaffMembers(forEdit)).then(()=>{
      setEdit(false);
      setData({fullName:"",email:"",telephone:"",password:"",adminID:_id})
    });
  }, [forEdit])
  
  const handleUpdate = () => {
    setForEdit({...forEdit,fullName:data.fullName,email:data.email,telephone:data.telephone,password:data.password,adminID:_id,_id:editID})
  }

  const handleUpdateCancel = () =>{
    setEdit(false);
    setData({fullName:"",email:"",telephone:"",password:"",adminID:_id})
  }

  return (
    <div className='w-full h-full'>
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full": "hidden" : "w-full" } " "`}>
            {/* <div className='lg:basis-3/4 w-full '> */}
            <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4'>Add key members of staff</p>
              <form className='lg:flex lg:flex-row flex-col' onSubmit={handleSubmit}>
            <div className='lg:basis-1/2 w-full'>
           
                <div className='p-2 flex flex-col w-full '>
                  <p className='p-1 font-semibold text-xs xxl:text-xl'>Staff Member Name</p>
                  <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.fullName} onChange={(e)=>setData({...data,fullName:e.target.value})} type="text" required/>
                  <p className='p-1 font-semibold text-xs xxl:text-xl'>Telephone</p>
                  <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.telephone} onChange={(e)=>setData({...data,telephone:e.target.value})}  type="text" required />
                  <p className='p-1 font-semibold text-xs xxl:text-xl'>Email</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.email} onChange={(e)=>setData({...data,email:e.target.value})} autocomplete="off" type="email" required />
                </div>
               
              </div>
          
              <div className='basis-1/2 flex-col flex  justify-between items-end'>
                 <div className='p-2 flex flex-col w-full '>
                  <p className='p-1 font-semibold text-xs xxl:text-xl'>Password</p>
                    <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' value={data.password} onChange={(e)=>setData({...data,password:e.target.value})}  type="text" required/>
                  </div>
                <div className='w-full pl-2'>
                {edit?
                <>
                  <button className=' w-24 h-8 mr-3 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' onClick={(e) => handleUpdate(e)}>Update</button>
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' onClick={handleUpdateCancel}>Cancel</button>
                </>
                :
                  <button className=' w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen' onSubmit={handleSubmit} type='submit'>Save</button>                
                }
              </div>
              
              </div>
              </form>
                <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 xxl:py-5'>Key members of Staff</p>
              <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden '>
                <table className='editor_listing_table ' id="table">
                  <thead className='w-full'>
                  <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>
                  {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Staff ID</th> */}
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Email</th>
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Telephone</th>
                  <th className='rounded-r-lg text-white font-normal text-sm xxl:text-base'>Action</th>
                </tr>
                </thead>
                <div className='h-2'></div>
                  <tbody className=' '>
                {staffMemberDetails?
                staffMemberDetails.map((data) => (
                  

                  <tr className=''>
                  {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data._id}</td> */}
                  <td className='w-4/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.fullName}</td>
                  <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.email}</td>
                  <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.telephone}</td>
                  <td className=' w-2/12 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm]'>
                    <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                      <div onClick={(e) => editItem(data)} className='flex flex-col items-center'>
                        <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Edit</p>
                     </div>
                     <div onClick={(e) => deleteItem(data)} className='flex flex-col items-center'>
                      <DeleteIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Delete</p>
                     </div>                      
                    </div>
                  </td>
                  </tr>
                  )):<></>}
</tbody>

</table>
              
</div>
            </div>
        </div>
    </div>
  )
}

export default StaffMember