import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoo from "../pictures/TheBestescafflogo.png";
import URL from "../functions/URLString";

function CustomerLogo() {
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState("");
  const logo = useSelector((state) => state.customer.companyLogo);

  useEffect(() => {
    setCompanyLogo(logo);
  }, [logo]);

  useEffect(() => {
    setCompanyLogo(logo);
    console.log(logo,"---",logo)
  }, []);

  return (
    <>
      <img
        className="w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain"
        src={companyLogo !== "" ? `${URL}/uploads/` + logo : logoo}
        alt="logo"
      />
    </>
  );
}

export default CustomerLogo