import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { approveEscaffQoutes, approvetemproryroofqoute,fetchCompanyDetails, customerActions, declineEscaffQoutes, fetchCustomerPendingQoutes, declinetemproryroofqoute, fetchCustomerApprovedQoutes, fetchPriceList, fetchCustomerApprovedAdaptionQoutes, fetchCustomerAllQoutes, fetchCustomerAllAdaptionQoutes } from '../store/customerSlice';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CustomerFourButtonsTwo from '../components/CustomerFourButtonsTwo';
import CustomerSidebar from '../components/CustomerSidebar';
import CustomerLogo from '../components/CustomerLogo';
import EditIcon from '@mui/icons-material/Edit';
import axios from "../functions/axios"
import { saveAs } from 'file-saver';
function CusotmerQuotesApproved() {
  const [needed, setNeeded] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const statePendingQoutes = useSelector((state) => state.customer.approvedQoutes)
  const approvedAdaptionQoutes = useSelector((state) => state.customer.approvedAdaptionQoutes)
  const _id = useSelector((state) => state.customer.customer_id)
  const adminID = useSelector((state) => state.customer.adminID)
  const statePriceList = useSelector((state) => state.customer.priceList)
  const customerAllQoutes = useSelector((state) => state.customer.customerAllQoutes)
  const customerAllAdaptions = useSelector((state) => state.customer.customerAllAdaptions)

  

  useEffect(() => {
    dispatch(fetchCustomerApprovedQoutes({ customerID: _id }))
    dispatch(fetchCustomerApprovedAdaptionQoutes({ customerID: _id }))
    dispatch(fetchPriceList({adminID:adminID}))
    dispatch(customerActions.removeSwalAlert());
    dispatch(fetchCompanyDetails({ customerID:_id, adminID: adminID }))
    dispatch(fetchCustomerAllQoutes({customerID:_id}))
    dispatch(fetchCustomerAllAdaptionQoutes({customerID:_id}))
  }, [])
  useEffect(() => {
    // console.log(statePendingQoutes);
    
  }, [statePendingQoutes])
  useEffect(() => {
    if (!_id) {
      // navigate()
    }
  }, [])
  const handleTemprory = (_id, b, c, d, f) => {
    console.log(f)
    var x = 0
    var y = 0;
    if (d === undefined) {
      y=0
    } else {
      y=f
    }
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
  axios.post("/admin/fetchsingletemproryroofqoute", {adminID:c, _id: _id }).then((res) => {
    axios.post("/admin/fetchsingletemproryroofqadaptionqoute", {adminID:c, _id: x }).then((ress) => {
      axios.post("customer/fetchcompanydetails", { customerID:d,adminID: c },{
        headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
            // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
        }
      }).then((resss) => {
        axios.post("customer/fetchsingleadaption", { adaptionID:y,customerID:d },{
          headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
          }
        }).then((ressss) => {
        const data = { objects: [res.data, statePriceList,ress.data,resss.data,ressss.data] };
        axios.post('/createtemproryroofqoutationpdf', data)
          .then(() => axios.get('/fetchfile',
 
            {
              headers: {
                'Content-type': 'application/json',
              },
              responseType: 'blob'
            }

          ))
      
          .then((res) => {
            // console.log(res.data);
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            // console.log(JSON.stringify(pdfBlob));
            saveAs(pdfBlob, "new.pdf");
       
          })
        })
        })
      })})
  
  
}
  const handleEscaff = (_id, b, c, d, f) => {
    console.log(f)
    var y = 0;
    if (f === undefined) {
      y=0
    } else {
      y=f
    }
    var x=0
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
  axios.post("/admin/fetchsingleescaffqoute", { adminID:c,_id: _id }).then((res) => {
    axios.post("/admin/fetchsingleescaffadaptionqoute", { adminID:c, _id: x }).then((ress) => {
      axios.post("customer/fetchcompanydetails", {customerID:d, adminID: c },{
        headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
            // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
        }
      }).then((resss) => {
        axios.post("customer/fetchsingleadaption", { adaptionID:y,customerID:d},{
          headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
          }
        }).then((ressss) => {
        const data = { objects: [res.data, statePriceList,ress.data,resss.data,ressss.data] };
    
      axios.post('/createescaffqoutationpdf', data)
        .then(() => axios.get('/fetchfile',
 
          {
            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          }

        ))
      
        .then((res) => {
          // console.log(res.data);
          const pdfBlob = new Blob([res.data], { type: "application/pdf" })
          // console.log(JSON.stringify(pdfBlob));
          saveAs(pdfBlob, "new.pdf");
       
        })
      })
      })
    })})
  
  
}
  return (
    <div className='w-full h-full'>
    <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 '>
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        <CustomerLogo />

      <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
      <CustomerFourButtonsTwo/>
    </div>
 </header>
 <div className='w-full flex h-auto p-6'>
    <CustomerSidebar needed={needed}/>
      <div className='flex flex-col w-full'>
     <div className='lg:basis-3/4 w-full '>
     <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Scaffolding Quotes Approved</p>
       
       <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
         <table className='editor_listing_table '>
           <thead className='w-full'>
              <tr className='bg-gradient-to-b  from-lightgreen to-darkgreen'>
              <th className='whitespace-nowrap mr-2 text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>

           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Job Address</th>
           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Erection Date</th>
           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Project Cost</th>
           <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Status</th>
         </tr>
         </thead>
         <div className='h-2'></div>
           <tbody className=' '>
                  {statePendingQoutes.map((data) => {
                    const adaptionFound = customerAllAdaptions.find((item) => item.adaptionID.qouteID === data._id)
                    var adaptionQouteId = 0
                 
                  
                    if (adaptionFound !== undefined) {
                   
                      adaptionQouteId=adaptionFound._id
                    }
           return (

           <tr className=''>
           <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
               {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.joobID.jobDescription}</td> */}
           <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobID.job1 + " "+data.jobID.job2 }</td>
           <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.dateOfErection}</td>
           <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'><i className='z-10 relative pr-1 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>{data.totalCostResult}</td>
           <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
           <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
           {data.type==="temproryRoofQoute"? <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescafftemproofqoutationpdf?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                     </div> :
                       <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescaffscaffolldingqoutation?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>}  
                 
                </div>
           </td>
           </tr>
           )})}
</tbody>

</table>
       
</div>
          </div>
     <div className='lg:basis-3/4 w-full '>
     <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Scaffolding Adaption Quotes Approved</p>
       
       <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
         <table className='editor_listing_table '>
           <thead className='w-full'>
              <tr className='bg-gradient-to-b  from-lightgreen to-darkgreen'>
              <th className='whitespace-nowrap mr-2 text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>

           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Job Address</th>
           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Erection Date</th>
           <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Project Cost</th>
           <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Status</th>
         </tr>
         </thead>
         <div className='h-2'></div>
           <tbody className=' '>
                  {approvedAdaptionQoutes.map((data) => {

                    const adaptionFound = customerAllQoutes.find((item) => item._id === data.adaptionID.qouteID)
                    var adaptionQouteId = 0
                    if (adaptionFound !== undefined) {
                   
                      adaptionQouteId=adaptionFound._id
                    }

           return (

           <tr className=''>
           <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
               {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.joobID.jobDescription}</td> */}
           <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobID.job1 + " "+data.jobID.job2 }</td>
           <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.dateOfErection}</td>
           <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'><i className='z-10 relative pr-1 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>{data.totalCostResult}</td>
           <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
           <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
           {data.type==="temproryRoofQoute"? <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescafftemproofqoutationpdf?escaffqouteid=${adaptionQouteId}&adaptionquoteid=${data._id}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                     </div> :
                       <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescaffscaffolldingqoutation?escaffqouteid=${adaptionQouteId}&adaptionquoteid=${data._id}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>}  
                 
                </div>
           </td>
           </tr>
           )})}
</tbody>

</table>
       
</div>
          </div>
          </div>
 </div>
</div>
  )
}

export default CusotmerQuotesApproved