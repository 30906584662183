import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { adaptionStatusComplete, adaptionStatusStart, fetchAdaption, fetchAdaptionQoute, fetchSingleAdaptionsForStaffMember, fetchSingleJobsForStaffMember, fetchSingleQoutationsForStaffMember, jobStatusComplete, jobStatusStart } from '../store/staffSlice';
import URL from '../functions/URLString';
import googleMaps from '../pictures/googleMaps.png'
import { json, useNavigate } from 'react-router-dom';
import StaffLogo from '../components/StaffLogo';



function StaffDismantle() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id")
  const jobID = queryParameters.get("jobID")
  const type = queryParameters.get("type")
  const stateSingleAdaptionsForStaffMember = useSelector((state) => state.staff.singlejobsForStaffMember)
  const adaptionDetails = useSelector((state) => state.staff.adaptionDetails)
  const [image, setImage] = useState("");
  const [data, setData] = useState({jobDescription:"",job1:"",job2:"",jobDetails:"",status:0,adaptionDescription:"",requestedDate:"",partialDismantleDescription:"",completeDismantle:false});
  const [images, setImages] = useState([]);

  useEffect(()=>{
  
    dispatch(fetchSingleJobsForStaffMember({_id:jobID}))
    dispatch(fetchSingleQoutationsForStaffMember({_id:id,jobID,type}))
    
  },[])

  useEffect(()=>{
    if (JSON.stringify(stateSingleAdaptionsForStaffMember) !== "{}") {  
      setImage(stateSingleAdaptionsForStaffMember.Images[0]);
      setImages(stateSingleAdaptionsForStaffMember.Images);
      setData({...data,jobDescription:stateSingleAdaptionsForStaffMember.jobDescription,job1:stateSingleAdaptionsForStaffMember.job1,job2:stateSingleAdaptionsForStaffMember.job2,jobDetails:stateSingleAdaptionsForStaffMember.jobDetails,status:stateSingleAdaptionsForStaffMember.status,requestedDate:adaptionDetails.dismantleRequestedDate,partialDismantleDescription:adaptionDetails.partialDismantleDescription,completeDismantle:adaptionDetails.completeDismantle});
    }
    console.log(adaptionDetails);
    
  },[stateSingleAdaptionsForStaffMember,adaptionDetails])

 

  const next = () => {
      if(image === images[images.length - 1]){
          setImage(images[0])
          return image;
      } else {
          images.map((im,index) => {
              if(im === image){
                  setImage(images[index+1])
              }
              return image;
          })
      }
      return image;
  };

  const back = () => {
      if(image === images[0]){
          setImage(images[images.length-1])
          return image;
      } else {
          images.map((im,index) => {
              if(im === image){
                  setImage(images[index-1])
              }
              return image;
          })
      }
      return image;
  };

  return (
    <div className='w-full'>
        <div className='lg:w-full lg:flex lg:justify-center hidden lg:flex-wrap lg:h-screen lg:content-center'>
            <div className=" bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative   lg:block" role="alert">
                <strong className="font-bold">Warning!</strong>
                <span className="block sm:inline"> This page is only visible with medium and small devices.</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                </span>
            </div>
        </div>
        <div className='w-full h-screen flex-col py-2 lg:hidden flex overflow-hidden'>
          <div className=' w-full flex-col h-1/6 px-4 flex justify-center items-center'>
          <StaffLogo/>
            {/* <img src={logo} className="w-[290px] h-[70px] md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] object-contain" alt="logo"/> */}
            <p className='flex flex-row w-full pt-1 justify-center items-center'>
              <hr className='bg-grayy w-10/12 h-[1px]'/>
            </p>
          </div>

          <div className='w-full h-full m-0 bg-white rounded-md px-4 flex flex-col overflow-scroll scrollbar-hide'>

            <div className='flex h-1/2 flex-col items-center justify-start w-full bg-white'>

              <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-10'>
                  <h1 className='font-semibold text-gray-600 font-sans text-2xl'>My Dismantle</h1>
              </div>

              <div className='flex h-4/6 flex-col items-center justify-center w-full bg-white'>
                {(image === "")?(
                  <CircularProgress className='align-middle' size={100} color="success"/>
                ):(
                  <img className='object-scale-down h-full w-full' src={`${URL}/uploads/${image}`} alt="" />                          
                )}
              </div>

              <div className='flex h-1/6 flex-row justify-between items-end w-1/3'>
                <div className='bg-darkgreen flex justify-center  space-x-32 p-2 rounded-full xxl:p-2'>
                  <ArrowBackIosNewIcon onClick={back} sx={{width:'20px',height:"20px"}} className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"/>
                </div>
                <div className='bg-darkgreen flex justify-center space-x-32 p-2 rounded-full xxl:p-2'>
                  <ArrowForwardIosIcon onClick={next} sx={{width:'20px',height:"20px"}} className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"/>
                </div>
              </div>

            </div>

            <div className='flex h-1/2 justify-center w-full flex-col'>
              <div className='flex h-4/6 flex-shrink-0 my-2 pt-0 justify-start w-full flex-col overflow-scroll scrollbar-hide'>
                <div className='flex flex-row w-full items-start'>
                  <p className='text-sm whitespace-nowrap top-0'>Address : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.job1} {data.job2}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Description : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.jobDescription}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Access : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.jobDetails}</p>
                </div>
                
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Dismantle Requested Date : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.requestedDate}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Dismantle Description : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.partialDismantleDescription}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Dismantle Type : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.completeDismantle?(<span>Complete Dismantle </span>):(<span>Partial Dismantle </span>)}</p>
                </div>
                 
              </div>

              <div className='flex h-1/6 bg-gray-100 rounded-md' >
                <a className='flex flex-row space-x-2 w-full justify-center items-center' href="https://www.google.com/maps" rel="noreferrer" target="_blank">
                  <img src={googleMaps} alt="mape" className='object-contain w-6 h-6'/>
                  <p className='text-[10px]'>Open Google maps in a separate window</p>
                </a>
              </div>

              <div className='w-full h-1/6 bg-white rounded-md px-4 pt-1 flex flex-row items-center justify-between space-x-2 z-20'>
                <button type='submit' onClick={() => navigate("/staffjoblist")} className='px-3 whitespace-nowrap bg-lightgreen text-sm rounded-md p-1 text-white hover:bg-darkgreen'>Back</button>
                
              </div>
            </div>

          </div>
        </div>
        {/* <iframe  frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Little+Lidgey+Campus+Moor+Ponsanooth+++  TR3 7HR&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe> */}
    </div>
  )
}

export default StaffDismantle