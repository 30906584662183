import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';

import {
 
  deleteHandOver,

  fetchHandOver,
 
  signOut,
} from "../store/adminSlice";
import { useNavigate } from "react-router-dom";
import HeaderScaff from "../components/HeaderScaff";
import { IconButton, useMediaQuery } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


import $ from "jquery";
import Swal from "sweetalert2";
$.DataTable = require("datatables.net");

function ViewHandOver() {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [needed, setNeeded] = useState(false);

  const dispatch = useDispatch();
  const handOver = useSelector((state) => state.admin.handOver);

  const _id = useSelector((state) => state.admin._id);
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut = useSelector((state) => state.admin.signOut);
  const swalAlert = useSelector((state) => state.admin.swalAlert);
  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [statesignOut]);

  useEffect(() => {
    dispatch(fetchHandOver({ adminID: _id }));

    setTimeout(() => {
      $("#table").DataTable({ pagingType: "simple_numbers", retrieve: true });
    }, 5000);

    if (!isNonMobile) {
      setSidebarOpen(false);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchHandOver({ adminID: _id }));

    setTimeout(() => {
      $("#table").DataTable({ pagingType: "simple_numbers", retrieve: true });
      $("#table1").DataTable({ pagingType: "simple_numbers", retrieve: true });
    }, 5000);
  }, [swalAlert]);

  const deletee = (id) => {
    dispatch(deleteHandOver({ _id: id, adminID: _id }));
  };

  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
  }, [_id, subscribed]);

  return (
    <div className="w-full h-full">
      <HeaderScaff setSidebarOpen={setSidebarOpen} />
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "basis-1/5"
                : "flex justify-center w-full"
              : "hidden"
          } " h-full"`}
        >
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            needed={needed}
            isNonMobile={isNonMobile}
          />
        </div>

        <div
          onClick={() => setSidebarOpen(true)}
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "hidden"
                : "hidden"
              : isNonMobile
              ? "flex w-12 mt-4"
              : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
          } h-fit`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? ""
                  : ""
                : isNonMobile
                ? ""
                : "hidden"
            } `}
          >
            <IconButton onClick={() => setSidebarOpen(true)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "hidden"
                : "flex"
            } `}
          >
            <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
          </div>
        </div>
        <div
          className={`${
            sidebarOpen ? (isNonMobile ? "" : "hidden") : ""
          } 'flex flex-col w-full lg:basis-3/4 '`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "w-full overflow-x-scroll scrollbar-hide"
                  : "hidden"
                : "w-full overflow-x-scroll scrollbar-hide"
            } " "`}
          >
            <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3">
              Handover
            </p>

            <div className="lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 ">
              <table className="editor_listing_table " id="table">
                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white">
                      Customer Name
                    </th>
                    {/* <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Job ID</th> */}
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Customer address
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Scaffolding Contractor 
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Completion Date
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white">
                      Hand Over Type
                    </th>
                  
                    <th className="  text-sm xxl:text-base text-white font-normal rounded-r-lg ">
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  {handOver.length !== 0 &&
                    handOver.map((data) => {
                      return (
                       
                        <tr className="">
                          <td className="w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm text-center">
                            {data.clientName}
                          </td>
                          {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data._id}</td> */}
                          <td className="w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                            {data.address}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                            {data.scaffoldingContractor}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                            {data.dateOfScoffolldingCompletion}
                          </td>
                          <td className="w-3/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap">
                            {data.adaptionID?"Adaption":"Qoutation"}
                          </td>
                         
                          <td className=" w-1/12 border-[1px] border-gray-300 p-1">
                            <div className="flex flex-row justify-center space-x-2 xxl:space-x-5 px-4">
                              <div
                                className="flex flex-col items-center"
                                onClick={(e) => deletee(data._id)}
                              >
                                <DeleteIcon
                                  sx={{ width: "17px", height: "17px" }}
                                  className="text-lightgreen hover:text-darkgreen cursor-pointer"
                                />
                                <p className="text-[10px] xxl:text-sm">
                                  Delete
                                </p>
                              </div>
                              <div className='flex flex-col items-center' onClick={(e)=>navigate(`/viewhandoverform?id=${data._id}&type=${data.adaptionID?"adaption":"qoutation"}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewHandOver;
