import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  addDismantle,
  adminActions,
  fetchAdminApprovedQoutes,
  signOut,
} from "../store/adminSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import HeaderScaff from "../components/HeaderScaff";
import escapeHtml from "../functions/Validator";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Switch } from "@mui/material";
import moment from "moment";

function Dismantle() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [needed, setNeeded] = useState(false);
  const dispatch = useDispatch();
  const stateSwalAlert = useSelector((state) => state.admin.swalAlert);
  const _id = useSelector((state) => state.admin._id);
  const [data, setData] = useState({
    type: "",
    date: "",
    completeDismantle: false,
    partialDismantleDescription: "",
    qouteID: "",
    adminID:_id
  });
  const navigate = useNavigate();
  const subscribed = useSelector((state) => state.admin.subscribed);
  const stateApprovedQoutes = useSelector(
    (state) => state.admin.adminApprovedQoutes
  );
  const statesignOut = useSelector((state) => state.admin.signOut);
  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [statesignOut]);
  const assignQouteAndType = (a) => {
    const arr = a.split("<");
    setData({ ...data, type: arr[1], qouteID: arr[0] });
  };
  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, date: escapeHtml(newDate) });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };

  useEffect(() => {
    dispatch(adminActions.removeSwalAlert());
    dispatch(fetchAdminApprovedQoutes({ adminID: _id }));
  }, []);

  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
  }, []);

  useEffect(() => {
    if (stateSwalAlert === "dismantleAdded") {
      Swal.fire({
        icon: "success",
        title: "success",
        confirmButtonColor: "#A7B778",
        text: "Dismantle Added Successfully! ",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        setData({
          type: "",
          date: "",
          completeDismantle: false,
          partialDismantleDescription: "",
          qouteID: "",
        });
      });
    }
  }, [stateSwalAlert]);

  const submit = (e) => {
    e.preventDefault();
    console.log(data);
    dispatch(addDismantle(data));
  };

  useEffect(() => {
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
  }, []);

  return (
    <div className="w-full h-full">
      <HeaderScaff />
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "basis-1/5"
                : "flex justify-center w-full"
              : "hidden"
          } "w-full h-full"`}
        >
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            needed={needed}
            isNonMobile={isNonMobile}
          />
        </div>

        <div
          onClick={() => setSidebarOpen(true)}
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "hidden"
                : "hidden"
              : isNonMobile
              ? "flex w-12 mt-4"
              : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
          } h-fit`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? ""
                  : ""
                : isNonMobile
                ? ""
                : "hidden"
            } `}
          >
            <IconButton onClick={() => setSidebarOpen(true)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "hidden"
                : "flex"
            } `}
          >
            <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
          </div>
        </div>

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 w-full "
                : "hidden"
              : "w-full"
          } " "`}
        >
          <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4">
            Dismantle Required
          </p>
          <div className="lg:flex lg:flex-row flex-col">
            <div className="lg:basis-1/2 w-full">
              <div className="p-2 flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                  Select Address
                </p>
                <select
                  name="cars"
                  onChange={(e) => assignQouteAndType(e.target.value)}
                  className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  id="cars"
                >
                  <option value="" className="text-xs xxl:text-xl">
                    Select Address
                  </option>
                  {stateApprovedQoutes.map((c) => {
                    return (
                      <option
                        value={c._id + "<" + c.type}
                        className="text-xs xxl:text-xl"
                      >
                        {c.jobID.job1 + " " + c.jobID.job2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="basis-1/2 flex-col flex  justify-between ">
              <div className=" flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:mt-2 xxl:text-xl mt-1">
                  Requested Date
                </p>
                <div className="flex justify-between">
                  <input
                    className="bg-gray-200 opacity-50 p-[2px] w-full xxl:p-3 outline-none pl-1"
                    value={data.date}
                    onChange={(e) => onChangeDate(e)}
                    required
                    type="date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ml-2 lg:w-full xxl:w-full mt-4 ">
            <p className="p-1 font-semibold text-xs xxl:mt-2 xxl:text-xl mt-16">
              If this is a complete dismantle please select the toggle below
            </p>
            <p className="p-1 font-semibold text-xs xxl:mt-2 xxl:text-xl mt-1">
              Complete Dismantle{" "}
              <span>
                <Switch
                  className="ml-4 text-lightgreen -my-2"
                  onChange={() =>
                    setData({
                      ...data,
                      completeDismantle: !data.completeDismantle,
                    })
                  }
                  checked={data.completeDismantle}
                  color="success"
                />
              </span>
            </p>
            <p className="p-1 font-semibold text-xs xxl:text-xl mt-16">
              If this is a partial dismantle for example - drop the top lift,
              please give the details below
            </p>
            <textarea
              className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full"
              value={data.partialDismantleDescription}
              onChange={(e) =>
                setData({
                  ...data,
                  partialDismantleDescription: escapeHtml(e.target.value),
                })
              }
              id="w3review"
              name="w3review"
              rows="6"
              cols="50"
            ></textarea>
            <div className="flex justify-end ">
              <button
                id="abw"
                className="h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
                onClick={submit}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dismantle;
