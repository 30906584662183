import React, { useEffect } from 'react'
import builderImage from '../pictures/builderImageWaterMark.jpeg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdaptionForStaffMember, fetchCompanyDetails, fetchJobsForStaffMember, fetchQoutaionForStaffMember, signOut, staffActions } from '../store/staffSlice';
import StaffLogo from '../components/StaffLogo';
import { useNavigate } from 'react-router-dom';



function StaffJobList() {
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const adminID = useSelector((state) => state.staff.adminID)
    const ID = useSelector((state) => state.staff.staff_id)
    const stateJobsForStaffMember = useSelector((state) => state.staff.jobsForStaffMember)
    const adaptionsForStaffMember = useSelector((state) => state.staff.adaptionsForStaffMember)
    const qoutaionForStaffMember = useSelector((state) => state.staff.qoutaionForStaffMember)
    const StatesignOut = useSelector((state) => state.staff.signOut);


    useEffect(()=>{
       
    },[stateJobsForStaffMember])
    
    useEffect(() => {
        dispatch(fetchCompanyDetails({adminID:adminID}))
        dispatch(fetchJobsForStaffMember({_id:ID}))
        dispatch(fetchQoutaionForStaffMember({ _id: ID }))
        
        dispatch(fetchAdaptionForStaffMember({ _id: ID }))
        console.log(adaptionsForStaffMember,qoutaionForStaffMember)
        // console.log(qoutaionForStaffMember[0].dismantleRequestedDate,"---------");
    }, [])
    useEffect(() => {
        if (StatesignOut === "signOut" || ID==="") {
            dispatch(staffActions.removesignout())
          dispatch(staffActions.signout())
        navigate("/")
      }
    },[StatesignOut])
    const signout = () => {
        dispatch(signOut())
        
    }

  return (
    <div className='w-full'>
        <div className='lg:w-full lg:flex lg:justify-center hidden lg:flex-wrap lg:h-screen lg:content-center'>
            <div className=" bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative   lg:block" role="alert">
                <strong className="font-bold">Warning!</strong>
                <span className="block sm:inline"> This page is only visible with medium and small devices.</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                </span>
            </div>
        </div>
        <div className='w-full h-screen flex-col lg:hidden flex items-center justify-between overflow-hidden'>
            <div className=' w-full h-1/6 px-4 flex justify-center items-center'>
                <StaffLogo/>
            </div>
            <p className='flex flex-row w-full justify-center items-center relative'>
                <hr className='bg-grayy w-10/12 h-[1px]'/>
            </p>
            <div className='w-full h-5/6 bg-white rounded-md px-4 flex flex-col justify-center z-20  space-y-[3px]'>
                <div className='w-full h-5/6 bg-white rounded-md flex flex-col items-center justify-center z-10'>
                    <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-10'>
                        <h1 className='font-semibold text-gray-600 font-sans text-lg'>My Jobs</h1>
                    </div>
                    <div className='w-full h-5/6 m-0 max-h-min object-fill flex flex-col top-0 py-2 space-y-2 justify-start overflow-auto scrollbar-hide'>
                          {stateJobsForStaffMember.map((a) => {
                          return    a.status!==2 &&
                              
                              (
                            <div onClick={() => navigate(`/staffjob?id=${a._id}&type=${a.typeofScafollding}`)} className='flex flex-row shadow-md border-x-[1px] border-t-[1px] hover:shadow-lg w-full px-2 justify-start h-fit rounded-md py-1 space-x-2 items-center z-30'>
                                <div className='w-1/6 h-12 flex items-center justify-center'>
                                    <img src={builderImage} alt="" className="w-12 h-12 opacity-80 border-2 rounded-full object-contain"/>
                                </div>
                                <div className='w-5/6 h-12 flex items-center overflow-hidden'>
                                    <p className='whitespace-nowrap'>
                                        {a.job1} {a.job2}
                                    </p>
                                </div>
                            </div>
                        )})}
                      </div>
                      <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-10'>
                        <h1 className='font-semibold text-gray-600 font-sans text-lg'>My Dismantle</h1>
                    </div>
                    <div className='w-full h-5/6 m-0 max-h-min object-fill flex flex-col top-0 py-2 space-y-2 justify-start overflow-auto scrollbar-hide'>
                        {qoutaionForStaffMember.map((a) => (
                           a.dismantleRequestedDate!==undefined?(<div onClick={() => navigate(`/staffdismantle?id=${a._id}&jobID=${a.jobID._id}&type=${a.jobID.typeofScafollding}`)} className='flex flex-row shadow-md border-x-[1px] border-t-[1px] hover:shadow-lg w-full px-2 justify-start h-fit rounded-md py-1 space-x-2 items-center z-30'>
                           <div className='w-1/6 h-12 flex items-center justify-center'>
                               <img src={builderImage} alt="" className="w-12 h-12 opacity-80 border-2 rounded-full object-contain"/>
                           </div>
                           <div className='w-5/6 h-12 flex items-center overflow-hidden'>
                               <p className='whitespace-nowrap'>
                                   {a.jobID.job1} {a.jobID.job2}
                               </p>
                           </div>
                            </div>
                            ) : (<div></div>)
                        ))}
                    </div>
                    <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-10'>
                        <h1 className='font-semibold text-gray-600 font-sans text-lg'>My Adaptions</h1>
                    </div>
                    <div className='w-full h-5/6 m-0 max-h-min object-fill flex flex-col top-0 py-2 space-y-2 justify-start overflow-auto scrollbar-hide'>
                          {adaptionsForStaffMember.map((a) =>
                          {
                            return  a.status!==2&&
                              (
                                  <div onClick={() => navigate(`/staffadaption?id=${a._id}&type=${a.jobID.typeofScafollding}`)} className='flex flex-row shadow-md border-x-[1px] border-t-[1px] hover:shadow-lg w-full px-2 justify-start h-fit rounded-md py-1 space-x-2 items-center z-30'>
                                      <div className='w-1/6 h-12 flex items-center justify-center'>
                                          <img src={builderImage} alt="" className="w-12 h-12 opacity-80 border-2 rounded-full object-contain" />
                                      </div>
                                      <div className='w-5/6 h-12 flex items-center overflow-hidden'>
                                          <p className='whitespace-nowrap'>
                                              {a.jobID.job1} {a.jobID.job2}
                                          </p>
                                      </div>
                                  </div>
                              )
                          })}
                    </div>
                </div>
                <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-20'>
                    <button onClick={(e)=>window.location.reload(true)} className='w-10/12 bg-lightgreen rounded-md p-1 my-2 text-white hover:bg-darkgreen'>Refresh</button>
                    <button onClick={(e)=>signout()} className='w-10/12 bg-lightgreen rounded-md p-1 my-2 text-white hover:bg-darkgreen'>Back</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StaffJobList