import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../pictures/TheBestescafflogo.png'
import logooo from '../pictures/Logo.png'
import tradesmanLogo from '../pictures/tradeslogo.jpg'
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../store/adminSlice';

function Sidebar({setSidebarOpen, isNonMobile, needed }) {
    const navigate = useNavigate();
    const stateAlert=useSelector((state)=>state.admin.alert);
  const adminID = useSelector((state) => state.admin._id);
  const statesignOut=useSelector((state)=>state.admin.signOut)
    const dispatch = useDispatch();

    useEffect(() => {
      if (adminID === "" ) {
        
        navigate("/signin")
      }
      if (statesignOut === "signOut") {
        dispatch(signOut())
      }
    },[adminID,statesignOut])

    const signOut = () => {
      const adminData= localStorage.removeItem("adminData");
      dispatch(adminActions.signOut());
    };

    const priceList = () => {
      if(!isNonMobile){
        navigate('/pricelist');
        setSidebarOpen(false);
      } else {
        navigate('/pricelist');
      }
    }

    const scaffoldingInspection = () => {
      if(!isNonMobile){
        navigate('/scaffoldinginspections');
        setSidebarOpen(false);
      } else {
        navigate('/scaffoldinginspections');
      }
    }

    const keyStaff = () => {
      if(!isNonMobile){
        navigate('/staffmember');
        setSidebarOpen(false);
      } else {
        navigate('/staffmember');
      }
    }

    const addNewJob = () => {
      if(!isNonMobile){
        navigate('/addanewjob');
        setSidebarOpen(false);
      } else {
        navigate('/addanewjob');
      }
  }
    const addAdaption = () => {
      if(!isNonMobile){
        navigate('/addadaption');
        setSidebarOpen(false);
      } else {
        navigate('/addAdaption');
      }
  }
  const AddANewVechile = () => {
    if(!isNonMobile){
      navigate('/addanewvechile');
      setSidebarOpen(false);
    } else {
      navigate('/addanewvechile');
    }
  }
  const archiveQoute = () => {
    if(!isNonMobile){
      navigate('/archivependingprojectquotes');
      setSidebarOpen(false);
    } else {
      navigate('/archivependingprojectquotes');
    }
  }

    const createNewCustomer = () => {
      if(!isNonMobile){
        navigate('/createnewcustomer');
        setSidebarOpen(false);
      } else {
        navigate('/createnewcustomer');
      }
    }

    const adminUpload = () => {
      if(!isNonMobile){
        navigate('/adminupload');
        setSidebarOpen(false);
      } else {
        navigate('/adminupload');
      }
  }
  const vechicleCheck = () => {
    if(!isNonMobile){
      navigate('/vechiclecheck');
      setSidebarOpen(false);
    } else {
      navigate('/vechiclecheck');
    }
}
  const vechicleInspection = () => {
    if(!isNonMobile){
      navigate('/vechicleinspection');
      setSidebarOpen(false);
    } else {
      navigate('/vechicleinspection');
    }
}
  const dismantle = () => {
    if(!isNonMobile){
      navigate('/dismantle');
      setSidebarOpen(false);
    } else {
      navigate('/dismantle');
    }

  }
  const handOver = () => {
    if (!isNonMobile) {
      navigate('/viewhandover');
      setSidebarOpen(false);
    } else {
      navigate('/viewhandover');
    }
  }
  const jobList = () => {
    if(!isNonMobile){
      navigate('/joblist');
      setSidebarOpen(false);
    } else {
      navigate('/joblist');
    }
}
  const printallinspections = () => {
    if(!isNonMobile){
      navigate('/scaffoldinspectionlist');
      setSidebarOpen(false);
    } else {
      navigate('/scaffoldinspectionlist');
    }
}
  const scaffoldingInspections = () => {
    if(!isNonMobile){
      navigate('/allscaffoldingInspections');
      setSidebarOpen(false);
    } else {
      navigate('/allscaffoldingInspections');
    }
}
  const signOutt=()=>{
    dispatch(signOut())
  }
  const healthAndsafety = () => {
    if(!isNonMobile){
      navigate('/healthandsafety');
      setSidebarOpen(false);
    } else {
      navigate('/healthandsafety');
    }
  }

    return (
    <div className={`${isNonMobile? "sticky top-24 basis-1/5 -ml-4 sm:-ml-4 md:-ml-0 mt-0 md:mt-5 xxl:mt-8 w-full flex flex-col h-fit justify-between mr-5 items-center lg:inline-block"
    :"sticky top-24 pt-4 w-full flex flex-col h-full space-y-3"}`}>
    {/* <div className='flex justify-center h-full w-full flex-col content-center flex-wrap'>
      <div className={`${isNonMobile? "rounded-t-lg":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={adminUpload}>Company Details</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={keyStaff}>Key Staff</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={priceList} >Your Price List</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={healthAndsafety} >Upload Health & Safety</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={createNewCustomer}>Create new Customer</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={addNewJob}>Add new Job</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={addAdaption}>Add Adaption</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={AddANewVechile}>Add Vehicle</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={vechicleCheck}>Vehicle Check</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={vechicleInspection}>Vehicle Inspection</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={dismantle}>Dismantle</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center border-b-0`} onClick={scaffoldingInspections}>Scaffolding Inspections</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:text-white hover:bg-lightgreen whitespace-nowrap px-1 sm:px-6 sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:px-4 xxl:py-1 xxl:text-xl text-center`} onClick={(e)=>signOutt()}>Sign Out</div>
    </div>
    <a href="https://www.tradesman-insurance4u.co.uk/" rel="noreferrer" target="_blank"><header className='w-full flex flex-col items-center justify-center pt-1'>
         <img  className={`${isNonMobile? "":"w-36 h-16" } w-32 h-8 sm:w-36 sm:h-10 md:w-48 md:h-20 xxl:w-48 xxl:h-32 object-contain`} src={tradesmanLogo} alt="logo" />
         <p className='text-xs'>Sponsored by Trades Man Insurance</p>
     </header></a>
    <a href="https://scaffoldfinance.co.uk/" rel="noreferrer" target="_blank"><header className='w-full flex flex-col items-center justify-center pt-1'>
         <img  className={`${isNonMobile? "":"w-36 h-16" } w-32 h-8 sm:w-36 sm:h-10 md:w-48 md:h-20 xxl:w-48 xxl:h-32 object-contain`} src={logooo} alt="logo" />
         <p className='text-xs'>Sponsored by Scaffold Finance</p>
     </header></a>
    <div className={`${needed? "flex" : "flex" } w-full items-center justify-center pt-4`}>
      <IconButton onClick={() => setSidebarOpen(false)}>
        <ArrowBackIosIcon/>
      </IconButton>
    </div>
   
     <header className='w-full flex items-center justify-center pt-12'>
         <img onClick={() => navigate('/createnewcustomer')} className={`${isNonMobile? "":"w-36 h-16" } w-32 h-8 sm:w-36 sm:h-10 md:w-36 md:h-16 xxl:w-48 xxl:h-32 object-contain `} src={logo} alt="logo" />
     </header> */}

     {/* New sidebar design */}

<div className='flex justify-center h-full w-full flex-col content-center flex-wrap'>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-bold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base font-semibold xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={adminUpload}>Set up your company</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={keyStaff}>Add key staff</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={priceList} >Set up your Price List</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={scaffoldingInspections}>Scaffolding Inspections</div>

      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-bold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base font-semibold mt-5 xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={createNewCustomer}>Create a customer</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={addNewJob}>Add a new job</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={healthAndsafety} >Upload health & safety</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={addAdaption}>Add adaptions</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={dismantle}>Dismantles</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={handOver}>Handovers</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={jobList}>Print Job</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={printallinspections}>Print Inspections</div>

          <div className={isNonMobile?"hidden":`${isNonMobile ? "" : "w-full py-[5px] border-x-0"} border-lightgreen cursor-pointer border-[1px] hover:font-bold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base font-semibold mt-5 xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={archiveQoute}>Archive Qoute</div>
          
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-bold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base font-semibold mt-5 xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={AddANewVechile}>Add your vehicle</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={vechicleCheck}>Vehicle check</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={vechicleInspection}>Vehicle Inspection</div>
      <div className={`${isNonMobile? "":"w-full py-[5px] border-x-0" } border-lightgreen cursor-pointer border-[1px] hover:font-semibold hover:translate-x-1 whitespace-nowrap sm:py-[4px] py-[1px] text-[12px] sm:text-sm lg:text-base xxl:py-1 xxl:text-xl text-start border-t-0 border-x-0`} onClick={(e)=>signOutt()}>Sign Out</div>
    </div>
    <div className={`${needed? "flex" : "flex" } w-full items-center justify-center pt-4`}>
      <IconButton onClick={() => setSidebarOpen(false)}>
        <ArrowBackIosIcon/>
      </IconButton>
    </div>
   
     <header className='w-full flex items-center justify-center pt-12'>
         <img onClick={() => navigate('/createnewcustomer')} className={`${isNonMobile? "":"w-36 h-16" } w-32 h-8 sm:w-36 sm:h-10 md:w-36 md:h-16 xxl:w-48 xxl:h-32 object-contain `} src={logo} alt="logo" />
     </header>


 </div>
)
}

export default Sidebar