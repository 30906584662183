import React, { useEffect, useMemo, useState } from "react";
import FiveButtons from "../components/FiveButtons";
import ImageSlider from "../components/ImageSlider";
import { Switch } from "@mui/material";
import Logo from "../components/Logo";
import { useDispatch, useSelector } from "react-redux";
import {
  addEscaffQoutation,
  adminActions,
  fetchEscaffQoutation,
  fetchPriceList,
  fetchSingleJob,
  signOut,
} from "../store/adminSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import escapeHtml from "../functions/Validator";

function CreateQuotation() {
  const [elevationName, setElevationName] = useState("");
  const [tempArr, setTempArr] = useState([]);
  const [dynamicOBJ, setDynamicOBJ] = useState([]);
  const [result, setResult] = useState(0);
  const statePriceList = useSelector((state) => state.admin.priceList);
  const stateSingleJob = useSelector((state) => state.admin.singleJob);
  const escaffQuotationSwalAlert = useSelector(
    (state) => state.admin.swalAlert
  );
  const stateEscaffFetched = useSelector((state) => state.admin.escaffFetched);
  const [additionalItem, setAdditionalItem] = useState([]);
  const [sod, setSod] = useState([]);
  // const [additionalItemInput,setAdditionalItemInput]=useState({additionalItemCost:"",numberOfadditionalItem:""})

  const _id = useSelector((state) => state.admin._id);
  const stateEscaffQoutation = useSelector(
    (state) => state.admin.escaffQoutation
  );
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const navigate = useNavigate();
  const [data, setData] = useState(stateEscaffQoutation);

  const [sendingData, setSendingData] = useState(data);
  const [preReady, setPreReady] = useState();
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
  }, []);
  useEffect(() => {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, dateOfErection: newDate, nextInspectionDate: newDate });
    // setData({ ...data, customerID: stateSingleJob.customer._id })
  };
  const handleElevation = () => {
    if (elevationName !== "") {
      tempArr.push(elevationName);
      setDynamicOBJ([
        ...dynamicOBJ,
        { elevationNamee: "", elevationLength: "0", elevationLifts: "0" },
      ]);
      setElevationName("");
      setData({ ...data, customerID: stateSingleJob.customer._id });
    }
  };
  const handleSubmit = () => {
    if (!result) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly calculate first.!",
      });
    } else {
      console.log(result,"resu");
      setSendingData({ ...data, jobID: id, totalCostResult: result });
    }
  };

  useEffect(() => {
    dispatch(addEscaffQoutation(sendingData));
  }, [sendingData]);

  useEffect(() => {
    setData(stateEscaffQoutation);
    setAdditionalItem(stateEscaffQoutation.additionalItem);
  }, [stateEscaffQoutation]);

  useEffect(() => {
    if (stateEscaffFetched) {
      // data.dynamicOBJ.map((as) => {
      //     setDynamicOBJ([...dynamicOBJ, { elevationNamee: as.elevationNamee, elevationLength: as.elevationLength, elevationLifts: as.elevationLifts }])
      //     console.log(as.elevationNamee,"-",as.elevationLength,"-",as.elevationLifts);
      // })
      console.log(data.dynamicOBJ);

      setDynamicOBJ(data.dynamicOBJ);
      data.dynamicOBJ.map((a) => {
        tempArr.push(a.elevationNamee);
      });
      dispatch(adminActions.removeEscaffFetched());
    }
  }, [data]);

  useEffect(() => {
    console.log(dynamicOBJ);
  }, [dynamicOBJ]);

  useEffect(() => {
    if (escaffQuotationSwalAlert.message === "Saved") {
      Swal.fire({
        icon: "success",
        title: "Saved",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        window.location.reload();
      });
    }
    if (escaffQuotationSwalAlert.message === "updatedEscaff") {
      Swal.fire({
        icon: "success",
        title: "Updated",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeSwalAlert());
        window.location.reload();
      });
    }
  }, [escaffQuotationSwalAlert]);

  useEffect(() => {
    dispatch(fetchEscaffQoutation({ jobID: id,adminID:_id }));
    dispatch(adminActions.removeSwalAlert());
  }, []);

  const handleCalculate = () => {
    if (
      dynamicOBJ.length === 0 ||
      dynamicOBJ[0].elevationNamee === "" ||
      data.dateOfErection === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Add Elevation/Select Date & add number of weeks.!",
      });
    } else {
      var WeeklyScaffoldingInspection =
        statePriceList.scaffoldingInspectionPerVisit;
      var elevationResult = dynamicOBJ.reduce(
        (sum, item) =>
          sum +
          item.elevationLength *
            item.elevationLifts *
            statePriceList.linearMerterageCost,
        0
      );
      
      var gantryResult =
        parseInt(statePriceList.linearMerterageCost) *
        parseInt(data.gantryLength) *
        parseInt(data.gantryLift) *
        parseInt(data.gantryWidth);
      var bayResult =
        parseInt(statePriceList.linearMerterageCost) *
        parseInt(data.baylength) *
        parseInt(data.bayLift) *
        parseInt(data.bayWidth);
      var deckResult =
        parseInt(statePriceList.linearMerterageCost) *
        parseInt(data.deckLength) *
        parseInt(data.deckLift) *
        parseInt(data.deckWidth);
      var hopResult = parseInt(statePriceList.hopUpCost) * parseInt(data.hopUp);
      var saddleResult =
        parseInt(statePriceList.saddleCost) * parseInt(data.saddle);
      var throwInResult =
        parseInt(statePriceList.throwinCost) * parseInt(data.throwIn);
      var hakkiLayherResult =
        parseInt(statePriceList.hakkiLayher) * parseInt(data.hakkiStair);
      var fivebyoneResult =
        parseInt(statePriceList.fivebyonepointeightTower) *
        parseInt(data.fiveFootTower) *
        parseInt(data.fiveFootTowerLift);
      console.log(fivebyoneResult,statePriceList.fivebyonepointeightTower,data.fiveFootTower,data.fiveFootTowerLift)
      var eightByFourResult =
        parseInt(statePriceList.eightbytwopointfourTower) *
        parseInt(data.eightFootTower) *
        parseInt(data.eightFootTowerLift);
      var tenResult =
        parseInt(statePriceList.tenbythreeTower) *
        parseInt(data.tenFootTower) *
        parseInt(data.tenFootTowerLift);
      var thirteenResult =
        parseInt(statePriceList.thirteenbyfourTower) *
        parseInt(data.thirteenFootTower) *
        parseInt(data.thirteenFootTowerLift);
      var additionalItemss = 0;
      if (data.additionalItem.length !== 0) {
        additionalItemss = preReady;
        console.log(additionalItemss,
        "==adddddd====")
      }
      var nonpercentage =
        parseInt(data.streetLicenseCost) + parseInt(data.externalDesignCost);
      var res = 0;
      res =
        res +
        elevationResult +
        gantryResult +
        bayResult +
        deckResult +
        hopResult +
        saddleResult +
        throwInResult +
        hakkiLayherResult +
        fivebyoneResult +
        eightByFourResult +
        tenResult +
        thirteenResult +
        additionalItemss;
   
      var weeks = data.numberOfWeekRequired;
      if (data.WeeklyScaffoldingInspections) {
        console.log(data.WeeklyScaffoldingInspections, "true");
        var nweek = weeks - 1;
        var r1 = nweek * WeeklyScaffoldingInspection;
        res = res + r1;

      }
      console.log(data.customerVatRegisterSwitch,"switchhhhhhh")
      if (data.customerVatRegisterSwitch) {
        var newresult =
          elevationResult +
          gantryResult +
          bayResult +
          deckResult +
          hopResult +
          saddleResult +
          throwInResult +
          hakkiLayherResult +
          fivebyoneResult +
          eightByFourResult +
          tenResult +
          thirteenResult +
          additionalItemss;
        var theVatPercentage = (data.customerVatRegister / 100) * newresult;
        console.log(theVatPercentage,"thevatpercen")
        res = res + theVatPercentage;
      }
      console.log(parseInt(data.numberOfWeekRequired) > parseInt(data.standardNumberOfWeek),"true ot false")
      console.log(parseInt(data.numberOfWeekRequired) +" "+ parseInt(data.standardNumberOfWeek),"true ot false")
      if (parseInt(data.numberOfWeekRequired) > parseInt(data.standardNumberOfWeek)) {
        var percentageWeek = data.numberOfWeekRequired - data.standardNumberOfWeek;

        var overhired = statePriceList.overhirePercentage * percentageWeek;

        var orignalPercentage = (overhired / 100) * res;
        console.log(orignalPercentage, "origanl peeeeeeee")
        console.log(res,"ressssssssssss");


        res = res + nonpercentage + orignalPercentage;
      } else {
        res = res + nonpercentage;
      }
      if (!res) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          confirmButtonColor: "#A7B778",
          text: "Check all the inputs.!",
        });
        setResult(res);
      } else {
        setResult(res.toFixed(2));
        setData({ ...data, totalCostResult: res });

        setData({
          ...data,
          dynamicOBJ: dynamicOBJ,
          approved: 0,
          additionalItem: additionalItem,
        });
      }
    }
  };

  const handleDynamic = (e, i) => {
    const { name, value } = e.target;
    const list = [...dynamicOBJ];
    list[i][name] = value;
    setDynamicOBJ(list);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleJob({ _id: id ,adminID:_id}));
      dispatch(fetchPriceList({_id:_id,adminID:_id}));
    } else {
      navigate("/pendingprojectqoutes");
    }
  }, []);

  const removeDynamicObject = (a) => {
    setDynamicOBJ(dynamicOBJ.filter((x, i) => i !== a));
    setTempArr(tempArr.filter((b, i) => i !== a));
  };
  const handleAdditionalItem = (e, i) => {
    const { name, value } = e.target;
    const splitr = value.split("_");
    const list = [...additionalItem];
    list[i][name] = splitr[0];
    if (splitr.length > 1) {
      list[i]["additionalItemName"] = splitr[1];
    }
    setAdditionalItem(list);
  };

  useEffect(() => {
    setPreReady(
      additionalItem.reduce(
        (sum, item) =>
          sum +
          parseInt(item.additionalItemCost) *
            parseInt(item.numberOfadditionalItem),
        0
      )
    );
  }, [additionalItem]);

  const addAdditionalItem = () => {
    setAdditionalItem([
      ...additionalItem,
      {
        additionalItemName: "",
        additionalItemCost: 0,
        numberOfadditionalItem: 0,
      },
    ]);
  };
  const handleRemoveAdditionalItem = (i) => {
    const list = [...additionalItem];
    list.splice(i, 1);
    setAdditionalItem(list);
  };

  return (
    <div className="w-full">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-20 sticky top-0 bg-white z-40">
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        <Logo />
        <div className="w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8">
          <FiveButtons />
        </div>
      </header>
      <div className="w-full flex flex-col sm:flex-row h-fit p-6">
        <div className="flex flex-col basis-1 sm:basis-1/2 w-full px-0 sm:px-12 xxl:mt-4">
          <p className="text-base xxl:text-2xl text-slate-400">
            Create your quotation{" "}
          </p>
          <p className="text-xs xxl:text-base font-semibold">
            create a quotation filling out the details below
          </p>
          <div className="flex flex-col w-full mt-2">
            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter a new elevation and name it
              <span className="font-normal text-gray-400">
                (example: front elevation or rear elevation)
              </span>
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              onChange={(e) => setElevationName(escapeHtml(e.target.value))}
              value={elevationName}
              type="text"
              required
            />

            <button
              onClick={handleElevation}
              className="self-start outline-none bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-5 mt-2 text-sm xxl:text-lg"
            >
              Add
            </button>

            {dynamicOBJ.map((a, i) => (
              <div>
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Select elevation
                </p>
                <select
                  onChange={(e) => handleDynamic(e, i)}
                  value={dynamicOBJ.elevationNamee}
                  name="elevationNamee"
                  id="elevationName"
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-2 w-full outline-none pl-1 mt-1"
                >
                  <option value="">Select Elevation</option>
                  {tempArr.map((a) =>
                    !stateEscaffQoutation ? (
                      <option value={a}>{a}</option>
                    ) : dynamicOBJ[i].elevationNamee === a ? (
                      <option value={a} selected>
                        {a}
                      </option>
                    ) : (
                      <option value={a}>{a}</option>
                    )
                  )}
                </select>

                <div className="flex w-full space-x-1 flex-col lg:flex-row">
                  <div className="w-full sm:basis-1/2 flex flex-col items-center">
                    <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                      Enter elevation length in meters
                    </p>
                    <input
                      onChange={(e) => handleDynamic(e, i)}
                      value={dynamicOBJ[i].elevationLength}
                      name="elevationLength"
                      className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                      type="text"
                      required
                    />
                  </div>
                  <div className="w-full sm:basis-1/2 flex flex-col items-center">
                    <p className="px-1 pt-3 pl-0 self-start font-semibold text-[12px] xxl:text-base">
                      Enter the number of lifts
                    </p>
                    <input
                      onChange={(e) => handleDynamic(e, i)}
                      value={dynamicOBJ[i].elevationLifts}
                      name="elevationLifts"
                      className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={(e) => removeDynamicObject(i)}
                    className="outline-none bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-5 mt-2 text-sm xxl:text-lg"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}

            <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
              Select the date of erection
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              value={data.dateOfErection}
              type="date"
              onChange={onChangeDate}
              required
            />
            <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter the number of weeks required
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              onChange={(e) =>
                setData({
                  ...data,
                  numberOfWeekRequired: escapeHtml(e.target.value),
                })
              }
              value={data.numberOfWeekRequired}
              type="text"
              required
            />
            <p className="px-1 pt-2 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter the number of standard weeks hire before overhire commence
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              onChange={(e) =>
                setData({
                  ...data,
                  standardNumberOfWeek: escapeHtml(e.target.value),
                })
              }
              value={data.standardNumberOfWeek}
              type="text"
              required
            />

            <p className="px-1 pt-3 pb-1 pl-0 font-semibold text-xs xxl:text-xl">
              Add a Gantry
            </p>
            <p className="px-1 pt-0 pl-0 font-semibold text-[12px] xxl:text-base">
              Select the elevation the Gantry is going to be erected on
            </p>
            <select
              id="elevationName"
              name="elevationName"
              onChange={(e) =>
                setData({
                  ...data,
                  gantryElevation: escapeHtml(e.target.value),
                })
              }
              value={data.gantryElevation}
              className="bg-gray-200 opacity-50 p-[2px] xxl:p-2 w-full outline-none pl-1 mt-1"
            >
              <option value="">Select Elevation</option>
              {tempArr.map((a, i) => (
                <option value={a}>{a}</option>
              ))}
            </select>
            <div className="flex w-full space-x-1">
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Gantry length
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      gantryLength: escapeHtml(e.target.value),
                    })
                  }
                  value={data.gantryLength}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Gantry Width
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      gantryWidth: escapeHtml(e.target.value),
                    })
                  }
                  value={data.gantryWidth}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, gantryLift: escapeHtml(e.target.value) })
                  }
                  value={data.gantryLift}
                  type="text"
                  required
                />
              </div>
            </div>

            <p className="px-1 pt-3 pb-1 pl-0 font-semibold text-xs xxl:text-xl">
              Add a Loading Bay
            </p>
            <p className="px-1 pt-0 pl-0 font-semibold text-[12px] xxl:text-base">
              Select if the elevation the Loading Bay is going to be erected on
            </p>
            {/* <input className='bg-gray-200 opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1' type="text" required/> */}
            <select
              id="elevationName"
              name="elevationName"
              onChange={(e) =>
                setData({ ...data, bayElevation: escapeHtml(e.target.value) })
              }
              className="bg-gray-200 opacity-50 p-[2px] xxl:p-2 w-full outline-none pl-1 mt-1"
            >
              <option value="">Select Elevation</option>
              {tempArr.map((a, i) => (
                <option value={a}>{a}</option>
              ))}
            </select>
            <div className="flex w-full space-x-1">
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Bay length
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, baylength: escapeHtml(e.target.value) })
                  }
                  value={data.baylength}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Bay width
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, bayWidth: escapeHtml(e.target.value) })
                  }
                  value={data.bayWidth}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, bayLift: escapeHtml(e.target.value) })
                  }
                  value={data.bayLift}
                  type="text"
                  required
                />
              </div>
            </div>

            <p className="px-1 pt-3 -mb-2 pl-0 font-semibold text-xs xxl:text-xl">
              Bird Cage/Crash Deck
            </p>
            <div className="flex w-full space-x-1">
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  length
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, deckLength: escapeHtml(e.target.value) })
                  }
                  value={data.deckLength}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Width
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, deckWidth: escapeHtml(e.target.value) })
                  }
                  value={data.deckWidth}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({ ...data, deckLift: escapeHtml(e.target.value) })
                  }
                  value={data.deckLift}
                  type="text"
                  required
                />
              </div>
            </div>

            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter the number of Hop-Ups
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              type="text"
              onChange={(e) =>
                setData({ ...data, hopUp: escapeHtml(e.target.value) })
              }
              value={data.hopUp}
              required
            />
            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter the number of Saddles
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              onChange={(e) =>
                setData({ ...data, saddle: escapeHtml(e.target.value) })
              }
              value={data.saddle}
              type="text"
              required
            />
            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Enter the number of Throw-Ins
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              onChange={(e) =>
                setData({ ...data, throwIn: escapeHtml(e.target.value) })
              }
              value={data.throwIn}
              type="text"
              required
            />

            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Hakki/Lahyer Stair number of lifts
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
              type="text"
              onChange={(e) =>
                setData({ ...data, hakkiStair: escapeHtml(e.target.value) })
              }
              value={data.hakkiStair}
              required
            />

            <p className="px-1 pt-3 -mb-2 pl-0 font-semibold text-xs xxl:text-xl">
              Towers - enter the number of towers required of each size
            </p>
            <div className="flex w-full space-x-1">
              <div className="basis-2/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                  5 foot/1.8 meter tower
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  type="text"
                  onChange={(e) =>
                    setData({
                      ...data,
                      fiveFootTower: escapeHtml(e.target.value),
                    })
                  }
                  value={data.fiveFootTower}
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      fiveFootTowerLift: escapeHtml(e.target.value),
                    })
                  }
                  value={data.fiveFootTowerLift}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex w-full space-x-1">
              <div className="basis-2/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                  8 foot/2.4 meter tower
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      eightFootTower: escapeHtml(e.target.value),
                    })
                  }
                  value={data.eightFootTower}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      eightFootTowerLift: escapeHtml(e.target.value),
                    })
                  }
                  value={data.eightFootTowerLift}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex w-full space-x-1">
              <div className="basis-2/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                  10 foot/3.0 meter tower
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      tenFootTower: escapeHtml(e.target.value),
                    })
                  }
                  value={data.tenFootTower}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      tenFootTowerLift: escapeHtml(e.target.value),
                    })
                  }
                  value={data.tenFootTowerLift}
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex w-full space-x-1">
              <div className="basis-2/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                  13 foot/4.0 meter tower
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      thirteenFootTower: escapeHtml(e.target.value),
                    })
                  }
                  value={data.thirteenFootTower}
                  type="text"
                  required
                />
              </div>
              <div className="basis-1/3 flex flex-col items-center">
                <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                  Number of lifts
                </p>
                <input
                  className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      thirteenFootTowerLift: escapeHtml(e.target.value),
                    })
                  }
                  value={data.thirteenFootTowerLift}
                  type="text"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row w-full space-x-1 pr-8 items-center justify-start pt-4">
              <p className="pr-1 xxl:mr-20 font-semibold text-[12px] xxl:text-base whitespace-nowrap w-52 xxl:w-1/2">
                Weekly Scaffolding Inspections
              </p>
              <Switch
                className="ml-4 text-lightgreen -my-2"
                onChange={() =>
                  setData({
                    ...data,
                    WeeklyScaffoldingInspections:
                      !data.WeeklyScaffoldingInspections,
                  })
                }
                checked={data.WeeklyScaffoldingInspections}
                color="success"
              />
            </div>

            <p className="pt-3 font-semibold text-[12px] xxl:text-base">
              Enter the Street license Cost
            </p>
            <input
              className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
              onChange={(e) =>
                setData({
                  ...data,
                  streetLicenseCost: escapeHtml(e.target.value),
                })
              }
              value={data.streetLicenseCost}
              type="text"
              required
            />

            <div className="flex flex-col w-full">
              <p className="pt-3 font-semibold text-[12px] xxl:text-base">
                Customer is VAT Registered
              </p>
              <div className="flex flex-row justify-start w-full">
                <input
                  className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      customerVatRegister: escapeHtml(e.target.value),
                    })
                  }
                  value={data.customerVatRegister}
                  type="text"
                  required
                />
                <Switch
                  className="ml-4 xxl:-ml-2 text-lightgreen -my-2"
                  onChange={() =>
                    setData({
                      ...data,
                      customerVatRegisterSwitch:
                        !data.customerVatRegisterSwitch,
                    })
                  }
                  checked={data.customerVatRegisterSwitch}
                  color="success"
                />
              </div>
            </div>

            <p className="pt-3 font-semibold text-[12px] xxl:text-base">
              External Design Cost
            </p>
            <input
              className="xxl:mr-20 bg-gray-200  opacity-60 px-[2px] xxl:p-2 w-52 xxl:w-1/2 outline-none pl-1"
              onChange={(e) =>
                setData({
                  ...data,
                  externalDesignCost: escapeHtml(e.target.value),
                })
              }
              value={data.externalDesignCost}
              type="text"
              required
            />

            <p className="px-1 pt-3 -mb-2 pl-0 font-semibold text-xs xxl:text-xl">
              Additional items
            </p>

            {additionalItem.map((x, i) => {
              return (
                <>
                  <div className="flex w-full space-x-1">
                    <div className="basis-2/3 flex flex-col items-center">
                      <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start">
                        Select an item from your list
                      </p>
                      <select
                        onChange={(e) => handleAdditionalItem(e, i)}
                        value={
                          x.additionalItemCost + "_" + x.additionalItemName
                        }
                        name="additionalItemCost"
                        id="elevationName"
                        className="bg-gray-200 opacity-50 p-[2px] xxl:p-2 w-full outline-none pl-1 mt-1"
                      >
                        <option value=""></option>
                        {statePriceList.addItem.map((a) => (
                          <option value={a.cost + "_" + a.name}>
                            {a.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="basis-1/3 flex flex-col items-center mt-1">
                      <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
                        Number of items{" "}
                      </p>
                      <input
                        className="bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1"
                        value={x.numberOfadditionalItem}
                        name="numberOfadditionalItem"
                        onChange={(e) => handleAdditionalItem(e, i)}
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={(e) => handleRemoveAdditionalItem(i)}
                      className="self-start outline-none bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-5 mt-2 text-sm xxl:text-lg"
                    >
                      Remove
                    </button>
                  </div>
                </>
              );
            })}
            <button
              onClick={addAdditionalItem}
              className="self-start outline-none bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-5 mt-2 text-sm xxl:text-lg"
            >
              Add
            </button>

            {/* <div className='flex w-full space-x-1'>
                        <div className='basis-2/3 flex flex-col items-center'>
                            <p className='px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base self-start'>Select an item from your list</p>
                            <select     onChange={(e)=>setData({...data,additionalItem:e.target.value})}  value={data.additionalItem} name="elevationNamee" id="elevationName" className='bg-gray-200 opacity-50 p-[2px] xxl:p-2 w-full outline-none pl-1 mt-1'>
                            <option value=""></option>
                            {statePriceList.addItem.map((a) => (
                                <option value={a.cost}>{a.name}</option>
                            ))}
                        </select>
                        </div>
                        <div className='basis-1/3 flex flex-col items-center mt-1'>
                            <p className='px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base'>Number of items </p>
                            <input className='bg-gray-200  opacity-50 px-[2px] xxl:p-2 w-full outline-none pl-1'  onChange={(e)=>setData({...data,numberOfAdditionalItem:escapeHtml(e.target.value)})}  value={data.numberOfAdditionalItem} type="text" required/>
                        </div>
                        </div> */}

            <hr className="my-2 bg-black h-[2px] w-full opacity-25" />

            <p className="px-1 pt-3 pl-0 font-semibold text-[12px] xxl:text-base">
              Total cost
            </p>
            <input
              className="bg-gray-200  opacity-50 px-[2px] text-black font-bold xxl:p-2 w-11/12 outline-none pl-4 xxl:pl-6 z-20"
              step=".01"
              disabled
              value={result}
              type="text"
              required
            />
            <i className="z-10 relative pl-1 xxl:pl-2 text-black -mt-6 xxl:-mt-8 xxl:pb-2">
              £
            </i>

            {/* <div className='flex flex-row w-full space-x-1 pr-8 items-center justify-start pt-4'>
                        <p className='pr-1 xxl:mr-20 font-semibold text-[12px] xxl:text-base whitespace-nowrap w-[220px]'>Adaptions are included in the quotation</p>
                        <Switch className='text-lightgreen -my-2' onChange={(e)=>setData({...data,adaptionIncludedInTheQuotation:!data.adaptionIncludedInTheQuotation})} checked={data.adaptionIncludedInTheQuotation} color='success' />
                    </div> */}

            <div className="flex flex-row space-x-1 pt-2">
              <button
                onClick={(e) => handleCalculate()}
                className="self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg"
              >
                Calculate
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="self-start bg-lightgreen py-[2px] rounded-md text-white hover:bg-darkgreen px-4 mt-2 text-sm xxl:text-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <div className="basis-1/2 w-full bg-white z-20">
          {stateSingleJob.Images && (
            <ImageSlider
              images={stateSingleJob.Images}
              stateSingleJob={stateSingleJob}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateQuotation;
