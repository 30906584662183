import React, { useEffect, useState } from "react";

import Sidebar from "../components/Sidebar";

import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  addVechicleInspection,
  adminActions,
  fetchVechicle,
  signOut,
} from "../store/adminSlice";
import HeaderScaff from "../components/HeaderScaff";
import escapeHtml from "../functions/Validator";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

import im1 from "../pictures/Van/slice1.png";
import im2 from "../pictures/Van/slice2.png";
import im3 from "../pictures/Van/slice3.png";
import im4 from "../pictures/Van/slice4.png";
import im5 from "../pictures/Van/slice5.png";
import im6 from "../pictures/Van/slice6.png";
import im7 from "../pictures/Van/slice7.png";
import im8 from "../pictures/Van/slice8.png";
import im9 from "../pictures/Van/slice9.png";
import im10 from "../pictures/Van/slice10.png";
import im11 from "../pictures/Van/slice11.png";
import im12 from "../pictures/Van/slice12.png";
import im13 from "../pictures/Van/slice13.png";
import im14 from "../pictures/Van/slice14.png";
import im15 from "../pictures/Van/slice15.png";

import im16 from "../pictures/Van/slice16.png";
import im17 from "../pictures/Van/slice17.png";
import im18 from "../pictures/Van/slice18.png";
import im19 from "../pictures/Van/slice19.png";
import im20 from "../pictures/Van/slice20.png";
import im21 from "../pictures/Van/slice21.png";
import im22 from "../pictures/Van/slice22.png";
import im23 from "../pictures/Van/slice23.png";
import im24 from "../pictures/Van/slice24.png";
import im25 from "../pictures/Van/slice25.png";
import im26 from "../pictures/Van/slice26.png";
import im27 from "../pictures/Van/slice27.png";
import im28 from "../pictures/Van/slice28.png";
import im29 from "../pictures/Van/slice29.png";
import im30 from "../pictures/Van/slice30.png";

import im31 from "../pictures/Van/slice31.png";
import im32 from "../pictures/Van/slice32.png";
import im33 from "../pictures/Van/slice33.png";
import im34 from "../pictures/Van/slice34.png";
import im35 from "../pictures/Van/slice35.png";
import im36 from "../pictures/Van/slice36.png";
import im37 from "../pictures/Van/slice37.png";
import im38 from "../pictures/Van/slice38.png";
import im39 from "../pictures/Van/slice39.png";
import im40 from "../pictures/Van/slice40.png";
import im41 from "../pictures/Van/slice41.png";
import im42 from "../pictures/Van/slice42.png";
import im43 from "../pictures/Van/slice43.png";
import im44 from "../pictures/Van/slice44.png";
import im45 from "../pictures/Van/slice45.png";

import im46 from "../pictures/Van/slice46.png";
import im47 from "../pictures/Van/slice47.png";
import im48 from "../pictures/Van/slice48.png";
import im49 from "../pictures/Van/slice49.png";
import im50 from "../pictures/Van/slice50.png";
import im51 from "../pictures/Van/slice51.png";
import im52 from "../pictures/Van/slice52.png";
import im53 from "../pictures/Van/slice53.png";
import im54 from "../pictures/Van/slice54.png";
import im55 from "../pictures/Van/slice55.png";
import im56 from "../pictures/Van/slice56.png";
import im57 from "../pictures/Van/slice57.png";
import im58 from "../pictures/Van/slice58.png";
import im59 from "../pictures/Van/slice59.png";
import im60 from "../pictures/Van/slice60.png";

import im61 from "../pictures/Van/slice61.png";
import im62 from "../pictures/Van/slice62.png";
import im63 from "../pictures/Van/slice63.png";
import im64 from "../pictures/Van/slice64.png";
import im65 from "../pictures/Van/slice65.png";
import im66 from "../pictures/Van/slice66.png";
import im67 from "../pictures/Van/slice67.png";
import im68 from "../pictures/Van/slice68.png";
import im69 from "../pictures/Van/slice69.png";
import im70 from "../pictures/Van/slice70.png";
import im71 from "../pictures/Van/slice71.png";
import im72 from "../pictures/Van/slice72.png";
import im73 from "../pictures/Van/slice73.png";
import im74 from "../pictures/Van/slice74.png";
import im75 from "../pictures/Van/slice75.png";

import im76 from "../pictures/Van/slice76.png";
import im77 from "../pictures/Van/slice77.png";
import im78 from "../pictures/Van/slice78.png";
import im79 from "../pictures/Van/slice79.png";
import im80 from "../pictures/Van/slice80.png";
import im81 from "../pictures/Van/slice81.png";
import im82 from "../pictures/Van/slice82.png";
import im83 from "../pictures/Van/slice83.png";
import im84 from "../pictures/Van/slice84.png";
import im85 from "../pictures/Van/slice85.png";
import im86 from "../pictures/Van/slice86.png";
import im87 from "../pictures/Van/slice87.png";
import im88 from "../pictures/Van/slice88.png";
import im89 from "../pictures/Van/slice89.png";
import im90 from "../pictures/Van/slice90.png";

import im91 from "../pictures/Van/slice91.png";
import im92 from "../pictures/Van/slice92.png";
import im93 from "../pictures/Van/slice93.png";
import im94 from "../pictures/Van/slice94.png";
import im95 from "../pictures/Van/slice95.png";
import im96 from "../pictures/Van/slice96.png";
import im97 from "../pictures/Van/slice97.png";
import im98 from "../pictures/Van/slice98.png";
import im99 from "../pictures/Van/slice99.png";
import im100 from "../pictures/Van/slice100.png";
import im101 from "../pictures/Van/slice101.png";
import im102 from "../pictures/Van/slice102.png";
import im103 from "../pictures/Van/slice103.png";
import im104 from "../pictures/Van/slice104.png";
import im105 from "../pictures/Van/slice105.png";

import im106 from "../pictures/Van/slice106.png";
import im107 from "../pictures/Van/slice107.png";
import im108 from "../pictures/Van/slice108.png";
import im109 from "../pictures/Van/slice109.png";
import im110 from "../pictures/Van/slice110.png";
import im111 from "../pictures/Van/slice111.png";
import im112 from "../pictures/Van/slice112.png";
import im113 from "../pictures/Van/slice113.png";
import im114 from "../pictures/Van/slice114.png";
import im115 from "../pictures/Van/slice115.png";
import im116 from "../pictures/Van/slice116.png";
import im117 from "../pictures/Van/slice117.png";
import im118 from "../pictures/Van/slice118.png";
import im119 from "../pictures/Van/slice119.png";
import im120 from "../pictures/Van/slice120.png";

import im121 from "../pictures/Van/slice121.png";
import im122 from "../pictures/Van/slice122.png";
import im123 from "../pictures/Van/slice123.png";
import im124 from "../pictures/Van/slice124.png";
import im125 from "../pictures/Van/slice125.png";
import im126 from "../pictures/Van/slice126.png";
import im127 from "../pictures/Van/slice127.png";
import im128 from "../pictures/Van/slice128.png";
import im129 from "../pictures/Van/slice129.png";
import im130 from "../pictures/Van/slice130.png";
import im131 from "../pictures/Van/slice131.png";
import im132 from "../pictures/Van/slice132.png";
import im133 from "../pictures/Van/slice133.png";
import im134 from "../pictures/Van/slice134.png";
import im135 from "../pictures/Van/slice135.png";

import im136 from "../pictures/Van/slice136.png";
import im137 from "../pictures/Van/slice137.png";
import im138 from "../pictures/Van/slice138.png";
import im139 from "../pictures/Van/slice139.png";
import im140 from "../pictures/Van/slice140.png";
import im141 from "../pictures/Van/slice141.png";
import im142 from "../pictures/Van/slice142.png";
import im143 from "../pictures/Van/slice143.png";
import im144 from "../pictures/Van/slice144.png";
import im145 from "../pictures/Van/slice145.png";
import im146 from "../pictures/Van/slice146.png";
import im147 from "../pictures/Van/slice147.png";
import im148 from "../pictures/Van/slice148.png";
import im149 from "../pictures/Van/slice149.png";
import im150 from "../pictures/Van/slice150.png";

import im151 from "../pictures/Van/slice151.png";
import im152 from "../pictures/Van/slice152.png";
import im153 from "../pictures/Van/slice153.png";
import im154 from "../pictures/Van/slice154.png";
import im155 from "../pictures/Van/slice155.png";
import im156 from "../pictures/Van/slice156.png";
import im157 from "../pictures/Van/slice157.png";
import im158 from "../pictures/Van/slice158.png";
import im159 from "../pictures/Van/slice159.png";
import im160 from "../pictures/Van/slice160.png";
import im161 from "../pictures/Van/slice161.png";
import im162 from "../pictures/Van/slice162.png";
import im163 from "../pictures/Van/slice163.png";
import im164 from "../pictures/Van/slice164.png";
import im165 from "../pictures/Van/slice165.png";

import im166 from "../pictures/Van/slice166.png";
import im167 from "../pictures/Van/slice167.png";
import im168 from "../pictures/Van/slice168.png";
import im169 from "../pictures/Van/slice169.png";
import im170 from "../pictures/Van/slice170.png";
import im171 from "../pictures/Van/slice171.png";
import im172 from "../pictures/Van/slice172.png";
import im173 from "../pictures/Van/slice173.png";
import im174 from "../pictures/Van/slice174.png";
import im175 from "../pictures/Van/slice175.png";
import im176 from "../pictures/Van/slice176.png";
import im177 from "../pictures/Van/slice177.png";
import im178 from "../pictures/Van/slice178.png";
import im179 from "../pictures/Van/slice179.png";
import im180 from "../pictures/Van/slice180.png";

import im181 from "../pictures/Van/slice181.png";
import im182 from "../pictures/Van/slice182.png";
import im183 from "../pictures/Van/slice183.png";
import im184 from "../pictures/Van/slice184.png";
import im185 from "../pictures/Van/slice185.png";
import im186 from "../pictures/Van/slice186.png";
import im187 from "../pictures/Van/slice187.png";
import im188 from "../pictures/Van/slice188.png";
import im189 from "../pictures/Van/slice189.png";
import im190 from "../pictures/Van/slice190.png";
import im191 from "../pictures/Van/slice191.png";
import im192 from "../pictures/Van/slice192.png";
import im193 from "../pictures/Van/slice193.png";
import im194 from "../pictures/Van/slice194.png";
import im195 from "../pictures/Van/slice195.png";

import im196 from "../pictures/Van/slice196.png";
import im197 from "../pictures/Van/slice197.png";
import im198 from "../pictures/Van/slice198.png";
import im199 from "../pictures/Van/slice199.png";
import im200 from "../pictures/Van/slice200.png";
import im201 from "../pictures/Van/slice201.png";
import im202 from "../pictures/Van/slice202.png";
import im203 from "../pictures/Van/slice203.png";
import im204 from "../pictures/Van/slice204.png";
import im205 from "../pictures/Van/slice205.png";
import im206 from "../pictures/Van/slice206.png";
import im207 from "../pictures/Van/slice207.png";
import im208 from "../pictures/Van/slice208.png";
import im209 from "../pictures/Van/slice209.png";
import im210 from "../pictures/Van/slice210.png";

import im211 from "../pictures/Van/slice211.png";
import im212 from "../pictures/Van/slice212.png";
import im213 from "../pictures/Van/slice213.png";
import im214 from "../pictures/Van/slice214.png";
import im215 from "../pictures/Van/slice215.png";
import im216 from "../pictures/Van/slice216.png";
import im217 from "../pictures/Van/slice217.png";
import im218 from "../pictures/Van/slice218.png";
import im219 from "../pictures/Van/slice219.png";
import im220 from "../pictures/Van/slice220.png";
import im221 from "../pictures/Van/slice221.png";
import im222 from "../pictures/Van/slice222.png";
import im223 from "../pictures/Van/slice223.png";
import im224 from "../pictures/Van/slice224.png";
import im225 from "../pictures/Van/slice225.png";

import im226 from "../pictures/Van/slice226.png";
import im227 from "../pictures/Van/slice227.png";
import im228 from "../pictures/Van/slice228.png";
import im229 from "../pictures/Van/slice229.png";
import im230 from "../pictures/Van/slice230.png";
import im231 from "../pictures/Van/slice231.png";
import im232 from "../pictures/Van/slice232.png";
import im233 from "../pictures/Van/slice233.png";
import im234 from "../pictures/Van/slice234.png";
import im235 from "../pictures/Van/slice235.png";
import im236 from "../pictures/Van/slice236.png";
import im237 from "../pictures/Van/slice237.png";
import im238 from "../pictures/Van/slice238.png";
import im239 from "../pictures/Van/slice239.png";
import im240 from "../pictures/Van/slice240.png";

import im241 from "../pictures/Van/slice241.png";
import im242 from "../pictures/Van/slice242.png";
import im243 from "../pictures/Van/slice243.png";
import im244 from "../pictures/Van/slice244.png";
import im245 from "../pictures/Van/slice245.png";
import im246 from "../pictures/Van/slice246.png";
import im247 from "../pictures/Van/slice247.png";
import im248 from "../pictures/Van/slice248.png";
import im249 from "../pictures/Van/slice249.png";
import im250 from "../pictures/Van/slice250.png";
import im251 from "../pictures/Van/slice251.png";
import im252 from "../pictures/Van/slice252.png";
import im253 from "../pictures/Van/slice253.png";
import im254 from "../pictures/Van/slice254.png";
import im255 from "../pictures/Van/slice255.png";

import im256 from "../pictures/Van/slice256.png";
import im257 from "../pictures/Van/slice257.png";
import im258 from "../pictures/Van/slice258.png";
import im259 from "../pictures/Van/slice259.png";
import im260 from "../pictures/Van/slice260.png";
import im261 from "../pictures/Van/slice261.png";
import im262 from "../pictures/Van/slice262.png";
import im263 from "../pictures/Van/slice263.png";
import im264 from "../pictures/Van/slice264.png";
import im265 from "../pictures/Van/slice265.png";
import im266 from "../pictures/Van/slice266.png";
import im267 from "../pictures/Van/slice267.png";
import im268 from "../pictures/Van/slice268.png";
import im269 from "../pictures/Van/slice269.png";
import im270 from "../pictures/Van/slice270.png";

import im271 from "../pictures/Van/slice271.png";
import im272 from "../pictures/Van/slice272.png";
import im273 from "../pictures/Van/slice273.png";
import im274 from "../pictures/Van/slice274.png";
import im275 from "../pictures/Van/slice275.png";
import im276 from "../pictures/Van/slice276.png";
import im277 from "../pictures/Van/slice277.png";
import im278 from "../pictures/Van/slice278.png";
import im279 from "../pictures/Van/slice279.png";
import im280 from "../pictures/Van/slice280.png";
import im281 from "../pictures/Van/slice281.png";
import im282 from "../pictures/Van/slice282.png";
import im283 from "../pictures/Van/slice283.png";
import im284 from "../pictures/Van/slice284.png";
import im285 from "../pictures/Van/slice285.png";

import img1 from "../pictures/Lorry/slice1.png";
import img2 from "../pictures/Lorry/slice2.png";
import img3 from "../pictures/Lorry/slice3.png";
import img4 from "../pictures/Lorry/slice4.png";
import img5 from "../pictures/Lorry/slice5.png";
import img6 from "../pictures/Lorry/slice6.png";
import img7 from "../pictures/Lorry/slice7.png";
import img8 from "../pictures/Lorry/slice8.png";
import img9 from "../pictures/Lorry/slice9.png";
import img10 from "../pictures/Lorry/slice10.png";
import img11 from "../pictures/Lorry/slice11.png";
import img12 from "../pictures/Lorry/slice12.png";
import img13 from "../pictures/Lorry/slice13.png";
import img14 from "../pictures/Lorry/slice14.png";
import img15 from "../pictures/Lorry/slice15.png";

import img16 from "../pictures/Lorry/slice16.png";
import img17 from "../pictures/Lorry/slice17.png";
import img18 from "../pictures/Lorry/slice18.png";
import img19 from "../pictures/Lorry/slice19.png";
import img20 from "../pictures/Lorry/slice20.png";
import img21 from "../pictures/Lorry/slice21.png";
import img22 from "../pictures/Lorry/slice22.png";
import img23 from "../pictures/Lorry/slice23.png";
import img24 from "../pictures/Lorry/slice24.png";
import img25 from "../pictures/Lorry/slice25.png";
import img26 from "../pictures/Lorry/slice26.png";
import img27 from "../pictures/Lorry/slice27.png";
import img28 from "../pictures/Lorry/slice28.png";
import img29 from "../pictures/Lorry/slice29.png";
import img30 from "../pictures/Lorry/slice30.png";

import img31 from "../pictures/Lorry/slice31.png";
import img32 from "../pictures/Lorry/slice32.png";
import img33 from "../pictures/Lorry/slice33.png";
import img34 from "../pictures/Lorry/slice34.png";
import img35 from "../pictures/Lorry/slice35.png";
import img36 from "../pictures/Lorry/slice36.png";
import img37 from "../pictures/Lorry/slice37.png";
import img38 from "../pictures/Lorry/slice38.png";
import img39 from "../pictures/Lorry/slice39.png";
import img40 from "../pictures/Lorry/slice40.png";
import img41 from "../pictures/Lorry/slice41.png";
import img42 from "../pictures/Lorry/slice42.png";
import img43 from "../pictures/Lorry/slice43.png";
import img44 from "../pictures/Lorry/slice44.png";
import img45 from "../pictures/Lorry/slice45.png";

import img46 from "../pictures/Lorry/slice46.png";
import img47 from "../pictures/Lorry/slice47.png";
import img48 from "../pictures/Lorry/slice48.png";
import img49 from "../pictures/Lorry/slice49.png";
import img50 from "../pictures/Lorry/slice50.png";
import img51 from "../pictures/Lorry/slice51.png";
import img52 from "../pictures/Lorry/slice52.png";
import img53 from "../pictures/Lorry/slice53.png";
import img54 from "../pictures/Lorry/slice54.png";
import img55 from "../pictures/Lorry/slice55.png";
import img56 from "../pictures/Lorry/slice56.png";
import img57 from "../pictures/Lorry/slice57.png";
import img58 from "../pictures/Lorry/slice58.png";
import img59 from "../pictures/Lorry/slice59.png";
import img60 from "../pictures/Lorry/slice60.png";

import img61 from "../pictures/Lorry/slice61.png";
import img62 from "../pictures/Lorry/slice62.png";
import img63 from "../pictures/Lorry/slice63.png";
import img64 from "../pictures/Lorry/slice64.png";
import img65 from "../pictures/Lorry/slice65.png";
import img66 from "../pictures/Lorry/slice66.png";
import img67 from "../pictures/Lorry/slice67.png";
import img68 from "../pictures/Lorry/slice68.png";
import img69 from "../pictures/Lorry/slice69.png";
import img70 from "../pictures/Lorry/slice70.png";
import img71 from "../pictures/Lorry/slice71.png";
import img72 from "../pictures/Lorry/slice72.png";
import img73 from "../pictures/Lorry/slice73.png";
import img74 from "../pictures/Lorry/slice74.png";
import img75 from "../pictures/Lorry/slice75.png";

import img76 from "../pictures/Lorry/slice76.png";
import img77 from "../pictures/Lorry/slice77.png";
import img78 from "../pictures/Lorry/slice78.png";
import img79 from "../pictures/Lorry/slice79.png";
import img80 from "../pictures/Lorry/slice80.png";
import img81 from "../pictures/Lorry/slice81.png";
import img82 from "../pictures/Lorry/slice82.png";
import img83 from "../pictures/Lorry/slice83.png";
import img84 from "../pictures/Lorry/slice84.png";
import img85 from "../pictures/Lorry/slice85.png";
import img86 from "../pictures/Lorry/slice86.png";
import img87 from "../pictures/Lorry/slice87.png";
import img88 from "../pictures/Lorry/slice88.png";
import img89 from "../pictures/Lorry/slice89.png";
import img90 from "../pictures/Lorry/slice90.png";

import img91 from "../pictures/Lorry/slice91.png";
import img92 from "../pictures/Lorry/slice92.png";
import img93 from "../pictures/Lorry/slice93.png";
import img94 from "../pictures/Lorry/slice94.png";
import img95 from "../pictures/Lorry/slice95.png";
import img96 from "../pictures/Lorry/slice96.png";
import img97 from "../pictures/Lorry/slice97.png";
import img98 from "../pictures/Lorry/slice98.png";
import img99 from "../pictures/Lorry/slice99.png";
import img100 from "../pictures/Lorry/slice100.png";
import img101 from "../pictures/Lorry/slice101.png";
import img102 from "../pictures/Lorry/slice102.png";
import img103 from "../pictures/Lorry/slice103.png";
import img104 from "../pictures/Lorry/slice104.png";
import img105 from "../pictures/Lorry/slice105.png";

import img106 from "../pictures/Lorry/slice106.png";
import img107 from "../pictures/Lorry/slice107.png";
import img108 from "../pictures/Lorry/slice108.png";
import img109 from "../pictures/Lorry/slice109.png";
import img110 from "../pictures/Lorry/slice110.png";
import img111 from "../pictures/Lorry/slice111.png";
import img112 from "../pictures/Lorry/slice112.png";
import img113 from "../pictures/Lorry/slice113.png";
import img114 from "../pictures/Lorry/slice114.png";
import img115 from "../pictures/Lorry/slice115.png";
import img116 from "../pictures/Lorry/slice116.png";
import img117 from "../pictures/Lorry/slice117.png";
import img118 from "../pictures/Lorry/slice118.png";
import img119 from "../pictures/Lorry/slice119.png";
import img120 from "../pictures/Lorry/slice120.png";

import img121 from "../pictures/Lorry/slice121.png";
import img122 from "../pictures/Lorry/slice122.png";
import img123 from "../pictures/Lorry/slice123.png";
import img124 from "../pictures/Lorry/slice124.png";
import img125 from "../pictures/Lorry/slice125.png";
import img126 from "../pictures/Lorry/slice126.png";
import img127 from "../pictures/Lorry/slice127.png";
import img128 from "../pictures/Lorry/slice128.png";
import img129 from "../pictures/Lorry/slice129.png";
import img130 from "../pictures/Lorry/slice130.png";
import img131 from "../pictures/Lorry/slice131.png";
import img132 from "../pictures/Lorry/slice132.png";
import img133 from "../pictures/Lorry/slice133.png";
import img134 from "../pictures/Lorry/slice134.png";
import img135 from "../pictures/Lorry/slice135.png";

function VechicleCheck() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const [needed, setNeeded] = useState(false);
  const _id = useSelector((state) => state.admin._id);
  const newVechicle = useSelector((state) => state.admin.newVechicle);
  const swalAlert = useSelector((state) => state.admin.swalAlert);
  const [data, setData] = useState({
    registration: "",
    nameOfChecker: "",
    dateOfCheck: "",
    defectReport: "",
    defectRectified: "",
    signedOff: "",
    date: "",
    adminID: _id,
    typeOfVehicle:"1"
  });
  const [van, setVan] = useState({
    im1: false,
    im2: false,
    im3: false,
    im4: false,
    im5: false,
    im6: false,
    im7: false,
    im8: false,
    im9: false,
    im10: false,
    im11: false,
    im12: false,
    im13: false,
    im14: false,
    im15: false,
    im16: false,
    im17: false,
    im18: false,
    im19: false,
    im20: false,
    im21: false,
    im22: false,
    im23: false,
    im24: false,
    im25: false,
    im26: false,
    im27: false,
    im28: false,
    im29: false,
    im30: false,
    im31: false,
    im32: false,
    im33: false,
    im34: false,
    im35: false,
    im36: false,
    im37: false,
    im38: false,
    im39: false,
    im40: false,
    im41: false,
    im42: false,
    im43: false,
    im44: false,
    im45: false,
    im46: false,
    im47: false,
    im48: false,
    im49: false,
    im50: false,
    im51: false,
    im52: false,
    im53: false,
    im54: false,
    im55: false,
    im56: false,
    im57: false,
    im58: false,
    im59: false,
    im60: false,
    im61: false,
    im62: false,
    im63: false,
    im64: false,
    im65: false,
    im66: false,
    im67: false,
    im68: false,
    im69: false,
    im70: false,
    im71: false,
    im72: false,
    im73: false,
    im74: false,
    im75: false,
    im76: false,
    im77: false,
    im78: false,
    im79: false,
    im80: false,
    im81: false,
    im82: false,
    im83: false,
    im84: false,
    im85: false,
    im86: false,
    im87: false,
    im88: false,
    im89: false,
    im90: false,
    im91: false,
    im92: false,
    im93: false,
    im94: false,
    im95: false,
    im96: false,
    im97: false,
    im98: false,
    im99: false,
    im100: false,
    im101: false,
    im102: false,
    im103: false,
    im104: false,
    im105: false,
    im106: false,
    im107: false,
    im108: false,
    im109: false,
    im110: false,
    im111: false,
    im112: false,
    im113: false,
    im114: false,
    im115: false,
    im116: false,
    im117: false,
    im118: false,
    im119: false,
    im120: false,
    im121: false,
    im122: false,
    im123: false,
    im124: false,
    im125: false,
    im126: false,
    im127: false,
    im128: false,
    im129: false,
    im130: false,
    im131: false,
    im132: false,
    im133: false,
    im134: false,
    im135: false,
    im136: false,
    im137: false,
    im138: false,
    im139: false,
    im140: false,
    im141: false,
    im142: false,
    im143: false,
    im144: false,
    im145: false,
    im146: false,
    im147: false,
    im148: false,
    im149: false,
    im150: false,
    im151: false,
    im152: false,
    im153: false,
    im154: false,
    im155: false,
    im156: false,
    im157: false,
    im158: false,
    im159: false,
    im160: false,
    im161: false,
    im162: false,
    im163: false,
    im164: false,
    im165: false,
    im166: false,
    im167: false,
    im168: false,
    im169: false,
    im170: false,
    im171: false,
    im172: false,
    im173: false,
    im174: false,
    im175: false,
    im176: false,
    im177: false,
    im178: false,
    im179: false,
    im180: false,
    im181: false,
    im182: false,
    im183: false,
    im184: false,
    im185: false,
    im186: false,
    im187: false,
    im188: false,
    im189: false,
    im190: false,
    im191: false,
    im192: false,
    im193: false,
    im194: false,
    im195: false,
    im196: false,
    im197: false,
    im198: false,
    im199: false,
    im200: false,
    im201: false,
    im202: false,
    im203: false,
    im204: false,
    im205: false,
    im206: false,
    im207: false,
    im208: false,
    im209: false,
    im210: false,
    im211: false,
    im212: false,
    im213: false,
    im214: false,
    im215: false,
    im216: false,
    im217: false,
    im218: false,
    im219: false,
    im220: false,
    im221: false,
    im222: false,
    im223: false,
    im224: false,
    im225: false,
    im226: false,
    im227: false,
    im228: false,
    im229: false,
    im230: false,
    im231: false,
    im232: false,
    im233: false,
    im234: false,
    im235: false,
    im236: false,
    im237: false,
    im238: false,
    im239: false,
    im240: false,
    im241: false,
    im242: false,
    im243: false,
    im244: false,
    im245: false,
    im246: false,
    im247: false,
    im248: false,
    im249: false,
    im250: false,
    im251: false,
    im252: false,
    im253: false,
    im254: false,
    im255: false,
    im256: false,
    im257: false,
    im258: false,
    im259: false,
    im260: false,
    im261: false,
    im262: false,
    im263: false,
    im264: false,
    im265: false,
    im266: false,
    im267: false,
    im268: false,
    im269: false,
    im270: false,
    im271: false,
    im272: false,
    im273: false,
    im274: false,
    im275: false,
    im276: false,
    im277: false,
    im278: false,
    im279: false,
    im280: false,
    im281: false,
    im282: false,
    im283: false,
    im284: false,
    im285: false,
  });
  const [lorry, setLorry] = useState({
    img1: false,
    img2: false,
    img3: false,
    img4: false,
    img5: false,
    img6: false,
    img7: false,
    img8: false,
    img9: false,
    img10: false,
    img11: false,
    img12: false,
    img13: false,
    img14: false,
    img15: false,
    img16: false,
    img17: false,
    img18: false,
    img19: false,
    img20: false,
    img21: false,
    img22: false,
    img23: false,
    img24: false,
    img25: false,
    img26: false,
    img27: false,
    img28: false,
    img29: false,
    img30: false,
    img31: false,
    img32: false,
    img33: false,
    img34: false,
    img35: false,
    img36: false,
    img37: false,
    img38: false,
    img39: false,
    img40: false,
    img41: false,
    img42: false,
    img43: false,
    img44: false,
    img45: false,
    img46: false,
    img47: false,
    img48: false,
    img49: false,
    img50: false,
    img51: false,
    img52: false,
    img53: false,
    img54: false,
    img55: false,
    img56: false,
    img57: false,
    img58: false,
    img59: false,
    img60: false,
    img61: false,
    img62: false,
    img63: false,
    img64: false,
    img65: false,
    img66: false,
    img67: false,
    img68: false,
    img69: false,
    img70: false,
    img71: false,
    img72: false,
    img73: false,
    img74: false,
    img75: false,
    img76: false,
    img77: false,
    img78: false,
    img79: false,
    img80: false,
    img81: false,
    img82: false,
    img83: false,
    img84: false,
    img85: false,
    img86: false,
    img87: false,
    img88: false,
    img89: false,
    img90: false,
    img91: false,
    img92: false,
    img93: false,
    img94: false,
    img95: false,
    img96: false,
    img97: false,
    img98: false,
    img99: false,
    img100: false,
    img101: false,
    img102: false,
    img103: false,
    img104: false,
    img105: false,
    img106: false,
    img107: false,
    img108: false,
    img109: false,
    img110: false,
    img111: false,
    img112: false,
    img113: false,
    img114: false,
    img115: false,
    img116: false,
    img117: false,
    img118: false,
    img119: false,
    img120: false,
    img121: false,
    img122: false,
    img123: false,
    img124: false,
    img125: false,
    img126: false,
    img127: false,
    img128: false,
    img129: false,
    img130: false,
    img131: false,
    img132: false,
    img133: false,
    img134: false,
    img135: false,
  });
  const [vechicleCheck, setVechicleCheck] = useState({
    engineOil: 0,
    indicator: 0,
    doorWingsMirror: 0,
    seatBelts: 0,
    brakes: 0,
    sideLights: 0,
    wiperBlades: 0,
    firstAidKits: 0,
    clutches: 0,
    headLightsDimmed: 0,
    screenWasher: 0,
    fireExtinguisher: 0,
    powerSteering: 0,
    headLightsMain: 0,
    tyrePressure: 0,
    headRestraintAdjustment: 0,
    autoTransmission: 0,
    numberPlate: 0,
    tyreWear: 0,
    torch: 0,
    screenWash: 0,
    reversingLights: 0,
    tyreDamage: 0,
    warningTriangle: 0,
    fuel1by4Full: 0,
    warningLights: 0,
    spareWheel: 0,
    generalBodyWork: 0,
    coolent: 0,
    horn: 0,
    cleanNumberPlateAndWindowLights: 0,
    roadFuelLicense: 0,
    adBlue: 0,
    wideScreenWiper: 0,
    secureRoofRack: 0,
    copyOfMotor: 0,
    battery: 0,
    doorLocking: 0,
  });
  const handleStatus = (a, b) => {
    if (b === "engineOil") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, engineOil: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, engineOil: a + 1 });
      }
    } else if (b === "doorLocking") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, doorLocking: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, doorLocking: a + 1 });
      }
    } else if (b === "battery") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, battery: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, battery: a + 1 });
      }
    } else if (b === "copyOfMotor") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, copyOfMotor: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, copyOfMotor: a + 1 });
      }
    } else if (b === "secureRoofRack") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, secureRoofRack: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, secureRoofRack: a + 1 });
      }
    } else if (b === "wideScreenWiper") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, wideScreenWiper: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, wideScreenWiper: a + 1 });
      }
    } else if (b === "adBlue") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, adBlue: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, adBlue: a + 1 });
      }
    } else if (b === "roadFuelLicense") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, roadFuelLicense: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, roadFuelLicense: a + 1 });
      }
    } else if (b === "cleanNumberPlateAndWindowLights") {
      if (a === 2) {
        setVechicleCheck({
          ...vechicleCheck,
          cleanNumberPlateAndWindowLights: 0,
        });
      } else {
        setVechicleCheck({
          ...vechicleCheck,
          cleanNumberPlateAndWindowLights: a + 1,
        });
      }
    } else if (b === "horn") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, horn: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, horn: a + 1 });
      }
    } else if (b === "coolent") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, coolent: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, coolent: a + 1 });
      }
    } else if (b === "generalBodyWork") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, generalBodyWork: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, generalBodyWork: a + 1 });
      }
    } else if (b === "spareWheel") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, spareWheel: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, spareWheel: a + 1 });
      }
    } else if (b === "warningLights") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, warningLights: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, warningLights: a + 1 });
      }
    } else if (b === "fuel1by4Full") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, fuel1by4Full: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, fuel1by4Full: a + 1 });
      }
    } else if (b === "warningTriangle") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, warningTriangle: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, warningTriangle: a + 1 });
      }
    } else if (b === "tyreDamage") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, tyreDamage: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, tyreDamage: a + 1 });
      }
    } else if (b === "reversingLights") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, reversingLights: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, reversingLights: a + 1 });
      }
    } else if (b === "screenWash") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, screenWash: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, screenWash: a + 1 });
      }
    } else if (b === "torch") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, torch: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, torch: a + 1 });
      }
    } else if (b === "tyreWear") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, tyreWear: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, tyreWear: a + 1 });
      }
    } else if (b === "numberPlate") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, numberPlate: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, numberPlate: a + 1 });
      }
    } else if (b === "autoTransmission") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, autoTransmission: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, autoTransmission: a + 1 });
      }
    } else if (b === "headRestraintAdjustment") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, headRestraintAdjustment: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, headRestraintAdjustment: a + 1 });
      }
    } else if (b === "tyrePressure") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, tyrePressure: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, tyrePressure: a + 1 });
      }
    } else if (b === "headLightsMain") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, headLightsMain: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, headLightsMain: a + 1 });
      }
    } else if (b === "powerSteering") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, powerSteering: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, powerSteering: a + 1 });
      }
    } else if (b === "fireExtinguisher") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, fireExtinguisher: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, fireExtinguisher: a + 1 });
      }
    } else if (b === "screenWasher") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, screenWasher: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, screenWasher: a + 1 });
      }
    } else if (b === "headLightsDimmed") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, headLightsDimmed: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, headLightsDimmed: a + 1 });
      }
    } else if (b === "clutches") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, clutches: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, clutches: a + 1 });
      }
    } else if (b === "firstAidKits") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, firstAidKits: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, firstAidKits: a + 1 });
      }
    } else if (b === "sideLights") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, sideLights: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, sideLights: a + 1 });
      }
    } else if (b === "brakes") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, brakes: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, brakes: a + 1 });
      }
    } else if (b === "seatBelts") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, seatBelts: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, seatBelts: a + 1 });
      }
    } else if (b === "doorWingsMirror") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, doorWingsMirror: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, doorWingsMirror: a + 1 });
      }
    } else if (b === "indicator") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, indicator: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, indicator: a + 1 });
      }
    } else if (b === "wiperBlades") {
      if (a === 2) {
        setVechicleCheck({ ...vechicleCheck, wiperBlades: 0 });
      } else {
        setVechicleCheck({ ...vechicleCheck, wiperBlades: a + 1 });
      }
    }
  };

  const statesignOut = useSelector((state) => state.admin.signOut);
  const [PMILorry, setPMILorry] = useState();
  const navigate = useNavigate();
  const subscribed = useSelector((state) => state.admin.subscribed);
  useEffect(() => {
    if (statesignOut === "signOut") {
      dispatch(signOut());
    }
  }, [statesignOut]);
  useEffect(() => {
    if (!_id) {
      navigate("/signin");
    } else if (!subscribed) {
      navigate("/signin");
    }
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchVechicle({ adminID: _id }));
  }, []);
  const setDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, date: escapeHtml(newDate) });
  };
  const setDateOfCheck = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setData({ ...data, dateOfCheck: escapeHtml(newDate) });
  };
  const handleSubmit = (e) => {
    if (
      data.adminID === "" ||
      data.registration === "" ||
      data.nameOfChecker === "" ||
      data.dateOfCheck === "" ||
      data.defectReport === "" ||
      data.defectRectified === "" ||
      data.date === "" ||
      data.signedOff === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly fill all the inputs & Upload logo/documents.!",
      });
      return;
    } else {
      const formData = new FormData();
      formData.append("adminID", data.adminID);
      formData.append("registration", data.registration);
      formData.append("nameOfChecker", data.nameOfChecker);
      formData.append("dateOfCheck", data.dateOfCheck);
      formData.append("defectReport", data.defectReport);
      formData.append("defectRectified", data.defectRectified);
      formData.append("date", data.date);
      formData.append("signedOff", data.signedOff);
      formData.append("typeOfVehicle", data.typeOfVehicle);
      formData.append("vechicleCheck", JSON.stringify(vechicleCheck));
      data.typeOfVehicle === "0"?   formData.append("van", JSON.stringify(van)):formData.append("van", JSON.stringify(lorry))
   
      formData.append("PMILorry", PMILorry);
      dispatch(addVechicleInspection(formData));
    }
  };

  useEffect(() => {
    // console.log("check->", vechicleCheck);
  }, [vechicleCheck]);
  useEffect(() => {
    if (swalAlert === "success") {
      Swal.fire({
        icon: "success",
        title: "Saved",
        confirmButtonColor: "#A7B778",
      }).then(() => {
        dispatch(adminActions.removeCustomerSwalAlert());
      });
    }
  }, [swalAlert]);

  const handleVanStatus = (a) => {
    if (a === "im1") {
      console.log("hello");
      if (van.im1) {
        setVan({ ...van, im1: false });
      } else {
        setVan({ ...van, im1: true });
      }
    } else if (a === "im2") {
      if (van.im2) {
        setVan({ ...van, im2: false });
      } else {
        setVan({ ...van, im2: true });
      }
    } else if (a === "im3") {
      if (van.im3) {
        setVan({ ...van, im3: false });
      } else {
        setVan({ ...van, im3: true });
      }
    } else if (a === "im4") {
      if (van.im4) {
        setVan({ ...van, im4: false });
      } else {
        setVan({ ...van, im4: true });
      }
    } else if (a === "im5") {
      if (van.im5) {
        setVan({ ...van, im5: false });
      } else {
        setVan({ ...van, im5: true });
      }
    } else if (a === "im6") {
      if (van.im6) {
        setVan({ ...van, im6: false });
      } else {
        setVan({ ...van, im6: true });
      }
    } else if (a === "im7") {
      if (van.im7) {
        setVan({ ...van, im7: false });
      } else {
        setVan({ ...van, im7: true });
      }
    } else if (a === "im8") {
      if (van.im8) {
        setVan({ ...van, im8: false });
      } else {
        setVan({ ...van, im8: true });
      }
    } else if (a === "im9") {
      if (van.im9) {
        setVan({ ...van, im9: false });
      } else {
        setVan({ ...van, im9: true });
      }
    } else if (a === "im10") {
      if (van.im10) {
        setVan({ ...van, im10: false });
      } else {
        setVan({ ...van, im10: true });
      }
    } else if (a === "im11") {
      if (van.im11) {
        setVan({ ...van, im11: false });
      } else {
        setVan({ ...van, im11: true });
      }
    } else if (a === "im12") {
      if (van.im12) {
        setVan({ ...van, im12: false });
      } else {
        setVan({ ...van, im12: true });
      }
    } else if (a === "im13") {
      if (van.im13) {
        setVan({ ...van, im13: false });
      } else {
        setVan({ ...van, im13: true });
      }
    } else if (a === "im14") {
      if (van.im14) {
        setVan({ ...van, im14: false });
      } else {
        setVan({ ...van, im14: true });
      }
    } else if (a === "im15") {
      if (van.im15) {
        setVan({ ...van, im15: false });
      } else {
        setVan({ ...van, im15: true });
      }
    } else if (a === "im16") {
      if (van.im16) {
        setVan({ ...van, im16: false });
      } else {
        setVan({ ...van, im16: true });
      }
    } else if (a === "im17") {
      if (van.im17) {
        setVan({ ...van, im17: false });
      } else {
        setVan({ ...van, im17: true });
      }
    } else if (a === "im18") {
      if (van.im18) {
        setVan({ ...van, im18: false });
      } else {
        setVan({ ...van, im18: true });
      }
    } else if (a === "im19") {
      if (van.im19) {
        setVan({ ...van, im19: false });
      } else {
        setVan({ ...van, im19: true });
      }
    } else if (a === "im20") {
      if (van.im20) {
        setVan({ ...van, im20: false });
      } else {
        setVan({ ...van, im20: true });
      }
    } else if (a === "im21") {
      if (van.im21) {
        setVan({ ...van, im21: false });
      } else {
        setVan({ ...van, im21: true });
      }
    } else if (a === "im22") {
      if (van.im22) {
        setVan({ ...van, im22: false });
      } else {
        setVan({ ...van, im22: true });
      }
    } else if (a === "im23") {
      if (van.im23) {
        setVan({ ...van, im23: false });
      } else {
        setVan({ ...van, im23: true });
      }
    } else if (a === "im24") {
      if (van.im24) {
        setVan({ ...van, im24: false });
      } else {
        setVan({ ...van, im24: true });
      }
    } else if (a === "im25") {
      if (van.im25) {
        setVan({ ...van, im25: false });
      } else {
        setVan({ ...van, im25: true });
      }
    } else if (a === "im26") {
      if (van.im26) {
        setVan({ ...van, im26: false });
      } else {
        setVan({ ...van, im26: true });
      }
    } else if (a === "im27") {
      if (van.im27) {
        setVan({ ...van, im27: false });
      } else {
        setVan({ ...van, im27: true });
      }
    } else if (a === "im28") {
      if (van.im28) {
        setVan({ ...van, im28: false });
      } else {
        setVan({ ...van, im28: true });
      }
    } else if (a === "im29") {
      if (van.im29) {
        setVan({ ...van, im29: false });
      } else {
        setVan({ ...van, im29: true });
      }
    } else if (a === "im30") {
      if (van.im30) {
        setVan({ ...van, im30: false });
      } else {
        setVan({ ...van, im30: true });
      }
    } else if (a === "im31") {
      if (van.im31) {
        setVan({ ...van, im31: false });
      } else {
        setVan({ ...van, im31: true });
      }
    } else if (a === "im32") {
      if (van.im32) {
        setVan({ ...van, im32: false });
      } else {
        setVan({ ...van, im32: true });
      }
    } else if (a === "im33") {
      if (van.im33) {
        setVan({ ...van, im33: false });
      } else {
        setVan({ ...van, im33: true });
      }
    } else if (a === "im34") {
      if (van.im34) {
        setVan({ ...van, im34: false });
      } else {
        setVan({ ...van, im34: true });
      }
    } else if (a === "im35") {
      if (van.im35) {
        setVan({ ...van, im35: false });
      } else {
        setVan({ ...van, im35: true });
      }
    } else if (a === "im36") {
      if (van.im36) {
        setVan({ ...van, im36: false });
      } else {
        setVan({ ...van, im36: true });
      }
    } else if (a === "im37") {
      if (van.im37) {
        setVan({ ...van, im37: false });
      } else {
        setVan({ ...van, im37: true });
      }
    } else if (a === "im38") {
      if (van.im38) {
        setVan({ ...van, im38: false });
      } else {
        setVan({ ...van, im38: true });
      }
    } else if (a === "im39") {
      if (van.im39) {
        setVan({ ...van, im39: false });
      } else {
        setVan({ ...van, im39: true });
      }
    } else if (a === "im40") {
      if (van.im40) {
        setVan({ ...van, im40: false });
      } else {
        setVan({ ...van, im40: true });
      }
    } else if (a === "im41") {
      if (van.im41) {
        setVan({ ...van, im41: false });
      } else {
        setVan({ ...van, im41: true });
      }
    } else if (a === "im42") {
      if (van.im42) {
        setVan({ ...van, im42: false });
      } else {
        setVan({ ...van, im42: true });
      }
    } else if (a === "im43") {
      if (van.im43) {
        setVan({ ...van, im43: false });
      } else {
        setVan({ ...van, im43: true });
      }
    } else if (a === "im44") {
      if (van.im44) {
        setVan({ ...van, im44: false });
      } else {
        setVan({ ...van, im44: true });
      }
    } else if (a === "im45") {
      if (van.im45) {
        setVan({ ...van, im45: false });
      } else {
        setVan({ ...van, im45: true });
      }
    } else if (a === "im46") {
      if (van.im46) {
        setVan({ ...van, im46: false });
      } else {
        setVan({ ...van, im46: true });
      }
    } else if (a === "im47") {
      if (van.im47) {
        setVan({ ...van, im47: false });
      } else {
        setVan({ ...van, im47: true });
      }
    } else if (a === "im48") {
      if (van.im48) {
        setVan({ ...van, im48: false });
      } else {
        setVan({ ...van, im48: true });
      }
    } else if (a === "im49") {
      if (van.im49) {
        setVan({ ...van, im49: false });
      } else {
        setVan({ ...van, im49: true });
      }
    } else if (a === "im50") {
      if (van.im50) {
        setVan({ ...van, im50: false });
      } else {
        setVan({ ...van, im50: true });
      }
    } else if (a === "im51") {
      if (van.im51) {
        setVan({ ...van, im51: false });
      } else {
        setVan({ ...van, im51: true });
      }
    } else if (a === "im52") {
      if (van.im52) {
        setVan({ ...van, im52: false });
      } else {
        setVan({ ...van, im52: true });
      }
    } else if (a === "im53") {
      if (van.im53) {
        setVan({ ...van, im53: false });
      } else {
        setVan({ ...van, im53: true });
      }
    } else if (a === "im54") {
      if (van.im54) {
        setVan({ ...van, im54: false });
      } else {
        setVan({ ...van, im54: true });
      }
    } else if (a === "im55") {
      if (van.im55) {
        setVan({ ...van, im55: false });
      } else {
        setVan({ ...van, im55: true });
      }
    } else if (a === "im56") {
      if (van.im56) {
        setVan({ ...van, im56: false });
      } else {
        setVan({ ...van, im56: true });
      }
    } else if (a === "im57") {
      if (van.im57) {
        setVan({ ...van, im57: false });
      } else {
        setVan({ ...van, im57: true });
      }
    } else if (a === "im58") {
      if (van.im58) {
        setVan({ ...van, im58: false });
      } else {
        setVan({ ...van, im58: true });
      }
    } else if (a === "im59") {
      if (van.im59) {
        setVan({ ...van, im59: false });
      } else {
        setVan({ ...van, im59: true });
      }
    } else if (a === "im60") {
      if (van.im60) {
        setVan({ ...van, im60: false });
      } else {
        setVan({ ...van, im60: true });
      }
    } else if (a === "im61") {
      if (van.im61) {
        setVan({ ...van, im61: false });
      } else {
        setVan({ ...van, im61: true });
      }
    } else if (a === "im62") {
      if (van.im62) {
        setVan({ ...van, im62: false });
      } else {
        setVan({ ...van, im62: true });
      }
    } else if (a === "im63") {
      if (van.im63) {
        setVan({ ...van, im63: false });
      } else {
        setVan({ ...van, im63: true });
      }
    } else if (a === "im64") {
      if (van.im64) {
        setVan({ ...van, im64: false });
      } else {
        setVan({ ...van, im64: true });
      }
    } else if (a === "im65") {
      if (van.im65) {
        setVan({ ...van, im65: false });
      } else {
        setVan({ ...van, im65: true });
      }
    } else if (a === "im66") {
      if (van.im66) {
        setVan({ ...van, im66: false });
      } else {
        setVan({ ...van, im66: true });
      }
    } else if (a === "im67") {
      if (van.im67) {
        setVan({ ...van, im67: false });
      } else {
        setVan({ ...van, im67: true });
      }
    } else if (a === "im68") {
      if (van.im68) {
        setVan({ ...van, im68: false });
      } else {
        setVan({ ...van, im68: true });
      }
    } else if (a === "im69") {
      if (van.im69) {
        setVan({ ...van, im69: false });
      } else {
        setVan({ ...van, im69: true });
      }
    } else if (a === "im70") {
      if (van.im70) {
        setVan({ ...van, im70: false });
      } else {
        setVan({ ...van, im70: true });
      }
    } else if (a === "im71") {
      if (van.im71) {
        setVan({ ...van, im71: false });
      } else {
        setVan({ ...van, im71: true });
      }
    } else if (a === "im72") {
      if (van.im72) {
        setVan({ ...van, im72: false });
      } else {
        setVan({ ...van, im72: true });
      }
    } else if (a === "im73") {
      if (van.im73) {
        setVan({ ...van, im73: false });
      } else {
        setVan({ ...van, im73: true });
      }
    } else if (a === "im74") {
      if (van.im74) {
        setVan({ ...van, im74: false });
      } else {
        setVan({ ...van, im74: true });
      }
    } else if (a === "im75") {
      if (van.im75) {
        setVan({ ...van, im75: false });
      } else {
        setVan({ ...van, im75: true });
      }
    } else if (a === "im76") {
      if (van.im76) {
        setVan({ ...van, im76: false });
      } else {
        setVan({ ...van, im76: true });
      }
    } else if (a === "im77") {
      if (van.im77) {
        setVan({ ...van, im77: false });
      } else {
        setVan({ ...van, im77: true });
      }
    } else if (a === "im78") {
      if (van.im78) {
        setVan({ ...van, im78: false });
      } else {
        setVan({ ...van, im78: true });
      }
    } else if (a === "im79") {
      if (van.im79) {
        setVan({ ...van, im79: false });
      } else {
        setVan({ ...van, im79: true });
      }
    } else if (a === "im80") {
      if (van.im80) {
        setVan({ ...van, im80: false });
      } else {
        setVan({ ...van, im80: true });
      }
    } else if (a === "im81") {
      if (van.im81) {
        setVan({ ...van, im81: false });
      } else {
        setVan({ ...van, im81: true });
      }
    } else if (a === "im82") {
      if (van.im82) {
        setVan({ ...van, im82: false });
      } else {
        setVan({ ...van, im82: true });
      }
    } else if (a === "im83") {
      if (van.im83) {
        setVan({ ...van, im83: false });
      } else {
        setVan({ ...van, im83: true });
      }
    } else if (a === "im84") {
      if (van.im84) {
        setVan({ ...van, im84: false });
      } else {
        setVan({ ...van, im84: true });
      }
    } else if (a === "im85") {
      if (van.im85) {
        setVan({ ...van, im85: false });
      } else {
        setVan({ ...van, im85: true });
      }
    } else if (a === "im86") {
      if (van.im86) {
        setVan({ ...van, im86: false });
      } else {
        setVan({ ...van, im86: true });
      }
    } else if (a === "im87") {
      if (van.im87) {
        setVan({ ...van, im87: false });
      } else {
        setVan({ ...van, im87: true });
      }
    } else if (a === "im88") {
      if (van.im88) {
        setVan({ ...van, im88: false });
      } else {
        setVan({ ...van, im88: true });
      }
    } else if (a === "im89") {
      if (van.im89) {
        setVan({ ...van, im89: false });
      } else {
        setVan({ ...van, im89: true });
      }
    } else if (a === "im90") {
      if (van.im90) {
        setVan({ ...van, im90: false });
      } else {
        setVan({ ...van, im90: true });
      }
    } else if (a === "im91") {
      if (van.im91) {
        setVan({ ...van, im91: false });
      } else {
        setVan({ ...van, im91: true });
      }
    } else if (a === "im92") {
      if (van.im92) {
        setVan({ ...van, im92: false });
      } else {
        setVan({ ...van, im92: true });
      }
    } else if (a === "im93") {
      if (van.im93) {
        setVan({ ...van, im93: false });
      } else {
        setVan({ ...van, im93: true });
      }
    } else if (a === "im94") {
      if (van.im94) {
        setVan({ ...van, im94: false });
      } else {
        setVan({ ...van, im94: true });
      }
    } else if (a === "im95") {
      if (van.im95) {
        setVan({ ...van, im95: false });
      } else {
        setVan({ ...van, im95: true });
      }
    } else if (a === "im96") {
      if (van.im96) {
        setVan({ ...van, im96: false });
      } else {
        setVan({ ...van, im96: true });
      }
    } else if (a === "im97") {
      if (van.im97) {
        setVan({ ...van, im97: false });
      } else {
        setVan({ ...van, im97: true });
      }
    } else if (a === "im98") {
      if (van.im98) {
        setVan({ ...van, im98: false });
      } else {
        setVan({ ...van, im98: true });
      }
    } else if (a === "im99") {
      if (van.im99) {
        setVan({ ...van, im99: false });
      } else {
        setVan({ ...van, im99: true });
      }
    } else if (a === "im100") {
      if (van.im100) {
        setVan({ ...van, im100: false });
      } else {
        setVan({ ...van, im100: true });
      }
    } else if (a === "im101") {
      if (van.im101) {
        setVan({ ...van, im101: false });
      } else {
        setVan({ ...van, im101: true });
      }
    } else if (a === "im102") {
      if (van.im102) {
        setVan({ ...van, im102: false });
      } else {
        setVan({ ...van, im102: true });
      }
    } else if (a === "im103") {
      if (van.im103) {
        setVan({ ...van, im103: false });
      } else {
        setVan({ ...van, im103: true });
      }
    } else if (a === "im104") {
      if (van.im104) {
        setVan({ ...van, im104: false });
      } else {
        setVan({ ...van, im104: true });
      }
    } else if (a === "im105") {
      if (van.im105) {
        setVan({ ...van, im105: false });
      } else {
        setVan({ ...van, im105: true });
      }
    } else if (a === "im106") {
      if (van.im106) {
        setVan({ ...van, im106: false });
      } else {
        setVan({ ...van, im106: true });
      }
    } else if (a === "im107") {
      if (van.im107) {
        setVan({ ...van, im107: false });
      } else {
        setVan({ ...van, im107: true });
      }
    } else if (a === "im108") {
      if (van.im108) {
        setVan({ ...van, im108: false });
      } else {
        setVan({ ...van, im108: true });
      }
    } else if (a === "im109") {
      if (van.im109) {
        setVan({ ...van, im109: false });
      } else {
        setVan({ ...van, im109: true });
      }
    } else if (a === "im110") {
      if (van.im110) {
        setVan({ ...van, im110: false });
      } else {
        setVan({ ...van, im110: true });
      }
    } else if (a === "im111") {
      if (van.im111) {
        setVan({ ...van, im111: false });
      } else {
        setVan({ ...van, im111: true });
      }
    } else if (a === "im112") {
      if (van.im112) {
        setVan({ ...van, im112: false });
      } else {
        setVan({ ...van, im112: true });
      }
    } else if (a === "im113") {
      if (van.im113) {
        setVan({ ...van, im113: false });
      } else {
        setVan({ ...van, im113: true });
      }
    } else if (a === "im114") {
      if (van.im114) {
        setVan({ ...van, im114: false });
      } else {
        setVan({ ...van, im114: true });
      }
    } else if (a === "im115") {
      if (van.im115) {
        setVan({ ...van, im115: false });
      } else {
        setVan({ ...van, im115: true });
      }
    } else if (a === "im116") {
      if (van.im116) {
        setVan({ ...van, im116: false });
      } else {
        setVan({ ...van, im116: true });
      }
    } else if (a === "im117") {
      if (van.im117) {
        setVan({ ...van, im117: false });
      } else {
        setVan({ ...van, im117: true });
      }
    } else if (a === "im118") {
      if (van.im118) {
        setVan({ ...van, im118: false });
      } else {
        setVan({ ...van, im118: true });
      }
    } else if (a === "im119") {
      if (van.im119) {
        setVan({ ...van, im119: false });
      } else {
        setVan({ ...van, im119: true });
      }
    } else if (a === "im120") {
      if (van.im120) {
        setVan({ ...van, im120: false });
      } else {
        setVan({ ...van, im120: true });
      }
    } else if (a === "im121") {
      if (van.im121) {
        setVan({ ...van, im121: false });
      } else {
        setVan({ ...van, im121: true });
      }
    } else if (a === "im122") {
      if (van.im122) {
        setVan({ ...van, im122: false });
      } else {
        setVan({ ...van, im122: true });
      }
    } else if (a === "im123") {
      if (van.im123) {
        setVan({ ...van, im123: false });
      } else {
        setVan({ ...van, im123: true });
      }
    } else if (a === "im124") {
      if (van.im124) {
        setVan({ ...van, im124: false });
      } else {
        setVan({ ...van, im124: true });
      }
    } else if (a === "im125") {
      if (van.im125) {
        setVan({ ...van, im125: false });
      } else {
        setVan({ ...van, im125: true });
      }
    } else if (a === "im126") {
      if (van.im126) {
        setVan({ ...van, im126: false });
      } else {
        setVan({ ...van, im126: true });
      }
    } else if (a === "im127") {
      if (van.im127) {
        setVan({ ...van, im127: false });
      } else {
        setVan({ ...van, im127: true });
      }
    } else if (a === "im128") {
      if (van.im128) {
        setVan({ ...van, im128: false });
      } else {
        setVan({ ...van, im128: true });
      }
    } else if (a === "im129") {
      if (van.im129) {
        setVan({ ...van, im129: false });
      } else {
        setVan({ ...van, im129: true });
      }
    } else if (a === "im130") {
      if (van.im130) {
        setVan({ ...van, im130: false });
      } else {
        setVan({ ...van, im130: true });
      }
    } else if (a === "im131") {
      if (van.im131) {
        setVan({ ...van, im131: false });
      } else {
        setVan({ ...van, im131: true });
      }
    } else if (a === "im132") {
      if (van.im132) {
        setVan({ ...van, im132: false });
      } else {
        setVan({ ...van, im132: true });
      }
    } else if (a === "im133") {
      if (van.im133) {
        setVan({ ...van, im133: false });
      } else {
        setVan({ ...van, im133: true });
      }
    } else if (a === "im134") {
      if (van.im134) {
        setVan({ ...van, im134: false });
      } else {
        setVan({ ...van, im134: true });
      }
    } else if (a === "im135") {
      if (van.im135) {
        setVan({ ...van, im135: false });
      } else {
        setVan({ ...van, im135: true });
      }
    } else if (a === "im136") {
      if (van.im136) {
        setVan({ ...van, im136: false });
      } else {
        setVan({ ...van, im136: true });
      }
    } else if (a === "im137") {
      if (van.im137) {
        setVan({ ...van, im137: false });
      } else {
        setVan({ ...van, im137: true });
      }
    } else if (a === "im138") {
      if (van.im138) {
        setVan({ ...van, im138: false });
      } else {
        setVan({ ...van, im138: true });
      }
    } else if (a === "im139") {
      if (van.im139) {
        setVan({ ...van, im139: false });
      } else {
        setVan({ ...van, im139: true });
      }
    } else if (a === "im140") {
      if (van.im140) {
        setVan({ ...van, im140: false });
      } else {
        setVan({ ...van, im140: true });
      }
    } else if (a === "im141") {
      if (van.im141) {
        setVan({ ...van, im141: false });
      } else {
        setVan({ ...van, im141: true });
      }
    } else if (a === "im142") {
      if (van.im142) {
        setVan({ ...van, im142: false });
      } else {
        setVan({ ...van, im142: true });
      }
    } else if (a === "im143") {
      if (van.im143) {
        setVan({ ...van, im143: false });
      } else {
        setVan({ ...van, im143: true });
      }
    } else if (a === "im144") {
      if (van.im144) {
        setVan({ ...van, im144: false });
      } else {
        setVan({ ...van, im144: true });
      }
    } else if (a === "im145") {
      if (van.im145) {
        setVan({ ...van, im145: false });
      } else {
        setVan({ ...van, im145: true });
      }
    } else if (a === "im146") {
      if (van.im146) {
        setVan({ ...van, im146: false });
      } else {
        setVan({ ...van, im146: true });
      }
    } else if (a === "im147") {
      if (van.im147) {
        setVan({ ...van, im147: false });
      } else {
        setVan({ ...van, im147: true });
      }
    } else if (a === "im148") {
      if (van.im148) {
        setVan({ ...van, im148: false });
      } else {
        setVan({ ...van, im148: true });
      }
    } else if (a === "im149") {
      if (van.im149) {
        setVan({ ...van, im149: false });
      } else {
        setVan({ ...van, im149: true });
      }
    } else if (a === "im150") {
      if (van.im150) {
        setVan({ ...van, im150: false });
      } else {
        setVan({ ...van, im150: true });
      }
    } else if (a === "im151") {
      if (van.im151) {
        setVan({ ...van, im151: false });
      } else {
        setVan({ ...van, im151: true });
      }
    } else if (a === "im152") {
      if (van.im152) {
        setVan({ ...van, im152: false });
      } else {
        setVan({ ...van, im152: true });
      }
    } else if (a === "im153") {
      if (van.im153) {
        setVan({ ...van, im153: false });
      } else {
        setVan({ ...van, im153: true });
      }
    } else if (a === "im154") {
      if (van.im154) {
        setVan({ ...van, im154: false });
      } else {
        setVan({ ...van, im154: true });
      }
    } else if (a === "im155") {
      if (van.im155) {
        setVan({ ...van, im155: false });
      } else {
        setVan({ ...van, im155: true });
      }
    } else if (a === "im156") {
      if (van.im156) {
        setVan({ ...van, im156: false });
      } else {
        setVan({ ...van, im156: true });
      }
    } else if (a === "im157") {
      if (van.im157) {
        setVan({ ...van, im157: false });
      } else {
        setVan({ ...van, im157: true });
      }
    } else if (a === "im158") {
      if (van.im158) {
        setVan({ ...van, im158: false });
      } else {
        setVan({ ...van, im158: true });
      }
    } else if (a === "im159") {
      if (van.im159) {
        setVan({ ...van, im159: false });
      } else {
        setVan({ ...van, im159: true });
      }
    } else if (a === "im160") {
      if (van.im160) {
        setVan({ ...van, im160: false });
      } else {
        setVan({ ...van, im160: true });
      }
    } else if (a === "im161") {
      if (van.im161) {
        setVan({ ...van, im161: false });
      } else {
        setVan({ ...van, im161: true });
      }
    } else if (a === "im162") {
      if (van.im162) {
        setVan({ ...van, im162: false });
      } else {
        setVan({ ...van, im162: true });
      }
    } else if (a === "im163") {
      if (van.im163) {
        setVan({ ...van, im163: false });
      } else {
        setVan({ ...van, im163: true });
      }
    } else if (a === "im164") {
      if (van.im164) {
        setVan({ ...van, im164: false });
      } else {
        setVan({ ...van, im164: true });
      }
    } else if (a === "im165") {
      if (van.im165) {
        setVan({ ...van, im165: false });
      } else {
        setVan({ ...van, im165: true });
      }
    } else if (a === "im166") {
      if (van.im166) {
        setVan({ ...van, im166: false });
      } else {
        setVan({ ...van, im166: true });
      }
    } else if (a === "im167") {
      if (van.im167) {
        setVan({ ...van, im167: false });
      } else {
        setVan({ ...van, im167: true });
      }
    } else if (a === "im168") {
      if (van.im168) {
        setVan({ ...van, im168: false });
      } else {
        setVan({ ...van, im168: true });
      }
    } else if (a === "im169") {
      if (van.im169) {
        setVan({ ...van, im169: false });
      } else {
        setVan({ ...van, im169: true });
      }
    } else if (a === "im170") {
      if (van.im170) {
        setVan({ ...van, im170: false });
      } else {
        setVan({ ...van, im170: true });
      }
    } else if (a === "im171") {
      if (van.im171) {
        setVan({ ...van, im171: false });
      } else {
        setVan({ ...van, im171: true });
      }
    } else if (a === "im172") {
      if (van.im172) {
        setVan({ ...van, im172: false });
      } else {
        setVan({ ...van, im172: true });
      }
    } else if (a === "im173") {
      if (van.im173) {
        setVan({ ...van, im173: false });
      } else {
        setVan({ ...van, im173: true });
      }
    } else if (a === "im174") {
      if (van.im174) {
        setVan({ ...van, im174: false });
      } else {
        setVan({ ...van, im174: true });
      }
    } else if (a === "im175") {
      if (van.im175) {
        setVan({ ...van, im175: false });
      } else {
        setVan({ ...van, im175: true });
      }
    } else if (a === "im176") {
      if (van.im176) {
        setVan({ ...van, im176: false });
      } else {
        setVan({ ...van, im176: true });
      }
    } else if (a === "im177") {
      if (van.im177) {
        setVan({ ...van, im177: false });
      } else {
        setVan({ ...van, im177: true });
      }
    } else if (a === "im178") {
      if (van.im178) {
        setVan({ ...van, im178: false });
      } else {
        setVan({ ...van, im178: true });
      }
    } else if (a === "im179") {
      if (van.im179) {
        setVan({ ...van, im179: false });
      } else {
        setVan({ ...van, im179: true });
      }
    } else if (a === "im180") {
      if (van.im180) {
        setVan({ ...van, im180: false });
      } else {
        setVan({ ...van, im180: true });
      }
    } else if (a === "im181") {
      if (van.im181) {
        setVan({ ...van, im181: false });
      } else {
        setVan({ ...van, im181: true });
      }
    } else if (a === "im182") {
      if (van.im182) {
        setVan({ ...van, im182: false });
      } else {
        setVan({ ...van, im182: true });
      }
    } else if (a === "im183") {
      if (van.im183) {
        setVan({ ...van, im183: false });
      } else {
        setVan({ ...van, im183: true });
      }
    } else if (a === "im184") {
      if (van.im184) {
        setVan({ ...van, im184: false });
      } else {
        setVan({ ...van, im184: true });
      }
    } else if (a === "im185") {
      if (van.im185) {
        setVan({ ...van, im185: false });
      } else {
        setVan({ ...van, im185: true });
      }
    } else if (a === "im186") {
      if (van.im186) {
        setVan({ ...van, im186: false });
      } else {
        setVan({ ...van, im186: true });
      }
    } else if (a === "im187") {
      if (van.im187) {
        setVan({ ...van, im187: false });
      } else {
        setVan({ ...van, im187: true });
      }
    } else if (a === "im188") {
      if (van.im188) {
        setVan({ ...van, im188: false });
      } else {
        setVan({ ...van, im188: true });
      }
    } else if (a === "im189") {
      if (van.im189) {
        setVan({ ...van, im189: false });
      } else {
        setVan({ ...van, im189: true });
      }
    } else if (a === "im190") {
      if (van.im190) {
        setVan({ ...van, im190: false });
      } else {
        setVan({ ...van, im190: true });
      }
    } else if (a === "im191") {
      if (van.im191) {
        setVan({ ...van, im191: false });
      } else {
        setVan({ ...van, im191: true });
      }
    } else if (a === "im192") {
      if (van.im192) {
        setVan({ ...van, im192: false });
      } else {
        setVan({ ...van, im192: true });
      }
    } else if (a === "im193") {
      if (van.im193) {
        setVan({ ...van, im193: false });
      } else {
        setVan({ ...van, im193: true });
      }
    } else if (a === "im194") {
      if (van.im194) {
        setVan({ ...van, im194: false });
      } else {
        setVan({ ...van, im194: true });
      }
    } else if (a === "im195") {
      if (van.im195) {
        setVan({ ...van, im195: false });
      } else {
        setVan({ ...van, im195: true });
      }
    } else if (a === "im196") {
      if (van.im196) {
        setVan({ ...van, im196: false });
      } else {
        setVan({ ...van, im196: true });
      }
    } else if (a === "im197") {
      if (van.im197) {
        setVan({ ...van, im197: false });
      } else {
        setVan({ ...van, im197: true });
      }
    } else if (a === "im198") {
      if (van.im198) {
        setVan({ ...van, im198: false });
      } else {
        setVan({ ...van, im198: true });
      }
    } else if (a === "im199") {
      if (van.im199) {
        setVan({ ...van, im199: false });
      } else {
        setVan({ ...van, im199: true });
      }
    } else if (a === "im200") {
      if (van.im200) {
        setVan({ ...van, im200: false });
      } else {
        setVan({ ...van, im200: true });
      }
    } else if (a === "im201") {
      if (van.im201) {
        setVan({ ...van, im201: false });
      } else {
        setVan({ ...van, im201: true });
      }
    } else if (a === "im202") {
      if (van.im202) {
        setVan({ ...van, im202: false });
      } else {
        setVan({ ...van, im202: true });
      }
    } else if (a === "im203") {
      if (van.im203) {
        setVan({ ...van, im203: false });
      } else {
        setVan({ ...van, im203: true });
      }
    } else if (a === "im204") {
      if (van.im204) {
        setVan({ ...van, im204: false });
      } else {
        setVan({ ...van, im204: true });
      }
    } else if (a === "im205") {
      if (van.im205) {
        setVan({ ...van, im205: false });
      } else {
        setVan({ ...van, im205: true });
      }
    } else if (a === "im206") {
      if (van.im206) {
        setVan({ ...van, im206: false });
      } else {
        setVan({ ...van, im206: true });
      }
    } else if (a === "im207") {
      if (van.im207) {
        setVan({ ...van, im207: false });
      } else {
        setVan({ ...van, im207: true });
      }
    } else if (a === "im208") {
      if (van.im208) {
        setVan({ ...van, im208: false });
      } else {
        setVan({ ...van, im208: true });
      }
    } else if (a === "im209") {
      if (van.im209) {
        setVan({ ...van, im209: false });
      } else {
        setVan({ ...van, im209: true });
      }
    } else if (a === "im210") {
      if (van.im210) {
        setVan({ ...van, im210: false });
      } else {
        setVan({ ...van, im210: true });
      }
    } else if (a === "im211") {
      if (van.im211) {
        setVan({ ...van, im211: false });
      } else {
        setVan({ ...van, im211: true });
      }
    } else if (a === "im212") {
      if (van.im212) {
        setVan({ ...van, im212: false });
      } else {
        setVan({ ...van, im212: true });
      }
    } else if (a === "im213") {
      if (van.im213) {
        setVan({ ...van, im213: false });
      } else {
        setVan({ ...van, im213: true });
      }
    } else if (a === "im214") {
      if (van.im214) {
        setVan({ ...van, im214: false });
      } else {
        setVan({ ...van, im214: true });
      }
    } else if (a === "im215") {
      if (van.im215) {
        setVan({ ...van, im215: false });
      } else {
        setVan({ ...van, im215: true });
      }
    } else if (a === "im216") {
      if (van.im216) {
        setVan({ ...van, im216: false });
      } else {
        setVan({ ...van, im216: true });
      }
    } else if (a === "im217") {
      if (van.im217) {
        setVan({ ...van, im217: false });
      } else {
        setVan({ ...van, im217: true });
      }
    } else if (a === "im218") {
      if (van.im218) {
        setVan({ ...van, im218: false });
      } else {
        setVan({ ...van, im218: true });
      }
    } else if (a === "im219") {
      if (van.im219) {
        setVan({ ...van, im219: false });
      } else {
        setVan({ ...van, im219: true });
      }
    } else if (a === "im220") {
      if (van.im220) {
        setVan({ ...van, im220: false });
      } else {
        setVan({ ...van, im220: true });
      }
    } else if (a === "im221") {
      if (van.im221) {
        setVan({ ...van, im221: false });
      } else {
        setVan({ ...van, im221: true });
      }
    } else if (a === "im222") {
      if (van.im222) {
        setVan({ ...van, im222: false });
      } else {
        setVan({ ...van, im222: true });
      }
    } else if (a === "im223") {
      if (van.im223) {
        setVan({ ...van, im223: false });
      } else {
        setVan({ ...van, im223: true });
      }
    } else if (a === "im224") {
      if (van.im224) {
        setVan({ ...van, im224: false });
      } else {
        setVan({ ...van, im224: true });
      }
    } else if (a === "im225") {
      if (van.im225) {
        setVan({ ...van, im225: false });
      } else {
        setVan({ ...van, im225: true });
      }
    } else if (a === "im226") {
      if (van.im226) {
        setVan({ ...van, im226: false });
      } else {
        setVan({ ...van, im226: true });
      }
    } else if (a === "im227") {
      if (van.im227) {
        setVan({ ...van, im227: false });
      } else {
        setVan({ ...van, im227: true });
      }
    } else if (a === "im228") {
      if (van.im228) {
        setVan({ ...van, im228: false });
      } else {
        setVan({ ...van, im228: true });
      }
    } else if (a === "im229") {
      if (van.im229) {
        setVan({ ...van, im229: false });
      } else {
        setVan({ ...van, im229: true });
      }
    } else if (a === "im230") {
      if (van.im230) {
        setVan({ ...van, im230: false });
      } else {
        setVan({ ...van, im230: true });
      }
    } else if (a === "im231") {
      if (van.im231) {
        setVan({ ...van, im231: false });
      } else {
        setVan({ ...van, im231: true });
      }
    } else if (a === "im232") {
      if (van.im232) {
        setVan({ ...van, im232: false });
      } else {
        setVan({ ...van, im232: true });
      }
    } else if (a === "im233") {
      if (van.im233) {
        setVan({ ...van, im233: false });
      } else {
        setVan({ ...van, im233: true });
      }
    } else if (a === "im234") {
      if (van.im234) {
        setVan({ ...van, im234: false });
      } else {
        setVan({ ...van, im234: true });
      }
    } else if (a === "im235") {
      if (van.im235) {
        setVan({ ...van, im235: false });
      } else {
        setVan({ ...van, im235: true });
      }
    } else if (a === "im236") {
      if (van.im236) {
        setVan({ ...van, im236: false });
      } else {
        setVan({ ...van, im236: true });
      }
    } else if (a === "im237") {
      if (van.im237) {
        setVan({ ...van, im237: false });
      } else {
        setVan({ ...van, im237: true });
      }
    } else if (a === "im238") {
      if (van.im238) {
        setVan({ ...van, im238: false });
      } else {
        setVan({ ...van, im238: true });
      }
    } else if (a === "im239") {
      if (van.im239) {
        setVan({ ...van, im239: false });
      } else {
        setVan({ ...van, im239: true });
      }
    } else if (a === "im240") {
      if (van.im240) {
        setVan({ ...van, im240: false });
      } else {
        setVan({ ...van, im240: true });
      }
    } else if (a === "im241") {
      if (van.im241) {
        setVan({ ...van, im241: false });
      } else {
        setVan({ ...van, im241: true });
      }
    } else if (a === "im242") {
      if (van.im242) {
        setVan({ ...van, im242: false });
      } else {
        setVan({ ...van, im242: true });
      }
    } else if (a === "im243") {
      if (van.im243) {
        setVan({ ...van, im243: false });
      } else {
        setVan({ ...van, im243: true });
      }
    } else if (a === "im244") {
      if (van.im244) {
        setVan({ ...van, im244: false });
      } else {
        setVan({ ...van, im244: true });
      }
    } else if (a === "im245") {
      if (van.im245) {
        setVan({ ...van, im245: false });
      } else {
        setVan({ ...van, im245: true });
      }
    } else if (a === "im246") {
      if (van.im246) {
        setVan({ ...van, im246: false });
      } else {
        setVan({ ...van, im246: true });
      }
    } else if (a === "im247") {
      if (van.im247) {
        setVan({ ...van, im247: false });
      } else {
        setVan({ ...van, im247: true });
      }
    } else if (a === "im248") {
      if (van.im248) {
        setVan({ ...van, im248: false });
      } else {
        setVan({ ...van, im248: true });
      }
    } else if (a === "im249") {
      if (van.im249) {
        setVan({ ...van, im249: false });
      } else {
        setVan({ ...van, im249: true });
      }
    } else if (a === "im250") {
      if (van.im250) {
        setVan({ ...van, im250: false });
      } else {
        setVan({ ...van, im250: true });
      }
    } else if (a === "im251") {
      if (van.im251) {
        setVan({ ...van, im251: false });
      } else {
        setVan({ ...van, im251: true });
      }
    } else if (a === "im252") {
      if (van.im252) {
        setVan({ ...van, im252: false });
      } else {
        setVan({ ...van, im252: true });
      }
    } else if (a === "im253") {
      if (van.im253) {
        setVan({ ...van, im253: false });
      } else {
        setVan({ ...van, im253: true });
      }
    } else if (a === "im254") {
      if (van.im254) {
        setVan({ ...van, im254: false });
      } else {
        setVan({ ...van, im254: true });
      }
    } else if (a === "im255") {
      if (van.im255) {
        setVan({ ...van, im255: false });
      } else {
        setVan({ ...van, im255: true });
      }
    } else if (a === "im256") {
      if (van.im256) {
        setVan({ ...van, im256: false });
      } else {
        setVan({ ...van, im256: true });
      }
    } else if (a === "im257") {
      if (van.im257) {
        setVan({ ...van, im257: false });
      } else {
        setVan({ ...van, im257: true });
      }
    } else if (a === "im258") {
      if (van.im258) {
        setVan({ ...van, im258: false });
      } else {
        setVan({ ...van, im258: true });
      }
    } else if (a === "im259") {
      if (van.im259) {
        setVan({ ...van, im259: false });
      } else {
        setVan({ ...van, im259: true });
      }
    } else if (a === "im260") {
      if (van.im260) {
        setVan({ ...van, im260: false });
      } else {
        setVan({ ...van, im260: true });
      }
    } else if (a === "im261") {
      if (van.im261) {
        setVan({ ...van, im261: false });
      } else {
        setVan({ ...van, im261: true });
      }
    } else if (a === "im262") {
      if (van.im262) {
        setVan({ ...van, im262: false });
      } else {
        setVan({ ...van, im262: true });
      }
    } else if (a === "im263") {
      if (van.im263) {
        setVan({ ...van, im263: false });
      } else {
        setVan({ ...van, im263: true });
      }
    } else if (a === "im264") {
      if (van.im264) {
        setVan({ ...van, im264: false });
      } else {
        setVan({ ...van, im264: true });
      }
    } else if (a === "im265") {
      if (van.im265) {
        setVan({ ...van, im265: false });
      } else {
        setVan({ ...van, im265: true });
      }
    } else if (a === "im266") {
      if (van.im266) {
        setVan({ ...van, im266: false });
      } else {
        setVan({ ...van, im266: true });
      }
    } else if (a === "im267") {
      if (van.im267) {
        setVan({ ...van, im267: false });
      } else {
        setVan({ ...van, im267: true });
      }
    } else if (a === "im268") {
      if (van.im268) {
        setVan({ ...van, im268: false });
      } else {
        setVan({ ...van, im268: true });
      }
    } else if (a === "im269") {
      if (van.im269) {
        setVan({ ...van, im269: false });
      } else {
        setVan({ ...van, im269: true });
      }
    } else if (a === "im270") {
      if (van.im270) {
        setVan({ ...van, im270: false });
      } else {
        setVan({ ...van, im270: true });
      }
    } else if (a === "im271") {
      if (van.im271) {
        setVan({ ...van, im271: false });
      } else {
        setVan({ ...van, im271: true });
      }
    } else if (a === "im272") {
      if (van.im272) {
        setVan({ ...van, im272: false });
      } else {
        setVan({ ...van, im272: true });
      }
    } else if (a === "im273") {
      if (van.im273) {
        setVan({ ...van, im273: false });
      } else {
        setVan({ ...van, im273: true });
      }
    } else if (a === "im274") {
      if (van.im274) {
        setVan({ ...van, im274: false });
      } else {
        setVan({ ...van, im274: true });
      }
    } else if (a === "im275") {
      if (van.im275) {
        setVan({ ...van, im275: false });
      } else {
        setVan({ ...van, im275: true });
      }
    } else if (a === "im276") {
      if (van.im276) {
        setVan({ ...van, im276: false });
      } else {
        setVan({ ...van, im276: true });
      }
    } else if (a === "im277") {
      if (van.im277) {
        setVan({ ...van, im277: false });
      } else {
        setVan({ ...van, im277: true });
      }
    } else if (a === "im278") {
      if (van.im278) {
        setVan({ ...van, im278: false });
      } else {
        setVan({ ...van, im278: true });
      }
    } else if (a === "im279") {
      if (van.im279) {
        setVan({ ...van, im279: false });
      } else {
        setVan({ ...van, im279: true });
      }
    } else if (a === "im280") {
      if (van.im280) {
        setVan({ ...van, im280: false });
      } else {
        setVan({ ...van, im280: true });
      }
    } else if (a === "im281") {
      if (van.im281) {
        setVan({ ...van, im281: false });
      } else {
        setVan({ ...van, im281: true });
      }
    } else if (a === "im282") {
      if (van.im282) {
        setVan({ ...van, im282: false });
      } else {
        setVan({ ...van, im282: true });
      }
    } else if (a === "im283") {
      if (van.im283) {
        setVan({ ...van, im283: false });
      } else {
        setVan({ ...van, im283: true });
      }
    } else if (a === "im284") {
      if (van.im284) {
        setVan({ ...van, im284: false });
      } else {
        setVan({ ...van, im284: true });
      }
    } else if (a === "im285") {
      if (van.im285) {
        setVan({ ...van, im285: false });
      } else {
        setVan({ ...van, im285: true });
      }
    }
  };

  const handleLorryStatus = (a) => {
    if (a === "img1") {
      console.log("hello");
      if (  lorry.img1) {
        setLorry({ ...lorry, img1: false });
      } else {
        setLorry({ ...lorry, img1: true });
      }
    } else if (a === "img2") {
      if (lorry.img2) {
        setLorry({ ...lorry, img2: false });
      } else {
        setLorry({ ...lorry, img2: true });
      }
    } else if (a === "img3") {
      if (lorry.img3) {
        setLorry({ ...lorry, img3: false });
      } else {
        setLorry({ ...lorry, img3: true });
      }
    } else if (a === "img4") {
      if (lorry.img4) {
        setLorry({ ...lorry, img4: false });
      } else {
        setLorry({ ...lorry, img4: true });
      }
    } else if (a === "img5") {
      if (lorry.img5) {
        setLorry({ ...lorry, img5: false });
      } else {
        setLorry({ ...lorry, img5: true });
      }
    } else if (a === "img6") {
      if (lorry.img6) {
        setLorry({ ...lorry, img6: false });
      } else {
        setLorry({ ...lorry, img6: true });
      }
    } else if (a === "img7") {
      if (lorry.img7) {
        setLorry({ ...lorry, img7: false });
      } else {
        setLorry({ ...lorry, img7: true });
      }
    } else if (a === "img8") {
      if (lorry.img8) {
        setLorry({ ...lorry, img8: false });
      } else {
        setLorry({ ...lorry, img8: true });
      }
    } else if (a === "img9") {
      if (lorry.img9) {
        setLorry({ ...lorry, img9: false });
      } else {
        setLorry({ ...lorry, img9: true });
      }
    } else if (a === "img10") {
      if (lorry.img10) {
        setLorry({ ...lorry, img10: false });
      } else {
        setLorry({ ...lorry, img10: true });
      }
    } else if (a === "img11") {
      if (lorry.img11) {
        setLorry({ ...lorry, img11: false });
      } else {
        setLorry({ ...lorry, img11: true });
      }
    } else if (a === "img12") {
      if (lorry.img12) {
        setLorry({ ...lorry, img12: false });
      } else {
        setLorry({ ...lorry, img12: true });
      }
    } else if (a === "img13") {
      if (lorry.img13) {
        setLorry({ ...lorry, img13: false });
      } else {
        setLorry({ ...lorry, img13: true });
      }
    } else if (a === "img14") {
      if (lorry.img14) {
        setLorry({ ...lorry, img14: false });
      } else {
        setLorry({ ...lorry, img14: true });
      }
    } else if (a === "img15") {
      if (lorry.img15) {
        setLorry({ ...lorry, img15: false });
      } else {
        setLorry({ ...lorry, img15: true });
      }
    } else if (a === "img16") {
      if (lorry.img16) {
        setLorry({ ...lorry, img16: false });
      } else {
        setLorry({ ...lorry, img16: true });
      }
    } else if (a === "img17") {
      if (lorry.img17) {
        setLorry({ ...lorry, img17: false });
      } else {
        setLorry({ ...lorry, img17: true });
      }
    } else if (a === "img18") {
      if (lorry.img18) {
        setLorry({ ...lorry, img18: false });
      } else {
        setLorry({ ...lorry, img18: true });
      }
    } else if (a === "img19") {
      if (lorry.img19) {
        setLorry({ ...lorry, img19: false });
      } else {
        setLorry({ ...lorry, img19: true });
      }
    } else if (a === "img20") {
      if (lorry.img20) {
        setLorry({ ...lorry, img20: false });
      } else {
        setLorry({ ...lorry, img20: true });
      }
    } else if (a === "img21") {
      if (lorry.img21) {
        setLorry({ ...lorry, img21: false });
      } else {
        setLorry({ ...lorry, img21: true });
      }
    } else if (a === "img22") {
      if (lorry.img22) {
        setLorry({ ...lorry, img22: false });
      } else {
        setLorry({ ...lorry, img22: true });
      }
    } else if (a === "img23") {
      if (lorry.img23) {
        setLorry({ ...lorry, img23: false });
      } else {
        setLorry({ ...lorry, img23: true });
      }
    } else if (a === "img24") {
      if (lorry.img24) {
        setLorry({ ...lorry, img24: false });
      } else {
        setLorry({ ...lorry, img24: true });
      }
    } else if (a === "img25") {
      if (lorry.img25) {
        setLorry({ ...lorry, img25: false });
      } else {
        setLorry({ ...lorry, img25: true });
      }
    } else if (a === "img26") {
      if (lorry.img26) {
        setLorry({ ...lorry, img26: false });
      } else {
        setLorry({ ...lorry, img26: true });
      }
    } else if (a === "img27") {
      if (lorry.img27) {
        setLorry({ ...lorry, img27: false });
      } else {
        setLorry({ ...lorry, img27: true });
      }
    } else if (a === "img28") {
      if (lorry.img28) {
        setLorry({ ...lorry, img28: false });
      } else {
        setLorry({ ...lorry, img28: true });
      }
    } else if (a === "img29") {
      if (lorry.img29) {
        setLorry({ ...lorry, img29: false });
      } else {
        setLorry({ ...lorry, img29: true });
      }
    } else if (a === "img30") {
      if (lorry.img30) {
        setLorry({ ...lorry, img30: false });
      } else {
        setLorry({ ...lorry, img30: true });
      }
    } else if (a === "img31") {
      if (lorry.img31) {
        setLorry({ ...lorry, img31: false });
      } else {
        setLorry({ ...lorry, img31: true });
      }
    } else if (a === "img32") {
      if (lorry.img32) {
        setLorry({ ...lorry, img32: false });
      } else {
        setLorry({ ...lorry, img32: true });
      }
    } else if (a === "img33") {
      if (lorry.img33) {
        setLorry({ ...lorry, img33: false });
      } else {
        setLorry({ ...lorry, img33: true });
      }
    } else if (a === "img34") {
      if (lorry.img34) {
        setLorry({ ...lorry, img34: false });
      } else {
        setLorry({ ...lorry, img34: true });
      }
    } else if (a === "img35") {
      if (lorry.img35) {
        setLorry({ ...lorry, img35: false });
      } else {
        setLorry({ ...lorry, img35: true });
      }
    } else if (a === "img36") {
      if (lorry.img36) {
        setLorry({ ...lorry, img36: false });
      } else {
        setLorry({ ...lorry, img36: true });
      }
    } else if (a === "img37") {
      if (lorry.img37) {
        setLorry({ ...lorry, img37: false });
      } else {
        setLorry({ ...lorry, img37: true });
      }
    } else if (a === "img38") {
      if (lorry.img38) {
        setLorry({ ...lorry, img38: false });
      } else {
        setLorry({ ...lorry, img38: true });
      }
    } else if (a === "img39") {
      if (lorry.img39) {
        setLorry({ ...lorry, img39: false });
      } else {
        setLorry({ ...lorry, img39: true });
      }
    } else if (a === "img40") {
      if (lorry.img40) {
        setLorry({ ...lorry, img40: false });
      } else {
        setLorry({ ...lorry, img40: true });
      }
    } else if (a === "img41") {
      if (lorry.img41) {
        setLorry({ ...lorry, img41: false });
      } else {
        setLorry({ ...lorry, img41: true });
      }
    } else if (a === "img42") {
      if (lorry.img42) {
        setLorry({ ...lorry, img42: false });
      } else {
        setLorry({ ...lorry, img42: true });
      }
    } else if (a === "img43") {
      if (lorry.img43) {
        setLorry({ ...lorry, img43: false });
      } else {
        setLorry({ ...lorry, img43: true });
      }
    } else if (a === "img44") {
      if (lorry.img44) {
        setLorry({ ...lorry, img44: false });
      } else {
        setLorry({ ...lorry, img44: true });
      }
    } else if (a === "img45") {
      if (lorry.img45) {
        setLorry({ ...lorry, img45: false });
      } else {
        setLorry({ ...lorry, img45: true });
      }
    } else if (a === "img46") {
      if (lorry.img46) {
        setLorry({ ...lorry, img46: false });
      } else {
        setLorry({ ...lorry, img46: true });
      }
    } else if (a === "img47") {
      if (lorry.img47) {
        setLorry({ ...lorry, img47: false });
      } else {
        setLorry({ ...lorry, img47: true });
      }
    } else if (a === "img48") {
      if (lorry.img48) {
        setLorry({ ...lorry, img48: false });
      } else {
        setLorry({ ...lorry, img48: true });
      }
    } else if (a === "img49") {
      if (lorry.img49) {
        setLorry({ ...lorry, img49: false });
      } else {
        setLorry({ ...lorry, img49: true });
      }
    } else if (a === "img50") {
      if (lorry.img50) {
        setLorry({ ...lorry, img50: false });
      } else {
        setLorry({ ...lorry, img50: true });
      }
    } else if (a === "img51") {
      if (lorry.img51) {
        setLorry({ ...lorry, img51: false });
      } else {
        setLorry({ ...lorry, img51: true });
      }
    } else if (a === "img52") {
      if (lorry.img52) {
        setLorry({ ...lorry, img52: false });
      } else {
        setLorry({ ...lorry, img52: true });
      }
    } else if (a === "img53") {
      if (lorry.img53) {
        setLorry({ ...lorry, img53: false });
      } else {
        setLorry({ ...lorry, img53: true });
      }
    } else if (a === "img54") {
      if (lorry.img54) {
        setLorry({ ...lorry, img54: false });
      } else {
        setLorry({ ...lorry, img54: true });
      }
    } else if (a === "img55") {
      if (lorry.img55) {
        setLorry({ ...lorry, img55: false });
      } else {
        setLorry({ ...lorry, img55: true });
      }
    } else if (a === "img56") {
      if (lorry.img56) {
        setLorry({ ...lorry, img56: false });
      } else {
        setLorry({ ...lorry, img56: true });
      }
    } else if (a === "img57") {
      if (lorry.img57) {
        setLorry({ ...lorry, img57: false });
      } else {
        setLorry({ ...lorry, img57: true });
      }
    } else if (a === "img58") {
      if (lorry.img58) {
        setLorry({ ...lorry, img58: false });
      } else {
        setLorry({ ...lorry, img58: true });
      }
    } else if (a === "img59") {
      if (lorry.img59) {
        setLorry({ ...lorry, img59: false });
      } else {
        setLorry({ ...lorry, img59: true });
      }
    } else if (a === "img60") {
      if (lorry.img60) {
        setLorry({ ...lorry, img60: false });
      } else {
        setLorry({ ...lorry, img60: true });
      }
    } else if (a === "img61") {
      if (lorry.img61) {
        setLorry({ ...lorry, img61: false });
      } else {
        setLorry({ ...lorry, img61: true });
      }
    } else if (a === "img62") {
      if (lorry.img62) {
        setLorry({ ...lorry, img62: false });
      } else {
        setLorry({ ...lorry, img62: true });
      }
    } else if (a === "img63") {
      if (lorry.img63) {
        setLorry({ ...lorry, img63: false });
      } else {
        setLorry({ ...lorry, img63: true });
      }
    } else if (a === "img64") {
      if (lorry.img64) {
        setLorry({ ...lorry, img64: false });
      } else {
        setLorry({ ...lorry, img64: true });
      }
    } else if (a === "img65") {
      if (lorry.img65) {
        setLorry({ ...lorry, img65: false });
      } else {
        setLorry({ ...lorry, img65: true });
      }
    } else if (a === "img66") {
      if (lorry.img66) {
        setLorry({ ...lorry, img66: false });
      } else {
        setLorry({ ...lorry, img66: true });
      }
    } else if (a === "img67") {
      if (lorry.img67) {
        setLorry({ ...lorry, img67: false });
      } else {
        setLorry({ ...lorry, img67: true });
      }
    } else if (a === "img68") {
      if (lorry.img68) {
        setLorry({ ...lorry, img68: false });
      } else {
        setLorry({ ...lorry, img68: true });
      }
    } else if (a === "img69") {
      if (lorry.img69) {
        setLorry({ ...lorry, img69: false });
      } else {
        setLorry({ ...lorry, img69: true });
      }
    } else if (a === "img70") {
      if (lorry.img70) {
        setLorry({ ...lorry, img70: false });
      } else {
        setLorry({ ...lorry, img70: true });
      }
    } else if (a === "img71") {
      if (lorry.img71) {
        setLorry({ ...lorry, img71: false });
      } else {
        setLorry({ ...lorry, img71: true });
      }
    } else if (a === "img72") {
      if (lorry.img72) {
        setLorry({ ...lorry, img72: false });
      } else {
        setLorry({ ...lorry, img72: true });
      }
    } else if (a === "img73") {
      if (lorry.img73) {
        setLorry({ ...lorry, img73: false });
      } else {
        setLorry({ ...lorry, img73: true });
      }
    } else if (a === "img74") {
      if (lorry.img74) {
        setLorry({ ...lorry, img74: false });
      } else {
        setLorry({ ...lorry, img74: true });
      }
    } else if (a === "img75") {
      if (lorry.img75) {
        setLorry({ ...lorry, img75: false });
      } else {
        setLorry({ ...lorry, img75: true });
      }
    } else if (a === "img76") {
      if (lorry.img76) {
        setLorry({ ...lorry, img76: false });
      } else {
        setLorry({ ...lorry, img76: true });
      }
    } else if (a === "img77") {
      if (lorry.img77) {
        setLorry({ ...lorry, img77: false });
      } else {
        setLorry({ ...lorry, img77: true });
      }
    } else if (a === "img78") {
      if (lorry.img78) {
        setLorry({ ...lorry, img78: false });
      } else {
        setLorry({ ...lorry, img78: true });
      }
    } else if (a === "img79") {
      if (lorry.img79) {
        setLorry({ ...lorry, img79: false });
      } else {
        setLorry({ ...lorry, img79: true });
      }
    } else if (a === "img80") {
      if (lorry.img80) {
        setLorry({ ...lorry, img80: false });
      } else {
        setLorry({ ...lorry, img80: true });
      }
    } else if (a === "img81") {
      if (lorry.img81) {
        setLorry({ ...lorry, img81: false });
      } else {
        setLorry({ ...lorry, img81: true });
      }
    } else if (a === "img82") {
      if (lorry.img82) {
        setLorry({ ...lorry, img82: false });
      } else {
        setLorry({ ...lorry, img82: true });
      }
    } else if (a === "img83") {
      if (lorry.img83) {
        setLorry({ ...lorry, img83: false });
      } else {
        setLorry({ ...lorry, img83: true });
      }
    } else if (a === "img84") {
      if (lorry.img84) {
        setLorry({ ...lorry, img84: false });
      } else {
        setLorry({ ...lorry, img84: true });
      }
    } else if (a === "img85") {
      if (lorry.img85) {
        setLorry({ ...lorry, img85: false });
      } else {
        setLorry({ ...lorry, img85: true });
      }
    } else if (a === "img86") {
      if (lorry.img86) {
        setLorry({ ...lorry, img86: false });
      } else {
        setLorry({ ...lorry, img86: true });
      }
    } else if (a === "img87") {
      if (lorry.img87) {
        setLorry({ ...lorry, img87: false });
      } else {
        setLorry({ ...lorry, img87: true });
      }
    } else if (a === "img88") {
      if (lorry.img88) {
        setLorry({ ...lorry, img88: false });
      } else {
        setLorry({ ...lorry, img88: true });
      }
    } else if (a === "img89") {
      if (lorry.img89) {
        setLorry({ ...lorry, img89: false });
      } else {
        setLorry({ ...lorry, img89: true });
      }
    } else if (a === "img90") {
      if (lorry.img90) {
        setLorry({ ...lorry, img90: false });
      } else {
        setLorry({ ...lorry, img90: true });
      }
    } else if (a === "img91") {
      if (lorry.img91) {
        setLorry({ ...lorry, img91: false });
      } else {
        setLorry({ ...lorry, img91: true });
      }
    } else if (a === "img92") {
      if (lorry.img92) {
        setLorry({ ...lorry, img92: false });
      } else {
        setLorry({ ...lorry, img92: true });
      }
    } else if (a === "img93") {
      if (lorry.img93) {
        setLorry({ ...lorry, img93: false });
      } else {
        setLorry({ ...lorry, img93: true });
      }
    } else if (a === "img94") {
      if (lorry.img94) {
        setLorry({ ...lorry, img94: false });
      } else {
        setLorry({ ...lorry, img94: true });
      }
    } else if (a === "img95") {
      if (lorry.img95) {
        setLorry({ ...lorry, img95: false });
      } else {
        setLorry({ ...lorry, img95: true });
      }
    } else if (a === "img96") {
      if (lorry.img96) {
        setLorry({ ...lorry, img96: false });
      } else {
        setLorry({ ...lorry, img96: true });
      }
    } else if (a === "img97") {
      if (lorry.img97) {
        setLorry({ ...lorry, img97: false });
      } else {
        setLorry({ ...lorry, img97: true });
      }
    } else if (a === "img98") {
      if (lorry.img98) {
        setLorry({ ...lorry, img98: false });
      } else {
        setLorry({ ...lorry, img98: true });
      }
    } else if (a === "img99") {
      if (lorry.img99) {
        setLorry({ ...lorry, img99: false });
      } else {
        setLorry({ ...lorry, img99: true });
      }
    } else if (a === "img100") {
      if (lorry.img100) {
        setLorry({ ...lorry, img100: false });
      } else {
        setLorry({ ...lorry, img100: true });
      }
    } else if (a === "img101") {
      if (lorry.img101) {
        setLorry({ ...lorry, img101: false });
      } else {
        setLorry({ ...lorry, img101: true });
      }
    } else if (a === "img102") {
      if (lorry.img102) {
        setLorry({ ...lorry, img102: false });
      } else {
        setLorry({ ...lorry, img102: true });
      }
    } else if (a === "img103") {
      if (lorry.img103) {
        setLorry({ ...lorry, img103: false });
      } else {
        setLorry({ ...lorry, img103: true });
      }
    } else if (a === "img104") {
      if (lorry.img104) {
        setLorry({ ...lorry, img104: false });
      } else {
        setLorry({ ...lorry, img104: true });
      }
    } else if (a === "img105") {
      if (lorry.img105) {
        setLorry({ ...lorry, img105: false });
      } else {
        setLorry({ ...lorry, img105: true });
      }
    } else if (a === "img106") {
      if (lorry.img106) {
        setLorry({ ...lorry, img106: false });
      } else {
        setLorry({ ...lorry, img106: true });
      }
    } else if (a === "img107") {
      if (lorry.img107) {
        setLorry({ ...lorry, img107: false });
      } else {
        setLorry({ ...lorry, img107: true });
      }
    } else if (a === "img108") {
      if (lorry.img108) {
        setLorry({ ...lorry, img108: false });
      } else {
        setLorry({ ...lorry, img108: true });
      }
    } else if (a === "img109") {
      if (lorry.img109) {
        setLorry({ ...lorry, img109: false });
      } else {
        setLorry({ ...lorry, img109: true });
      }
    } else if (a === "img110") {
      if (lorry.img110) {
        setLorry({ ...lorry, img110: false });
      } else {
        setLorry({ ...lorry, img110: true });
      }
    } else if (a === "img111") {
      if (lorry.img111) {
        setLorry({ ...lorry, img111: false });
      } else {
        setLorry({ ...lorry, img111: true });
      }
    } else if (a === "img112") {
      if (lorry.img112) {
        setLorry({ ...lorry, img112: false });
      } else {
        setLorry({ ...lorry, img112: true });
      }
    } else if (a === "img113") {
      if (lorry.img113) {
        setLorry({ ...lorry, img113: false });
      } else {
        setLorry({ ...lorry, img113: true });
      }
    } else if (a === "img114") {
      if (lorry.img114) {
        setLorry({ ...lorry, img114: false });
      } else {
        setLorry({ ...lorry, img114: true });
      }
    } else if (a === "img115") {
      if (lorry.img115) {
        setLorry({ ...lorry, img115: false });
      } else {
        setLorry({ ...lorry, img115: true });
      }
    } else if (a === "img116") {
      if (lorry.img116) {
        setLorry({ ...lorry, img116: false });
      } else {
        setLorry({ ...lorry, img116: true });
      }
    } else if (a === "img117") {
      if (lorry.img117) {
        setLorry({ ...lorry, img117: false });
      } else {
        setLorry({ ...lorry, img117: true });
      }
    } else if (a === "img118") {
      if (lorry.img118) {
        setLorry({ ...lorry, img118: false });
      } else {
        setLorry({ ...lorry, img118: true });
      }
    } else if (a === "img119") {
      if (lorry.img119) {
        setLorry({ ...lorry, img119: false });
      } else {
        setLorry({ ...lorry, img119: true });
      }
    } else if (a === "img120") {
      if (lorry.img120) {
        setLorry({ ...lorry, img120: false });
      } else {
        setLorry({ ...lorry, img120: true });
      }
    } else if (a === "img121") {
      if (lorry.img121) {
        setLorry({ ...lorry, img121: false });
      } else {
        setLorry({ ...lorry, img121: true });
      }
    } else if (a === "img122") {
      if (lorry.img122) {
        setLorry({ ...lorry, img122: false });
      } else {
        setLorry({ ...lorry, img122: true });
      }
    } else if (a === "img123") {
      if (lorry.img123) {
        setLorry({ ...lorry, img123: false });
      } else {
        setLorry({ ...lorry, img123: true });
      }
    } else if (a === "img124") {
      if (lorry.img124) {
        setLorry({ ...lorry, img124: false });
      } else {
        setLorry({ ...lorry, img124: true });
      }
    } else if (a === "img125") {
      if (lorry.img125) {
        setLorry({ ...lorry, img125: false });
      } else {
        setLorry({ ...lorry, img125: true });
      }
    } else if (a === "img126") {
      if (lorry.img126) {
        setLorry({ ...lorry, img126: false });
      } else {
        setLorry({ ...lorry, img126: true });
      }
    } else if (a === "img127") {
      if (lorry.img127) {
        setLorry({ ...lorry, img127: false });
      } else {
        setLorry({ ...lorry, img127: true });
      }
    } else if (a === "img128") {
      if (lorry.img128) {
        setLorry({ ...lorry, img128: false });
      } else {
        setLorry({ ...lorry, img128: true });
      }
    } else if (a === "img129") {
      if (lorry.img129) {
        setLorry({ ...lorry, img129: false });
      } else {
        setLorry({ ...lorry, img129: true });
      }
    } else if (a === "img130") {
      if (lorry.img130) {
        setLorry({ ...lorry, img130: false });
      } else {
        setLorry({ ...lorry, img130: true });
      }
    } else if (a === "img131") {
      if (lorry.img131) {
        setLorry({ ...lorry, img131: false });
      } else {
        setLorry({ ...lorry, img131: true });
      }
    } else if (a === "img132") {
      if (lorry.img132) {
        setLorry({ ...lorry, img132: false });
      } else {
        setLorry({ ...lorry, img132: true });
      }
    } else if (a === "img133") {
      if (lorry.img133) {
        setLorry({ ...lorry, img133: false });
      } else {
        setLorry({ ...lorry, img133: true });
      }
    } else if (a === "img134") {
      if (lorry.img134) {
        setLorry({ ...lorry, img134: false });
      } else {
        setLorry({ ...lorry, img134: true });
      }
    } else if (a === "img135") {
      if (lorry.img135) {
        setLorry({ ...lorry, img135: false });
      } else {
        setLorry({ ...lorry, img135: true });
      }
    }
  };

  return (
    <div className="w-full h-full">
      <HeaderScaff />
      <div
        className={`${
          sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
        } 'w-full flex h-auto p-2 sm:p-6'`}
      >
        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "basis-1/5"
                : "flex justify-center w-full"
              : "hidden"
          } "w-full h-full"`}
        >
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            needed={needed}
            isNonMobile={isNonMobile}
          />
        </div>

        <div
          onClick={() => setSidebarOpen(true)}
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "hidden"
                : "hidden"
              : isNonMobile
              ? "flex w-12 mt-4"
              : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
          } h-fit`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? ""
                  : ""
                : isNonMobile
                ? ""
                : "hidden"
            } `}
          >
            <IconButton onClick={() => setSidebarOpen(true)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "hidden"
                : "flex"
            } `}
          >
            <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
          </div>
        </div>

        <div
          className={`${
            sidebarOpen
              ? isNonMobile
                ? "lg:basis-3/4 w-full flex flex-col"
                : "hidden"
              : "w-full flex flex-col"
          } " "`}
        >
          <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4">
            Vehicle Check
          </p>

          <form className="lg:flex lg:flex-row flex-col">
            <div className="lg:basis-1/2 w-full">
              <div className="p-2 flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Select The Registration
                </p>
                <select
                  className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  onChange={(e) =>
                    setData({
                      ...data,
                      registration: escapeHtml(e.target.value),
                    })
                  }
                >
                  <option value="">Select Vehicle</option>
                  {newVechicle.map((item) => (
                    <option value={item._id}>{item.registration}</option>
                  ))}
                </select>
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Date of Check
                </p>
                <input
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  value={data.dateOfCheck}
                  onChange={(e) => setDateOfCheck(e)}
                  type="date"
                />
              </div>
            </div>
              
            <div className="basis-1/2 flex-col flex  justify-between ">
              <div className="p-2 flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Name of Checker
                </p>
                <input
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  value={data.nameOfChecker}
                  onChange={(e) =>
                    setData({
                      ...data,
                      nameOfChecker: escapeHtml(e.target.value),
                    })
                  }
                  type="text"
                  required
                />

                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Upload PMI lorry inspection
                </p>
                <div className="flex w-full space-x-2 pl-8">
                  <label
                    htmlFor="file-upload"
                    className="h-8 rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
                  >
                    <input
                      className="absolute -ml-5 opacity-0"
                      onChange={(e) => setPMILorry(e.target.files[0])}
                      style={{ width: "90px", cursor: "pointer" }}
                      id="file-upload"
                      type="file"
                      required
                    />
                    Upload
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className=" flex flex-col content-between py-8 justify-between w-full">
            <p className="font-semibold text-xs xxl:text-xl">
              The following vehicle checks are to be undertaken on a weekly
              basis for use of the first working day.
            </p>
            <div className="flex flex-col sm:flex-row pb-3">
              <p className="font-semibold text-xs xxl:text-xl pt-3">
                Please mark with a :
              </p>
              <p className="font-semibold text-xs xxl:text-xl xxl:pt-3">
                <span className="text-xl px-1">✓</span> for satisfactory
              </p>
              <p className="font-semibold text-xs xxl:text-xl xxl:pt-3">
                <span className="text-xl sm:ml-8 px-1">X</span> for detective or
                missing
              </p>
              <p className="font-semibold text-xs xxl:text-xl pb-3  xxl:pt-3">
                <span className="text-xl sm:ml-8 px-1">N/A</span> for not
                applicable
              </p>
            </div>
            <div className="lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 scrollbar-hidden">
              <table className="editor_listing_table hidden sm:table">
                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white">
                      Fluid
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white ">
                      Light/Electric
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap">
                      External Condition
                    </th>
                    <th className="text-start px-2 text-sm xxl:text-base text-white font-normal rounded-r-lg ">
                      Internal Condition
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Engine Oil
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.engineOil, "engineOil")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.engineOil === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.engineOil === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Indicators
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.indicator, "indicator")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.indicator === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.indicator === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Door wings mirrors
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.doorWingsMirror,
                              "doorWingsMirror"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.doorWingsMirror === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.doorWingsMirror === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Seat belts
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.seatBelts, "seatBelts")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.seatBelts === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.seatBelts === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Brakes
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.brakes, "brakes")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.brakes === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.brakes === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Side Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.sideLights, "sideLights")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.sideLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.sideLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Wiper Blades
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.wiperBlades,
                              "wiperBlades"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.wiperBlades === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.wiperBlades === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          First Aid Kit
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.firstAidKits,
                              "firstAidKits"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.firstAidKits === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.firstAidKits === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Clutch
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.clutches, "clutches")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.clutches === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.clutches === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Head Lights (Dimmed)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headLightsDimmed,
                              "headLightsDimmed"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headLightsDimmed === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headLightsDimmed === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Screen Washer
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.screenWasher,
                              "screenWasher"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.screenWasher === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.screenWasher === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Fire Extinguisher
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.fireExtinguisher,
                              "fireExtinguisher"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.fireExtinguisher === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.fireExtinguisher === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Power Steering
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.powerSteering,
                              "powerSteering"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.powerSteering === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.powerSteering === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Head Lights (Main)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headLightsMain,
                              "headLightsMain"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headLightsMain === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headLightsMain === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre Pressure
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.tyrePressure,
                              "tyrePressure"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyrePressure === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyrePressure === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Head Restraint Adjustment
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headRestraintAdjustment,
                              "headRestraintAdjustment"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headRestraintAdjustment === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headRestraintAdjustment === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Auto Transmission
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.autoTransmission,
                              "autoTransmission"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.autoTransmission === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.autoTransmission === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Number Plates
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.numberPlate,
                              "numberPlate"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.numberPlate === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.numberPlate === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre wear
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.tyreWear, "tyreWear")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyreWear === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyreWear === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Torch
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.torch, "torch")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.torch === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.torch === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Screen Wash
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.screenWash, "screenWash")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.screenWash === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.screenWash === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Reversing Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.reversingLights,
                              "reversingLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.reversingLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.reversingLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre Damage
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.tyreDamage, "tyreDamage")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyreDamage === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyreDamage === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Warning Triangle
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.warningTriangle,
                              "warningTriangle"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.warningTriangle === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.warningTriangle === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Fuel (min 1/4 full)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.fuel1by4Full,
                              "fuel1by4Full"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.fuel1by4Full === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.fuel1by4Full === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Warning Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.warningLights,
                              "warningLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.warningLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.warningLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Spare Wheel
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.spareWheel, "spareWheel")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.spareWheel === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.spareWheel === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          General Bodywork
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.generalBodyWork,
                              "generalBodyWork"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.generalBodyWork === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.generalBodyWork === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Coolent
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.coolent, "coolent")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.coolent === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.coolent === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Horn
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.horn, "horn")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.horn === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.horn === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 ">
                          Clean Number Plates,windows,lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.cleanNumberPlateAndWindowLights,
                              "cleanNumberPlateAndWindowLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.cleanNumberPlateAndWindowLights ===
                            0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.cleanNumberPlateAndWindowLights ===
                              1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Road Fund license
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.roadFuelLicense,
                              "roadFuelLicense"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.roadFuelLicense === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.roadFuelLicense === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 ">
                          ad blue (if applicable)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.adBlue, "adBlue")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.adBlue === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.adBlue === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Widescreen wiper
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.wideScreenWiper,
                              "wideScreenWiper"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.wideScreenWiper === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.wideScreenWiper === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2">
                          Secure roof rack, tail lift, winch etc{" "}
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.secureRoofRack,
                              "secureRoofRack"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.secureRoofRack === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.secureRoofRack === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2">
                          A copy of motor incident report form
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.copyOfMotor,
                              "copyOfMotor"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.copyOfMotor === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.copyOfMotor === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          x
                        </p>
                        <div className="border-4 border-gray-200 w-1/4 flex justify-center">
                          {/* <p className=' font-semibold text-xs xxl:text-xl px-2 mt-1'>{vechicleCheck.engineOil===0?(<span>N/A</span>):vechicleCheck.engineOil===1?(<span>✓</span>):(<span>X</span>)}</p> */}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Battery
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.battery, "battery")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.battery === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.battery === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Door Locking
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.doorLocking,
                              "doorLocking"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.doorLocking === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.doorLocking === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Engine Oil
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.engineOil, "engineOil")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.engineOil === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.engineOil === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="editor_listing_table sm:hidden">
                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-md font-normal border-[1px] border-r-white">
                      Fluid
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Engine Oil
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.engineOil, "engineOil")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.engineOil === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.engineOil === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Brakes
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.brakes, "brakes")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.brakes === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.brakes === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Clutch
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.clutches, "clutches")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.clutches === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.clutches === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Power Steering
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.powerSteering,
                              "powerSteering"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.powerSteering === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.powerSteering === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Auto Transmission
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.autoTransmission,
                              "autoTransmission"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.autoTransmission === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.autoTransmission === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Screen Wash
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.screenWash, "screenWash")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.screenWash === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.screenWash === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Fuel (min 1/4 full)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.fuel1by4Full,
                              "fuel1by4Full"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.fuel1by4Full === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.fuel1by4Full === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Coolent
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.coolent, "coolent")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.coolent === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.coolent === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          ad blue (if applicable)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.adBlue, "adBlue")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.adBlue === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.adBlue === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          x
                        </p>
                        <div className="border-4 border-gray-200 w-1/4 flex justify-center">
                          {/* <p className=' font-semibold text-xs xxl:text-xl px-2 mt-1'>{vechicleCheck.engineOil===0?(<span>N/A</span>):vechicleCheck.engineOil===1?(<span>✓</span>):(<span>X</span>)}</p> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="text-start px-2 py-1 text-sm xxl:text-base rounded-md text-white font-normal border-[1px] border-r-white ">
                      Light/Electric
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Indicators
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.indicator, "indicator")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.indicator === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.indicator === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Side Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.sideLights, "sideLights")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.sideLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.sideLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 ">
                          Head Lights (Dimmed)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headLightsDimmed,
                              "headLightsDimmed"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headLightsDimmed === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headLightsDimmed === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Head Lights (Main)
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headLightsMain,
                              "headLightsMain"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headLightsMain === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headLightsMain === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Number Plates
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.numberPlate,
                              "numberPlate"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.numberPlate === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.numberPlate === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Reversing Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.reversingLights,
                              "reversingLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.reversingLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.reversingLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Warning Lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.warningLights,
                              "warningLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.warningLights === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.warningLights === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Horn
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.horn, "horn")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.horn === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.horn === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Widescreen wiper
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.wideScreenWiper,
                              "wideScreenWiper"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.wideScreenWiper === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.wideScreenWiper === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Battery
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.battery, "battery")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.battery === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.battery === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="text-start px-2 py-1 text-sm xxl:text-base rounded-md text-white font-normal border-[1px] border-r-white whitespace-nowrap">
                      External Condition
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Door wings mirrors
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.doorWingsMirror,
                              "doorWingsMirror"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.doorWingsMirror === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.doorWingsMirror === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Wiper Blades
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.wiperBlades,
                              "wiperBlades"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.wiperBlades === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.wiperBlades === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Screen Washer
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.screenWasher,
                              "screenWasher"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.screenWasher === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.screenWasher === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre Pressure
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.tyrePressure,
                              "tyrePressure"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyrePressure === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyrePressure === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre wear
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.tyreWear, "tyreWear")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyreWear === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyreWear === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Tyre Damage
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.tyreDamage, "tyreDamage")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.tyreDamage === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.tyreDamage === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Spare Wheel
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.spareWheel, "spareWheel")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.spareWheel === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.spareWheel === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2">
                          Clean Number Plates, windows, lights
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.cleanNumberPlateAndWindowLights,
                              "cleanNumberPlateAndWindowLights"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.cleanNumberPlateAndWindowLights ===
                            0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.cleanNumberPlateAndWindowLights ===
                              1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Secure roof rack, tail lift, winch etc{" "}
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.secureRoofRack,
                              "secureRoofRack"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.secureRoofRack === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.secureRoofRack === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Door Locking
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.doorLocking,
                              "doorLocking"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.doorLocking === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.doorLocking === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <thead className="w-full">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="text-start px-2 text-sm xxl:text-base rounded-md text-white font-normal rounded-r-lg ">
                      Internal Condition
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Seat belts
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.seatBelts, "seatBelts")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.seatBelts === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.seatBelts === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          First Aid Kit
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.firstAidKits,
                              "firstAidKits"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.firstAidKits === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.firstAidKits === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Fire Extinguisher
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.fireExtinguisher,
                              "fireExtinguisher"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.fireExtinguisher === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.fireExtinguisher === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Head Restraint Adjustment
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.headRestraintAdjustment,
                              "headRestraintAdjustment"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.headRestraintAdjustment === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.headRestraintAdjustment === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Torch
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.torch, "torch")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.torch === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.torch === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Warning Triangle
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.warningTriangle,
                              "warningTriangle"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.warningTriangle === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.warningTriangle === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          General Bodywork
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.generalBodyWork,
                              "generalBodyWork"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.generalBodyWork === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.generalBodyWork === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Road Fund license
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.roadFuelLicense,
                              "roadFuelLicense"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.roadFuelLicense === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.roadFuelLicense === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          A copy of motor incident report form
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(
                              vechicleCheck.copyOfMotor,
                              "copyOfMotor"
                            )
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.copyOfMotor === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.copyOfMotor === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flex w-full my-1 px-2">
                        <p className="border-4 border-gray-200 w-full sm:w-3/4 pl-1 text-xs xxl:text-xl font-medium py-2 whitespace-nowrap">
                          Engine Oil
                        </p>
                        <div
                          className="border-4 border-gray-200 w-1/4 flex justify-center"
                          onClick={() =>
                            handleStatus(vechicleCheck.engineOil, "engineOil")
                          }
                        >
                          <p className=" font-semibold text-xs xxl:text-xl px-2 mt-1">
                            {vechicleCheck.engineOil === 0 ? (
                              <span>N/A</span>
                            ) : vechicleCheck.engineOil === 1 ? (
                              <span>✓</span>
                            ) : (
                              <span>X</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-1/4 mb-3 ml-2">
            <select
                    className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full"
                    onChange={(e) =>
                      setData({
                        ...data,
                        typeOfVehicle: escapeHtml(e.target.value),
                      })
                    }
                  >
                    <option value="0">Van</option>
                    <option value="1" selected>Lorry</option>
                    
             </select>
            </div>
         {data.typeOfVehicle==="0" ? (<div className="flex justify-center">
            <div className="p-1 w-fit block -space-y-[2px] ">
              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im1")}
                  className={
                    van.im1
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im1}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im2")}
                  className={
                    van.im2
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im2}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im3")}
                  className={
                    van.im3
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im3}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im4")}
                  className={
                    van.im4
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im4}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im5")}
                  className={
                    van.im5
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im5}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im6")}
                  className={
                    van.im6
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im6}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im7")}
                  className={
                    van.im7
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im7}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im8")}
                  className={
                    van.im8
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im8}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im9")}
                  className={
                    van.im9
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im9}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im10")}
                  className={
                    van.im10
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im10}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im11")}
                  className={
                    van.im11
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im11}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im12")}
                  className={
                    van.im12
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im12}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im13")}
                  className={
                    van.im13
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im13}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im14")}
                  className={
                    van.im14
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im14}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im15")}
                  className={
                    van.im15
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im15}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im16")}
                  className={
                    van.im16
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im16}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im17")}
                  className={
                    van.im17
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im17}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im18")}
                  className={
                    van.im18
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im18}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im19")}
                  className={
                    van.im19
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im19}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im20")}
                  className={
                    van.im20
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im20}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im21")}
                  className={
                    van.im21
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im21}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im22")}
                  className={
                    van.im22
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im22}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im23")}
                  className={
                    van.im23
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im23}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im24")}
                  className={
                    van.im24
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im24}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im25")}
                  className={
                    van.im25
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im25}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im26")}
                  className={
                    van.im26
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im26}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im27")}
                  className={
                    van.im27
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im27}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im28")}
                  className={
                    van.im28
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im28}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im29")}
                  className={
                    van.im29
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im29}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im30")}
                  className={
                    van.im30
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im30}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im31")}
                  className={
                    van.im31
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im31}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im32")}
                  className={
                    van.im32
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im32}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im33")}
                  className={
                    van.im33
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im33}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im34")}
                  className={
                    van.im34
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im34}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im35")}
                  className={
                    van.im35
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im35}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im36")}
                  className={
                    van.im36
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im36}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im37")}
                  className={
                    van.im37
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im37}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im38")}
                  className={
                    van.im38
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im38}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im39")}
                  className={
                    van.im39
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im39}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im40")}
                  className={
                    van.im40
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im40}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im41")}
                  className={
                    van.im41
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im41}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im42")}
                  className={
                    van.im42
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im42}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im43")}
                  className={
                    van.im43
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im43}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im44")}
                  className={
                    van.im44
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im44}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im45")}
                  className={
                    van.im45
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im45}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im46")}
                  className={
                    van.im46
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im46}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im47")}
                  className={
                    van.im47
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im47}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im48")}
                  className={
                    van.im48
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im48}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im49")}
                  className={
                    van.im49
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im49}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im50")}
                  className={
                    van.im50
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im50}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im51")}
                  className={
                    van.im51
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im51}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im52")}
                  className={
                    van.im52
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im52}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im53")}
                  className={
                    van.im53
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im53}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im54")}
                  className={
                    van.im54
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im54}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im55")}
                  className={
                    van.im55
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im55}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im56")}
                  className={
                    van.im56
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im56}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im57")}
                  className={
                    van.im57
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im57}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im58")}
                  className={
                    van.im58
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im58}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im59")}
                  className={
                    van.im59
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im59}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im60")}
                  className={
                    van.im60
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im60}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im61")}
                  className={
                    van.im61
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im61}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im62")}
                  className={
                    van.im62
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im62}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im63")}
                  className={
                    van.im63
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im63}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im64")}
                  className={
                    van.im64
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im64}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im65")}
                  className={
                    van.im65
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im65}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im66")}
                  className={
                    van.im66
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im66}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im67")}
                  className={
                    van.im67
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im67}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im68")}
                  className={
                    van.im68
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im68}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im69")}
                  className={
                    van.im69
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im69}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im70")}
                  className={
                    van.im70
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im70}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im71")}
                  className={
                    van.im71
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im71}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im72")}
                  className={
                    van.im72
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im72}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im73")}
                  className={
                    van.im73
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im73}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im74")}
                  className={
                    van.im74
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im74}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im75")}
                  className={
                    van.im75
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im75}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im76")}
                  className={
                    van.im76
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im76}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im77")}
                  className={
                    van.im77
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im77}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im78")}
                  className={
                    van.im78
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im78}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im79")}
                  className={
                    van.im79
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im79}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im80")}
                  className={
                    van.im80
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im80}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im81")}
                  className={
                    van.im81
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im81}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im82")}
                  className={
                    van.im82
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im82}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im83")}
                  className={
                    van.im83
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im83}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im84")}
                  className={
                    van.im84
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im84}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im85")}
                  className={
                    van.im85
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im85}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im86")}
                  className={
                    van.im86
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im86}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im87")}
                  className={
                    van.im87
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im87}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im88")}
                  className={
                    van.im88
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im88}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im89")}
                  className={
                    van.im89
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im89}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im90")}
                  className={
                    van.im90
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im90}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im91")}
                  className={
                    van.im91
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im91}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im92")}
                  className={
                    van.im92
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im92}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im93")}
                  className={
                    van.im93
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im93}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im94")}
                  className={
                    van.im94
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im94}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im95")}
                  className={
                    van.im95
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im95}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im96")}
                  className={
                    van.im96
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im96}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im97")}
                  className={
                    van.im97
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im97}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im98")}
                  className={
                    van.im98
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im98}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im99")}
                  className={
                    van.im99
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im99}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im100")}
                  className={
                    van.im100
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im100}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im101")}
                  className={
                    van.im101
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im101}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im102")}
                  className={
                    van.im102
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im102}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im103")}
                  className={
                    van.im103
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im103}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im104")}
                  className={
                    van.im104
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im104}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im105")}
                  className={
                    van.im105
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im105}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im106")}
                  className={
                    van.im106
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im106}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im107")}
                  className={
                    van.im107
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im107}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im108")}
                  className={
                    van.im108
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im108}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im109")}
                  className={
                    van.im109
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im109}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im110")}
                  className={
                    van.im110
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im110}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im111")}
                  className={
                    van.im111
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im111}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im112")}
                  className={
                    van.im112
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im112}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im113")}
                  className={
                    van.im113
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im113}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im114")}
                  className={
                    van.im114
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im114}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im115")}
                  className={
                    van.im115
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im115}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im116")}
                  className={
                    van.im116
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im116}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im117")}
                  className={
                    van.im117
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im117}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im118")}
                  className={
                    van.im118
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im118}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im119")}
                  className={
                    van.im119
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im119}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im120")}
                  className={
                    van.im120
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im120}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im121")}
                  className={
                    van.im121
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im121}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im122")}
                  className={
                    van.im122
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im122}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im123")}
                  className={
                    van.im123
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im123}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im124")}
                  className={
                    van.im124
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im124}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im125")}
                  className={
                    van.im125
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im125}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im126")}
                  className={
                    van.im126
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im126}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im127")}
                  className={
                    van.im127
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im127}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im128")}
                  className={
                    van.im128
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im128}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im129")}
                  className={
                    van.im129
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im129}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im130")}
                  className={
                    van.im130
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im130}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im131")}
                  className={
                    van.im131
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im131}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im132")}
                  className={
                    van.im132
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im132}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im133")}
                  className={
                    van.im133
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im133}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im134")}
                  className={
                    van.im134
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im134}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im135")}
                  className={
                    van.im135
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im135}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im136")}
                  className={
                    van.im136
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im136}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im137")}
                  className={
                    van.im137
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im137}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im138")}
                  className={
                    van.im138
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im138}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im139")}
                  className={
                    van.im139
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im139}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im140")}
                  className={
                    van.im140
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im140}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im141")}
                  className={
                    van.im141
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im141}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im142")}
                  className={
                    van.im142
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im142}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im143")}
                  className={
                    van.im143
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im143}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im144")}
                  className={
                    van.im144
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im144}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im145")}
                  className={
                    van.im145
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im145}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im146")}
                  className={
                    van.im146
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im146}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im147")}
                  className={
                    van.im147
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im147}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im148")}
                  className={
                    van.im148
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im148}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im149")}
                  className={
                    van.im149
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im149}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im150")}
                  className={
                    van.im150
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im150}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im151")}
                  className={
                    van.im151
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im151}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im152")}
                  className={
                    van.im152
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im152}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im153")}
                  className={
                    van.im153
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im153}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im154")}
                  className={
                    van.im154
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im154}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im155")}
                  className={
                    van.im155
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im155}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im156")}
                  className={
                    van.im156
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im156}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im157")}
                  className={
                    van.im157
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im157}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im158")}
                  className={
                    van.im158
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im158}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im159")}
                  className={
                    van.im159
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im159}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im160")}
                  className={
                    van.im160
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im160}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im161")}
                  className={
                    van.im161
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im161}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im162")}
                  className={
                    van.im162
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im162}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im163")}
                  className={
                    van.im163
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im163}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im164")}
                  className={
                    van.im164
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im164}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im165")}
                  className={
                    van.im165
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im165}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im166")}
                  className={
                    van.im166
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im166}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im167")}
                  className={
                    van.im167
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im167}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im168")}
                  className={
                    van.im168
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im168}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im169")}
                  className={
                    van.im169
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im169}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im170")}
                  className={
                    van.im170
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im170}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im171")}
                  className={
                    van.im171
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im171}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im172")}
                  className={
                    van.im172
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im172}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im173")}
                  className={
                    van.im173
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im173}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im174")}
                  className={
                    van.im174
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im174}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im175")}
                  className={
                    van.im175
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im175}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im176")}
                  className={
                    van.im176
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im176}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im177")}
                  className={
                    van.im177
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im177}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im178")}
                  className={
                    van.im178
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im178}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im179")}
                  className={
                    van.im179
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im179}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im180")}
                  className={
                    van.im180
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im180}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im181")}
                  className={
                    van.im181
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im181}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im182")}
                  className={
                    van.im182
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im182}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im183")}
                  className={
                    van.im183
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im183}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im184")}
                  className={
                    van.im184
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im184}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im185")}
                  className={
                    van.im185
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im185}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im186")}
                  className={
                    van.im186
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im186}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im187")}
                  className={
                    van.im187
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im187}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im188")}
                  className={
                    van.im188
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im188}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im189")}
                  className={
                    van.im189
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im189}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im190")}
                  className={
                    van.im190
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im190}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im191")}
                  className={
                    van.im191
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im191}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im192")}
                  className={
                    van.im192
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im192}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im193")}
                  className={
                    van.im193
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im193}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im194")}
                  className={
                    van.im194
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im194}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im195")}
                  className={
                    van.im195
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im195}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im196")}
                  className={
                    van.im196
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im196}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im197")}
                  className={
                    van.im197
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im197}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im198")}
                  className={
                    van.im198
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im198}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im199")}
                  className={
                    van.im199
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im199}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im200")}
                  className={
                    van.im200
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im200}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im201")}
                  className={
                    van.im201
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im201}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im202")}
                  className={
                    van.im202
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im202}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im203")}
                  className={
                    van.im203
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im203}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im204")}
                  className={
                    van.im204
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im204}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im205")}
                  className={
                    van.im205
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im205}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im206")}
                  className={
                    van.im206
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im206}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im207")}
                  className={
                    van.im207
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im207}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im208")}
                  className={
                    van.im208
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im208}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im209")}
                  className={
                    van.im209
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im209}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im210")}
                  className={
                    van.im210
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im210}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im211")}
                  className={
                    van.im211
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im211}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im212")}
                  className={
                    van.im212
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im212}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im213")}
                  className={
                    van.im213
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im213}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im214")}
                  className={
                    van.im214
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im214}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im215")}
                  className={
                    van.im215
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im215}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im216")}
                  className={
                    van.im216
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im216}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im217")}
                  className={
                    van.im217
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im217}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im218")}
                  className={
                    van.im218
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im218}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im219")}
                  className={
                    van.im219
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im219}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im220")}
                  className={
                    van.im220
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im220}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im221")}
                  className={
                    van.im221
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im221}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im222")}
                  className={
                    van.im222
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im222}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im223")}
                  className={
                    van.im223
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im223}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im224")}
                  className={
                    van.im224
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im224}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im225")}
                  className={
                    van.im225
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im225}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im226")}
                  className={
                    van.im226
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im226}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im227")}
                  className={
                    van.im227
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im227}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im228")}
                  className={
                    van.im228
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im228}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im229")}
                  className={
                    van.im229
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im229}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im230")}
                  className={
                    van.im230
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im230}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im231")}
                  className={
                    van.im231
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im231}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im232")}
                  className={
                    van.im232
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im232}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im233")}
                  className={
                    van.im233
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im233}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im234")}
                  className={
                    van.im234
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im234}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im235")}
                  className={
                    van.im235
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im235}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im236")}
                  className={
                    van.im236
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im236}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im237")}
                  className={
                    van.im237
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im237}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im238")}
                  className={
                    van.im238
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im238}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im239")}
                  className={
                    van.im239
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im239}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im240")}
                  className={
                    van.im240
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im240}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im241")}
                  className={
                    van.im241
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im241}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im242")}
                  className={
                    van.im242
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im242}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im243")}
                  className={
                    van.im243
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im243}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im244")}
                  className={
                    van.im244
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im244}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im245")}
                  className={
                    van.im245
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im245}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im246")}
                  className={
                    van.im246
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im246}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im247")}
                  className={
                    van.im247
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im247}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im248")}
                  className={
                    van.im248
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im248}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im249")}
                  className={
                    van.im249
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im249}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im250")}
                  className={
                    van.im250
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im250}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im251")}
                  className={
                    van.im251
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im251}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im252")}
                  className={
                    van.im252
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im252}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im253")}
                  className={
                    van.im253
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im253}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im254")}
                  className={
                    van.im254
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im254}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im255")}
                  className={
                    van.im255
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im255}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im256")}
                  className={
                    van.im256
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im256}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im257")}
                  className={
                    van.im257
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im257}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im258")}
                  className={
                    van.im258
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im258}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im259")}
                  className={
                    van.im259
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im259}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im260")}
                  className={
                    van.im260
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im260}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im261")}
                  className={
                    van.im261
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im261}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im262")}
                  className={
                    van.im262
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im262}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im263")}
                  className={
                    van.im263
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im263}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im264")}
                  className={
                    van.im264
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im264}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im265")}
                  className={
                    van.im265
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im265}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im266")}
                  className={
                    van.im266
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im266}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im267")}
                  className={
                    van.im267
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im267}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im268")}
                  className={
                    van.im268
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im268}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im269")}
                  className={
                    van.im269
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im269}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im270")}
                  className={
                    van.im270
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im270}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleVanStatus("im271")}
                  className={
                    van.im271
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im271}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im272")}
                  className={
                    van.im272
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im272}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im273")}
                  className={
                    van.im273
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im273}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im274")}
                  className={
                    van.im274
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im274}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im275")}
                  className={
                    van.im275
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im275}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im276")}
                  className={
                    van.im276
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im276}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im277")}
                  className={
                    van.im277
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im277}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im278")}
                  className={
                    van.im278
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im278}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im279")}
                  className={
                    van.im279
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im279}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im280")}
                  className={
                    van.im280
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im280}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im281")}
                  className={
                    van.im281
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im281}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im282")}
                  className={
                    van.im282
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im282}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im283")}
                  className={
                    van.im283
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im283}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im284")}
                  className={
                    van.im284
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im284}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleVanStatus("im285")}
                  className={
                    van.im285
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={im285}
                  alt="abc"
                />
              </div>
            </div>
          </div>):(<div className="flex justify-center">
            <div className="p-1 w-fit block -space-y-[2px] ">
              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img1")}
                  className={
                    lorry.img1
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img1}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img2")}
                  className={
                    lorry.img2
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img2}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img3")}
                  className={
                    lorry.img3
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img3}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img4")}
                  className={
                    lorry.img4
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img4}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img5")}
                  className={
                    lorry.img5
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img5}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img6")}
                  className={
                    lorry.img6
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img6}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img7")}
                  className={
                    lorry.img7
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img7}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img8")}
                  className={
                    lorry.img8
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img8}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img9")}
                  className={
                    lorry.img9
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img9}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img10")}
                  className={
                    lorry.img10
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img10}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img11")}
                  className={
                    lorry.img11
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img11}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img12")}
                  className={
                    lorry.img12
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img12}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img13")}
                  className={
                    lorry.img13
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img13}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img14")}
                  className={
                    lorry.img14
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img14}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img15")}
                  className={
                    lorry.img15
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img15}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img16")}
                  className={
                    lorry.img16
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img16}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img17")}
                  className={
                    lorry.img17
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img17}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img18")}
                  className={
                    lorry.img18
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img18}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img19")}
                  className={
                    lorry.img19
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img19}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img20")}
                  className={
                    lorry.img20
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img20}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img21")}
                  className={
                    lorry.img21
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img21}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img22")}
                  className={
                    lorry.img22
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img22}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img23")}
                  className={
                    lorry.img23
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img23}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img24")}
                  className={
                    lorry.img24
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img24}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img25")}
                  className={
                    lorry.img25
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img25}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img26")}
                  className={
                    lorry.img26
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img26}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img27")}
                  className={
                    lorry.img27
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img27}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img28")}
                  className={
                    lorry.img28
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img28}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img29")}
                  className={
                    lorry.img29
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img29}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img30")}
                  className={
                    lorry.img30
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img30}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img31")}
                  className={
                    lorry.img31
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img31}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img32")}
                  className={
                    lorry.img32
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img32}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img33")}
                  className={
                    lorry.img33
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img33}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img34")}
                  className={
                    lorry.img34
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img34}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img35")}
                  className={
                    lorry.img35
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img35}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img36")}
                  className={
                    lorry.img36
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img36}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img37")}
                  className={
                    lorry.img37
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img37}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img38")}
                  className={
                    lorry.img38
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img38}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img39")}
                  className={
                    lorry.img39
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img39}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img40")}
                  className={
                    lorry.img40
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img40}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img41")}
                  className={
                    lorry.img41
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img41}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img42")}
                  className={
                    lorry.img42
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img42}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img43")}
                  className={
                    lorry.img43
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img43}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img44")}
                  className={
                    lorry.img44
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img44}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img45")}
                  className={
                    lorry.img45
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img45}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img46")}
                  className={
                    lorry.img46
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img46}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img47")}
                  className={
                    lorry.img47
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img47}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img48")}
                  className={
                    lorry.img48
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img48}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img49")}
                  className={
                    lorry.img49
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img49}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img50")}
                  className={
                    lorry.img50
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img50}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img51")}
                  className={
                    lorry.img51
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img51}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img52")}
                  className={
                    lorry.img52
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img52}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img53")}
                  className={
                    lorry.img53
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img53}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img54")}
                  className={
                    lorry.img54
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img54}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img55")}
                  className={
                    lorry.img55
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img55}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img56")}
                  className={
                    lorry.img56
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img56}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img57")}
                  className={
                    lorry.img57
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img57}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img58")}
                  className={
                    lorry.img58
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img58}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img59")}
                  className={
                    lorry.img59
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img59}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img60")}
                  className={
                    lorry.img60
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img60}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img61")}
                  className={
                    lorry.img61
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img61}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img62")}
                  className={
                    lorry.img62
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img62}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img63")}
                  className={
                    lorry.img63
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img63}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img64")}
                  className={
                    lorry.img64
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img64}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img65")}
                  className={
                    lorry.img65
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img65}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img66")}
                  className={
                    lorry.img66
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img66}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img67")}
                  className={
                    lorry.img67
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img67}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img68")}
                  className={
                    lorry.img68
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img68}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img69")}
                  className={
                    lorry.img69
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img69}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img70")}
                  className={
                    lorry.img70
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img70}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img71")}
                  className={
                    lorry.img71
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img71}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img72")}
                  className={
                    lorry.img72
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img72}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img73")}
                  className={
                    lorry.img73
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img73}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img74")}
                  className={
                    lorry.img74
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img74}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img75")}
                  className={
                    lorry.img75
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img75}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img76")}
                  className={
                    lorry.img76
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img76}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img77")}
                  className={
                    lorry.img77
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img77}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img78")}
                  className={
                    lorry.img78
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img78}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img79")}
                  className={
                    lorry.img79
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img79}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img80")}
                  className={
                    lorry.img80
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img80}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img81")}
                  className={
                    lorry.img81
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img81}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img82")}
                  className={
                    lorry.img82
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img82}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img83")}
                  className={
                    lorry.img83
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img83}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img84")}
                  className={
                    lorry.img84
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img84}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img85")}
                  className={
                    lorry.img85
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img85}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img86")}
                  className={
                    lorry.img86
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img86}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img87")}
                  className={
                    lorry.img87
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img87}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img88")}
                  className={
                    lorry.img88
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img88}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img89")}
                  className={
                    lorry.img89
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img89}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img90")}
                  className={
                    lorry.img90
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img90}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img91")}
                  className={
                    lorry.img91
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img91}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img92")}
                  className={
                    lorry.img92
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img92}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img93")}
                  className={
                    lorry.img93
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img93}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img94")}
                  className={
                    lorry.img94
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img94}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img95")}
                  className={
                    lorry.img95
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img95}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img96")}
                  className={
                    lorry.img96
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img96}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img97")}
                  className={
                    lorry.img97
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img97}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img98")}
                  className={
                    lorry.img98
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img98}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img99")}
                  className={
                    lorry.img99
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img99}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img100")}
                  className={
                    lorry.img100
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img100}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img101")}
                  className={
                    lorry.img101
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img101}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img102")}
                  className={
                    lorry.img102
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img102}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img103")}
                  className={
                    lorry.img103
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img103}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img104")}
                  className={
                    lorry.img104
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img104}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img105")}
                  className={
                    lorry.img105
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img105}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img106")}
                  className={
                    lorry.img106
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img106}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img107")}
                  className={
                    lorry.img107
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img107}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img108")}
                  className={
                    lorry.img108
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img108}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img109")}
                  className={
                    lorry.img109
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img109}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img110")}
                  className={
                    lorry.img110
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img110}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img111")}
                  className={
                    lorry.img111
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img111}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img112")}
                  className={
                    lorry.img112
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img112}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img113")}
                  className={
                    lorry.img113
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img113}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img114")}
                  className={
                    lorry.img114
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img114}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img115")}
                  className={
                    lorry.img115
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img115}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img116")}
                  className={
                    lorry.img116
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img116}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img117")}
                  className={
                    lorry.img117
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img117}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img118")}
                  className={
                    lorry.img118
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img118}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img119")}
                  className={
                    lorry.img119
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img119}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img120")}
                  className={
                    lorry.img120
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img120}
                  alt="abc"
                />
              </div>

              <div className="flex flex-row bg-red-600 w-fit -space-x-[1px] z-50">
                <img
                  onClick={(e) => handleLorryStatus("img121")}
                  className={
                    lorry.img121
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img121}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img122")}
                  className={
                    lorry.img122
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img122}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img123")}
                  className={
                    lorry.img123
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img123}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img124")}
                  className={
                    lorry.img124
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img124}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img125")}
                  className={
                    lorry.img125
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img125}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img126")}
                  className={
                    lorry.img126
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img126}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img127")}
                  className={
                    lorry.img127
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img127}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img128")}
                  className={
                    lorry.img128
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img128}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img129")}
                  className={
                    lorry.img129
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img129}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img130")}
                  className={
                    lorry.img130
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img130}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img131")}
                  className={
                    lorry.img131
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img131}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img132")}
                  className={
                    lorry.img132
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img132}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img133")}
                  className={
                    lorry.img133
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img133}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img134")}
                  className={
                    lorry.img134
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img134}
                  alt="abc"
                />
                <img
                  onClick={(e) => handleLorryStatus("img135")}
                  className={
                    lorry.img135
                      ? `w-4 h-4 sm:w-fit sm:h-fit opacity-40`
                      : `w-4 h-4 sm:w-fit sm:h-fit`
                  }
                  src={img135}
                  alt="abc"
                />
              </div>
            </div>
          </div>)}
          

          <div className="flex flex-col w-full sm:pr-5">
            <p className="p-1 font-semibold text-xs xxl:text-xl">
              Defect Report
            </p>
            <textarea
              className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
              value={data.defectReport}
              onChange={(e) =>
                setData({ ...data, defectReport: escapeHtml(e.target.value) })
              }
            ></textarea>
            <p className="p-1 font-semibold text-xs xxl:text-xl">
              Defect Rectified
            </p>
            <textarea
              className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
              value={data.defectRectified}
              onChange={(e) =>
                setData({
                  ...data,
                  defectRectified: escapeHtml(e.target.value),
                })
              }
            ></textarea>
          </div>
          <div className="flex flex-col sm:flex-row sm:pr-5">
            <div className="w-full sm:basis-1/2">
              <p className="p-1 font-semibold text-xs xxl:text-xl">
                Signed off and road worthy condition by:
              </p>
              <input
                type="text"
                className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full"
                value={data.signedOff}
                onChange={(e) =>
                  setData({ ...data, signedOff: escapeHtml(e.target.value) })
                }
              />
            </div>
            <div className="w-full sm:basis-1/2">
              <p className="p-1 font-semibold text-xs xxl:text-xl sm:ml-2">
                Date:
              </p>
              <input
                type="date"
                className=" sm:ml-2 bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full"
                value={data.date}
                onChange={(e) => setDate(e)}
              />
            </div>
          </div>
          <div className="flex justify-end mt-4 sm:pr-5">
            <button
              className=" w-24 h-8 xxl:w-32 xxl:h-10 text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-lg hover:bg-darkgreen"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VechicleCheck;
