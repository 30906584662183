import React, { useEffect, useReducer, useState } from 'react'
import logoo from '../pictures/TheBestescafflogo.png'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyDetails, addCompanyDocuments, adminSubscribed, checkToken, deleteFile, getCompanydata, signOut } from '../store/adminSlice';
import validator from '../functions/Validator';
import Swal from 'sweetalert2'
import Logo from '../components/Logo';
import { adminActions } from '../store/adminSlice';
import URL from '../functions/URLString';
import HeaderScaff from '../components/HeaderScaff';
import axios from "../functions/axios"
import HeaderScaffAdminUpload from '../components/HeaderScaffAdminUpload';



function AdminUpload() {
  const dispatch=useDispatch();

  const navigate = useNavigate();
  const subscribed=useSelector((state)=>state.admin.subscribed);
  const email=useSelector((state)=>state.admin.email);
  const _id=useSelector((state)=>state.admin._id);
  const stateCompanyDetails=useSelector((state)=>state.admin.companyDetails);
  const stateDocuments = useSelector((state)=>state.admin.companyDocuments);
  const tokenCheck = useSelector((state)=>state.admin.tokenCheck);
  const stateSwalAlert = useSelector((state)=>state.admin.swalAlert);
  const [data,setData]=useState(stateCompanyDetails)
  const [logo,setLogo]=useState()
  const [documents,setDocument]=useState([])
  const [logoup, setlogoup] = useState();
  

  const queryParameters = new URLSearchParams(window.location.search)
  const session_id = queryParameters.get("session_id")
  const redirectID = queryParameters.get("id")
  const statesignOut = useSelector((state) => state.admin.signOut)
  const [token , setToken]= useState(true);
  
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
  }, [statesignOut])
  const  dispatcGetcompanyData=() => {
    dispatch(getCompanydata(_id))

  }
  useEffect(() => {
    console.log(session_id,"session");
    if (session_id) {
      setToken(false);
      
     
      // dispatch(checkToken()).then(() => {
        
          axios.post("/admin/stripetokencheck",{ token_id: session_id },{
            headers:{
                authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
            }
          }).then((res) => {
            if (res.data.message === "validToken") {

              dispatch(adminSubscribed({ _id: _id, adminID: _id }))

          
          } else {
            dispatch(signOut())
            navigate("/")   
        }
          })


        
      // })
      
     
    }else if(subscribed===false){
      console.log("subscribed")
      dispatch(signOut())
      navigate("/")
      
    }else if(!email){
      console.log("email")
      navigate("/")
      dispatch(signOut())


    } else if (redirectID === "12345") {
      navigate("/liveprojects")
    } else {
      dispatch(getCompanydata(_id))
    }
 
    

      setData(stateCompanyDetails)
      dispatch(adminActions.removeSwalAlert())
  },[])

  useEffect(() => {
    setData(stateCompanyDetails)
  },[stateCompanyDetails]);


  useEffect(() => {
    console.log("hello world"); 
    if(stateSwalAlert==="UploadCompanyDocumentsFirst!"){
      console.log("here"); 

    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      confirmButtonColor:"#A7B778",
      text: 'Kindly Add Company Details first!',
    }).then(()=>{

      dispatch(adminActions.removeSwalAlert())
    })
  }
  },[stateSwalAlert]);

  const submit= event =>{
    event.preventDefault();
    const formData= new FormData();
   
    formData.append("customerOrCompanyName",data.customerOrCompanyName)
    formData.append("telephone",data.telephone)
    formData.append("email",data.email)
    formData.append("address1",data.address1)
    formData.append("address2",data.address2)
    formData.append("postCode",data.postCode)
    formData.append("companyLogo",logo)
    formData.append("adminID",_id)

    Swal.fire({
      title: 'Do you want to save the changes?',
      showCancelButton: true,
      cancelButtonColor:"#A7B778",
      confirmButtonColor:"#A7B778",
      denyButtonColor:"#A7B778",
      confirmButtonText: 'Save changes',
      
    }).then((result) => {
      if (result.isConfirmed) {
        if (logo === undefined || data.customerOrCompanyName === "" || data.telephone === "" || data.email === "" || data.address2 === "" || data.address1 === "" || data.postCode === "") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            confirmButtonColor: "#A7B778",
            text: 'Kindly fill all the inputs & Upload Logo !',
          })
          return;
        } else {
          dispatch(addCompanyDetails(formData)).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Saved',
              confirmButtonColor: "#A7B778",
            })
            navigate("/adminupload")
          })
        }
      } 
    })

  }
  const submitDocument=(e)=>{
      e.preventDefault();
     const formData= new FormData();
  
     for (let i = 0; i < documents.length; i++) {
      formData.append("companyDocument",documents[i])
    }
    formData.append("adminID",_id)
    dispatch(addCompanyDocuments(formData))

  }

  const deleteFilee = (thtFilee) => {
    var nn= {thtFile:thtFilee,adminID:_id}
    console.log(nn);
    dispatch(deleteFile(nn))
  }

  return (
    
     <div className='w-full flex flex-col h-full'>
        <HeaderScaffAdminUpload/>
      <p className='p-3 pb-1 pt-5 sm:pt-10 sm:pb-2 px-4 sm:px-12 xxl:text-3xl md:px-36 text-center md:text-start'>Welcome to eScaff lets get started by setting up your company, upload your documents and set up your staff</p>

      <div className='flex flex-col w-full p-1 sm:p-6 md:-mt-5'>
      <form encType="multipart/form-data">

          <div className='flex flex-col md:flex-row w-full overflow-hidden'>

            <div className='flex w-full pr-5 md:pr-0 md:w-1/2 ml-1 xl:ml-10 h-fit flex-col items-center sm:flex-row'>
              <p className='mt-4 -mr-5 xl:-mr-0 rounded-full bg-lightgreen flex-shrink-0 h-fit p-1 sm:p-2 w-10 sm:w-12 xxl:w-[66px] xxl:p-4 xxl:text-3xl text-2xl text-white'><span className='ml-[10px]'>1</span></p>
              <div className='p-2 flex flex-col w-full ml-1 sm:ml-8'>
                <p className='p-1 font-semibold text-xs xxl:text-xl'>Customer / Company Name</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 w-full lg:w-10/12 outline-none pl-1' onChange={(e)=>setData({...data,customerOrCompanyName:validator(e.target.value)})} value={data.customerOrCompanyName} type="text" required/>
                <p className='p-1 font-semibold text-xs xxl:text-xl'>Telephone</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 w-full lg:w-10/12 outline-none pl-1' onChange={(e)=>setData({...data,telephone:validator(e.target.value)})} value={data.telephone} type="text" required/>
                <p className='p-1 font-semibold text-xs xxl:text-xl'>Email</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 w-full lg:w-10/12 outline-none pl-1' onChange={(e)=>setData({...data,email:validator(e.target.value)})} value={data.email} type="email" required/>
              </div>
            </div>

            <div className='flex w-full md:w-1/2 ml-1 pr-5 sm:ml-10 sm:pr-14 md:pr-0 md:ml-5 h-fit flex-row'>
              <div className='p-2 flex flex-col w-full ml-1 sm:ml-4 space-y-1'>
                <p className='p-1 font-semibold text-xs xxl:text-xl'>Address</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full md:w-10/12' onChange={(e)=>setData({...data,address1:validator(e.target.value)})} value={data.address1} type="text" required/>
                <p className='opacity-0 font-semibold text-xs xxl:text-xs'>Telephone</p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full md:w-10/12'onChange={(e)=>setData({...data,address2:validator(e.target.value)})} value={data.address2} type="text" />
                <p className='p-1 font-semibold text-xs xxl:text-xl'>Postcode</p>
                <div className='flex flex-col sm:flex-row w-full items-start sm:items-center'>
                  <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none w-full sm:w-28 xl:w-36 pl-1 appearance-none' onChange={(e)=>setData({...data,postCode:validator(e.target.value)})} value={data.postCode} type="text" required/>
                  <p className='pt-2 sm:pt-0 text-[12px] break-words whitespace-nowrap sm:text-xs xxl:text-xl xl:text-sm font-semibold px-1 py-1 sm:py-0 sm:px-4'>Upload your company Logo</p>
                  <div className='flex w-full space-x-2'>
                    <label htmlFor="file-upload" className='h-8 rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen'>
                      <input className='absolute -ml-5 opacity-0' value={logoup} onChange={(e)=>setLogo(e.target.files[0])} style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="file" required/>Select
                    </label>
                    <label htmlFor="file-upload" className='h-8 rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen'>
                      <input className='absolute -ml-5 opacity-0 cursor-pointer' onClick={submit} style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="file" required/>Submit
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>

{/* second */}

          <div className='flex flex-col md:flex-row w-full xxl:pt-6'>

            <div className='flex flex-col w-full md:w-2/5 space-y-3 -mt-2 sm:-mt-0 md:pt-4 xl:pt-0'>
              <p className='opacity-0 p-2 sm:pl-14 md:pl-24 lg:pl-32 whitespace-nowrap text-normal sm:text-lg xxl:text-3xl pt-10 md:pt-0 lg:pt-3 xl:pt-0 text-center sm:text-start'>Your uploaded company documents</p>

              <div className='flex w-full pl-2 xl:pl-10 h-fit flex-col sm:flex-row items-center'>
                <p className='rounded-full bg-lightgreen h-fit flex-shrink-0 p-1 sm:p-2 w-10 sm:w-12 xxl:w-[66px] xxl:p-4 xxl:text-3xl text-2xl text-white'><span className='ml-[10px]'>2</span></p>
                <div className='p-2 flex flex-col sm:flex-row w-full ml-0 xl:ml-8 items-center justify-between '>
                  <div className='flex flex-col w-fit justify-center whitespace-nowrap'>
                    <p className='pl-0 p-1 pt-0 font-semibold text-sm xxl:text-xl'>Upload your company documents</p>
                    <p className='text-xs xxl:text-base'>i.e Insurance, public, Liability , (not health & safety)</p>
                  </div>
                  <div className='flex w-full space-x-2 sm:ml-2 justify-center py-3 sm:py-0 sm:justify-end'>
                  <label htmlFor="file-upload" className='w-24 h-8 xxl:w-32 xxl:h-10 self-end items-center flex justify-center text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>
                    <input className='absolute -ml-5 opacity-0'  onChange={(e)=>setDocument(e.target.files)} multiple style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="file" required/>Select
                  </label>
                  <label htmlFor="file-upload" className='w-24 h-8 xxl:w-32 xxl:h-10 self-end rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>
                      <input className='absolute -ml-5 opacity-0 cursor-pointer' value={logoup} onClick={submitDocument} style={{width:'90px',cursor: 'pointer'}} id="file-upload" type="file" required/>Submit
                  </label>
                  </div>
                </div>
              </div>

             

              <div className='flex w-full pl-2 xl:pl-10 h-fit flex-col sm:flex-row items-center'>
                <p className='rounded-full bg-lightgreen h-fit flex-shrink-0 p-1 sm:p-2 w-10 sm:w-12 xxl:w-[66px] xxl:p-4 xxl:text-3xl text-2xl text-white'><span className='ml-[10px]'>3</span></p>
                <div className='p-2 flex flex-col w-full ml-2 xl:ml-8 items-center justify-between'>
                  <div className='flex flex-col w-full justify-center'>
                    <p className='pl-0 p-1 pt-0 font-semibold text-sm xxl:text-xl'>Add key members of staff</p>
                    <p className='text-xs xxl:text-base'>Add your key members of staff that will be allocated to jobs, so you can keep track of who is working on what job.</p>
                  </div>
                  <button onClick={()=>navigate("/staffmember")} className='w-24 h-8 xxl:w-32 xxl:h-10 mt-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'>Add staff</button>
                </div>
              </div>

            </div>
           
            <div className='flex flex-col w-full md:w-3/5'>
              <div className='flex flex-col w-full p-4 sm:px-14 md:px-32 md:pr-20'>
                <div className=''>
              <span className='text-sm xxl:text-2xl font-semibold pb-1'>Uploaded Documents</span><span className='text-sm xxl:text-2xl  pb-1'>(.png, .jpg, .gif, .jpeg, .doc, .docx, .rft, .xls, .xlsx, .ppt, .pptx, .txt, .pdf )</span>
              </div>
                <table className='w-full'>

                <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                  <th className='text-start px-2 py-1 text-xs xxl:text-lg text-white rounded-l-md font-normal border-[1px] border-r-white'>File Name</th>
                  <th className='rounded-r-md w-16 lg:w-44'></th>
                </tr>
                <div className='h-2'></div>

                {
                  stateDocuments.map((data) => {
                  var name=data.split("-")
                  return (
                  <tr key={data} className=''>
                  <td className='w-2/3 border-[1px] border-gray-300 pl-3 p-1 text-sm xxl:text-lg'>{name[3]}</td>
                  <td className=' border-[1px] border-gray-300 p-1'>
                    <div className='flex flex-row justify-center space-x-2 xxl:space-x-6'>
                      <a href={`${URL}/uploads/${data}`} download ><div className='flex flex-col items-center cursor-pointer'>
                        <UploadFileIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm'>View</p>
                      </div></a>
                      <div onClick={(e) => deleteFilee(data)} className='flex flex-col items-center cursor-pointer'>
                        <DeleteIcon sx={{width:'25px',height:"25px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[8px] xxl:text-sm'>Delete</p>
                      </div>                     
                    </div>
                  </td>
                  </tr>
                  )})
                  }

              </table>


              </div>

            </div>

          </div>

    </form>
      </div>
    </div>
  )
}

export default AdminUpload