import React, { useEffect, useState } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { adaptionStatusComplete, adaptionStatusStart, fetchAdaption, fetchAdaptionQoute, fetchSingleAdaptionsForStaffMember, fetchSingleJobsForStaffMember, jobStatusComplete, jobStatusStart } from '../store/staffSlice';
import URL from '../functions/URLString';
import googleMaps from '../pictures/googleMaps.png'
import { json, useNavigate } from 'react-router-dom';
import StaffLogo from '../components/StaffLogo';



function StaffAdaption() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id")
  const type = queryParameters.get("type")
  const stateSingleAdaptionsForStaffMember = useSelector((state) => state.staff.singleAdaptionsForStaffMember)
  const adaptionDetails = useSelector((state) => state.staff.adaptionDetails)
  const [image, setImage] = useState("");
  const [data, setData] = useState({jobDescription:"",job1:"",job2:"",jobDetails:"",status:0,adaptionDescription:""});
  const [images, setImages] = useState([]);

  useEffect(()=>{
    // dispatch(fetchSingleJobsForStaffMember({_id:id}))
    dispatch(fetchSingleAdaptionsForStaffMember({_id:id}))
    dispatch(fetchAdaptionQoute({jobID:id,type}))
    console.log(adaptionDetails);
  },[])

  useEffect(()=>{
    if (JSON.stringify(stateSingleAdaptionsForStaffMember) !== "{}") {
      console.log(stateSingleAdaptionsForStaffMember)
      setImage(stateSingleAdaptionsForStaffMember.images[0]);
      setImages(stateSingleAdaptionsForStaffMember.images);
      setData({...data,jobDescription:stateSingleAdaptionsForStaffMember.jobID.jobDescription,job1:stateSingleAdaptionsForStaffMember.job1,job2:stateSingleAdaptionsForStaffMember.jobID.job2,jobDetails:stateSingleAdaptionsForStaffMember.jobID.jobDetails,status:stateSingleAdaptionsForStaffMember.status,adaptionDetails:stateSingleAdaptionsForStaffMember.adaptionDetails});
    }
  },[stateSingleAdaptionsForStaffMember])

  const handleStatusSt = (e) => {
    e.preventDefault();
    dispatch(adaptionStatusStart({ jobID: id ,type}));
    //  setTimeout(()=>window.location.reload(true), 3000);
    
  }

  const handleStatusCm = () => {
    dispatch(adaptionStatusComplete({ jobID: id ,type}));
    // setTimeout(()=>window.location.reload(true), 3000);

  }

  const next = () => {
      if(image === images[images.length - 1]){
          setImage(images[0])
          return image;
      } else {
          images.map((im,index) => {
              if(im === image){
                  setImage(images[index+1])
              }
              return image;
          })
      }
      return image;
  };

  const back = () => {
      if(image === images[0]){
          setImage(images[images.length-1])
          return image;
      } else {
          images.map((im,index) => {
              if(im === image){
                  setImage(images[index-1])
              }
              return image;
          })
      }
      return image;
  };

  return (
    <div className='w-full'>
        <div className='lg:w-full lg:flex lg:justify-center hidden lg:flex-wrap lg:h-screen lg:content-center'>
            <div className=" bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative   lg:block" role="alert">
                <strong className="font-bold">Warning!</strong>
                <span className="block sm:inline"> This page is only visible with medium and small devices.</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                </span>
            </div>
        </div>
        <div className='w-full h-screen flex-col py-2 lg:hidden flex overflow-hidden'>
          <div className=' w-full flex-col h-1/6 px-4 flex justify-center items-center'>
          <StaffLogo/>
            {/* <img src={logo} className="w-[290px] h-[70px] md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] object-contain" alt="logo"/> */}
            <p className='flex flex-row w-full pt-1 justify-center items-center'>
              <hr className='bg-grayy w-10/12 h-[1px]'/>
            </p>
          </div>

          <div className='w-full h-full m-0 bg-white rounded-md px-4 flex flex-col overflow-scroll scrollbar-hide'>

            <div className='flex h-1/2 flex-col items-center justify-start w-full bg-white'>

              <div className='w-full h-1/6 bg-white rounded-md px-4 flex flex-col items-center justify-center z-10'>
                  <h1 className='font-semibold text-gray-600 font-sans text-2xl'>My Adaptions</h1>
              </div>

              <div className='flex h-4/6 flex-col items-center justify-center w-full bg-white'>
                {(image === "")?(
                  <CircularProgress className='align-middle' size={100} color="success"/>
                ):(
                  <img className='object-scale-down h-full w-full' src={`${URL}/uploads/${image}`} alt="" />                          
                )}
              </div>

              <div className='flex h-1/6 flex-row justify-between items-end w-1/3'>
                <div className='bg-darkgreen flex justify-center  space-x-32 p-2 rounded-full xxl:p-2'>
                  <ArrowBackIosNewIcon onClick={back} sx={{width:'20px',height:"20px"}} className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"/>
                </div>
                <div className='bg-darkgreen flex justify-center space-x-32 p-2 rounded-full xxl:p-2'>
                  <ArrowForwardIosIcon onClick={next} sx={{width:'20px',height:"20px"}} className="text-white hover:text-lightgreen hover:translate-x-[2px] cursor-pointer"/>
                </div>
              </div>

            </div>

            <div className='flex h-1/2 justify-center w-full flex-col'>
              <div className='flex h-4/6 flex-shrink-0 my-2 pt-0 justify-start w-full flex-col overflow-scroll scrollbar-hide'>
                <div className='flex flex-row w-full items-start'>
                  <p className='text-sm whitespace-nowrap top-0'>Address : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.job1} {data.job2}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Description : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.jobDescription}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Access : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.jobDetails}</p>
                </div>
                <div className='flex flex-row w-full items-start -pt-1'>
                  <p className='text-sm whitespace-nowrap top-0'>Adaption Description : </p>
                  <p className='text-xs self-start pt-1 ml-2'>{data.adaptionDetails}</p>
                </div>
                  {adaptionDetails?(
                    (adaptionDetails.type == "temproryRoofQoute")?
                    (
                      <>
                        {(parseInt(adaptionDetails.permiterOfBuilding) > 0 )?(
                          <>
                            <div className='flex flex-row w-full items-start pt-2'>
                              <p className='text-sm whitespace-nowrap top-0'>Perimeter of building in meters : </p>
                              <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.permiterOfBuilding}</p>
                            </div>
                            <div className='flex flex-row w-full items-start -pt-1'>
                              <p className='text-sm whitespace-nowrap top-0'>Number of lifts : </p>
                              <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.numberOfLiftsForPerimeter}</p>
                            </div>
                            </>
                        ):(
                          <></>
                        )}
                        {(parseInt(adaptionDetails.widthOfBuilding) > 0 )?(
                          <>
                            <div className='flex flex-row w-full items-start pt-2'>
                              <p className='text-sm whitespace-nowrap top-0'>width of the building in meters : </p>
                              <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.widthOfBuilding}</p>
                            </div>
                            <div className='flex flex-row w-full items-start -pt-1'>
                              <p className='text-sm whitespace-nowrap top-0'>length of the building in meters : </p>
                              <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.lengthOfBuilding}</p>
                            </div>
                            </>
                        ):(
                          <></>
                        )}



                      </>
                    ):
                    (
                      <>
                      {(adaptionDetails.dynamicOBJ.length !== 0)?(
                        (adaptionDetails.dynamicOBJ.map((a) => (
                          <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>Elevation Name : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{a.elevationNamee}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Elevation length in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{a.elevationLength}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Elevation lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{a.elevationLifts}</p>
                          </div>
                          </>
                        )))
                      ):(
                        <></>
                      )}
                      {(adaptionDetails.gantryElevation.length !== 0 )?(
                      // {(adaptionDetails.gantryElevation.length !== 0 && parseInt(adaptionDetails.gantryLength) > 0 && parseInt(adaptionDetails.gantryLift) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>Gantry Elevation : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryElevation}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Gantry length in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryLength}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Gantry lifts in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryLift}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Gantry width in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryWidth}</p>
                          </div>
                          </>
                      ):(
                        <></>
                      )}
                      {(adaptionDetails.bayElevation.length !== 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>Bay Elevation : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.bayElevation}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Bay length  in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.baylength}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Bay lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.bayLift}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Bay width  in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.bayWidth}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.deckLength) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>Bird Cage/Crash Deck</p>
                            {/* <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryElevation}</p> */}
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>length  in meters : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryLength}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryLifts}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>width  in meter : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.gantryWidth}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.hopUp) > 0 )?(
                        <div className='flex flex-row w-full items-start pt-2'>
                          <p className='text-sm whitespace-nowrap top-0'>Hop-Ups : </p>
                          <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.hopUp}</p>
                        </div>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.saddle) > 0 )?(
                        <div className='flex flex-row w-full items-start pt-1'>
                          <p className='text-sm whitespace-nowrap top-0'>Saddles : </p>
                          <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.saddle}</p>
                        </div>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.throwIn) > 0 )?(
                        <div className='flex flex-row w-full items-start pt-1'>
                          <p className='text-sm whitespace-nowrap top-0'>Throw-Ins : </p>
                          <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.throwIn}</p>
                        </div>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.fiveFootTower) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>5 foot/1.8 meter tower : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.fiveFootTower}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Number of lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.fiveFootTowerLift}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.eightFootTower) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>8 foot/2.4 meter tower : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.eightFootTower}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Number of lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.eightFootTowerLift}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.tenFootTower) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>10 foot/3.0 meter tower : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.tenFootTower}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Number of lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.tenFootTowerLift}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(parseInt(adaptionDetails.thirteenFootTower) > 0 )?(
                        <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>13 foot/4.0 meter tower : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.thirteenFootTower}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Number of lifts : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{adaptionDetails.thirteenFootTowerLift}</p>
                          </div>
                        </>
                      ):(
                        <></>
                      )}
                      {(adaptionDetails.additionalItem.length !== 0)?(
                        (adaptionDetails.additionalItem.map((a) => (
                          <>
                          <div className='flex flex-row w-full items-start pt-2'>
                            <p className='text-sm whitespace-nowrap top-0'>Additional Item : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{a.additionalItemName}</p>
                          </div>
                          <div className='flex flex-row w-full items-start -pt-1'>
                            <p className='text-sm whitespace-nowrap top-0'>Number of items : </p>
                            <p className='text-xs self-start pt-1 ml-2'>{a.numberOfadditionalItem}</p>
                          </div>
                          </>
                        )))
                      ):(
                        <></>
                      )}
                      </>
                    )
                    ):(
                      <p className='text-xs xxl:text-base self-start pt-1 ml-2'>no adaption details</p>
                    )
                  }
              </div>


              <div className='w-full h-1/6 mb-3 bg-white rounded-md px-4 pt-1 flex flex-row items-center justify-between space-x-2 z-20'>
                <button type='submit' onClick={() => navigate("/staffjoblist")} className='px-3 whitespace-nowrap bg-lightgreen text-sm rounded-md p-1 text-white hover:bg-darkgreen'>Back</button>
                <div className='flex flex-col items-center'>
                  {(data.status === 0)?(
                    <>
                    <p className='text-base font-semibold'>Status</p>
                    <p className='text-sm -mt-1'>Pending</p>
                    </>
                  ):(
                  (data.status === 1)?(
                    <>
                    <p className='text-base font-semibold'>Status</p>
                    <p className='text-sm -mt-1'>In Progress...</p>
                    </>
                  ):(
                    <>
                    <p className='text-base font-semibold'>Completed</p>
                    </>
                  )
                  )}
                </div>
                {(data.status === 0)?(
                  <button onClick={handleStatusSt} type='submit' className='px-3 whitespace-nowrap bg-lightgreen text-sm rounded-md p-1 text-white hover:bg-darkgreen'>Start</button>
                ):(
                  (data.status === 1)?(
                    <button onClick={handleStatusCm}  type='submit' className='px-3 whitespace-nowrap bg-lightgreen text-sm rounded-md p-1 text-white hover:bg-darkgreen'>Complete</button>
                  ):(
                    <></>
                  )
                )}
            </div>
            <button onClick={(e)=>navigate(`/staffformadaption?adaptionID=${stateSingleAdaptionsForStaffMember._id}&id=${stateSingleAdaptionsForStaffMember.customerID._id}&clientName=${stateSingleAdaptionsForStaffMember.customerID.customerCompanyName}&address=${stateSingleAdaptionsForStaffMember.jobID.job1 +" "+stateSingleAdaptionsForStaffMember.jobID.job2}&postcode=${stateSingleAdaptionsForStaffMember.jobID.postCode}&adminname=${stateSingleAdaptionsForStaffMember.adminID.fullName}&adminemail=${stateSingleAdaptionsForStaffMember.adminID.email}&adminID=${stateSingleAdaptionsForStaffMember.adminID._id}`)} className='px-3 whitespace-nowrap bg-lightgreen text-sm rounded-md p-1 text-white hover:bg-darkgreen'>Hand Over</button>
            </div>

          </div>
        </div>
        {/* <iframe  frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Little+Lidgey+Campus+Moor+Ponsanooth+++  TR3 7HR&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe> */}
    </div>
  )
}

export default StaffAdaption