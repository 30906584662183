import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../functions/axios";


const adminData= localStorage.getItem("adminData")!==null ? JSON.parse(localStorage.getItem("adminData")):{_id:"",email:"",fullName:"",isLoading:false,alert:[],subscribed:false} 
const config = {
    headers:{
        authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
    }
  };
export const registerUser = createAsyncThunk('/regiterUser',async(user)=>{
    const res = await axios.post("/admin/signup",user,config);
    return res.data;
})
export const fetchSingleescaffQoute = createAsyncThunk('/fetchsingleescaffqoute',async(user)=>{
    const res = await axios.post("/admin/fetchsingleescaffqoute",user,config);
    return res.data;
})
export const fetchSingleescaffAdaptionqoute = createAsyncThunk('/fetchsingleescaffadaptionqoute',async(user)=>{
    const res = await axios.post("/admin/fetchsingleescaffadaptionqoute",user,config);
    return res.data;
})
export const fetchsingletemproryroofqoute = createAsyncThunk('/fetchsingletemproryroofqoute',async(user)=>{
    const res = await axios.post("/admin/fetchsingletemproryroofqoute",user,config);
    return res.data;
})
export const fetchsingletemproryroofqadaptionqoute = createAsyncThunk('/fetchsingletemproryroofqadaptionqoute',async(user)=>{
    const res = await axios.post("/admin/fetchsingletemproryroofqadaptionqoute",user,config);
    return res.data;
})
export const loginUser = createAsyncThunk('/loginUser',async(user)=>{
    const res = await axios.post("/admin/login",user,config);
    return res.data;
})
export const savePriceList = createAsyncThunk('/savepriceList',async(user)=>{
    const res = await axios.post("/admin/savepricelist",user,config);
    return res.data;
})
export const fetchPriceList = createAsyncThunk('/fetchpriceList',async(user)=>{
    const res = await axios.post("/admin/fetchpricelist",user,config);
    return res.data;
})
export const addCompanyDetails = createAsyncThunk('/addCompanyDetails',async(user)=>{
    const res = await axios.post("/admin/companyDetails",user,config);
    return res.data;
})
export const addCompanyDocuments = createAsyncThunk('/addCompanyDocuments',async(user)=>{
    const res = await axios.post("/admin/addcompanydocuments",user,config);
    return res.data;
})

export const deleteFile = createAsyncThunk('/deletefile',async(user)=>{
    const res = await axios.post("/admin/deletefile",user,config);
    return res.data;
})
export const adminSubscribed = createAsyncThunk(`/subscibeddeded`,async(user)=>{
    const res = await axios.post(`/admin/subscibeddeded`,user,config);
    return res.data;
})
export const getCompanydata = createAsyncThunk('/getcompanydata',async(user)=>{
    const res = await axios.post("/admin/getcompanydata",{adminID:user},config);
    return res.data;
})
export const checkToken = createAsyncThunk('/checkToken',async(user)=>{
    const res = await axios.post("/admin/stripetokencheck",user,config);
    return res.data;
})
export const addKeyStaffMember = createAsyncThunk('/addKeyStaffMember',async(user)=>{
    const res = await axios.post("/admin/addkeystaffmember",user,config);
    return res.data;
})
export const fetchKeyStaffMember = createAsyncThunk('/fetchKeyStaffMember',async(user)=>{
    const res = await axios.post("/admin/fetchkeystaffmember",user,config);
    return res.data;
})
export const deleteItemKeyStaffMembers = createAsyncThunk('/deleteItemKeyStaffMembers',async(user)=>{
    const res = await axios.post("/admin/deleteItemKeyStaffMembers",user,config);
    return res.data;
})
export const editKeyStaffMembers = createAsyncThunk('/editKeyStaffMembers',async(user)=>{
    const res = await axios.post("/admin/editKeyStaffMembers",user,config);
    return res.data;
})
export const createNewCustomer = createAsyncThunk('/createNewCustomer',async(user)=>{
    const res = await axios.post("/admin/createNewCustomer",user,config);
    return res.data;
})
export const fetchCustomer = createAsyncThunk('/fetchCustomer',async(user)=>{
    const res = await axios.post("/admin/fetchCustomer",user,config);
    return res.data;
})
export const fetchVechicle = createAsyncThunk('/fetchVechicle',async(user)=>{
    const res = await axios.post("/admin/fetchvechicle",user,config);
    return res.data;
})
export const fetchHealthAndSafety = createAsyncThunk('/fetchHealthAndSafety',async(user)=>{
    const res = await axios.post("/admin/fetchhealthandsafety",user,config);
    return res.data;
})
export const deleteCustomer = createAsyncThunk('/deleteCustomer',async(user)=>{
    const res = await axios.post("/admin/deleteCustomer",user,config);
    return res.data;
})
export const editCustomer = createAsyncThunk('/editCustomer',async(user)=>{
    const res = await axios.post("/admin/editCustomer",user,config);
    return res.data;
})
export const addANewJob = createAsyncThunk('/addANewJob',async(user)=>{
    const res = await axios.post("/admin/addanewjob",user,config);
    return res.data;
})
export const addANewAdaption = createAsyncThunk('/addANewAdaption',async(user)=>{
    const res = await axios.post("/admin/addanewadaption",user,config);
    return res.data;
})
export const addHealthAndSafety = createAsyncThunk('/addHealthAndSafety',async(user)=>{
    const res = await axios.post("/admin/addhealthandsafety",user,config);
    return res.data;
})
export const pendingQoutes = createAsyncThunk('/pendingQoutes',async(user)=>{
    const res = await axios.post("/admin/pendingqoutes",user,config);
    return res.data;
})
export const fetchHandOver = createAsyncThunk('/fetchHandOver',async(user)=>{
    const res = await axios.post("/admin/fetchandover",user,config);
    return res.data;
})
export const pendingArchiveQoutes = createAsyncThunk('/pendingArchiveQoutes',async(user)=>{
    const res = await axios.post("/admin/pendingarchiveqoutes",user,config);
    return res.data;
})
export const pendingQoutesAddToArchive = createAsyncThunk('/pendingQoutesAddToArchive',async(user)=>{
    const res = await axios.post("/admin/pendingqoutesaddtoarchive",user,config);
    return res.data;
})
export const pendingQoutesAddToUnArchive = createAsyncThunk('/pendingQoutesAddToUnArchive',async(user)=>{
    const res = await axios.post("/admin/pendingqoutesaddtounarchive",user,config);
    return res.data;
})
export const pendingAdaptions = createAsyncThunk('/pendingAdaptions',async(user)=>{
    const res = await axios.post("/admin/pendingadaption",user,config);
    return res.data;
})
export const pendingArchiveAdaptions = createAsyncThunk('/pendingArchiveAdaptions',async(user)=>{
    const res = await axios.post("/admin/pendingarchiveadaptions",user,config);
    return res.data;
})
export const pendingAdaptionsAddToArchive = createAsyncThunk('/pendingAdaptionsAddToArchive',async(user)=>{
    const res = await axios.post("/admin/pendingadaptionsaddtoarchive",user,config);
    return res.data;
})
export const pendingAdaptionsAddToUnArchive = createAsyncThunk('/pendingAdaptionsAddToUnArchive',async(user)=>{
    const res = await axios.post("/admin/pendingadaptionsaddtounarchive",user,config);
    return res.data;
})
export const deleteJob = createAsyncThunk('/deletependingQoutes',async(user)=>{
    const res = await axios.post("/admin/deletependingqoutes",user,config);
    return res.data;
})
export const deleteHandOver = createAsyncThunk('/deletehandover',async(user)=>{
    const res = await axios.post("/admin/deletehandover",user,config);
    return res.data;
})
export const deleteAdaption = createAsyncThunk('/deletependingAdaptionQoutes',async(user)=>{
    const res = await axios.post("/admin/deletependingadaptionqoutes",user,config);
    return res.data;
})
export const deleteApproveQoutes = createAsyncThunk('/deleteApproveQoutes',async(user)=>{
    const res = await axios.post("/admin/deleteqoutation",user,config);
    return res.data;
})
export const deleteApproveAdaptionQoutes = createAsyncThunk('/deleteApproveAdaptionQoutes',async(user)=>{
    const res = await axios.post("/admin/deleteAdaptionqoutation",user,config);
    return res.data;
})
export const deletedeclineqoutation = createAsyncThunk('/deletedeclineqoutation',async(user)=>{
    const res = await axios.post("/admin/deletedeclineqoutation",user,config);
    return res.data;
})
export const fetchSingleJob=createAsyncThunk('/fetchSingleJob',async(user)=>{
    const res = await axios.post("/admin/fetchsinglejob",user,config);
    return res.data;
})
export const fetchSingleAdaption=createAsyncThunk('/fetchSingleAdaption',async(user)=>{
    const res = await axios.post("/admin/fetchsingleadaption",user,config);
    return res.data;
})
export const addEscaffQoutation=createAsyncThunk('/addEscaffQoutation',async(user)=>{
    const res = await axios.post("/admin/addescaffQoutation",user,config);
    return res.data;
})
export const fetchEscaffQoutation=createAsyncThunk('/fetchEscaffQoutation',async(user)=>{
    const res = await axios.post("/admin/fetchescaffQoutation",user,config);
    return res.data;
})
export const fetchAdaptionEscaffQoutation=createAsyncThunk('/fetchAdaptionEscaffQoutation',async(user)=>{
    const res = await axios.post("/admin/fetchadaptionescaffQoutation",user,config);
    return res.data;
})
export const fetchTemproryRoofQoutation=createAsyncThunk('/fetchTemproryRoofQoutation',async(user)=>{
    const res = await axios.post("/admin/fetchetemproryroofqoutation",user,config);
    return res.data;
})
export const addTemproryRoofQoute=createAsyncThunk('/addtemperoryroofqoute',async(user)=>{
    const res = await axios.post("/admin/addtemperoryroofqoute",user,config);
    return res.data;
})
export const fetchTemproryRoofAdaptionQoutation=createAsyncThunk('/fetchTemproryRoofAdaptionQoutation',async(user)=>{
    const res = await axios.post("/admin/fetchtemproryRoofadaptionQoutation",user,config);
    return res.data;
})
export const addTemproryRoofAdaoptionQoute=createAsyncThunk('/addTemproryRoofAdaoptionQoute',async(user)=>{
    const res = await axios.post("/admin/addtemproryroofadaoptionqoute",user,config);
    return res.data;
})
export const fetchAdminApprovedQoutes=createAsyncThunk('/fetchAdminApprovedQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedqoutes",user,config);
    return res.data;
})
export const fetchAdminApprovedQoutesJobs=createAsyncThunk('/fetchAdminApprovedQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedqoutesjobs",user,config);
    return res.data;
})
export const fetchAdminApprovedAdaptionQoutes=createAsyncThunk('/fetchAdminApprovedAdaptionQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedadaptionQoutes",user,config);
    return res.data;
})
export const fetchAdminApprovedAdaptionQoutesJobs=createAsyncThunk('/fetchAdminApprovedAdaptionQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedadaptionQoutesjobs",user,config);
    return res.data;
})
export const fetchAdminApprovedQoutesLiveProject=createAsyncThunk('/fetchAdminApprovedQoutesLiveProject',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedqoutesliveproject",user,config);
    return res.data;
})
export const fetchAdminApprovedAdaptionQoutesLiveProject=createAsyncThunk('/fetchAdminApprovedAdaptionQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadminapprovedadaptionQoutesliveproject",user,config);
    return res.data;
})
export const fetchAdminDeclineQoutes=createAsyncThunk('/fetchAdminDeclineQoutes',async(user)=>{
    const res = await axios.post("/admin/fetchadmindeclineqoutes",user,config);
    return res.data;
})
export const addHandOver=createAsyncThunk('/addHandOver',async(user)=>{
    const res = await axios.post("/admin/addhandover",user,config);
    return res.data;
})
export const addAdaptionHandOver=createAsyncThunk('/addAdaptionHandOver',async(user)=>{
    const res = await axios.post("/admin/addadaptionhandover",user,config);
    return res.data;
})
export const assignJobToStaffMember=createAsyncThunk('/assignJobToStaffMember',async(user)=>{
    const res = await axios.post("/admin/assignjobtostaffmember",user,config);
    return res.data;
})
export const fetchAdminScaffoldingInspection=createAsyncThunk('/fetchadminscaffoldinginspection',async(user)=>{
    const res = await axios.post("/admin/fetchadminscaffoldinginspection",user,config);
    return res.data;
})
export const assignAdaptionToStaffMember=createAsyncThunk('/assignAdaptionToStaffMember',async(user)=>{
    const res = await axios.post("/admin/assignadaptiontostaffmember",user,config);
    return res.data;
})
export const addInspections=createAsyncThunk('/addInspections',async(user)=>{
    const res = await axios.post("/admin/addinspections",user,config);
    return res.data;
})
export const addAdaptionInspections=createAsyncThunk('/addAdaptionInspections',async(user)=>{
    const res = await axios.post("/admin/addadaptioninspections",user,config);
    return res.data;
})
export const fetchJobsForStaffMember = createAsyncThunk('/fetchJobsForStaffMember',async(user)=>{
    const res = await axios.post("/admin/fetchjobsforstaffmember",user,config);
    return res.data;
})
export const fetchAdminInpectionList = createAsyncThunk('/fetchAdminInpectionList',async(user)=>{
    const res = await axios.post("/admin/fetchadmininpectionlist",user,config);
    return res.data;
})
export const signOut = createAsyncThunk("/signOut", async (user) => {
    const res = await axios.post("/admin/signout",user,config);
    return res.data;   
})
export const jobStatusStart = createAsyncThunk('/jobStatusStart',async(user)=>{
    const res = await axios.post("/admin/jobstatusstart",user,config);
    return res.data;
})
export const jobStatusComplete = createAsyncThunk('/jobStatusComplete',async(user)=>{
    const res = await axios.post("/admin/jobstatuscomplete",user,config);
    return res.data;
})
export const deleteHealth = createAsyncThunk("/deleteHealth", async (user) => {
    const res = await axios.post("/admin/deleteHealth",user,config);
    return res.data;   
})
export const adaptionStatusStart = createAsyncThunk('/adaptionstatusstart',async(user)=>{
    const res = await axios.post("/admin/adaptionstatusstart",user,config);
    return res.data;
})
export const adaptionStatusComplete = createAsyncThunk('/adaptionstatuscomplete',async(user)=>{
    const res = await axios.post("/admin/adaptionstatuscomplete",user,config);
    return res.data;
})
export const allQuotations = createAsyncThunk("/allquotations", async (user) => {
    const res = await axios.post("/admin/allquotations",user,config);
    return res.data;
})
export const allQoutedAdaption = createAsyncThunk("/allQoutedAdaption", async (user) => {
    const res = await axios.post("/admin/allqoutedadaption",user,config);
    return res.data;
})
export const addANewVechile = createAsyncThunk("/addANewVechile", async (user) => {
    const res = await axios.post("/admin/addanewvechile",user,config);
    return res.data;
})
export const fetchSingleHandover = createAsyncThunk("/fetchSingleHandover", async (user) => {
    const res = await axios.post("/admin/fetchsinglehandover",user,config);
    return res.data;
})
export const editVechicle = createAsyncThunk("/editVechicle", async (user) => {
    const res = await axios.post("/admin/editvechicle",user,config);
    return res.data;
})
export const deleteVechicle = createAsyncThunk("/deleteVechicle", async (user) => {
    const res = await axios.post("/admin/deletevechicle",user,config);
    return res.data;
})
export const addVechicleInspection = createAsyncThunk("/addVechicleInspection", async (user) => {
    const res = await axios.post("/admin/addvechicleinspection",user,config);
    return res.data;
})
export const fetchVechicleInspectionList = createAsyncThunk("/fetchVechicleInspectionList", async (user) => {
    const res = await axios.post("/admin/fetchvechicleinspectionlist",user,config);
    return res.data;
})
export const fetchSingleVechicleInspectionList = createAsyncThunk("/fetchSingleVechicleInspectionList", async (user) => {
    const res = await axios.post("/admin/fetchsinglevechicleinspectionlist",user,config);
    return res.data;
})
export const addDismantle = createAsyncThunk("/addDismantle", async (user) => {
    const res = await axios.post("/admin/adddismantle",user,config);
    return res.data;
})
export const fetchAllScaffolldingInspections = createAsyncThunk("/fetchAllScaffolldingInspections", async (user) => {
    const res = await axios.post("/admin/fetchallscaffolldinginspections",user,config);
    return res.data;
})
export const addAdaptionEscaffQoutation=createAsyncThunk('/addAdaptionEscaffQoutation',async(user)=>{
    const res = await axios.post("/admin/addadaptionescaffQoutation",user,config);
    return res.data;
})
export const approveQoutes=createAsyncThunk('/approveQoutes',async(user)=>{
    const res = await axios.post("/admin/approveqoutes",user,config);
    return res.data;
})
export const approveAdaptions=createAsyncThunk('/approveadaptions',async(user)=>{
    const res = await axios.post("/admin/approveadaptions",user,config);
    return res.data;
})

const adminSlice=createSlice({
    name:"admin",
    initialState:{
        _id:adminData._id,
        email:adminData.email,
        fullName:adminData.fullName,
        isLoading:adminData.isLoading,
        alert:adminData.alert,
        stripeCustomerId:adminData.stripeCustomerId,
        priceId:"price_1Nghimmbghjj",
        subscribed:adminData.subscribed,
        companyDetails:{customerOrCompanyName:"",telephone:"",email:"",address1:"",address2:"",postCode:""},
        companyDocuments:[],
        companyLogo:"",
        keyMemberStaff:[],
        swalAlert:"",
        priceList:{linearMerterageCost:"35.00",hopUpCost:"35.00",saddleCost:"36.00",throwinCost:"35.00",hakkiLayher:"35.00",temporaryRoof:"28.00",monoflex:"3.00",shrinkwrap:"6.00",fivebyonepointeightTower:"175.00",fivebyonepointeightTowerCostPerLift:"75.00",eightbytwopointfourTower:"200.00",eightbytwopointfourTowerCostPerLift:"85.00",tenbythreeTower:"250.00",tenbythreeTowerCostPerLift:"95.00",thirteenbyfourTower:"300.00",thirteenbyfourTowerCostPerLift:"100.00",scaffoldingInspectionPerVisit:"75.00",overhirePercentage:"10",addItem:[],adminID:adminData._id},
        newCustomer:[],
        customerSwalAlert:"",
        pendingQoutes:[],
        handOver:[],
        handOverData:{
            customerID: "",
            clientName:"",
            address:"",
            postcode:"",
            dateOfScoffolldingCompletion: "",
            time: "",
            descriptionHandover: "",
            drawingNo: "",
            MaximumLoading: "",
            methodOfTyping: "",
            tiesBeenTested: "",
            methodOfCladding: "",
            handover: "",
            detailsNessesary: "",
            scaffoldingContractor: "",
            scaffolderEmail: "",
            adminID: "",
          },
        singleJob:{},
        singleAdaption:{},
        reload: true,
        escaffQoutation:{nextInspectionDate:"",dateOfErection:"",numberOfWeekRequired:4,standardNumberOfWeek:4,gantryElevation:"",gantryLift:0,gantryLength:0,gantryWidth:0,bayElevation:"",bayLift:0,baylength:0,bayWidth:0,deckLength:0,deckLift:0,deckWidth:0,hopUp:0,saddle:0,throwIn:0,hakkiStair:0,fiveFootTower:0,fiveFootTowerLift:0,eightFootTower:0,eightFootTowerLift:0,tenFootTower:0,tenFootTowerLift:0,thirteenFootTower:0,thirteenFootTowerLift:0,streetLicenseCost:0,customerVatRegister:0,externalDesignCost:0,additionalItem:[],WeeklyScaffoldingInspections:false,customerVatRegisterSwitch:false,adaptionIncludedInTheQuotation:false,totalCostResult:0,dynamicOBJ:[],adminID:adminData._id,jobID:"",customerID:"",approved:0},
        escaffAdaptionQoutation:{nextInspectionDate:"",dateOfErection:"",numberOfWeekRequired:4,standardNumberOfWeek:4,gantryElevation:"",gantryLift:0,gantryLength:0,gantryWidth:0,bayElevation:"",bayLift:0,baylength:0,bayWidth:0,deckLength:0,deckLift:0,deckWidth:0,hopUp:0,saddle:0,throwIn:0,hakkiStair:0,fiveFootTower:0,fiveFootTowerLift:0,eightFootTower:0,eightFootTowerLift:0,tenFootTower:0,tenFootTowerLift:0,thirteenFootTower:0,thirteenFootTowerLift:0,streetLicenseCost:0,customerVatRegister:0,externalDesignCost:0,additionalItem:[],WeeklyScaffoldingInspections:false,customerVatRegisterSwitch:false,adaptionIncludedInTheQuotation:false,totalCostResult:0,dynamicOBJ:[],adminID:adminData._id,jobID:"",customerID:"",approved:0},
        escaffFetched: false,
        temporaryRoofQoutation: {nextInspectionDate:"", dateOfErection: 0, numberOfWeeks: 4,standardNumberOfWeek:4, permiterOfBuilding: 0, numberOfLiftsForPerimeter: 0, widthOfBuilding: 0, lengthOfBuilding: 0, monoflexOrShrinkWrap: 0, HeightFromGutteringToTop: 0, weeklyScoffoldingInspectionSwitch: false, streetLicenseCost: 0, customerVatRegister: 0, customerVatRegisterSwitch: false, externalDesingCost: 0,  totalCostResult: 0, jobID: "", adminID: adminData._id, customerID: "",approved:0 },
        temporaryRoofAdaptionQoutation: {nextInspectionDate:"", dateOfErection: 0,totalCostResult:0, jobID: "", adminID: adminData._id, customerID: "",approved:0,adaptionID:"" },
        adminApprovedQoutes: [],
        adminApprovedAdaptionQoutes: [],
        adminDeclinedQoutes: [],
        jobforStaffMember: [],
        signOut: "",
        healthAndSafety:[],
        allQuotationQuoted: [],
        newVechicle: [],
        vechicleInspectionList: [],
        viewVechicleCheck: {},
        viewVechicleVan: {},
        viewVechicleData: {},
        listOfScaffoldingInspection: [],
        pendingAdaptions: [],
        allQoutedAdaption: [],
        allQuotationAdaptionQuoted: [],
        tokenCheck: "",
        adaptionDetails:"",
        

    },
    reducers:{
        addAlert(state,action){
            state.alert=[action.payload.type,action.payload.title,action.payload.message]
        },
        removeAlert(state,action){
            state.alert=[];
        },
        removeSwalAlert(state,action){
            state.swalAlert="";
        },
        removeCustomerSwalAlert(state,action){
            state.swalAlert="";
        },
        setReloadToFasle(state,action){
            state.reload=false;
        },
        setReloadToTrue(state,action){
            state.reload=true;
        },
        removeEscaffFetched(state,action){
            state.escaffFetched=false;
        },
        signOut(state,action){
            state._id="";
            state.email="";
            state.fullName="";
            state.isLoading="";
            state.alert="";
            state.stripeCustomerId="";
            state.priceId="price_1MZAvSL4AlFFiyI0GwvwIqaT";
            state.subscribed = "";
            localStorage.removeItem("adminData")
            localStorage.removeItem("customerData")
            localStorage.removeItem("staffData")
        },
        removeSignout(state, action) {
            state.signOut=""
        }
        ,
        removeCheckToken(state, action) {
            state.checkToken=""
        }
        ,
        adaminSubscribed(state, action) {
            state.subscribed = true
            localStorage.setItem('adminData',JSON.stringify( {_id:state._id,fullName:state.fullName,email:state.email,isLoading:false,alert:["success","LoggedIn Successfully!",""],subscribed:true}))
        }
    },
    extraReducers:{
        [registerUser.fulfilled]:(state,{payload})=>{
            state.alert=["success","Registered Successfully","kindly LogIn !"]
        },
        [registerUser.pending]:(state,{payload})=>{
            state.isLoading=true
        },
        [registerUser.rejected]:(state,{payload})=>{
            state.isLoading=false
          state.alert=["unsuccess","Unsuccessfull","E-mail is already registered !"]
        },
        [loginUser.fulfilled]:(state,{payload})=>{
            if(payload.message==="email"){

                state.alert=["unsuccess","Unsuccessfull","E-mail Not Found!"]
            }else if(payload.message==="password"){
                state.alert=["unsuccess","Unsuccessfull","Incorrect Password!"]
            }else{
                localStorage.setItem('adminData',JSON.stringify( {_id:payload._id,fullName:payload.fullName,email:payload.email,isLoading:false,alert:["success","LoggedIn Successfully!",""],subscribed:payload.subscribed}))

                state._id=payload._id
                state.fullName=payload.fullName
                state.email=payload.email
                state.isLoading=false
                state.alert=["success","LoggedIn Successfully!",""]
                state.subscribed=payload.subscribed
            }
        },
         [adminSubscribed.fulfilled]:(state,{payload})=>{
            localStorage.setItem('adminData',JSON.stringify( {_id:payload._id,fullName:payload.fullName,email:payload.email,isLoading:false,alert:["success","LoggedIn Successfully!",""],subscribed:payload.subscribed}))

                state._id=payload._id
                state.fullName=payload.fullName
                state.email=payload.email
                state.isLoading=false
                state.alert=["success","LoggedIn Successfully!",""]
                state.subscribed=payload.subscribed
        },
        [savePriceList.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }
        },
        [addCompanyDetails.fulfilled]:(state,{payload})=>{
        if (payload.error === "Adminnotsubscribed") {
            state.signOut="signOut";
        }else  if (!payload.error) {
                state.companyDetails = { customerOrCompanyName: payload.customerOrCompanyName, telephone: payload.telephone, email: payload.email, address1: payload.address1, address2: payload.address2, postCode: payload.postCode }
                state.companyLogo = payload.companyLogo;
                state.formAlert = payload
            }
        },
        [getCompanydata.fulfilled]: (state, { payload }) => {
            
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload.message === "noData") {
                    state.companyDetails = { customerOrCompanyName: "", telephone: "", email: "", address1: "", address2: "", postCode: "" }
                } else {
                    state.companyDetails = { customerOrCompanyName: payload[0].customerOrCompanyName, telephone: payload[0].telephone, email: payload[0].email, address1: payload[0].address1, address2: payload[0].address2, postCode: payload[0].postCode }
                    state.companyDocuments = payload[0].companyDocument;
                    state.companyLogo = payload[0].companyLogo;
                }
            }
        
        },
        [deleteFile.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.companyDocuments = payload[0].companyDocument;
            }
        },
        [checkToken.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            } else if (!payload.error) {
                console.log(payload.message)
                state.tokenCheck = payload.message;
                if (payload.message === "invalidToken") {
                    state.subscribed=false
                } else {
                    state.subscribed=true
                }
            }
        },
        [addKeyStaffMember.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload.message === "emailDuplicated") {
                    state.swalAlert = payload.message
                } else {
                    state.keyMemberStaff = payload
                }
            }
        },
        [fetchKeyStaffMember.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.keyMemberStaff = payload;
            }
        },
        [deleteItemKeyStaffMembers.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.keyMemberStaff = payload;
            }
        },
        [editKeyStaffMembers.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload.message) {
                } else {
                    state.keyMemberStaff = payload;
                }
            }
        },
        [fetchPriceList.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload == null) {
                } else {
                    state.priceList = payload;
                }
            }
        },
        [createNewCustomer.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if(!payload.error){
            if(payload.message==="emailDuplicated"){
                state.customerSwalAlert = payload.message
            }else{
                state.newCustomer=payload
            }  }          
        },
        [fetchCustomer.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
           
                state.newCustomer = payload;
            }
        },
        [addANewJob.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.swalAlert = "added"
            }
           
            // state.newCustomer = payload;
        },
        [addANewAdaption.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.swalAlert = "added"
            }
           
            // state.newCustomer = payload;
        },
        [fetchSingleHandover.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.handOverData = payload
            }
           
            // state.newCustomer = payload;
        },
        [pendingQoutes.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                state.pendingQoutes = payload;
            }
        },
        [fetchHandOver.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                state.handOver = payload;
            }
        },
        [deleteHandOver.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                state.handOver = payload;
            }
        },
        [pendingArchiveQoutes.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                state.pendingQoutes = payload;
            }
        },
        [pendingQoutesAddToArchive.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                   state.swalAlert = "done";
            }
        },
        [pendingQoutesAddToUnArchive.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                   state.swalAlert = "done";
            }
        },
        [pendingAdaptionsAddToArchive.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                   state.swalAlert = "done";
            }
        },
        [pendingAdaptionsAddToUnArchive.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                   console.log(payload);
                   state.swalAlert = "done";
            }
        },
        [pendingAdaptions.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                //    console.log(payload);
                state.pendingAdaptions = payload;
            }
        },
        [pendingArchiveAdaptions.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                //    console.log(payload);
                state.pendingAdaptions = payload;
            }
        },
        [deleteApproveQoutes.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                //    console.log(payload);
                state.adminApprovedQoutes = payload;
            }
        },
        [fetchAdminScaffoldingInspection.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                //    console.log(payload);
                state.adminApprovedQoutes = payload;
            }
        },
        [deleteApproveAdaptionQoutes.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                //    console.log(payload);
                state.adminApprovedAdaptionQoutes = payload;
            }
        },
        [deletedeclineqoutation.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                //    console.log(payload);
                state.adminDeclinedQoutes = payload;
            }
        },
        [deleteJob.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.pendingQoutes = payload;
                
            }
         },
        [approveQoutes.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = "done";
                
            }
         },
        [approveAdaptions.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = "done";
                
            }
         },
        [deleteAdaption.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.pendingAdaptions = payload;
                
            }
         },
        [fetchSingleJob.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.singleJob = payload;
            }
         },
        [fetchSingleAdaption.fulfilled]: (state, { payload }) => {
            console.log(payload)
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.singleAdaption = payload;
            }
         },
        [deleteCustomer.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.newCustomer = payload;
            }
        },
        [addEscaffQoutation.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = payload
            }
        },
        [addAdaptionEscaffQoutation.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = payload
            }
        },
        [addTemproryRoofQoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = payload
            }
        },
        [addTemproryRoofAdaoptionQoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert = payload
            }
        },
        [editCustomer.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload.message) {
                } else {
                    state.newCustomer = payload;
                }
            }
        },
        [fetchEscaffQoutation.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload === null) {
                } else {
                    state.escaffQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },
        [fetchSingleescaffQoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload === null) {
                } else {
                    state.escaffQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },
        [fetchAdaptionEscaffQoutation.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload === null) {
                } else {
                    state.escaffAdaptionQoutation = payload;
                    state.adaptionDetails=payload.adaptionID.adaptionDetails
                    state.escaffFetched = true;
                }
            }
        },
         [fetchSingleescaffAdaptionqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                if (payload === null) {
                } else {
                    state.escaffAdaptionQoutation = payload;
                    state.adaptionDetails=payload.adaptionID.adaptionDetails
                    state.escaffFetched = true;
                }
            }
        },
        [fetchAdminApprovedQoutesLiveProject.fulfilled]: (state, { payload }) => {
           
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedQoutes = payload
            }
        },
        [fetchAdminApprovedQoutes.fulfilled]: (state, { payload }) => {
           
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedQoutes = payload
            }
        },
        [fetchAdminApprovedQoutesJobs.fulfilled]: (state, { payload }) => {
           
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedQoutes = payload
            }
        },
        [fetchAdminApprovedAdaptionQoutesLiveProject.fulfilled]: (state, { payload }) => {
            // if(payload === null){
            //     console.log("----aaaa-aa----")
            // } else {
            //     state.escaffQoutation = payload;
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedAdaptionQoutes = payload
            }
        },
        [fetchAdminApprovedAdaptionQoutes.fulfilled]: (state, { payload }) => {
            // if(payload === null){
            //     console.log("----aaaa-aa----")
            // } else {
            //     state.escaffQoutation = payload;
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedAdaptionQoutes = payload
            }
        },
        [fetchAdminApprovedAdaptionQoutesJobs.fulfilled]: (state, { payload }) => {
            // if(payload === null){
            //     console.log("----aaaa-aa----")
            // } else {
            //     state.escaffQoutation = payload;
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {   //     state.escaffFetched = true;
                // }
                state.adminApprovedAdaptionQoutes = payload
            }
        },
        [fetchAdminDeclineQoutes.fulfilled]: (state, { payload }) => {
            // if(payload === null){
            //     console.log("----aaaa-aa----")
            // } else {
            //     state.escaffQoutation = payload;
            //     state.escaffFetched = true;
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {    // }
                state.adminDeclinedQoutes = payload
            }
        },
        [fetchTemproryRoofQoutation.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload === null) {
                } else {
                    state.temporaryRoofQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },
        [fetchsingletemproryroofqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload === null) {
                } else {
                    state.temporaryRoofQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },
        [fetchTemproryRoofAdaptionQoutation.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload === null) {
                } else {
                    state.temporaryRoofAdaptionQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },
        [fetchsingletemproryroofqadaptionqoute.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload === null) {
                } else {
                    state.temporaryRoofAdaptionQoutation = payload;
                    state.escaffFetched = true;
                }
            }
        },

        [addCompanyDocuments.fulfilled]:(state,{payload})=>{
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                if (payload.message === "addCompanyDetailsFirst") {
                    state.swalAlert = "UploadCompanyDocumentsFirst!"
                } else {
                    // console.log(payload)
                    state.companyDocuments = payload[0].companyDocument;
                }
            }
        },
        [addHandOver.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (payload.mesasge === "handoverAdded") {
                state.swalAlert = payload.mesasge
            }
           
         },
        [addAdaptionHandOver.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (payload.mesasge === "handoverAdded") {
                state.swalAlert = payload.mesasge
            }
           
         },
        [addHealthAndSafety.fulfilled]: (state, { payload }) => {
         
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.healthAndSafety = payload
            }
           
         },
        [fetchHealthAndSafety.fulfilled]: (state, { payload }) => {
         
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.healthAndSafety = payload
            }
           
         },
        [deleteHealth.fulfilled]: (state, { payload }) => {
         
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else   if (!payload.error) {
                state.healthAndSafety = payload
            }
           
         },
         [assignJobToStaffMember.fulfilled]:(state,{payload})=>{
           
        },
        [addInspections.fulfilled]:(state,{payload})=>{
           
         },
        [fetchJobsForStaffMember.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.jobforStaffMember = payload
            }
         },
         [signOut.fulfilled]: (state, { payload }) => {
             console.log("hello", "test")
             localStorage.clear();
                state._id="";
                state.email="";
                state.fullName="";
                state.isLoading="";
                state.alert="";
                state.stripeCustomerId="";
                state.priceId="price_1MZAvSL4AlFFiyI0GwvwIqaT";
                state.subscribed = "";
               
                state.signOut = "signOut";
           
         },
         [allQuotations.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
            state.allQuotationQuoted = payload;}
         },
        [allQoutedAdaption.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
                state.allQuotationAdaptionQuoted = payload;}
            
         },
        [addANewVechile.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
                state.newVechicle = payload;}
            
         },
        [fetchVechicle.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
                state.newVechicle = payload;}
            
         },
        [editVechicle.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
                state.newVechicle = payload;}
            
         },
        [deleteVechicle.fulfilled]: (state, { payload }) => {
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if(!payload.error ){
                state.newVechicle = payload;}
            
        },
        [addVechicleInspection.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else if (!payload.error) {
                
                state.swalAlert = payload.message;
            }
         },
        [fetchVechicleInspectionList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                
                state.vechicleInspectionList = payload;
            }
         },
        [fetchSingleVechicleInspectionList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.viewVechicleCheck=JSON.parse(payload.vechicleCheck)
                state.viewVechicleVan = JSON.parse(payload.van)
                state.viewVechicleData = {typeOfVehicle:payload.typeOfVehicle, date: payload.date,dateOfCheck: payload.dateOfCheck,defectRectified: payload.defectRectified,defectReport: payload.defectReport,nameOfChecker: payload.nameOfChecker,registration: payload.registration,signedOff: payload.signedOff }
            }
         },
        [addDismantle.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.swalAlert=payload.message
            }
         },
        [fetchAllScaffolldingInspections.fulfilled]: (state, { payload }) => {
            console.log(payload);
            if (payload.error === "Adminnotsubscribed") {
                state.signOut="signOut";
            }else  if (!payload.error) {
                state.listOfScaffoldingInspection=payload
            }
         },
      
    }
})

export const adminActions=adminSlice.actions;
export default adminSlice;