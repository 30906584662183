import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
// import { addANewJob, adminActions, fetchCustomer } from '../store/adminSlice';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import logo from '../pictures/TheBestescafflogo.png'
import CustomerFourButtonsTwo from '../components/CustomerFourButtonsTwo';
import CustomerSidebar from '../components/CustomerSidebar';
import { addANewJob, customerActions } from '../store/customerSlice';
import CustomerLogo from '../components/CustomerLogo';
import escapeHtml from '../functions/Validator';

function CustomerAddANewJob() {
    const [ needed, setNeeded ] = useState(false);
    const dispatch=useDispatch();
    const [selectedImages,setSelectedImages]=useState([]);
    const stateSwalAlert = useSelector((state)=>state.customer.swalAlert);
    const stateCustomerID = useSelector((state)=>state.customer.customer_id);
    const stateCustomerName = useSelector((state)=>state.customer.customerFullName);
    const [documents,setDocument]=useState([]);
    const _id=useSelector((state)=>state.customer.adminID);
    const [data,setData]=useState({customer:"",job1:"",job2:"",postCode:"",jobDescription:"",jobDetails:"",scafolldingType:""})
    const navigate=useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      dispatch(customerActions.removeSwalAlert())
      // dispatch(fetchCustomer({adminID:_id}))
    }, [])
    
  const imageHandler = (e) => {
    setDocument(e.target.files);

    const fileArray= Array.from(e.target.files).map((file)=>URL.createObjectURL(file))
    
    setSelectedImages((prevImage)=>prevImage.concat(fileArray))
    Array.from(e.target.files).map((file)=>{
      return URL.revokeObjectURL(file);
    })

    }
    const renderPhotos= (source)=>{
     return source.map((photo)=>{
      return(<div className='mx-2 my-2 flex items-center justify-center'>
      <img className='mt-2 object-contain' src={photo} key={photo}alt='addJobPhoto' />
    </div>)
     })

    }

    useEffect(() => {
      if(stateSwalAlert==="added"){
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'success',
          confirmButtonColor:"#A7B778",
          text: 'Job Added Successfully! ',
        }).then(()=>{
        dispatch(customerActions.removeSwalAlert())
        window.location.reload(true)
      })
    }
    },[stateSwalAlert]);

    const submit=(e)=>{
      e.preventDefault();
      setLoading(true);

    if (
      data.job1 === "" ||
      data.postCode === "" ||
      data.jobDescription === "" ||
      data.jobDetails === "" ||
      documents.length === 0 || data.scafolldingType===""
    ) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly fill all the inputs & Upload logo/documents.!",
      });
    } else {
      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        formData.append("images", documents[i]);
      }
      formData.append("customer", stateCustomerID)
      formData.append("job1", data.job1);
      formData.append("job2", data.job2);
      formData.append("postCode", data.postCode);
      formData.append("jobDescription", data.jobDescription);
      formData.append("jobDetails", data.jobDetails);
      formData.append("typeofScafollding", data.scafolldingType);
      formData.append("adminID", _id);
      dispatch(addANewJob(formData));
    }
  };

  return (
    <div className="w-full h-full">
      <header className="border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24">
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        <CustomerLogo />
        <div className="w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8">
          <CustomerFourButtonsTwo />
        </div>
      </header>
      <div className="w-full flex h-auto p-6">
        <CustomerSidebar needed={needed} />

        <div className="lg:basis-3/4 w-full ">
          <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4">
            Add a new job
          </p>
          <div className="lg:flex lg:flex-row flex-col">
            <div className="lg:basis-1/2 w-full">
              <div className="p-2 flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                  Select Customer
                </p>
                <input className='bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1' disabled value={stateCustomerName} type="text"/>
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Add New Job Address
                </p>
                <input
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  value={data.job1}
                  onChange={(e) => setData({ ...data, job1: escapeHtml(e.target.value) })}
                  required
                  type="text"
                />

                <input
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 mt-5"
                  value={data.job2}
                  onChange={(e) => setData({ ...data, job2: escapeHtml(e.target.value) })}
                  required
                  type="text"
                />
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  PostCode
                </p>

                <input
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 w-1/2 outline-none pl-1"
                  value={data.postCode}
                  onChange={(e) =>
                    setData({ ...data, postCode: escapeHtml(e.target.value) })
                  }
                  required
                  type="text"
                />
              </div>
            </div>

            <div className="basis-1/2 flex-col flex  justify-between ">
              <div className="p-2 flex flex-col w-full ">
                <p className="p-1 font-semibold text-xs xxl:text-xl">
                  Job Description
                </p>
                <textarea
                  className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  value={data.jobDescription}
                  onChange={(e) =>
                    setData({ ...data, jobDescription: escapeHtml(e.target.value) })
                  }
                  id="w3review"
                  name="w3review"
                  rows="3"
                  cols="50"
                ></textarea>

                <p className="p-1 font-semibold text-xs xxl:mt-2 xxl:text-xl mt-1">
                  Access To Job Details
                </p>
                <div className="flex justify-between">
                  <input
                    className="bg-gray-200 opacity-50 p-[2px] w-full xxl:p-3 outline-none pl-1"
                    value={data.jobDetails}
                    onChange={(e) =>
                      setData({ ...data, jobDetails: escapeHtml(e.target.value) })
                    }
                    required
                    type="text"
                  />
                </div>
                  <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                  Type of Scaffolding (Please Select)
                </p>
                 <select
                  name="cars"
                  onChange={(e) =>
                    setData({
                      ...data,
                      scafolldingType: e.target.value
                    })
                  }
                  className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1"
                  id="cars"
                >
                  <option value="">Select Scaffolding</option>
                  <option value="0">Scaffolding</option>
                  <option value="1">Temporary</option>
                </select>
              </div>
  
          
         </div>
         
       </div>
          <div className='ml-2 lg:w-full xxl:w-full mt-4 '>
            <label className=' w-24 h-8 xxl:w-32 xxl:h-10 px-4 p-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'   >
              <input type="file" multiple onChange={imageHandler} className='hidden'/>
                    Add Photo
              </label>
            <div className='flex justify-end -mt-6'>
              {loading?(
                <>
                  <CircularProgress className='px-1 mx-2 -mt-2' color="success"/>
                  <p>Submitting Form</p>
                </>
              ):(
                <button id="abw" className='h-8 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen' onClick={submit}>submit</button>
              )}
            </div>
            <div className='grid  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xxl:grid-cols-5 mt-4'>
                  {renderPhotos(selectedImages)}
            </div>
          </div>
         </div>
     </div>
  </div>
    )
}

export default CustomerAddANewJob