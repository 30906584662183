import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from '../components/Sidebar'
import HeaderScaff from '../components/HeaderScaff';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions, deletedeclineqoutation, deleteJob, fetchAdminApprovedAdaptionQoutes, fetchAdminDeclineQoutes, signOut } from '../store/adminSlice';
import axios from "../functions/axios"
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import $  from 'jquery'
$.DataTable = require('datatables.net')

function QuotesDecline() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [needed, setNeeded] = useState(false);
  const _id = useSelector((state) => state.admin._id)
  const stateApprovedQoutes = useSelector((state) => state.admin.adminDeclinedQoutes)
  const statePriceList=useSelector((state)=>state.admin.priceList)
  const adminApprovedAdaptionQoutes = useSelector((state) => state.admin.adminApprovedAdaptionQoutes)

  const dispatch = useDispatch();
  const subscribed = useSelector((state) => state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  const navigate = useNavigate();
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut])
  useEffect(() => {
    if(!_id){
      navigate("/signin")
    } else if (!subscribed) {
      navigate("/signin")
      
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  },[])
  const deletee=(id)=>{
    dispatch(deletedeclineqoutation({_id:id,adminID:_id}))
  }
  
  
  useEffect(() => {
    dispatch(fetchAdminDeclineQoutes({ adminID: _id }))
    dispatch(fetchAdminApprovedAdaptionQoutes({ adminID: _id }))
    setTimeout(() => {
      $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
      
    }, 5000);
   
    dispatch(adminActions.removeSwalAlert());
  }, [])
  useEffect(() => {
 
  }, [stateApprovedQoutes])
  const handleTemprory = (_id, b, c) => {
    var x=0
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
    axios.post("/admin/fetchsingletemproryroofqoute", { _id: _id }).then((res) => {
      axios.post("/admin/fetchsingletemproryroofqadaptionqoute", { _id: x }).then((ress) => {
        axios.post("admin/getcompanydata", { adminID: c },{
          headers:{
              authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
          }
        }).then((resss) => {
          const data = { objects: [res.data, statePriceList,ress.data,resss.data] };
      console.log(data);
        axios.post('/createtemproryroofqoutationpdf',data)
        .then(() => axios.get('/fetchfile',
   
          {
            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          }
        ))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
          saveAs(pdfBlob, "new.pdf");
        })
      })
    })})
  }
  const handleEscaff = (_id, b, c) => {
    var x=0
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
    axios.post("/admin/fetchsingleescaffqoute", { _id: _id }).then((res) => {
      axios.post("/admin/fetchsingleescaffadaptionqoute", { _id: x }).then((ress) => {
        axios.post("admin/getcompanydata", { adminID: c },{
          headers:{
              authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
          }
        }).then((resss) => {
          const data = { objects: [res.data, statePriceList,ress.data,resss.data] };
      console.log(data);
        axios.post('/createescaffqoutationpdf',data)
        .then(() => axios.get('/fetchfile',
          {
            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          }
        ))
        .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
          saveAs(pdfBlob, "new.pdf");
        })
      })
    })})
  }
  
  return (
    <div className='w-full h-full'>
      <HeaderScaff setSidebarOpen={setSidebarOpen}/>
      <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide": "hidden" : "w-full overflow-x-scroll scrollbar-hide" } " "`}>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Quotes Declined</p>
         
         <div className='lg:w-full pr-1 '>
           <table className='editor_listing_table '   id="table"  >
             <thead className='w-full'>
             <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
             <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>PostCode</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Email</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white'>Telephone</th>
             <th className='text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=''>
                {stateApprovedQoutes.length !== 0 && stateApprovedQoutes.map((data) => {
                   var adaptionFound = adminApprovedAdaptionQoutes.find((item) => data._id === item.adaptionID.qouteID)
                   var adaptionQouteId = 0
                  
                   
                   if (adaptionFound !== undefined) {
                  
                     adaptionQouteId=adaptionFound._id
                   }
             return (

              <tr className=''>
              <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
              {/* <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-center text-[11px] xxl:text-sm'>{data.id}</td>
              <td className='w-3 /12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.name}</td> */}
              <td className='w-4/12 border-[1px] border-gray-300 pl-2 p-1 text-[10px] xxl:text-[13px]'>{data.jobID.job1 + " "+ data.jobID.job2}</td>
              <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.jobID.postCode}</td>
              <td className='w-2/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customerID.email}</td>
              <td className='w-1/12 border-[1px] border-gray-300 pl-2 p-1 text-[11px] xxl:text-sm'>{data.customerID.telephone}</td>
              <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
                <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
                {data.type==="temproryRoofQoute"? <div className='flex flex-col items-center' onClick={(e)=>navigate(`/escafftemproofqoutationpdf?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                     </div> :
                       <div className='flex flex-col items-center' onClick={(e)=>navigate(`/escaffscaffolldingqoutation?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>}   
                 <div className='flex flex-col items-center'>
                 <DeleteIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer" onClick={(e)=>deletee(data.jobID._id)}/><p className='text-[10px] xxl:text-sm'>Delete</p>
                 </div>                      
                </div>
              </td>
              </tr>
             )})}
</tbody>

</table>
         
</div>
       </div>
   </div>
</div>
  )
}

export default QuotesDecline