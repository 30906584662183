import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Sidebar from '../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { addANewAdaption, addANewJob, adminActions, fetchAdminApprovedQoutes, fetchAdminApprovedQoutesLiveProject, fetchCustomer, signOut } from '../store/adminSlice';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderScaff from '../components/HeaderScaff';
import escapeHtml from '../functions/Validator';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

function AddAdaption() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(false);
  const dispatch=useDispatch();
  const stateApprovedQoutes = useSelector((state) => state.admin.adminApprovedQoutes)
  const [selectedImages,setSelectedImages]=useState([]);
  const stateSwalAlert = useSelector((state)=>state.admin.swalAlert);
  const [documents,setDocument]=useState([])
  const _id=useSelector((state)=>state.admin._id);
  const [data,setData]=useState({qouteID:"",customerID:"",jobID:"",adaptionDetails:"",type:""})
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false);
  const subscribed=useSelector((state)=>state.admin.subscribed);
  const statesignOut=useSelector((state)=>state.admin.signOut)
  useEffect(() => {
    if (statesignOut==="signOut") {
        dispatch(signOut())
    }
},[statesignOut]) 
  useEffect(() => {
      dispatch(adminActions.removeSwalAlert())
      dispatch(fetchAdminApprovedQoutesLiveProject({ adminID: _id }))
    }, [])
  
    useEffect(() => {
      if(!_id){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
        
      }
    },[])

  const imageHandler = (e) => {
    setDocument(e.target.files);
    console.log(documents);

      const fileArray= Array.from(e.target.files).map((file)=>URL.createObjectURL(file))
    
      setSelectedImages((prevImage)=>prevImage.concat(fileArray))
      Array.from(e.target.files).map((file)=>{
       return URL.revokeObjectURL(file);
      })

    }
    const assignQouteAndType = (a)=>{
        const arr = a.split("<");
        console.log(arr);
          setData({ ...data, qouteID:arr[0],customerID:arr[1],jobID:arr[2],type:arr[3] })
        
        }
    const renderPhotos= (source)=>{
     return source.map((photo)=>{
      return(<div className='mx-2 my-2 flex items-center justify-center'>
      <img className='mt-2 object-contain' src={photo} key={photo}alt='addJobPhoto' />
    </div>)
     })

    }
    useEffect(() => {
      console.log("hello world"); 
      if(stateSwalAlert==="added"){
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'success',
          confirmButtonColor:"#A7B778",
          text: 'Job Added Successfully! ',
        }).then(()=>{
        dispatch(adminActions.removeSwalAlert())
        window.location.reload(true)
      })
    }
    },[stateSwalAlert]);

    const submit=(e)=>{
      e.preventDefault();
      setLoading(true);

    if (
      data.qouteID === "" ||
      data.customerID === "" ||
      data.jobID === "" ||
      data.adaptionDetails === "" ||documents.length===0
    
    ) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#A7B778",
        text: "Kindly fill all the inputs & Upload logo/documents.!",
      });
    } else {
      const formData = new FormData();
      for (let i = 0; i < documents.length; i++) {
        formData.append("images", documents[i]);
      }
      formData.append("qouteID", data.qouteID);
      formData.append("customerID", data.customerID);
      formData.append("jobID", data.jobID);
      formData.append("adaptionDetails", data.adaptionDetails);
      formData.append("type", data.type);
      formData.append("adminID", _id);
      dispatch(addANewAdaption(formData));
    }
  };

  useEffect(() => {
    console.log("hello world");
    if (!localStorage.getItem("firstLoad")) {
      console.log("hello world2");
      localStorage["firstLoad"] = true;
      // window.location.reload();
    } else {
      localStorage.removeItem("firstLoad");
    }
    if(!isNonMobile){
      setSidebarOpen(false);
    }
  }, []);
  return (
    <div className="w-full h-full">
      <HeaderScaff/>
      <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
        <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
          <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
        </div>

        <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

        <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full ": "hidden" : "w-full" } " "`}>
            <p className="text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4">
              Add a new Adaption
            </p>
            <div className="lg:flex lg:flex-row flex-col">
              <div className="lg:basis-2/2 w-full">
                <div className="p-2 flex flex-col w-full ">
                  <p className="p-1 font-semibold text-xs xxl:text-xl xxl:mb-1">
                    Select Job
                  </p>
                  <select
                    name="cars"
                    onChange={(e) =>
                        assignQouteAndType(e.target.value)
                    }
                    className="bg-gray-200  opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-1/2"
                    id="cars"
                  >
                    <option value="" className="text-xs xxl:text-xl">
                      Select Job
                    </option>
                    {stateApprovedQoutes.map((c) => {
                      return (
                        <option value={c._id+"<"+c.customerID._id+"<"+c.jobID._id+"<"+c.type} className="text-xs xxl:text-xl">
                           {c.jobID.job1 + " "+ c.jobID.job2}
                        </option>
                      );
                    })}
                  </select>
                  <p className="p-1 font-semibold text-xs xxl:text-xl mt-6">
                    Adaption Details    
                  </p>
                  <textarea
                    className="bg-gray-200 opacity-50 p-[2px] xxl:p-3 outline-none pl-1 w-full"
                    value={data.adaptionDetails}
                    onChange={(e) =>
                      setData({ ...data, adaptionDetails: escapeHtml(e.target.value) })
                    }
                    id="w3review"
                    name="w3review"
                    rows="6"
                    cols="50"
                  ></textarea>
                </div>
              </div>

             
          
        </div>
            <div className='ml-2 lg:w-full xxl:w-full mt-4 '>
              <label className=' w-24 h-8 xxl:w-32 xxl:h-10 px-4 p-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'   >
                <input type="file" multiple onChange={imageHandler} className='hidden'/>
                      Add Photo
                </label>
              <div className='flex justify-end -mt-6'>
                {loading?(
                  <>
                    <CircularProgress className='px-1 mx-2 -mt-2' color="success"/>
                    <p>Submitting Form</p>
                  </>
                ):(
                  <button id="abw" className='h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen' onClick={submit}>submit</button>
                )}
              </div>
              <div className='grid  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xxl:grid-cols-5 mt-4'>
                    {renderPhotos(selectedImages)}
              </div>
            </div>
        </div>
      </div>
    </div>
    )
}

export default AddAdaption;