import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import Sidebar from '../components/Sidebar'
import FourButtons from '../components/FourButtons'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addInspections, signOut } from '../store/adminSlice';
import HeaderScaff from '../components/HeaderScaff';
import Swal from 'sweetalert2';
import escapeHtml from '../functions/Validator';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

function ScaffoldingInspections() {
    const isNonMobile = useMediaQuery("(min-width: 768px)");
    const [ sidebarOpen, setSidebarOpen ] = useState(true);
    const [needed, setNeeded] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search)
    const cutomerName = queryParameters.get("cutomerName")
    const cutomerAddress = queryParameters.get("cutomerAddress")
    const siteAddress = queryParameters.get("siteAddress")
    const customerID = queryParameters.get("customerID")
    const jobID = queryParameters.get("jobID")
    const type = queryParameters.get("type")
    const adminID = useSelector((state) => state.admin._id)
    const dispatch = useDispatch()
    const [documents, setDocument] = useState([])
    const [selectedImages, setSelectedImages] = useState([]);
    const statesignOut=useSelector((state)=>state.admin.signOut)

    useEffect(() => {
        if (statesignOut==="signOut") {
            dispatch(signOut())
        }
    },[statesignOut])
    const today = moment().format('YYYY-MM-DD')
    console.log(today)
    const onChangeDate = e => {
        const newDate = moment(new Date(escapeHtml(e.target.value))).format('YYYY-MM-DD');
        setData({ ...data, todayDate: newDate })
        // setData({ ...data, customerID: stateSingleJob.customer._id })
    };
    const [data, setData] = useState({type,adminID,jobID,customerID,administering:"", siteAddress,scaffolldingComponentWithoutDefects: false, mudsillAndFrame: false, legsBraces: false, securedStructure: false, assessories: false, toeBoard: false, componentAndPanking: false, planksCorrectly: false, competentPerson: false, scaffoldLevelAndPump: false, mudsillsProperlySizedAndPlaced: false, screwJack: false, areaAroundSecure: false, nessesaryGaurdRail: false, visualCheckToClamp: false, properAccessToGetOn: false, DontUse: false, controlTagApproved: false, plankMinimumTwelve: false, ladderSecure: false, Notes: "", signed: "", todayDate: today })
    const handleSubmit = () => {
        const formData = new FormData();
        if (documents.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                confirmButtonColor: "#A7B778",
                text: "Please upload a picture of the scaffolding tag !",
            });
        } else {
      
            formData.append("type", data.type)
            formData.append("adminID", data.adminID)
            formData.append("jobID", data.jobID)
            formData.append("customerID", data.customerID)
            formData.append("administering", data.administering)
            formData.append("scaffolldingComponentWithoutDefects", data.scaffolldingComponentWithoutDefects)
            formData.append("siteAddress", data.siteAddress)
            formData.append("mudsillAndFrame", data.mudsillAndFrame)
            formData.append("legsBraces", data.legsBraces)
            formData.append("securedStructure", data.securedStructure)
            formData.append("screwJack", data.screwJack)
            formData.append("planksCorrectly", data.planksCorrectly)
            formData.append("assessories", data.assessories)
            formData.append("toeBoard", data.toeBoard)
            formData.append("componentAndPanking", data.componentAndPanking)
            formData.append("competentPerson", data.competentPerson)
            formData.append("scaffoldLevelAndPump", data.scaffoldLevelAndPump)
            formData.append("mudsillsProperlySizedAndPlaced", data.mudsillsProperlySizedAndPlaced)
            formData.append("areaAroundSecure", data.areaAroundSecure)
            formData.append("nessesaryGaurdRail", data.nessesaryGaurdRail)
            formData.append("visualCheckToClamp", data.visualCheckToClamp)
            formData.append("properAccessToGetOn", data.properAccessToGetOn)
            formData.append("DontUse", data.DontUse)
            formData.append("controlTagApproved", data.controlTagApproved)
            formData.append("plankMinimumTwelve", data.plankMinimumTwelve)
            formData.append("ladderSecure", data.ladderSecure)
       
            formData.append("Notes", data.Notes)
            formData.append("signed", data.signed)
            formData.append("todayDate", data.todayDate)
            formData.append("document", documents)
            formData.append("image", "")
      
                ; dispatch(addInspections(formData)).then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Saved',
                        confirmButtonColor: "#A7B778",
                    })
                })
        }
    }
    const subscribed=useSelector((state)=>state.admin.subscribed);
  const navigate = useNavigate();
    useEffect(() => {
      if(!adminID){
        navigate("/signin")
      } else if (!subscribed) {
        navigate("/signin")
      }
      if(!isNonMobile){
        setSidebarOpen(false);
      }
    }, [])
    const imageHandler = (e) => {
        setDocument(e.target.files[0]);
        setSelectedImages([])
        console.log(documents);
    
          const fileArray= Array.from(e.target.files).map((file)=>URL.createObjectURL(file))
        
          setSelectedImages((prevImage)=>prevImage.concat(fileArray))
          Array.from(e.target.files).map((file)=>{
           return URL.revokeObjectURL(file);
          })
    
    }
    const renderPhotos = (source) => {
        return source.map((photo) => {
            return (<div className='mx-2 my-2 flex items-center justify-center'>
                <img className='mt-2 object-contain' src={photo} key={photo} alt='addJobPhoto' />
            </div>)
        })
    }
   
    
    return (
        <div className='w-full h-full'>
        <HeaderScaff/>
        <div className={`${sidebarOpen? "" : isNonMobile? "": "flex-col"} 'w-full flex h-auto p-2 sm:p-6'`}>
      <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div>

      <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div>

      <div className={`${sidebarOpen? isNonMobile? "lg:basis-3/4 w-full flex flex-col": "hidden" : "w-full flex flex-col mt-4" } `}>
      {/* <div className='lg:basis-3/4 w-full flex flex-col'> */}
        <div className='flex flex-col md:flex-row w-full h-[100px] justify-between items-center'>

            <div className=' md:basis-2/2 md:py-0 h-full flex w-full justify-center pt-4 '>
                <p className='text-xl xxl:text-3xl whitespace-nowrap'>Scaffolding Inspections</p>
            </div>
            
                    </div>
                    <div className='flex flex-col md:flex-row w-full  justify-between items-center'>
                    <div className='basis-1 md:basis-1/2 h-full flex flex-col w-full'>
                          <p className='text-sm xxl:text-lg'>Customer Name : {cutomerName }</p>
                          <p className='text-sm xxl:text-lg'>Address: {cutomerAddress }</p>
            </div>
                    </div>

        <div className='w-full flex flex-col xl:flex-row py-8'>
            <div className='w-full basis-1/2 flex flex-row h-5 items-center justify-start pr-4'>
                <p className='text-sm pr-2 whitespace-nowrap w-fit'>Site address</p>
                <input type="text" className='w-full h-6 border-[2px] border-gray-200 pl-2 outline-none'onChange={(e)=>setData({...data,siteAddress:escapeHtml(e.target.value)})}  value={siteAddress}/>
            </div>
            <div className='w-full basis-1/2 pt-1 xl:pt-0 flex flex-row h-5 items-center justify-start'>
                <p className='text-sm pr-2 md:whitespace-nowrap w-fit'>Name of person administering this checklist</p>
                <input type="text" className='w-full h-6 border-[2px] border-gray-200 pl-2 outline-none'onChange={(e)=>setData({...data,administering:escapeHtml(e.target.value)})} />
            </div>
        </div>

        <div className='flex w-full flex-col space-y-2 sm:space-y-1 md:space-y-[2px] xxl:space-y-1'>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all the sufficient scaffolding components in place and without any defects?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer'  onChange={(e)=>setData({...data,scaffolldingComponentWithoutDefects:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,scaffolldingComponentWithoutDefects:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all base plates in firm contact with mud sills and the frame of the scaffold?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer'onChange={(e)=>setData({...data,mudsillAndFrame:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,mudsillAndFrame:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all scaffold legs braced and braces properly attached to the scaffold?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,legsBraces:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,legsBraces:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is the scaffold secured to the structure to prevent movement?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,securedStructure:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,securedStructure:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are brackets,tubes,planks & accessories properly placed with wedges tightened?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,assessories:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,assessories:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is a toe board properly installed when necessary?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,toeBoard:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,toeBoard:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all scaffolding components and planking in the acceptable condition?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,componentAndPanking:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,componentAndPanking:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all planks correctly graded?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,planksCorrectly:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,planksCorrectly:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is a qualified, competent person in charge of the erection/Inspection?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,competentPerson:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,competentPerson:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is the whole scaffold level and plumb?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,scaffoldLevelAndPump:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,scaffoldLevelAndPump:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are mud sills properly placed adequately sized where ever required?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,mudsillsProperlySizedAndPlaced:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,mudsillsProperlySizedAndPlaced:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Were screw jacks used to level and plumb the scaffold whenever required?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,screwJack:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,screwJack:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Has the area around the scaffold been secured and safety signs installed?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,areaAroundSecure:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,areaAroundSecure:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Are all necessary guard rails in place on all open sides and ends?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,nessesaryGaurdRail:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,nessesaryGaurdRail:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Has there been a visual check to verify clamps are secured in place?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,visualCheckToClamp:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,visualCheckToClamp:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is there proper access to get on and off the scaffold?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,properAccessToGetOn:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,properAccessToGetOn:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Has "Don't Use" tag been placed at all access points where inspection failed?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,DontUse:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,DontUse:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Has the scaffold control tag been signed and approved for this particular use?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,controlTagApproved:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,controlTagApproved:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Do all planks have a minimum of 12" overlap and extend 6" beyond supports?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,plankMinimumTwelve:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,plankMinimumTwelve:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col sm:flex-row items-center justify-start sm:pr-4 space-x-[1px]'>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[90%] md:h-6 break-words'>
                    <p className='text-xs md:text-sm xxl:text-base pr-2 md:whitespace-nowrap w-fit pl-2'>Is the ladder secured and in place?</p>
                </div>
                <div className='flex border-[2px] border-gray-200 w-full sm:basis-[10%] h-9 md:h-6 space-x-2 justify-center items-center'>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,ladderSecure:true})}/>
                        <p className='text-xs xxl:text-sm'>Yes</p>
                    </div>
                    <div className='flex flex-row items-center space-x-1'>
                        <input type="checkbox" className='w-4 h-4 cursor-pointer' onChange={(e)=>setData({...data,ladderSecure:false})}/>
                        <p className='text-xs xxl:text-sm'>No</p>
                    </div>
                </div>
            </div>

        </div>

        <div className='flex w-full flex-col py-5'>
            <p className='font-semibold text-sm xxl:text-base pb-1'>Notes</p>
            <textarea rows='4' className='w-[90%] flex h-6 border-[2px] border-gray-200 pl-2 outline-none'onChange={(e)=>setData({...data,Notes:escapeHtml(e.target.value)})}/>
        </div>

        <div className='flex w-full flex-col md:flex-row pb-4'>
            <div className='flex w-full flex-col md:flex-row basis-[70%] space-y-2 sm:space-y-0'>
                <div className='w-full basis-1/2 flex flex-row h-5 items-center justify-start pr-4'>
                    <p className='text-sm pr-2 whitespace-nowrap w-fit'>Signed</p>
                    <input type="text" className='w-full h-6 border-[2px] border-gray-200 pl-2 outline-none' onChange={(e)=>setData({...data,signed:escapeHtml(e.target.value)})}/>
                </div>
                <div className='w-full basis-1/2 flex flex-row h-5 items-center justify-start'>
                    <p className='text-sm pr-2 whitespace-nowrap w-fit'>Today's Date</p>
                    <input type="date" className='w-full h-6 border-[2px] border-gray-200 pl-2 outline-none'onChange={onChangeDate} value={today}/>
                </div>
            </div>

            <div className='flex flex-row space-x-4 pt-3 md:pt-0 md:justify-center basis-[30%]'>
            <label className=' w-24 h-8 xxl:w-32 xxl:h-10 text-center p-2 self-end text-white cursor-pointer rounded-md bg-lightgreen text-sm xxl:text-xl hover:bg-darkgreen'   >
                <input type="file"  onChange={imageHandler} className='hidden'/>
                      Upload
                            </label>
                            <button className='bg-lightgreen py-[3px] rounded-md text-white hover:bg-darkgreen px-4 text-sm xxl:text-lg' onClick={handleSubmit}>Submit</button>
                        </div>
                        

                    </div>
                    <div className='grid  sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xxl:grid-cols-5 mt-4'>
                    {renderPhotos(selectedImages)}
              </div>

     </div>
   </div>
</div>
  )
}

export default ScaffoldingInspections