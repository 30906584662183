import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchSingleAdaption, fetchSingleJob } from "../store/adminSlice";

import { useMediaQuery } from "@mui/material";

import $ from "jquery";
import URL from "../functions/URLString";
import Logo from "../components/Logo";
import { useNavigate } from "react-router-dom";
$.DataTable = require("datatables.net");

function ViewAdaption() {
  const isNonMobile = useMediaQuery("(min-width: 768px)");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [needed, setNeeded] = useState(false);
  const adminID = useSelector((state) => state.admin._id);

  const queryParameters = new URLSearchParams(window.location.search);
  const jobID = queryParameters.get("jobID");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const singleJob = useSelector((state) => state.admin.singleAdaption);

  const print = () => {
    window.print();
  };
  useEffect(() => {
    if (!isNonMobile) {
      setSidebarOpen(false);
    }
    dispatch(fetchSingleAdaption({ adminID: adminID, adaptionID: jobID }));
  }, []);

  return (
    <div className="w-full h-full">
      {/* <HeaderScaff setSidebarOpen={setSidebarOpen} /> */}
      <div className="pt-4 flex justify-between">
        <div className="flex flex-col  p-5">
          
            <div className="ml-4 whitespace-nowrap  text-[8px] lg:text-lg">
              {JSON.stringify(singleJob) !== "{}" &&
                singleJob.customerID.customerCompanyName}
            </div>
            <div className="ml-4  ">
              <span className=" text-[8px] lg:text-lg">
                {JSON.stringify(singleJob) !== "{}" &&
                  singleJob.jobID.job1 + " " + singleJob.jobID.job2}
              </span>
            </div>
          </div>
          
          <Logo />
        </div>
        <div
          className={`${
            sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
          } 'w-full flex h-auto p-2 sm:p-6'`}
        >
          {/* <div className={`${sidebarOpen? isNonMobile? "basis-1/5":"flex justify-center w-full" : "hidden"} "w-full h-full"`}>
        <Sidebar setSidebarOpen={setSidebarOpen} needed={needed} isNonMobile={isNonMobile}/>
      </div> */}

          {/* <div onClick={() => setSidebarOpen(true)} className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "flex w-12 mt-4":"flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center") } h-fit`}>
        <div className={`${sidebarOpen?(isNonMobile? "": ""):(isNonMobile? "":"hidden") } `}>
            <IconButton onClick={() => setSidebarOpen(true)}>
            <ArrowForwardIosIcon/>
            </IconButton>
        </div>
        <div className={`${sidebarOpen?(isNonMobile? "hidden": "hidden"):(isNonMobile? "hidden":"flex") } `}>
            <p className='text-gray-500 text-xs py-[2px]'>Sidebar</p>
        </div>
      </div> */}

          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide"
                  : "hidden"
                : "w-full overflow-x-scroll scrollbar-hide"
            } " "`}
          >
            <div className="flex  mb-4 items-center px-8">
              <div className="mr-4  text-[8px] lg:text-lg">
                {JSON.stringify(singleJob) !== "{}" &&
                  singleJob.jobID.jobDescription +
                    " " +
                    singleJob.jobID.jobDetails}
              </div>
            </div>
       
          <div className="grid grid-cols-2 gap-5 px-12">
            {JSON.stringify(singleJob) !== "{}" &&
              singleJob.images.map((img) => {
                return (
                  <div>
                    <img src={`${URL}/uploads/${img}`} alt="img" />
                  </div>
                );
              })}
          </div>
          <div className="flex justify-end items-end mt-4">
            <button
              id="abw"
              className="h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
              onClick={() => navigate("/joblist")}
            >
              back
            </button>
            <button
              id="abw"
              className="h-8 mr-4 w-24 xxl:w-32 xxl:h-10 xxl:text-xl xxl:px-8 text-center rounded-md flex items-center px-3 sm:px-6 text-white cursor-pointer bg-lightgreen"
              onClick={() => print()}
            >
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAdaption;
