import React, { useEffect, useState } from 'react'
import logo from '../pictures/TheBestescafflogo.png'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { approveEscaffQoutes, approvetemproryroofqoute,fetchCompanyDetails, customerActions, declineEscaffQoutes, fetchCustomerPendingQoutes, declinetemproryroofqoute, fetchPriceList, fetchCustomerPendingAdaptionQoutes, declineEscaffAdaptionQoutes, declinetemproryroofAdaptionqoute, approveEscaffAdaptionQoutes, approvetemproryroofAdaptionqoute, fetchCustomerAllQoutes, fetchCustomerAllAdaptionQoutes } from '../store/customerSlice';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CustomerFourButtonsTwo from '../components/CustomerFourButtonsTwo';
import CustomerSidebar from '../components/CustomerSidebar';
import CustomerLogo from '../components/CustomerLogo';
import EditIcon from '@mui/icons-material/Edit';
import axios from "../functions/axios"
import { saveAs } from 'file-saver';


function CustomerPendingQoutes() {
  const [needed, setNeeded] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const statePendingQoutes = useSelector((state) => state.customer.customerpendingQoutes)
  const customerpendingAdaptionQoutes = useSelector((state) => state.customer.customerpendingAdaptionQoutes)
  const customerAllQoutes = useSelector((state) => state.customer.customerAllQoutes)
  const customerAllAdaptions = useSelector((state) => state.customer.customerAllAdaptions)
  const _id = useSelector((state) => state.customer.customer_id)
  const adminID = useSelector((state) => state.customer.adminID)
  const statePriceList = useSelector((state) => state.customer.priceList)
  const statecompanyDetails = useSelector((state) => state.customer.companyDetails)

  useEffect(() => {
    dispatch(fetchCustomerPendingQoutes({customerID:_id}))
    dispatch(fetchCustomerPendingAdaptionQoutes({customerID:_id}))
    dispatch(fetchCustomerAllQoutes({customerID:_id}))
    dispatch(fetchCustomerAllAdaptionQoutes({customerID:_id}))
    dispatch(customerActions.removeSwalAlert());
    dispatch(fetchPriceList({adminID:adminID,customerID:_id}))

    dispatch(fetchCompanyDetails({adminID:adminID,customerID:_id}))
  }, [])
  useEffect(() => {
    // console.log(statePendingQoutes);
    
  }, [statePendingQoutes,customerAllQoutes,customerAllAdaptions])
  const handleTemprory = (_id, b, c, d, f) => {
    console.log(f)
    var x = 0
    var y = 0;
    if (d === undefined) {
      y=0
    } else {
      y=f
    }
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
  axios.post("/admin/fetchsingletemproryroofqoute", { _id: _id }).then((res) => {
    axios.post("/admin/fetchsingletemproryroofqadaptionqoute", { _id: x }).then((ress) => {
      axios.post("customer/fetchcompanydetails", { customerID:d,adminID: c },{
        headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
            // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
        }
      }).then((resss) => {
        axios.post("customer/fetchsingleadaption", { adaptionID:y,customerID:d },{
          headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
          }
        }).then((ressss) => {
        const data = { objects: [res.data, statePriceList,ress.data,resss.data,ressss.data] };
        axios.post('/createtemproryroofqoutationpdf', data)
          .then(() => axios.get('/fetchfile',
 
            {
              headers: {
                'Content-type': 'application/json',
              },
              responseType: 'blob'
            }

          ))
      
          .then((res) => {
            // console.log(res.data);
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            // console.log(JSON.stringify(pdfBlob));
            saveAs(pdfBlob, "new.pdf");
       
          })
        })
        })
      })})
  
  
}
  const handleEscaff = (_id, b, c, d, f) => {
    console.log(f)
    var y = 0;
    if (f === undefined) {
      y=0
    } else {
      y=f
    }
    var x=0
    if (b === undefined) {
      x=0
    } else {
       x=b._id
  }
  axios.post("/admin/fetchsingleescaffqoute", { _id: _id }).then((res) => {
    axios.post("/admin/fetchsingleescaffadaptionqoute", { _id: x }).then((ress) => {
      axios.post("customer/fetchcompanydetails", {customerID:d, adminID: c },{
        headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
            // authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05MrAEgHblyOkssyHLXlqOTVqnpfH6lvhj',
        }
      }).then((resss) => {
        axios.post("customer/fetchsingleadaption", { adaptionID:y,customerID:d},{
          headers:{
            authorization: 'arham BnD3Tb4QN4xGzX85XQNH252qvb07K05fnakjfnk12334886lvhj',
          }
        }).then((ressss) => {
        const data = { objects: [res.data, statePriceList,ress.data,resss.data,ressss.data] };
    
      axios.post('/createescaffqoutationpdf', data)
        .then(() => axios.get('/fetchfile',
 
          {
            headers: {
              'Content-type': 'application/json',
            },
            responseType: 'blob'
          }

        ))
      
        .then((res) => {
          // console.log(res.data);
          const pdfBlob = new Blob([res.data], { type: "application/pdf" })
          // console.log(JSON.stringify(pdfBlob));
          saveAs(pdfBlob, "new.pdf");
       
        })
      })
      })
    })})
  
  
}
  
  const approveQoute = (type, _id,customerID) => {
    console.log(_id);
    if (type === "escaffQoute") {
      dispatch(approveEscaffQoutes({_id:_id,customerID}))
      
    } else if (type === "temproryRoofQoute") {
      dispatch(approvetemproryroofqoute({_id:_id,customerID}))
      
    }
    
  }
  const declineQoute = (type, _id,customerID) => {
    console.log(_id);
    if (type === "escaffQoute") {
      dispatch(declineEscaffQoutes({_id:_id,customerID}))
      
    } else if (type === "temproryRoofQoute") {
      dispatch(declinetemproryroofqoute({_id:_id,customerID}))
      
    }
  }
  const approveAdaptionQoute = (type, _id,customerID) => {
    console.log(_id);
    if (type === "escaffQoute") {
      dispatch(approveEscaffAdaptionQoutes({_id:_id,customerID}))
      
    } else if (type === "temproryRoofQoute") {
      dispatch(approvetemproryroofAdaptionqoute({_id:_id,customerID}))
      
    }
    
  }
  const declineAdaptionQoute = (type, _id,customerID) => {
    console.log(_id);
    if (type === "escaffQoute") {
      dispatch(declineEscaffAdaptionQoutes({_id:_id,customerID}))
      
    } else if (type === "temproryRoofQoute") {
      dispatch(declinetemproryroofAdaptionqoute({_id:_id,customerID}))
      
    }
  }

  return (
    <div className='w-full h-full'>
      <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24 '>
        {/* <img className='w-38 h-14 md:w-[280px] md:h-[100px] xxl:w-[390px] xxl:h-[150px] mb-2 object-contain' src={logo} alt="logo" /> */}
        <CustomerLogo />
        <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
          <CustomerFourButtonsTwo/>
        </div>
   </header>
   <div className='w-full flex h-auto p-6'>
        <CustomerSidebar needed={needed} />
        <div className='basis-4/5 flex flex-col'>

       <div className='w-full '>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Pending Project Quotes</p>
         
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '>
             <thead className='w-full'>
                <tr className='bg-gradient-to-b  from-lightgreen to-darkgreen'>
                <th className='whitespace-nowrap mr-2 text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>

             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Job Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Erection Date</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Project Cost</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
                  {statePendingQoutes.map((data) => {
                    const adaptionFound = customerAllAdaptions.find((item) => data._id === item._id)
                    var adaptionQouteId = 0
                 
                  
                    if (adaptionFound !== undefined) {
                   
                      adaptionQouteId=adaptionFound._id
                    }
                    

             return (

             <tr className=''>
             <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
                 {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.joobID.jobDescription}</td> */}
             <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobID.job1 + " "+data.jobID.job2 }</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.dateOfErection}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'><i className='z-10 relative pr-1 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>{parseFloat(data.totalCostResult).toFixed(2)}</td>             <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
               {data.type==="temproryRoofQoute"? <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescafftemproofqoutationpdf?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                     </div> :
                       <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescaffscaffolldingqoutation?escaffqouteid=${data._id}&adaptionquoteid=${adaptionQouteId}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>}  
                  <div onClick="" className='flex flex-col items-center'>
                    <DoneIcon sx={{width:'17px',height:"17px"}} onClick={(e)=>approveQoute(data.type,data._id,data.customerID._id)} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Approve</p>
                  </div>
                  <div className='flex flex-col items-center' onClick={(e)=>declineQoute(data.type,data._id,data.customerID._id)}>
                   <CloseIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Decline</p>
                  </div>
                </div>
             </td>
             </tr>
             )})}
</tbody>

</table>
         
</div>
              
          </div>
       <div className='lg:basis-3/4 w-full '>
       <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2 mt-4 mb-3'>Pending Adaption Quotes</p>
         
         <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
           <table className='editor_listing_table '>
             <thead className='w-full'>
                <tr className='bg-gradient-to-b  from-lightgreen to-darkgreen'>
                <th className='whitespace-nowrap mr-2 text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>

             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Job Address</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Erection Date</th>
             <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap '>Project Cost</th>
             <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg '>Action</th>
           </tr>
           </thead>
           <div className='h-2'></div>
             <tbody className=' '>
                  {customerpendingAdaptionQoutes.map((data) => {
                    const adaptionFound = customerAllQoutes.find((item) => item._id === data.adaptionID.qouteID)
                    var adaptionQouteId = 0
                  if (adaptionFound !== undefined) {
                 
                    adaptionQouteId=adaptionFound._id
                  }
                
                    
             return (

             <tr className=''>
             <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.customerID.customerCompanyName}</td>
                 {/* <td className='w-1/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.joobID.jobDescription}</td> */}
             <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm whitespace-nowrap'>{data.jobID.job1 + " "+data.jobID.job2 }</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.dateOfErection}</td>
             <td className='w-2/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'><i className='z-10 relative pr-1 -mt-6 xxl:-mt-8 xxl:pb-2 text-gray-400'>£</i>{parseFloat(data.totalCostResult).toFixed(2)}</td>             <td className=' w-1/12 border-[1px] border-gray-300 p-1'>
               <div className='flex flex-row justify-between space-x-2 xxl:space-x-5 px-4'>
               {data.type==="temproryRoofQoute"? <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescafftemproofqoutationpdf?escaffqouteid=${adaptionQouteId}&adaptionquoteid=${data._id}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                     </div> :
                       <div className='flex flex-col items-center' onClick={(e)=>navigate(`/customerescaffscaffolldingqoutation?escaffqouteid=${adaptionQouteId}&adaptionquoteid=${data._id}`)}>
                 <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                </div>}   
                  <div onClick="" className='flex flex-col items-center'>
                    <DoneIcon sx={{width:'17px',height:"17px"}} onClick={(e)=>approveAdaptionQoute(data.type,data._id,data.customerID._id)} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Approve</p>
                  </div>
                  <div className='flex flex-col items-center' onClick={(e)=>declineAdaptionQoute(data.type,data._id,data.customerID._id)}>
                   <CloseIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Decline</p>
                  </div>
                </div>
             </td>
             </tr>
             )})}
</tbody>

</table>
         
</div>
              <p className=' text-sm pl-2 mt-2'>
                Welcome to your scaffolding management area-here you can view your quotation, approve the quotation and decline it.<br/>
                To view your quotation view the Quote button in the Action Tab <br/>
                To approve your quote click the Approve button in the Action Tab ,this will then go directly to your scaffolder to be scheduled into their program of works.<br/>
                You can view the list of your approved jobs from the menu above <br/>
                If you choose to decline a quote please check with your scaffolder that they have the correct details of the project as mistakes can be rectified <br/>
              </p>
              <p className='text-sm font-semibold my-2 pl-2'>
                Your Scaffolder direct number is : {statecompanyDetails.telephone}
              </p>
          </div>
          </div>    
   </div>
</div>
  )
}

export default CustomerPendingQoutes