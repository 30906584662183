 import { Route, Routes, useNavigate } from "react-router-dom";
import Main from "./pages/Main";
import MobileAdmin from "./pages/MobileAdmin";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import SubscriptionPage from "./pages/SubscriptionPage";
import Payment from "./pages/Payment";
import StaffMember from "./pages/StaffMember";
import CreateNewCustomer from "./pages/CreateNewCustomer";
import PendingProjectQoutes from "./pages/PendingProjectQuotes";
import QuotesDecline from "./pages/QuotesDeclined";
import QoutesApproved from "./pages/QuotesApproved";
import PriceList from "./pages/PriceList";
import LiveProjects from "./pages/LiveProjects";
import OverHiredProjects from "./pages/OverHiredProjects";
import CreateQuotation from "./pages/CreateQuotation";
import React, { useState } from "react";
import { Switch } from '@mui/material';
import ApprovedYourQoutes from "./pages/ApprovedYourQoutes";
import ScaffoldInspectionList from "./pages/ScaffoldInspectionList";
import ScaffoldInspectionComplete from "./pages/ScaffoldInspectionComplete";
import AddANewJob from "./pages/AddANewJob";
import TemporaryRoofQuotes from "./pages/TemporaryRoofQuotes";
import ScaffoldingInspections from "./pages/ScaffoldingInspections";
import QuotationPDF from "./pages/QuotationPDF";
import AdminUpload from "./pages/AdminUpload";
import CustomerPendingQoutes from "./pages/CustomerPendingQoutes";
import CustomerAddANewJob from "./pages/CustomerAddANewJob";
import CustomerLiveProjects from "./pages/CustomerInspectionList";
import CusotmerQuotesApproved from "./pages/CusotmerQuotesApproved";
import CompanyDetails from "./pages/CustomerCompanyDetails";
import CustomerInspectionList from "./pages/CustomerInspectionList";
import Form from "./pages/Form";
// import StaffLogin from "./pages/StaffLogin";
// import StaffJobs from "./pages/StaffJob";
import StaffJobList from "./pages/StaffJobList";
import StaffJob from "./pages/StaffJob";
import HealthAndsafety from "./pages/HealthAndSafety";
import VechicleCheck from "./pages/VechicleCheck";
import AddANewVechile from "./pages/AddANewVechile";
import VechicleInspection from "./pages/VechicleInspection";
import ViewVechicleInspectionList from "./pages/ViewVechicleInspectionList";
import Dismantle from "./pages/Dismantle";
import AllScaffolldingInspections from "./pages/AllScaffolldingInspections";
import AddAdaption from "./pages/AddAdaption";
import CreateAdaptionQuotation from "./pages/CreateAdaptionQuotation";
import TemporaryRoofAdaptionQuotes from "./pages/TemporaryRoofAdaptionQuotes";
import AdaptionScaffoldingInspections from "./pages/AdaptionScaffoldingInspections";
import FormAdaption from "./pages/FormAdaption";
import StaffAdaption from "./pages/StaffAdaption";
import TermsAndConditions from "./pages/TermsAndConditions";
import StaffDismantle from "./pages/StaffDismantle";
import EscaffScaffolldingQoutation from "./pages/EscaffScaffolldingQoutation";
import TemproryQoutationPDF from "./pages/TemproryQoutationPDF";
import CustomerEscaffScaffolldingQoutation from "./pages/CustomerEscaffScaffolldingQoutation";
import CustomerTemproryQoutationPDF from "./pages/CustomerTemproryQoutationPDF";
import ViewCreateQuotation from "./pages/ViewCreateQuotation";
import JobList from "./pages/JobList";
import ViewJob from "./pages/ViewJob";
import ViewAdaption from "./pages/ViewAdaption";
import MainVersionTwo from "./pages/MainVersionTwo";
import PrintScaffoldingInspections from "./pages/PrintScaffoldingInspections";
import ArchivePendingProjectQuotes from "./pages/ArchivePendingProjectQuotes";
import ViewHandOver from "./pages/ViewHandOver";
import StaffForm from "./pages/StaffForm";
import StaffFormAdaption from "./pages/StaffFormAdaption";
import ViewForm from "./pages/ViewForm";

function App() {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setToggle(event.target.checked)
  }

  return (
    // <div className="w-full h-full flex justify-center m-0 p-0 items-center">
      <div className="w-full h-full flex justify-center m-0 p-0 items-center">

        
        <Routes>
            <Route path="/" element={<MainVersionTwo />} />
            <Route path="/termsandconditons" element={<TermsAndConditions />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/pricelist" element={<PriceList />} />
            <Route path="/liveprojects" element={<LiveProjects />} />

            <Route path="/adminupload" element={<AdminUpload />} />
            <Route path="/createnewcustomer" element={<CreateNewCustomer />} />
            <Route path="/createquotation" element={<CreateQuotation />} />
            <Route path="/staffmember" element={<StaffMember/>} />
            <Route path="/overhiredprojects" element={<OverHiredProjects />} />
            <Route path="/temporaryroofquotes" element={<TemporaryRoofQuotes />} />
            <Route path="/scaffoldinginspections" element={<ScaffoldingInspections />} />
            <Route path="/quotationpdf" element={<QuotationPDF />} />

            <Route path="/mobileadmin" element={<MobileAdmin />} />
            <Route path="/pendingprojectqoutes" element={<PendingProjectQoutes />} />
            {/* <Route path="/customerpendingquotes" element={<CustomerPendingQuotes />} /> */}
            <Route path="/quotesdeclined" element={<QuotesDecline />} />
            <Route path="/quotesapproved" element={<QoutesApproved />} />
            <Route path="/scaffoldinspectionlist" element={<PrintScaffoldingInspections />} />
            <Route path="/scaffoldinspectioncomplete" element={<ScaffoldInspectionComplete />} />
            <Route path="/approveyourqoutes" element={<ApprovedYourQoutes />} />
            <Route path="/addanewjob" element={<AddANewJob />} />

            <Route path="/customerpendingqoutes" element={<CustomerPendingQoutes />} />
            <Route path="/customeraddanewjob" element={<CustomerAddANewJob />} />
            <Route path="/customerliveprojects" element={<CustomerLiveProjects />} />
            <Route path="/cusotmerquotesapproved" element={<CusotmerQuotesApproved />} />
            <Route path="/customerinspectionlist" element={<CustomerInspectionList />} />
            <Route path="/customercompanydetails" element={<CompanyDetails />} />
            <Route path="/form" element={<Form />} />

            {/* <Route path="/stafflogin" element={<StaffLogin />} /> */}
            <Route path="/staffjob" element={<StaffJob />} />
            <Route path="/staffadaption" element={<StaffAdaption />} />
            <Route path="/staffjoblist" element={<StaffJobList />} />
            <Route path="/healthandsafety" element={<HealthAndsafety />} />
            <Route path="/vechicleCheck" element={<VechicleCheck />} />
            <Route path="/addanewvechile" element={<AddANewVechile/>} />
            <Route path="/vechicleinspection" element={<VechicleInspection/>} />
            <Route path="/viewvechiclevnspectionlist" element={<ViewVechicleInspectionList/>} />
            <Route path="/dismantle" element={<Dismantle/>} />
            <Route path="/allscaffoldingInspections" element={<AllScaffolldingInspections/>} />
            <Route path="/addadaption" element={<AddAdaption/>} />
            <Route path="/createadaptionqoutation" element={<CreateAdaptionQuotation/>} />
            <Route path="/temporaryroofadaptionQuotes" element={<TemporaryRoofAdaptionQuotes/>} />
            <Route path="/scaffoldinginspectionsadaption" element={<AdaptionScaffoldingInspections/>} />
            <Route path="/adaptionhandover" element={<FormAdaption/>} />
            <Route path="/staffdismantle" element={<StaffDismantle/>} />
            <Route path="/escaffscaffolldingqoutation" element={<EscaffScaffolldingQoutation/>} />
            <Route path="/customerescaffscaffolldingqoutation" element={<CustomerEscaffScaffolldingQoutation/>} />
            <Route path="/escafftemproofqoutationpdf" element={<TemproryQoutationPDF/>} />
            <Route path="/customerescafftemproofqoutationpdf" element={<CustomerTemproryQoutationPDF/>} />
            <Route path="/viewcreatequotation" element={<ViewCreateQuotation/>} />
            <Route path="/joblist" element={<JobList/>} />
            <Route path="/viewjob" element={<ViewJob/>} />
            <Route path="/viewadaption" element={<ViewAdaption/>} />
            <Route path="/printallinspections" element={<PrintScaffoldingInspections/>} />
            <Route path="/archivependingprojectquotes" element={<ArchivePendingProjectQuotes/>} />
            <Route path="/viewhandover" element={<ViewHandOver/>} />
            <Route path="/staffform" element={<StaffForm/>} />
            <Route path="/staffformadaption" element={<StaffFormAdaption/>} />
            <Route path="/viewhandoverform" element={<ViewForm/>} />
          </Routes>

      </div>
    // </div>
  );
}

export default App;
