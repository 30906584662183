
function escapeHtml(str) {
    return str
        .replace(/&/g, "")
        .replace(/</g, "")
        .replace(/>/g, "")
        .replace(/"/g, "")
        .replace(/'/g, "")
        .replace(/\/+$/, "")
        .replace(String.fromCharCode(92),"");
  };

export default escapeHtml;

