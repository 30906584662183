import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CustomerFourButtonsTwo from '../components/CustomerFourButtonsTwo';
import CustomerSidebar from '../components/CustomerSidebar';
import CustomerLogo from '../components/CustomerLogo';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerAdaptionInspectionList, fetchCustomerInspectionList } from '../store/customerSlice';
import axios from "../functions/axios"
import { saveAs } from 'file-saver';
import EditIcon from '@mui/icons-material/Edit';

const handleHandOver = (_id) => {
  console.log("here")
  axios.post("/customer/fetchsingleinspection", { _id: _id }).then((res) => {
      console.log(res.data)
      axios.post('/creatinspectionTemplate',res.data)
      .then(() => axios.get('/fetchfile',
        {
          headers: {
            'Content-type': 'application/json',
          },
          responseType: 'blob'
        }
      ))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "new.pdf");
      })
  })
  
  
}
const handleAdaptionHandOver = (_id) => {
 
  console.log("here")
  axios.post("/customer/fetchsingleadaptioninspection", { _id: _id }).then((res) => {
      console.log(res.data)
      axios.post('/creatinspectionTemplate',res.data)
      .then(() => axios.get('/fetchfile',
        {
          headers: {
            'Content-type': 'application/json',
          },
          responseType: 'blob'
        }
      ))
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "new.pdf");
      })
  })
  
  
}

function CustomerInspectionList() {
  const [ sidebarOpen, setSidebarOpen ] = useState(true);
  const [ needed, setNeeded ] = useState(true);
  const stateCustomerID = useSelector((state) => state.customer.customer_id)
  const inspectionList = useSelector((state) => state.customer.customerInspection)
  const customerAdaptionInspection = useSelector((state) => state.customer.customerAdaptionInspection)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomerInspectionList({customerID:stateCustomerID}))
    dispatch(fetchCustomerAdaptionInspectionList({customerID:stateCustomerID}))
  
  }, [])
  

    return (
        <div className='w-full h-full'>
        <header className='border-b-2 w-full pt-3 xxl:pt-8 px-8 sm:px-24'>
        <CustomerLogo />
          <div className='w-full flex justify-center md:justify-end px-2 sm:px-2 xl:pr-12 mt-2 lg:-mt-5 xxl:-mt-8'>
              <CustomerFourButtonsTwo />
            </div>
        </header>
       <div className='w-full flex h-auto p-6'>
          <div className={`${sidebarOpen? "basis-1/5" : "hidden"} "w-full h-full"`}>
            <CustomerSidebar setSidebarOpen={setSidebarOpen} needed={needed}/>
          </div>
            <div className='flex flex-col w-full'>
           <div className="lg:basis-3/4 w-full">
            <div className='w-full flex justify-between mt-4'>
            <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2'>Scaffold inspection list</p>
                {/* <div className='flex space-x-4 items-center'>
                    <p className='text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap'>Search for a customer</p>
                    <div className='flex items-center'>
                        <SearchIcon sx={{width:'17px',height:"17px"}} className="-mr-5 relative left-0 text-lightgreen"/>
                        <input type="text" className='outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen'/>
                    </div>
                </div> */}
            </div>
             
            <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
               <table className='editor_listing_table '>
                 <thead className='w-full'>
                 <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                 <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>
                
                 <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap'>Job Address</th>
                 <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap'> Inspection Date</th>
          
                 <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg whitespace-nowrap'>Action</th>
               </tr>
               </thead>
               <div className='h-2'></div>
                 <tbody className=' '>
               {inspectionList.map((data) => {
                 return (
    
                 <tr className=''>
                 <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data.administering}</td>
                 <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data.siteAddress}</td>
                 <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.todayDate}</td>
                
                 <td className=' w-2/12 border-t-[1px] border-r-[1px] border-b-[1px] border-gray-300 p-1'>
                   <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                   <div onClick={(e)=>handleHandOver(data._id)} className='flex flex-col items-center'>
                      {/* <InsertDriveFileOutlinedIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Quote</p> */}
                      <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                    </div>
                                        
                   </div>
                 </td>
                 </tr>
                 )})}
    </tbody>
    
    </table>
             
    </div>
            </div>
            <div className="lg:basis-3/4 w-full">
              <div className='w-full flex justify-between mt-4'>
              <p className='text-lg xxl:text-2xl text-slate-400 pb-1 ml-2'>Adaption inspection list</p>
                  {/* <div className='flex space-x-4 items-center'>
                      <p className='text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap'>Search for a customer</p>
                      <div className='flex items-center'>
                          <SearchIcon sx={{width:'17px',height:"17px"}} className="-mr-5 relative left-0 text-lightgreen"/>
                          <input type="text" className='outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen'/>
                      </div>
                  </div> */}
              </div>
              
              <div className='lg:w-full overflow-x-auto lg:overflow-x-hidden pr-1 '>
                <table className='editor_listing_table '>
                  <thead className='w-full'>
                  <tr className='bg-gradient-to-b from-lightgreen to-darkgreen'>
                  <th className='whitespace-nowrap text-start px-2 py-1 text-sm xxl:text-base text-white rounded-l-md font-normal border-[1px] border-r-white'>Name</th>
                  
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap'>Job Address</th>
                  <th className='text-start px-2 py-1 text-sm xxl:text-base text-white font-normal border-[1px] border-r-white whitespace-nowrap'> Inspection Date</th>
            
                  <th className='  text-sm xxl:text-base text-white font-normal rounded-r-lg whitespace-nowrap'>Action</th>
                </tr>
                </thead>
                <div className='h-2'></div>
                  <tbody className=' '>
                {customerAdaptionInspection.map((data) => {
                  return (
      
                  <tr className=''>
                  <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data.administering}</td>
                  <td className='w-3/12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm text-center'>{data.siteAddress}</td>
                  <td className='w-1 /12 border-[1px] border-gray-300 pl-3 p-1 text-[11px] xxl:text-sm'>{data.todayDate}</td>
                  
                  <td className=' w-2/12 border-t-[1px] border-r-[1px] border-b-[1px] border-gray-300 p-1'>
                    <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                    <div onClick={(e)=>handleAdaptionHandOver(data._id)} className='flex flex-col items-center'>
                        {/* <InsertDriveFileOutlinedIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Quote</p> */}
                        <EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                      </div>
                                          
                    </div>
                  </td>
                  </tr>
                  )})}
      </tbody>
      
      </table>
              
      </div>
              </div>
            </div>
       </div>
    </div>
      )
    }

export default CustomerInspectionList