
  import React, { useEffect, useState } from "react";
  import Sidebar from "../components/Sidebar";
  import { useNavigate } from "react-router-dom";
  import SearchIcon from "@mui/icons-material/Search";
  import IconButton from "@mui/material/IconButton";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import Logo from "../components/Logo";
  import { useDispatch, useSelector } from "react-redux";
  import { fetchAllScaffolldingInspections, fetchVechicleInspectionList, signOut } from "../store/adminSlice";
  import HeaderScaff from "../components/HeaderScaff";
  import { useMediaQuery } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';

import moment from "moment";
import axios from "../functions/axios"
import { saveAs } from 'file-saver';


  import $  from 'jquery'
  $.DataTable = require('datatables.net')
  
  function AllScaffolldingInspections() {
    const isNonMobile = useMediaQuery("(min-width: 768px)");
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [needed, setNeeded] = useState(true);
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const _id = useSelector((state) => state.admin._id);
    const subscribed = useSelector((state) => state.admin.subscribed);
    const vechicleInspectionList = useSelector(
      (state) => state.admin.listOfScaffoldingInspection
    );
    const statesignOut=useSelector((state)=>state.admin.signOut)
    const handleHandOver = (_id) => {
      console.log("there")
      axios.post("/customer/fetchsingleinspection", { _id: _id }).then((res) => {
          console.log(res.data)
          axios.post('/creatinspectionTemplate',res.data)
          .then(() => axios.get('/fetchfile',
            {
              headers: {
                'Content-type': 'application/json',
              },
              responseType: 'blob'
            }
          ))
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            saveAs(pdfBlob, "new.pdf");
          })
      })
      
      
    }
    const handleHandOverr = (_id) => {
      console.log("here")
      axios.post("/customer/fetchsingleadaptioninspection", { _id: _id }).then((res) => {
          console.log(res.data)
          axios.post('/creatinspectionTemplate',res.data)
          .then(() => axios.get('/fetchfile',
            {
              headers: {
                'Content-type': 'application/json',
              },
              responseType: 'blob'
            }
          ))
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" })
            saveAs(pdfBlob, "new.pdf");
          })
      })
      
      
    }
    useEffect(() => {
      if (statesignOut==="signOut") {
          dispatch(signOut())
      }
  },[statesignOut])
    useEffect(() => {
      if (!_id) {
        navigate("/signin");
      } else if (!subscribed) {
        navigate("/signin");
      }
      if (!isNonMobile) {
        setSidebarOpen(false);
      }
      console.log(vechicleInspectionList);
    }, [vechicleInspectionList]);
  
    useEffect(() => {
      dispatch(fetchAllScaffolldingInspections({ adminID: _id }));
      setTimeout(() => {
        $('#table').DataTable({ pagingType: 'simple_numbers',retrieve: true,});
        
      }, 5000);
    }, []);
  
    return (
      <div className="w-full h-full">
        <HeaderScaff setSidebarOpen={setSidebarOpen} />
        <div
          className={`${
            sidebarOpen ? "" : isNonMobile ? "" : "flex-col"
          } 'w-full flex h-auto p-2 sm:p-6'`}
        >
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "basis-1/5"
                  : "flex justify-center w-full"
                : "hidden"
            } "w-full h-full"`}
          >
            <Sidebar
              setSidebarOpen={setSidebarOpen}
              needed={needed}
              isNonMobile={isNonMobile}
            />
          </div>
  
          <div
            onClick={() => setSidebarOpen(true)}
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "hidden"
                  : "hidden"
                : isNonMobile
                ? "flex w-12 mt-4"
                : "flex border-2 border-x-0 bg-gray-100/50 mt-2 -mb-1 w-full items-center justify-center"
            } h-fit`}
          >
            <div
              className={`${
                sidebarOpen
                  ? isNonMobile
                    ? ""
                    : ""
                  : isNonMobile
                  ? ""
                  : "hidden"
              } `}
            >
              <IconButton onClick={() => setSidebarOpen(true)}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <div
              className={`${
                sidebarOpen
                  ? isNonMobile
                    ? "hidden"
                    : "hidden"
                  : isNonMobile
                  ? "hidden"
                  : "flex"
              } `}
            >
              <p className="text-gray-500 text-xs py-[2px]">Sidebar</p>
            </div>
          </div>
  
          <div
            className={`${
              sidebarOpen
                ? isNonMobile
                  ? "lg:basis-3/4 w-full overflow-x-scroll scrollbar-hide"
                  : "hidden"
                : "w-full overflow-x-scroll scrollbar-hide"
            } " "`}
          >
            <div className="w-full flex justify-between mt-4 ">
              <div className="flex space-x-2">
                <p className="text-base xxl:text-2xl text-slate-400 pb-1 ml-2 hover:text-black cursor-pointer whitespace-nowrap">
                  All Scaffolding Inspections
                </p>
              </div>
              {/* <div className="flex space-x-4 items-center">
                <p className="text-xs xxl:text-base text-slate-700 ml-2 whitespace-nowrap">
                  Search for a customer
                </p>
                <div className="flex items-center">
                  <SearchIcon
                    sx={{ width: "17px", height: "17px" }}
                    className="-mr-5 relative left-0 text-lightgreen"
                  />
                  <input
                    type="text"
                    className="outline-none border-[1px] pl-5 h-6 xxl:h-7 rounded-md border-lightgreen"
                  />
                </div>
              </div> */}
            </div>
  
            <div className="lg:w-full pr-1">
              <table className="editor_listing_table"     id="table">
                <thead className="w-full ">
                  <tr className="bg-gradient-to-b from-lightgreen to-darkgreen">
                    <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white rounded-l-md font-normal border-[1px] border-r-white">
                      Date
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                      Customer Or Company Name
                    </th>
                    <th className="text-start px-2 py-1 text-sm xxl:text-base whitespace-nowrap text-white font-normal border-[1px] border-r-white">
                      Job Address
                    </th>
                    
                    <th className="text-sm xxl:text-base text-white font-normal rounded-r-lg whitespace-nowrap">
                      View Inspections
                    </th>
                  </tr>
                </thead>
                <div className="h-2"></div>
                <tbody className=" ">
                  {vechicleInspectionList.map((data) => {
                    const date = moment(new Date(data.todayDate)).format('DD-MM-YYYY');
                    
  
                   return (
                      <tr className="">
                        <td className="w-1/5 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {date}
                        </td>
                        <td className="w-1/5 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.customerID.customerCompanyName}
                        </td>
                        <td className="w-1/5 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm text-center">
                          {data.jobID.job1+" "+data.jobID.job2}
                        </td>
                        <td className="w-1/5 border-[1px] border-gray-300 p-1 text-[11px] xxl:text-sm  text-center">
                        <div className='flex flex-row justify-center space-x-2 xxl:space-x-5 px-4'>
                   {data.adaptionID===undefined?(<div onClick={(e)=>handleHandOver(data._id)} className='flex flex-col items-center'><EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                    </div>):(<div onClick={(e)=>handleHandOverr(data._id)} className='flex flex-col items-center'><EditIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>View</p>
                    </div>)}
                      {/* <InsertDriveFileOutlinedIcon sx={{width:'17px',height:"17px"}} className="text-lightgreen hover:text-darkgreen cursor-pointer"/><p className='text-[10px] xxl:text-sm'>Quote</p> */}
                      
                                        
                   </div>
                        </td>
                       
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AllScaffolldingInspections;
  